import React, { FC } from 'react';

import cn from 'classnames';

import { ArrowButtonProps } from './types';
import { Icon } from 'components/Icon/Icon';

import styles from './ArrowButton.module.scss';

export const ArrowButton: FC<ArrowButtonProps> = (props) => {
    const { className, isActive, testId, onClick } = props;
    return (
        <button
            data-testid={`${testId}.arrowBtn`}
            className={cn(styles.button, styles[className], {
                [styles['button-inactive']]: !isActive,
            })}
            onClick={onClick}
        >
            <Icon
                data-testid={`${testId}.arrowBtnIcon`}
                type="arrowleft"
                className={cn(styles.arrow, styles[`${className}-arrow`], {
                    [styles['arrow-inactive']]: !isActive,
                })}
            />
        </button>
    );
};
