import React, { FC } from 'react';
import cn from 'classnames';

import { ResponsiveCell } from '../../../ResponsiveCell/ResponsiveCell';

import { TableRowProps } from '../../../../types';

import styles from './TableRowWithDetails.module.scss';

export const TableRowWithDetails: FC<TableRowProps> = (props) => {
    const {
        className = '',
        config,
        row,
        rowIndex = 0,
        columnDefs,
        isChildRow,
        onRowSelected = () => {},
        onRowExpanded = () => {},
    } = props;

    const onExpandRow = (rowH: any): void => {
        onRowExpanded(rowH);
    };

    return (
        <>
            <div
                data-testid="table-row-with-details"
                className={cn(
                    styles.row,
                    {
                        [styles['row-expanded']]: row.expanded,
                    },
                    styles[className],
                )}
            >
                {columnDefs.map((columnDef) => {
                    return (
                        <ResponsiveCell
                            parentRow={props.parentRow}
                            key={`${columnDef.field}-cell-${props.row.id}`}
                            config={config}
                            row={props.row}
                            rowIndex={rowIndex}
                            columnDef={columnDef}
                            isChildRow={isChildRow}
                            onExpandRow={() => onExpandRow(props.row)}
                            onSelectRow={() => onRowSelected(props.row)}
                        />
                    );
                })}
            </div>

            {config && config.withDetails && row.expanded && (
                <div className={cn(styles.details)}>
                    {config.canExpand &&
                        row[config.canExpand].map((childRow: any) => (
                            <TableRowWithDetails
                                data-testid={`table-row-with-details-${row.id}-child`}
                                parentRow={row}
                                key={`row-${row.id}-child`}
                                row={childRow}
                                rowIndex={rowIndex}
                                columnDefs={columnDefs}
                                config={config}
                                className="details-row"
                                isChildRow
                            />
                        ))}
                </div>
            )}
        </>
    );
};
