export enum QueryTypes {
    ProductBySource = 'ps',
    ProductByRetailer = 'pr',
    RetailerBySource = 'rs',
    RetailerByProduct = 'rp',
    SourceByRetailer = 'sr',
    SourceByProduct = 'sp',
    RetailerBySourceAndProduct = 'rsp',
    RetailerByProductAndSource = 'rps',
    ProductBySourceAndRetailer = 'psr',
    ProductByRetailerAndSource = 'prs',
    SourceByProductAndRetailer = 'spr',
    AllSources = 'allSources',
    AllProducts = 'allProducts',
}
