import { specialEntries } from 'shared/constants';

export const isItemSpecial = (id: string) => {
    const cornerCaseTableItemsIds = [
        specialEntries.tracksUnderOneCent.id,
        specialEntries.foreignRoyalties.id,
        specialEntries.adjustment.id,
    ];
    return cornerCaseTableItemsIds.includes(id);
};
