export const leftNavMenuItems = [
    {
        label: 'Dashboard',
        link: '/',
    },
    {
        label: 'Earnings',
        link: '/earnings',
    },
    {
        label: 'Data Sources',
        link: '/data-sources',
    },
    {
        label: 'Teams',
        link: '/teams',
    },
];
