import { useState } from 'react';
import { InputTeamMemberRoles } from 'gql/__generated__/types';
import { TeamTableItemsType, TeamTableItemType } from 'shared/types';
import { v4 as uuidv4 } from 'uuid';
import { MembersToUpdateRoleList } from './types';
import { validateEmail } from 'components/EarningsExportModal/utils/validateEmail';

export const useTeamMemberTableData = (tableItems: TeamTableItemsType) => {
    const [tableItemsData, setTableItemsData] = useState(tableItems);
    const [membersToUpdateRoleList, setMembersToUpdateRoleList] = useState<MembersToUpdateRoleList>([]);

    const addMember = () => {
        setTableItemsData([
            ...tableItemsData,
            {
                role: 'Viewer',
                isConfirmedInvitation: false,
                invitationEmail: '',
                id: uuidv4() as string,
                teamId: tableItemsData[0].teamId,
                newMember: true,
            },
        ]);
    };

    const changePermission = (rowId: string, value: string) => {
        const changedMember = tableItemsData.find((member) => member.id === rowId);

        if (changedMember && changedMember.userId && !changedMember.userToCreate && !changedMember.newMember) {
            const newMembersToUpdateRoleList = [...membersToUpdateRoleList].filter(
                (member) => member.userId !== changedMember.userId,
            );

            newMembersToUpdateRoleList.push({
                userId: changedMember.userId,
                role: value.toUpperCase() as InputTeamMemberRoles,
            });

            setMembersToUpdateRoleList(newMembersToUpdateRoleList);
        }

        setTableItemsData(
            [...tableItemsData].map((member) => {
                if (member.id === rowId) {
                    return { ...member, role: value };
                }
                return member;
            }),
        );
    };

    const validateTeamMembers = () => {
        let membersAreValid = true;
        setTableItemsData(
            [...tableItemsData].map((member) => {
                const error = validateEmail(member.invitationEmail || '');
                if (member.newMember && error.length) {
                    membersAreValid = false;
                    return { ...member, error: error };
                }
                return member;
            }),
        );

        return membersAreValid;
    };

    const deleteRow = (row: TeamTableItemType) => {
        const memberToDelete = tableItemsData.find((member) => member.id === row.id && !member.userToCreate);

        setMembersToUpdateRoleList(
            [...membersToUpdateRoleList].filter((member) => member.userId !== memberToDelete?.userId),
        );
        setTableItemsData([...tableItemsData].filter((member) => member.id != row.id));
    };

    const confirmMember = (user: { email: string; id?: string }, row?: { [key: string]: any }) => {
        setTableItemsData(
            [...tableItemsData].map((member) => {
                if (row && row.id && member.id === row.id) {
                    return {
                        ...member,
                        invitationEmail: user.email,
                        userToCreate: true,
                        id: user?.id || '',
                    };
                }
                return member;
            }),
        );
    };

    const changeMember = (value: string, rowId: string) => {
        setTableItemsData(
            [...tableItemsData].map((member) => {
                if (member.id === rowId) {
                    return { ...member, invitationEmail: value, error: '' };
                }
                return member;
            }),
        );
    };

    return {
        tableItemsData,
        setTableItemsData,
        changePermission,
        deleteRow,
        addMember,
        confirmMember,
        membersToUpdateRoleList,
        changeMember,
        validateTeamMembers,
    };
};
