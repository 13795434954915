import React, { FC } from 'react';
import { DataSourceListTable } from './components/DataSourceListTable/DataSourceListTable';
import { DataSourceMembersCellRenderer } from 'components/Table/components/CellRenderers/DataSourceMembersCellRenderer/DataSourceMembersCellRenderer';
import { DataSourceSelectRenderer } from 'components/Table/components/CellRenderers/DataSourceSelectRenderer/DataSourceSelectRenderer';
import { SharedColumnHeader } from './components/SharedColumnHeader/SharedColumnHeader';
import { Button } from 'components/Button/Button';
import styles from './DataSourceTeamList.module.scss';
import { DataSourceTeamListProps } from './types';
import { styleTypes } from 'shared/constants';
import { AddDataSourceText, cellClassNames, headerNames, DataSourceListHeading, fieldNames } from './constants';
import { ColumnDef } from 'components/Table/types';
import { useGetDropdownOptions } from './hooks/useGetDropdownOptions';

export const DataSourceTeamList: FC<DataSourceTeamListProps> = (props) => {
    const {
        testId,
        tableItems,
        onChange,
        onDeleteSource,
        onCheckMember,
        onAddDataSource,
        dataSourceDropdownData,
        isViewer,
        isEditor,
    } = props;

    const teamColumnDefs: ColumnDef[] = [
        {
            field: fieldNames.name,
            headerName: headerNames.dataSource,
            cellRenderer: DataSourceSelectRenderer,
            noLeftCellPadding: true,
            className: cellClassNames.half,
            suggestedUsersList: dataSourceDropdownData,
            onChange,
            isViewer: isViewer,
            isEditor: isEditor,
            tableItems,
        },
        {
            field: fieldNames.members,
            headerName: (
                <SharedColumnHeader testId={`${testId}.sharedColumnHeader`} onClick={() => {}}>
                    {headerNames.shared}
                </SharedColumnHeader>
            ),
            suggestedUsersList: dataSourceDropdownData,
            cellRenderer: DataSourceMembersCellRenderer,
            className: cellClassNames.half,
            noLeftCellPadding: true,
            onCheckMember,
            onDeleteSource,
            isViewer: isViewer,
            isEditor: isEditor,
            tableItems,
        },
    ];

    const dropdownOptions = useGetDropdownOptions(tableItems, dataSourceDropdownData);

    const isAnyDataSourcesWuthoutName = tableItems.length > 0 && tableItems.some((item) => !item.name);

    const isButtonDisabled = isAnyDataSourcesWuthoutName || !dropdownOptions.length;

    return (
        <>
            <div className={styles.heading}>{DataSourceListHeading}</div>
            <DataSourceListTable
                testId={`${testId}.dataSourceTable`}
                columnDefs={teamColumnDefs}
                tableItems={tableItems}
            />
            {!isViewer && (
                <Button
                    style={styleTypes.secondary}
                    className={styles.addMemberButton}
                    isLink={false}
                    onClick={onAddDataSource}
                    isDisabled={isButtonDisabled}
                >
                    {AddDataSourceText}
                </Button>
            )}
        </>
    );
};
