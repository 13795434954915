import React from 'react';

import { Card } from 'components/Card/Card';
import { UserInfoRow } from '../MainInfoBlock/components/UserInfoRow/UserInfoRow';
import { EditProfileInfoForm } from '../EditProfileInfoForm/EditProfileInfoForm';
import { ChangePasswordForm } from '../ChangePasswordForm/ChangePasswordForm';
import { getPasswordResetUrl } from 'shared/services/AuthService';

import { MainInfoBlockProps, MainInfoBlockState } from './types';

import {
    emailLabel,
    firstname,
    isEmptyError,
    passLabel,
    // phoneLabel, verifiedOnText
} from './constants';
import { Icon } from 'components/Icon/Icon';

import styles from './MainInfoBlock.module.scss';
// import { ChangePhoneNumberForm } from '../ChangePhoneNumberForm/ChangePhoneNumberForm';
export class MainInfoBlock extends React.Component<MainInfoBlockProps, MainInfoBlockState> {
    constructor(props: MainInfoBlockProps) {
        super(props);
        this.state = {
            isEditProfileInfoOpened: false,
            isChangePasswordOpened: false,
            isChangePhoneNumberOpened: false,
            firstName: this.props.userDetails.firstName,
            lastName: this.props.userDetails.lastName || '',
            formErrors: { firstName: '', lastName: '' },
        };
    }

    handleEditProfileButtonClick() {
        this.setState({
            isEditProfileInfoOpened: true,
        });
    }

    handleEditPasswordButtonClick() {
        const url = getPasswordResetUrl();
        if (url) {
            window.location.replace(url);
        }
    }

    handleEditPhoneNumberButtonClick() {
        this.setState({
            isChangePhoneNumberOpened: true,
        });
    }

    handleCancelEditProfile() {
        this.setState({
            isEditProfileInfoOpened: false,
            firstName: this.props.userDetails.firstName,
            lastName: this.props.userDetails.lastName,
            formErrors: { firstName: '', lastName: '' },
        });
    }

    handleCancelChangePassword() {
        this.setState({
            isChangePasswordOpened: false,
        });
    }

    handleCancelChangePhoneNumber() {
        this.setState({
            isChangePhoneNumberOpened: false,
        });
    }

    onInputChange(name: string, value: string): void {
        this.setState({
            [name]: value,
        });

        if (name === firstname && this.state.formErrors!.firstName && !this.state.firstName) {
            this.setState({ formErrors: { firstName: '' } });
        }
    }

    handleClearInput(name: string): void {
        this.setState({
            [name]: '',
        });
    }

    validateInput(fieldName: string, value: string) {
        let fieldValidationErrors = this.state.formErrors;
        let firstNameValid = this.state.firstNameValid;

        if (fieldName === firstname) {
            firstNameValid = /\S/.test(value.trim());
            fieldValidationErrors!.firstName = firstNameValid ? '' : isEmptyError;
        }
        this.setState({
            firstNameValid,
            formErrors: fieldValidationErrors,
        });
    }

    async handleSaveProfileInfo() {
        await this.validateInput(firstname, this.state.firstName!);
        if (
            this.state.firstName &&
            this.state.firstNameValid &&
            (this.state.firstName !== this.props.userDetails.firstName ||
                this.state.lastName !== this.props.userDetails.lastName)
        ) {
            await this.props.updateUserDetails({
                email: this.props.userDetails.email,
                firstName: this.state.firstName.trim().replace(/\s+/g, ' '),
                lastName:
                    !this.state.lastName || !/\S/.test(this.state.lastName)
                        ? ''
                        : this.state.lastName.trim().replace(/\s+/g, ' '),
            });
            this.setState({
                isEditProfileInfoOpened: false,
            });
        }
    }

    render() {
        const { userDetails, testId, children, className } = this.props;
        const userName = `${userDetails.firstName} ${userDetails.lastName || ''}`;
        const { avatar } = userDetails;

        return (
            <Card className={className}>
                {this.state.isEditProfileInfoOpened ? (
                    <EditProfileInfoForm
                        data-testid={`${testId}.editProfileInfoForm`}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        onCancel={() => this.handleCancelEditProfile()}
                        onInputChange={(event: any) => this.onInputChange(event.target.name, event.target.value)}
                        onSave={() => this.handleSaveProfileInfo()}
                        onClearInput={(inputName: string) => this.handleClearInput(inputName)}
                        formErrors={this.state.formErrors!}
                        firstNameValid={this.state.firstNameValid}
                        testId={`${testId}.editProfileInfoForm`}
                        className={styles.form}
                        updatingProfile={this.props.updatingProfile || false}
                    />
                ) : (
                    <div className={styles['user-row']}>
                        <div className={styles['user-info']}>
                            <div className={styles['user-photo']}>
                                {avatar ? <img src={avatar} alt={userName} /> : <Icon type="user" />}
                            </div>
                            <span className={styles['user-name']}>{userName}</span>
                        </div>
                        <Icon
                            data-testid={`${testId}.editProfileButton`}
                            type="edit"
                            className={styles['edit-btn']}
                            onClick={() => this.handleEditProfileButtonClick()}
                        />
                    </div>
                )}
                <UserInfoRow
                    data-testid={`${testId}.emailLabel`}
                    rowLabel={emailLabel}
                    rowData={userDetails.email}
                    testId={`${testId}.userInfoRow`}
                />
                {this.state.isChangePasswordOpened ? (
                    <ChangePasswordForm
                        data-testid={`${testId}.changePasswordForm`}
                        onCancel={() => this.handleCancelChangePassword()}
                        testId={`${testId}.changePasswordForm`}
                        className={styles.form}
                    />
                ) : (
                    <UserInfoRow
                        data-testid={`${testId}.editPasswordButton`}
                        rowLabel={passLabel}
                        rowData={''} //TODO get rid of password
                        isEditable={true}
                        onEditClick={() => this.handleEditPasswordButtonClick()}
                        testId={`${testId}.userInfoRow`}
                    />
                )}
                {/* TODO: temp hidden */}
                {/* {this.state.isChangePhoneNumberOpened ? (
                    <ChangePhoneNumberForm
                        data-testid={`${testId}.changePhoneNumberForm`}
                        onCancel={() => this.handleCancelChangePhoneNumber()}
                        testId={`${testId}.changePhoneNumberForm`}
                        className={styles.form}
                    />
                ) : (
                    <UserInfoRow
                        data-testid={`${testId}.phoneLabel`}
                        rowLabel={phoneLabel}
                        rowData={userDetails.phone}
                        rowAdditional={`${verifiedOnText} ${userDetails.phoneVerifiedDate}`}
                        isEditable={true}
                        onEditClick={() => this.handleEditPhoneNumberButtonClick()}
                        testId={`${testId}.userInfoRow`}
                    />
                )} */}
                {children}
            </Card>
        );
    }
}
