import React, { FC } from 'react';
import { Icon } from 'components/Icon/Icon';
import { CellRendererProps } from 'components/Table/types';
import Dropdown from 'react-dropdown';
import styles from './ContextMenuCellRenderer.module.scss';
import { dropdownOptions } from './constants';

export const ContextMenuCellRenderer: FC<CellRendererProps> = ({ columnDef, row }) => {
    //TODO adjust context menu styles
    const handleSelectOption = (option: any) => {
        if (option.value === dropdownOptions[0].value) {
            return columnDef.onEditRow!(row);
        }
        return columnDef.onDeleteRow!(row);
    };
    return (
        <Dropdown
            options={dropdownOptions}
            className={styles.dropdown}
            controlClassName={styles['dropdown-control']}
            placeholderClassName={styles['dropdown-placeholder']}
            menuClassName={styles['dropdown-menu']}
            arrowOpen={<Icon type="threedots" className={styles['dropdown-icon']} />}
            arrowClosed={<Icon type="threedots" className={styles['dropdown-icon']} />}
            onChange={(option) => handleSelectOption(option)}
        />
    );
};
