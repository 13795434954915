import React, { FC } from 'react';
import { Input } from 'components/Form/components/Input/Input';
import { nameInputConfig } from './constants';
import { AccountTeamsNameSectionProps } from './types';
import styles from './AccountTeamsNameSection.module.scss';

export const AccountTeamsNameSection: FC<AccountTeamsNameSectionProps> = (props) => {
    const { onChange, onClear, value, testId, isViewer, isEditor, error } = props;
    const hasNoPermissionToEdit = isViewer || isEditor;

    return (
        <div className={styles.container}>
            <Input
                isDisabled={hasNoPermissionToEdit}
                data-testid={`${testId}.nameInput`}
                id={nameInputConfig.id}
                label={nameInputConfig.label}
                name={nameInputConfig.name}
                type={nameInputConfig.type}
                value={value}
                className={styles['input-container']}
                icon={hasNoPermissionToEdit ? '' : 'clear'}
                placeholder={nameInputConfig.placeholder}
                onIconClick={onClear}
                onChange={onChange}
            />
            <div className={styles.errorBlock}>{error}</div>
        </div>
    );
};
