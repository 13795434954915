import React, { FC } from 'react';

import { CellRendererProps } from '../../../types';

import styles from '../../../Table.module.scss';

export const DefaultCellRenderer: FC<CellRendererProps> = ({ columnDef, value, isEmpty }) =>
    isEmpty && !value ? (
        <span data-testid="empty-table-cell" className={styles['skeleton-cell-block']} />
    ) : (
        <span data-testid="default-table-cell" className={styles[`${columnDef.field}-label`]}>
            {value}
        </span>
    );
