import { addMonths, intervalToDuration } from 'date-fns';
import React, { FC } from 'react';
import { DateLabelsProps } from './interfaces';
import styles from './DateLabels.module.scss';
import { DATE_LABELS_GAPS } from '../../constants';
import { dateToString } from '../../utils/dateToString';

export const DateLabels: FC<DateLabelsProps> = (props) => {
    const { minDate, maxDate, columnsNumber, testId } = props;

    const labelsTestId = `${testId}.DateLabels`;

    const { years, months } = intervalToDuration({
        start: minDate,
        end: maxDate,
    });

    const monthsNumber = (years ?? 0) * 12 + (months ?? 0);

    const labelGaps = monthsNumber <= columnsNumber ? 0 : DATE_LABELS_GAPS;

    const step = Math.ceil(monthsNumber / labelGaps);

    const dateLabels = [
        minDate,
        ...new Array(labelGaps ? labelGaps - 1 : 0).fill(0).map((_, index) => {
            return addMonths(minDate, (index + 1) * step).getTime();
        }),
        maxDate,
    ].map((date) => dateToString(date));

    return (
        <div className={styles.container}>
            {dateLabels.map((label) => (
                <span key={label + Math.random()} className={styles.label} data-testid={`${labelsTestId}.Label`}>
                    {label}
                </span>
            ))}
        </div>
    );
};
