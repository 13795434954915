import { useState } from 'react';

export const useExportModal = (initialState: boolean) => {
    const [isExportModalOpen, setIsExportModalOpen] = useState(initialState);

    function handleModalToggle() {
        setIsExportModalOpen(!isExportModalOpen);
    }

    function handleModalClose() {
        setIsExportModalOpen(false);
    }

    return {
        isExportModalOpen,
        toggleExportModal: handleModalToggle,
        closeExportModal: handleModalClose,
    };
};
