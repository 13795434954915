import React, { FC } from 'react';
import cn from 'classnames';

import { Button } from 'components/Button/Button';
import { EarningsButtonsPropsType } from './types';

// import { Icon } from 'components/Icon/Icon';

import { styleTypes, viewAllEarningsText } from 'shared/constants';

import styles from './EarningsButtons.module.scss';
import { Icon } from 'components/Icon/Icon';

export const EarningsButtons: FC<EarningsButtonsPropsType> = ({
    showEarningsLink,
    toggleExportModal,
    isDisabled,
    downloadButtonLabel,
}) => {
    return (
        <div className={cn(styles['buttons-block'])}>
            {showEarningsLink && (
                <Button
                    style={styleTypes.secondary}
                    className={styles.button}
                    isLink
                    to="/earnings"
                    isDisabled={isDisabled}
                >
                    {viewAllEarningsText}
                </Button>
            )}
            <Button
                style={styleTypes.secondary}
                className={styles['download-button']}
                onClick={toggleExportModal}
                isDisabled={isDisabled}
            >
                {downloadButtonLabel}
                <Icon type="download" className={styles.icon} />
            </Button>
        </div>
    );
};
