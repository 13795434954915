import { UserInfo } from 'gql/__generated__/types';
import { v4 as uuidv4 } from 'uuid';

export const headerNames = {
    dataSource: 'Member',
    accounts: 'Email',
    status: 'Status',
    permission: 'Permission',
};

export const fieldNames = {
    delete: 'delete',
    permission: 'role',
    status: 'isConfirmedInvitation',
    account: 'invitationEmail',
    member: 'member',
};

export const cellClassNames = {
    large: 'large',
    hidden: 'hidden',
    largeDouble: 'largeDbl',
    small: 'small',
    smallCentered: 'smallCentered',
    iconColumn: 'iconColumn',
    permissionColumn: 'permissionColumn',
};

export const statusColors: { [key: string]: any } = {
    Pending: 'pending',
    'Not Sent': 'notSent',
    Active: 'active',
};

export const TeamMembersText = 'Team Members';

export const AccountDropdownOptions = [
    {
        value: 'EDITOR',
        label: 'Editor',
    },
    {
        value: 'VIEWER',
        label: 'Viewer',
    },
];

export const ownerValue = 'Owner';

export const invitationStatus = {
    pending: 'Pending',
    active: 'Active',
    notSent: 'Not Sent',
};

export const AddMemberButtonText = '+ Add Member';

export const okModalBtnText = 'remove';

export const cancelModalBtnText = 'cancel';

export const titleModalBtn = 'Remove Team Member';

export const descriptionModalText = 'Are you sure you want to remove this member?';

export const teamOwnerValue: any = (teamOwnerInfo: UserInfo): any => [
    {
        role: ownerValue,
        invitationEmail: teamOwnerInfo.email,
        name: teamOwnerInfo.name,
        surname: teamOwnerInfo.surname,
        id: uuidv4(),
    },
];
