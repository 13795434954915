import React, { FC } from 'react';

import { TableRow } from './components/TableRow/TableRow';
import { TableRowWithDetails } from './components/TableRowWithDetails/TableRowWithDetails';
import { TableRowProps } from '../../types';

export const TableRowRenderer: FC<TableRowProps> = (props) => {
    const { config, row, isEmpty } = props;

    const ExpandedRow = config.collapsedRow;

    if (row.collapsed) {
        return <ExpandedRow {...props} />;
    }
    const Row = config && config.withDetails && config.canExpand && !isEmpty ? TableRowWithDetails : TableRow;
    return <Row {...props} />;
};
