import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import { AmountFragmentDoc } from '../../fragments/__generated__/Amount.generated';
import { ProjectionFragmentDoc } from '../../fragments/__generated__/Projection.generated';
import { AccountFragmentDoc } from '../../fragments/__generated__/Account.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.Pagination>;
  sort?: Types.InputMaybe<Types.Sort>;
  filter?: Types.InputMaybe<Types.ProductFilter>;
}>;


export type GetProductsQuery = { __typename?: 'Query', getProducts?: { __typename?: 'ProductList', limit?: number | null, page?: number | null, total?: number | null, totalAmount?: number | null, products?: Array<{ __typename?: 'Product', id?: string | null, name?: string | null, totalAmount?: number | null, type?: Types.ProductType | null, amount?: Array<{ __typename?: 'Amount', amount?: number | null, currency?: { __typename?: 'Currency', id?: string | null, ticker?: string | null } | null, date?: { __typename?: 'Date', unix?: string | null } | null } | null> | null, projection?: { __typename?: 'Projection', month1?: number | null, month2?: number | null, month3?: number | null, month4?: number | null, month5?: number | null, month6?: number | null, currencyId?: string | null } | null, account?: Array<{ __typename?: 'Account', id?: string | null, totalAmount?: number | null, name?: string | null } | null> | null } | null> | null } | null };


export const GetProductsDocument = gql`
    query getProducts($pagination: Pagination, $sort: Sort, $filter: ProductFilter) {
  getProducts(pagination: $pagination, sort: $sort, filter: $filter) {
    limit
    page
    total
    totalAmount
    products {
      id
      name
      totalAmount
      type
      amount {
        ...Amount
      }
      projection {
        ...Projection
      }
      account {
        ...Account
      }
    }
  }
}
    ${AmountFragmentDoc}
${ProjectionFragmentDoc}
${AccountFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;