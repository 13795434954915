import React from 'react';
import { TableRowProps } from '../../../../types';
import { AccountTeamsTab } from 'components/AccountTeamsTab/AccountTeamsTab';
import styles from './AccountTeamExpandedRow.module.scss';
import { DEFAULT_DELAY } from './constants';
import { Card } from 'components/Card/Card';

export const AccountTeamExpandedRow = (props: TableRowProps) => {
    const { row, onCollapseRow, config } = props;

    const onCloseHandler = () => {
        setTimeout(() => {
            onCollapseRow(props.row.rowId);
        }, DEFAULT_DELAY);
    };

    return (
        <Card className={styles.container}>
            <AccountTeamsTab
                onClose={onCloseHandler}
                onSaveChanges={config.onSaveChanges}
                onDeleteTeam={config.onDeleteTeam}
                onLeaveTeam={config.onLeaveTeam}
                editMode={!row.isCreated}
                dataSourcesDropdownData={config.dataSourcesDropdownData}
                teamOwnerInfo={config.teamOwnerInfo!}
                rowId={row.rowId}
                row={row}
            />
        </Card>
    );
};
