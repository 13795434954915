import { ApolloError, useApolloClient } from '@apollo/client';
import { Pagination, RetailerFilter, Sort } from 'gql/__generated__/types';

import { useCallback, useState } from 'react';
import { EarningsPagination } from 'shared/types';
import { UseGetRetailerEarningsResponse } from './interfaces';
import { useGetRetailersLazyQuery, GetRetailersDocument } from 'gql/queries/__generated__/getRetailers.generated';
import { prepareRetailers } from '../utils/prepareRetailer';
import { UseEarningsParams } from '../interfaces';

export const useGetRetailerEarnings = ({ setEarnings }: UseEarningsParams): UseGetRetailerEarningsResponse => {
    const [earningsPagination, setEarningsPagination] = useState<EarningsPagination | undefined>(undefined);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<ApolloError | undefined>(undefined);

    const apolloClient = useApolloClient();
    const [getRetailerEarningsQuery] = useGetRetailersLazyQuery({ fetchPolicy: 'cache-first' });

    const getRetailerEarnings = useCallback(
        async (pagination: Pagination, filter: RetailerFilter, sort?: Sort) => {
            setIsLoading(true);

            const options = {
                variables: {
                    pagination,
                    sort,
                    filter,
                },
            };

            // lookup in cache first before requerying api
            let productData = apolloClient.readQuery({
                ...options,
                query: GetRetailersDocument,
            });
            if (!productData) {
                const { data, error: productError } = await getRetailerEarningsQuery(options);
                productData = data;
                setError(productError);
            }

            const retailers = productData?.getRetailers?.retailers ?? [];
            const preparedRetailers = prepareRetailers(retailers);
            const obtainedPagination = {
                limit: productData?.getRetailers?.limit ?? 0,
                page: productData?.getRetailers?.page ?? 0,
                total: productData?.getRetailers?.total ?? 0,
            };
            const total = productData?.getRetailers?.totalAmount ?? 0;

            setEarnings(preparedRetailers, obtainedPagination.page);
            setEarningsPagination(obtainedPagination);
            setTotalEarnings(total);
            setIsLoading(false);
        },
        [apolloClient, getRetailerEarningsQuery, setEarnings],
    );

    return {
        isLoading,
        error,
        pagination: earningsPagination,
        totalEarnings,
        getRetailerEarnings,
    };
};
