import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
export type ProjectionFragment = { __typename?: 'Projection', month1?: number | null, month2?: number | null, month3?: number | null, month4?: number | null, month5?: number | null, month6?: number | null, currencyId?: string | null };

export const ProjectionFragmentDoc = gql`
    fragment Projection on Projection {
  month1
  month2
  month3
  month4
  month5
  month6
  currencyId
}
    `;