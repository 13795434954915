import { Chart } from 'highcharts';
import variables from 'shared/styles/variables.module.scss';

export const createChartHeader = (chart: Chart) => {
    chart.renderer
        .rect()
        .attr({ x: 0, y: 0, width: chart.chartWidth, height: 40, zIndex: 10 })
        .css({ fill: variables.chartBackgroundColor })
        .add();
    chart.renderer
        .rect({ x: 0, y: 10, width: chart.chartWidth, height: 30, zIndex: 11 })
        .css({ fill: variables.chartFieldColor })
        .add();
};
