import { TOP_SIX } from 'pages/Dashboard/constants';
import { useEffect, useMemo } from 'react';
import { EarningsPagination, EarningsResponse } from 'shared/types';
import { useGetProductEarnings } from '../useGetProductEarnings/useGetProductEarnings';
import { useGetSourceEarnings } from '../useGetSourceEarnings/useGetSourceEarnings';

import { QueryTypes } from 'pages/Earnings/hooks/utils/types';
import { ProductFilter, RetailerFilter } from 'gql/__generated__/types';
import { useGetRetailerEarnings } from '../useGetRetailerEarnings/useGetRetailerEarnings';
import { EnhancedSourceFilter } from '../useGetSourceEarnings/interfaces';
import { useEarningsPagination } from '../useEarningsPagination/useEarningsPagination';
import { useGetEarningFilters } from '../useGetEarningFilters/useGetEarningFilters';

export const useGetEarnings = (): EarningsResponse => {
    const { earnings, setEarningsWithPagination } = useEarningsPagination();
    const {
        period,
        sourceId,
        productId,
        retailerId,
        category,
        date,
        showAllTeams,
        teamId,
        sort,
        search: searchName,
        query,
        pagination,
        isEarnings,
    } = useGetEarningFilters();

    const {
        isLoading: sourceLoading,
        error: sourceError,
        totalEarnings: sourceTotalEarnings,
        getSourceEarnings,
    } = useGetSourceEarnings({ setEarnings: setEarningsWithPagination, period });

    const {
        isLoading: productLoading,
        error: productError,
        pagination: productPagination,
        totalEarnings: productTotalEarnings,
        getProductEarnings,
    } = useGetProductEarnings({ setEarnings: setEarningsWithPagination, period });

    const {
        isLoading: retailerLoading,
        error: retailerError,
        pagination: retailerPagination,
        totalEarnings: retailerTotalEarnings,
        getRetailerEarnings,
    } = useGetRetailerEarnings({ setEarnings: setEarningsWithPagination, period });

    useEffect(() => {
        switch (query) {
            case '':
            case QueryTypes.AllSources:
                const allSourceFilter: EnhancedSourceFilter = {
                    date,
                    category,
                    sort,
                    name: searchName,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                    limit: !isEarnings ? TOP_SIX : undefined,
                };
                getSourceEarnings(allSourceFilter);
                break;
            case QueryTypes.SourceByProduct:
                const sourceByProductFilter: EnhancedSourceFilter = {
                    date,
                    category,
                    name: searchName,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                    sort,
                    productId,
                };
                getSourceEarnings(sourceByProductFilter);
                break;
            case QueryTypes.SourceByProductAndRetailer:
                const sourceByProductAndRetailerFilter: EnhancedSourceFilter = {
                    date,
                    category,
                    name: searchName,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                    sort,
                    productId,
                    retailerId,
                };
                getSourceEarnings(sourceByProductAndRetailerFilter);
                break;
            case QueryTypes.AllProducts:
                const allProductFilter: ProductFilter = {
                    name: searchName,
                    category,
                    date,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                };
                getProductEarnings(pagination, allProductFilter, sort);
                break;
            case QueryTypes.ProductBySource:
                //todo replace sourceId with sourceType
                const productBySourceFilter: ProductFilter = {
                    name: searchName,
                    category,
                    date,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                    sourceTypes: [sourceId],
                };

                getProductEarnings(pagination, productBySourceFilter, sort);
                break;
            case QueryTypes.ProductBySourceAndRetailer:
            case QueryTypes.ProductByRetailerAndSource:
                //todo replace sourceId with sourceType
                const productBySourceAndRetailerFilter: ProductFilter = {
                    name: searchName,
                    category,
                    date,
                    retailerId,
                    sourceTypes: [sourceId],
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                };

                getProductEarnings(pagination, productBySourceAndRetailerFilter, sort);
                break;
            case QueryTypes.RetailerBySource:
                //todo replace sourceId with sourceType
                const retailerBySourceFilter: RetailerFilter = {
                    name: searchName,
                    date,
                    sourceType: sourceId,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                };
                getRetailerEarnings(pagination, retailerBySourceFilter, sort);
                break;
            case QueryTypes.RetailerBySourceAndProduct:
            case QueryTypes.RetailerByProductAndSource:
                //todo replace sourceId with sourceType
                const retailerBySourceAndProductFilter: RetailerFilter = {
                    name: searchName,
                    date,
                    sourceType: sourceId,
                    productId,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                };
                getRetailerEarnings(pagination, retailerBySourceAndProductFilter, sort);
                break;
            case QueryTypes.RetailerByProduct:
                const retailerByProductFilter: RetailerFilter = {
                    name: searchName,
                    date,
                    productId,
                    showAllTeams: showAllTeams,
                    teamId: teamId,
                };
                getRetailerEarnings(pagination, retailerByProductFilter, sort);
                break;
            default:
                break;
        }
    }, [
        query,
        searchName,
        date,
        pagination,
        category,
        sort,
        isEarnings,
        getProductEarnings,
        getRetailerEarnings,
        getSourceEarnings,
        productId,
        retailerId,
        sourceId,
        teamId,
        showAllTeams,
    ]);

    const earningsPagination = useMemo(() => {
        let setPagination: { hasPagination: boolean; pagination?: EarningsPagination } = {
            hasPagination: false,
            pagination: undefined,
        };
        switch (query) {
            case QueryTypes.AllProducts:
            case QueryTypes.ProductBySource:
            case QueryTypes.ProductBySourceAndRetailer:
            case QueryTypes.ProductByRetailerAndSource:
                setPagination = { hasPagination: true, pagination: productPagination };
                break;
            case QueryTypes.RetailerBySource:
            case QueryTypes.RetailerByProduct:
            case QueryTypes.RetailerBySourceAndProduct:
            case QueryTypes.RetailerByProductAndSource:
                setPagination = { hasPagination: true, pagination: retailerPagination };
                break;
            default:
                setPagination = { hasPagination: false, pagination: undefined };
                break;
        }

        return setPagination;
    }, [productPagination, query, retailerPagination]);

    const isLoading = useMemo(() => {
        let loadingStatus = true;

        switch (query) {
            case QueryTypes.AllSources:
            case QueryTypes.SourceByProduct:
            case QueryTypes.SourceByProductAndRetailer:
                loadingStatus = sourceLoading;
                break;
            case QueryTypes.AllProducts:
            case QueryTypes.ProductBySource:
            case QueryTypes.ProductBySourceAndRetailer:
            case QueryTypes.ProductByRetailerAndSource:
                loadingStatus = productLoading;
                break;
            case QueryTypes.RetailerBySource:
            case QueryTypes.RetailerByProduct:
            case QueryTypes.RetailerBySourceAndProduct:
            case QueryTypes.RetailerByProductAndSource:
                loadingStatus = retailerLoading;
                break;
            default:
                loadingStatus = true;
                break;
        }

        return loadingStatus;
    }, [productLoading, query, retailerLoading, sourceLoading]);

    const totalEarnings = useMemo(() => {
        let setTotalEarnings = 0;

        switch (query) {
            case QueryTypes.AllSources:
            case QueryTypes.SourceByProduct:
            case QueryTypes.SourceByProductAndRetailer:
                setTotalEarnings = sourceTotalEarnings;
                break;
            case QueryTypes.AllProducts:
            case QueryTypes.ProductBySource:
            case QueryTypes.ProductBySourceAndRetailer:
            case QueryTypes.ProductByRetailerAndSource:
                setTotalEarnings = productTotalEarnings;
                break;
            case QueryTypes.RetailerBySource:
            case QueryTypes.RetailerByProduct:
            case QueryTypes.RetailerBySourceAndProduct:
            case QueryTypes.RetailerByProductAndSource:
                setTotalEarnings = retailerTotalEarnings;
                break;
            default:
                setTotalEarnings = 0;
                break;
        }

        return setTotalEarnings;
    }, [productTotalEarnings, query, retailerTotalEarnings, sourceTotalEarnings]);

    const error = sourceError || productError || retailerError;

    return {
        earnings,
        isLoading,
        pagination: earningsPagination.pagination,
        hasPagination: earningsPagination.hasPagination,
        error,
        totalEarnings,
    };
};
