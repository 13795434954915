import React, { FC } from 'react';
import { TooltipTotalProps } from './interfaces';
import { DECIMAL_PLACES, TOOLTIP_TOTAL_TITLE } from '../../../constants';
import BigNumber from 'bignumber.js';
import { getFormattedAndRoundedValue } from 'shared/utils/getFormattedAndRoundedValue';

import styles from './TooltipTotal.module.scss';

export const TooltipTotal: FC<TooltipTotalProps> = ({ total, testId }) => {
    const tooltipTotalTestId = `${testId}.Total`;
    const adjustedTotal = new BigNumber(total).decimalPlaces(DECIMAL_PLACES);

    return (
        <div className={styles['tooltip-total-container']}>
            <span>{TOOLTIP_TOTAL_TITLE}</span>
            <span data-testid={tooltipTotalTestId}>{getFormattedAndRoundedValue(adjustedTotal.toString())}</span>
        </div>
    );
};
