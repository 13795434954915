import { CSSObject } from 'highcharts';
import variables from 'shared/styles/variables.module.scss';

export const POINT_INTERVAL = 24 * 3600 * 1000 * 30; // one month

export const POINT_WIDTH = 100;
export const THREE_DAYS = 259200000;

export const THROTTLE_TIME = 10;

export const HEADER_LABEL_PADDING = 18;

export const MIN_HEADER_LABEL_PADDING = 8;

export const SCROLLBAR_CONFIG = {
    enabled: true,
    barBackgroundColor: variables.chartFieldColor,
    barBorderColor: variables.chartBackgroundColor,
    barBorderRadius: 10,
    barBorderWidth: 1,
    buttonArrowColor: variables.chartTextColor,
    buttonBackgroundColor: variables.chartFieldColor,
    buttonBorderColor: variables.chartBackgroundColor,
    buttonBorderWidth: 1,
    buttonBorderRadius: 10,
    trackBorderColor: variables.borderColorField,
    trackBackgroundColor: variables.chartBackgroundColor,
    trackBorderRadius: 10,
    trackBorderWidth: 0.5,
    height: 20,
    margin: 12,
    rifleColor: variables.chartTextColor,
};

export const PLOT_LINE_CONFIG = { color: variables.chartTextColor, width: 1, zIndex: 12 };

export const CHART_MAX_PADDING = 0.1;

export const CHART_LABEL_STYLES: CSSObject = {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: variables.chartTextColor,
};

export const CHART_HEADER_LABEL_STYLES: CSSObject = {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '17px',
    color: variables.baseTextColor,
};

export const AREA_OPACITY = 0.5;

export const DATE_LABELS_GAPS = 4;

export const TOOLTIP_TOTAL_TITLE = 'Total';

export const DECIMAL_PLACES = 2;

export enum ChartType {
    StackedBarChart,
    LineChart,
}

export const dropdownChartSelectorPlaceholder = 'Select an option';

export const dropdownChartTypeSelectorOptions = [
    {
        label: 'Stacked Bar',
        value: String(ChartType.StackedBarChart),
    },
    {
        label: 'Line',
        value: String(ChartType.LineChart),
    },
];

// THIS VALUE MUST MATCH BACKEND DEFAULT_DAY_VALUE
export const DEFAULT_DAY_VALUE = 4;

export const CROSSHAIR_CLASS_NAME = 'custom-crosshair-class';
