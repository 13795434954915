import { difference, intersectionBy, isEmpty } from 'lodash';
import { colors } from '../constants';

export const calculateAvailableColors = (
    newItems: Array<{ id: string; name: string }>,
    currentItems: Array<{ id: string; name: string; color: string }>,
    newItemsWithoutCurrentItems: Array<{ id: string; name: string }>,
) => {
    if (isEmpty(intersectionBy(newItems, currentItems, 'id'))) {
        return colors;
    }

    const releasedColors = difference(
        colors,
        currentItems.map((i) => i.color),
    );

    if (newItemsWithoutCurrentItems.length > releasedColors.length) {
        return colors;
    }

    return releasedColors;
};
