import React from 'react';
import cn from 'classnames';

import { PeriodValues } from 'shared/types';
import { PeriodSubtitlePropsType } from './types';
import { allTimeText, lastThreeMonthsText, thisYearText, disabledText } from '../PeriodSelector/constants';

import styles from './PeriodSubtitle.module.scss';

export const PeriodSubtitle = ({ className, selectedPeriod, isDisabled }: PeriodSubtitlePropsType) => {
    const subtitleByPeriod = {
        [PeriodValues.allTime]: allTimeText,
        [PeriodValues.thisYear]: thisYearText,
        [PeriodValues.lastThreeMonths]: lastThreeMonthsText,
    };

    return (
        <span className={cn(styles.container, className)}>
            {!isDisabled ? subtitleByPeriod[selectedPeriod] : disabledText}
        </span>
    );
};
