import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';

import { LeftNavMenuItemProps } from './types';

import { Icon } from 'components/Icon/Icon';
import styles from './LeftNavigationMenuItem.module.scss';

export const LeftNavigationMenuItem: FC<LeftNavMenuItemProps> = ({
    to,
    onClick,
    isNavMenuItemActive,
    label,
    children,
    testId,
}) => (
    <li className={styles.navItem}>
        <NavLink
            data-testid={`${testId}.link`}
            to={to}
            className={cn(styles.navLink, {
                [styles.active]: isNavMenuItemActive,
            })}
            onClick={onClick}
        >
            {isNavMenuItemActive ? (
                <Icon className={styles.navIcon} type={`${label}_active`} />
            ) : (
                <Icon className={styles.navIcon} type={label} />
            )}
            <span data-testid={`${testId}.label`} className={styles.navLabel}>
                {children}
            </span>
        </NavLink>
    </li>
);
