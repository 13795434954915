export const passwordInputParams = {
    placeholder: 'Enter your password',
    id: 'password',
    label: 'Password',
    name: 'password',
    typeText: 'text',
    typePassword: 'password',
};

export const groupsInputParams = {
    placeholder: 'Select or create groups',
    id: 'groups',
    label: 'Groups',
    name: 'groups',
    type: 'text',
    subLabel: 'Optional',
};

export const notificationPartOne = 'Please note that each account can be linked to';
export const notificationPartTwo = 'only one';
export const notificationPartThree = 'Wallet аccount.';
