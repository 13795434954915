import React, { FC } from 'react';
import cn from 'classnames';
import { DropdownHeaderProps } from './types';
import { Icon } from 'components/Icon/Icon';

import styles from './DropdownHeader.module.scss';

export const DropdownHeader: FC<DropdownHeaderProps> = ({ userName, userAvatar }) => (
    <div className={cn(styles['user-info-box'])}>
        <span className={styles['user-photo']}>
            {userAvatar ? <img src={userAvatar} alt={userName} /> : <Icon type="user" />}
        </span>
        <span data-testid="dropdownHeaderUserName" className={styles['user-name']}>
            {userName}
        </span>
    </div>
);
