import React, { FC } from 'react';

import { ReactComponent as dashboard } from './svg-icons/Dashboard.svg';
import { ReactComponent as dashboard_active } from './svg-icons/DashboardBold.svg';
import { ReactComponent as earnings } from './svg-icons/Earnings.svg';
import { ReactComponent as earnings_active } from './svg-icons/EarningsBold.svg';
import { ReactComponent as wallet } from './svg-icons/Wallet.svg';
import { ReactComponent as clear } from './svg-icons/Clear.svg';
import { ReactComponent as password } from './svg-icons/ShowPassword.svg';
import { ReactComponent as info } from './svg-icons/Info.svg';
import { ReactComponent as instagram } from './svg-icons/Instagram.svg';
import { ReactComponent as facebook } from './svg-icons/Facebook.svg';
import { ReactComponent as twitter } from './svg-icons/Twitter.svg';
import { ReactComponent as search } from './svg-icons/Search.svg';
import { ReactComponent as google } from './svg-icons/Google.svg';
import { ReactComponent as email } from './svg-icons/Email.svg';
import { ReactComponent as manage } from './svg-icons/Manage.svg';
import { ReactComponent as logout } from './svg-icons/Logout.svg';
import { ReactComponent as viewall } from './svg-icons/ViewAll.svg';
import { ReactComponent as download } from './svg-icons/Download.svg';
import { ReactComponent as caretdown } from './svg-icons/CaretDown.svg';
import { ReactComponent as caretup } from './svg-icons/CaretUp.svg';
import { ReactComponent as success } from './svg-icons/Success.svg';
import { ReactComponent as warning } from './svg-icons/Warning.svg';
import { ReactComponent as close } from './svg-icons/Close.svg';
import { ReactComponent as edit } from './svg-icons/Edit.svg';
import { ReactComponent as data } from './svg-icons/Data.svg';
import { ReactComponent as member } from './svg-icons/Member.svg';
import { ReactComponent as group } from './svg-icons/Group.svg';
import { ReactComponent as arrowleft } from './svg-icons/ArrowLeft.svg';
import { ReactComponent as arrowright } from './svg-icons/ArrowRight.svg';
import { ReactComponent as check } from './svg-icons/Check.svg';
import { ReactComponent as shapeprofile } from './svg-icons/ShapeProfile.svg';
import { ReactComponent as remove } from './svg-icons/Remove.svg';
import { ReactComponent as checkcolored } from './svg-icons/CheckColored.svg';
import { ReactComponent as clockcolored } from './svg-icons/ClockColored.svg';
import { ReactComponent as link } from './svg-icons/Link.svg';
import { ReactComponent as arrowup } from './svg-icons/ArrowUp.svg';
import { ReactComponent as arrowdown } from './svg-icons/ArrowDown.svg';
import { ReactComponent as checkeditem } from './svg-icons/CheckedItem.svg';
import { ReactComponent as uncheckeditem } from './svg-icons/UncheckedItem.svg';
import { ReactComponent as actions } from './svg-icons/Actions.svg';
import { ReactComponent as datasources } from './svg-icons/DataSources.svg';
import { ReactComponent as datasources_active } from './svg-icons/DataSourcesBold.svg';
import { ReactComponent as threedots } from './svg-icons/ThreeDots.svg';
import { ReactComponent as teams } from './svg-icons/Teams.svg';
import { ReactComponent as teams_active } from './svg-icons/TeamsBold.svg';
import { ReactComponent as user } from './svg-icons/User.svg';
import { ReactComponent as checkedsuccess } from './svg-icons/CheckedSuccess.svg';
import { ReactComponent as processing } from './svg-icons/Processing.svg';
import { ReactComponent as datasourcewarning } from './svg-icons/DataSourceWarning.svg';

const IconMap: {
    [key: string]: FC<React.SVGProps<SVGSVGElement>>;
} = {
    dashboard,
    dashboard_active,
    earnings,
    earnings_active,
    wallet,
    clear,
    password,
    info,
    instagram,
    facebook,
    twitter,
    search,
    google,
    email,
    manage,
    logout,
    viewall,
    download,
    caretdown,
    caretup,
    success,
    warning,
    close,
    edit,
    data,
    member,
    group,
    arrowleft,
    check,
    shapeprofile,
    remove,
    checkcolored,
    clockcolored,
    link,
    arrowup,
    arrowdown,
    arrowright,
    checkeditem,
    uncheckeditem,
    actions,
    teams,
    teams_active,
    datasources,
    datasources_active,
    threedots,
    user,
    checkedsuccess,
    processing,
    datasourcewarning,
};

export default IconMap;
