import React, { FC } from 'react';

import cn from 'classnames';

import { TabProps } from './types';

import styles from './Tab.module.scss';

export const Tab: FC<TabProps> = ({ className, children }) => (
    <div className={cn(styles.tab, className)}>{children}</div>
);
