import React, { FC } from 'react';
import cn from 'classnames';

import { MainTotalSumProps } from './types';

import styles from './MainTotalSum.module.scss';

export const MainTotalSum: FC<MainTotalSumProps> = ({ className, children }) => (
    <div className={cn(styles.container, className)}>{children}</div>
);
