import React, { FC } from 'react';
import { CellRendererProps } from '../../../types';
import styles from '../../../Table.module.scss';
import { Icon } from 'components/Icon/Icon';
import { LinkedSource } from 'shared/types';
import { isProcessing } from 'shared/utils/isProcessing';
import { sourceHasProblem } from 'shared/utils/sourceHasProblem';
import { AccountStatuses } from 'gql/__generated__/types';

enum IndicatorType {
    error = 'datasourcewarning',
    processing = 'processing',
    success = 'checkedsuccess',
}

export const DataSourceTitleRenderer: FC<CellRendererProps> = ({ columnDef, value, isEmpty, row }) => {
    const currentRow = row as LinkedSource;
    const errorIndicatorTooltipId = `indicator-tooltip-${IndicatorType.error}`;
    const processingIndicatorTooltipId = `indicator-tooltip-${IndicatorType.processing}`;

    const getErrorMessage = () => {
        if (currentRow?.status === AccountStatuses.LoginFailure) {
            return (
                'We were unable to log in to your account. Please check your login credentials and try again. <br />' +
                'If this error persists, please contact our customer support.'
            );
        } else {
            return (
                'We were temporarily unable to load some of your data. <br />' +
                'If this error persists or prevents your work, please contact our customer support.'
            );
        }
    };

    const getIndicator = () => {
        if (sourceHasProblem([currentRow])) {
            return (
                <Icon
                    tooltipInfo={{ id: errorIndicatorTooltipId, text: getErrorMessage() }}
                    type={IndicatorType.error}
                ></Icon>
            );
        } else if (isProcessing([currentRow])) {
            return (
                <Icon
                    tooltipInfo={{
                        id: processingIndicatorTooltipId,
                        text:
                            'Done! We will now load your data to Utopia Wallet. Please note that this may take up to 24 hours. <br />' +
                            'Check back later or see the Data Sources page for the current status.',
                    }}
                    type={IndicatorType.processing}
                ></Icon>
            );
        } else {
            return <Icon type={IndicatorType.success}></Icon>;
        }
    };

    return isEmpty && !value ? (
        <span className={styles['skeleton-cell-block']} />
    ) : (
        <>
            <span className={styles[`${columnDef.field}-label`]}>{value}</span>
            <span className={styles['data-source-title-status-indicator-block']}>{getIndicator()}</span>
        </>
    );
};
