import { useState } from 'react';
import { ChartType } from '../constants';

export const useChartTypeSelection = (chartType: ChartType) => {
    const [state, updateState] = useState({
        chartType: typeof chartType === 'number' ? chartType : ChartType.StackedBarChart,
    });
    const chartTypeValue = state.chartType;
    const updateChartTypeValue = (type: ChartType) => updateState({ chartType: type });

    return { chartTypeValue, updateChartTypeValue };
};
