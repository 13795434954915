import React from 'react';
import { DataSourceDropdownDataType, DataSourcesIdsListType, DataSourceTableRowType } from 'shared/types';
import { addDataSources, filterDataSourceMembers, removeDataSources } from '../utils';
import { v4 as uuidv4 } from 'uuid';
import { dataSourceRowsMock } from '../constants';

export const useDataSourceMemberTable = (
    sourceRows: DataSourceTableRowType[],
    dataSourcesDropdownData: DataSourceDropdownDataType,
) => {
    const [dataSourceRows, setDataSourceRows] = React.useState<DataSourceTableRowType[]>(sourceRows);
    const [dataSourcesToDeleteList, setDataSourcesToDeleteList] = React.useState<DataSourcesIdsListType>([]);
    const [dataSourcesToCreateList, setDataSourcesToCreateList] = React.useState<DataSourcesIdsListType>([]);

    const handleChange = (rowId: string, value: string) => {
        const selectedDataSource = dataSourcesDropdownData?.find((dataToChange) => dataToChange.name === value)!;

        setDataSourceRows(
            [...dataSourceRows].map((sourceRow: DataSourceTableRowType) => {
                if (rowId === sourceRow.rowId) {
                    return {
                        ...sourceRow,
                        name: value,
                        members:
                            sourceRows
                                .find((row) => row.name === value)
                                ?.members.map((member) => {
                                    return { ...member, checked: false };
                                }) ?? selectedDataSource.members,
                        noValueChosen: false,
                    };
                }
                return sourceRow;
            }),
        );
    };

    const handleCheckMember = (rowId: string, memberId: string) => {
        setDataSourceRows(
            [...dataSourceRows].map((sourceRow: DataSourceTableRowType) => {
                if (rowId === sourceRow.rowId) {
                    setDataSourcesToDeleteList(removeDataSources(sourceRow.members, memberId, dataSourcesToDeleteList));
                    setDataSourcesToCreateList(addDataSources(sourceRow.members, memberId, dataSourcesToCreateList));
                    const filteredMembers = filterDataSourceMembers(sourceRow.members, memberId);
                    return {
                        ...sourceRow,
                        members: [...filteredMembers],
                    };
                }
                return sourceRow;
            }),
        );
    };

    const handleDeleteSource = (rowId: string) => {
        const accountsFromSourceToDelete = [...dataSourceRows]
            .find((row: DataSourceTableRowType) => {
                return rowId === row.rowId;
            })!
            .members.filter((member) => member.checked);

        setDataSourcesToDeleteList([...dataSourcesToDeleteList, ...accountsFromSourceToDelete]);

        const dataSourceTableItems =
            dataSourceRows.length !== 1
                ? [...dataSourceRows].filter((sourceRow: DataSourceTableRowType) => {
                      return rowId !== sourceRow.rowId;
                  })
                : dataSourceRowsMock;

        setDataSourceRows(dataSourceTableItems);
    };

    const handleAddDataSource = () => {
        const dataRows = [...dataSourceRows];
        dataRows.push({ rowId: uuidv4(), name: '', members: [], isNew: true, noValueChosen: true });
        setDataSourceRows(dataRows);
    };

    return {
        dataSourceRows,
        dataSourcesDropdownData,
        dataSourcesToCreateList,
        dataSourcesToDeleteList,
        setDataSourceRows,
        handleChange,
        handleCheckMember,
        handleDeleteSource,
        handleAddDataSource,
    };
};
