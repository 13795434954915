export const datasourcesType = 'data-sources';

export const teamType = 'teams';

export const datasourcesText = 'Data Sources';

export const teamsText = 'Teams';

export const manageSourcesText = 'Manage sources';

export const manageTeamsText = 'Manage teams';
