import BigNumber from 'bignumber.js';
import { SortType } from 'gql/__generated__/types';
import { bigNumberFormat } from 'shared/constants';

BigNumber.config({ FORMAT: bigNumberFormat });

export const sortByBNStrings = (items: ReadonlyArray<any> | Array<any>, byField: string, order: SortType) => {
    return items.slice().sort((itemA, itemB) => {
        const valueA = new BigNumber(itemA[byField]);
        const valueB = new BigNumber(itemB[byField]);
        if (order === SortType.Asc) {
            if (valueA.lt(valueB)) return -1;
            if (valueA.gt(valueB)) return 1;
            return 0;
        }
        if (valueA.gt(valueB)) return -1;
        if (valueA.lt(valueB)) return 1;
        return 0;
    });
};
