import { useState } from 'react';
import { AccountFormState } from '../types';
import { validateInput } from 'pages/DataSourceSelected/utils';

export const useHandleForm = (accountToEdit: any) => {
    const initialState: AccountFormState = {
        account: {
            ...accountToEdit,
            email: { value: '', errors: '' },
            password: { value: '', errors: '' },
            title: { value: accountToEdit?.name || '', errors: '' },
        },
        showPassword: false,
        showErrors: false,
    };
    const [state, updateState]: any = useState<AccountFormState>(initialState);

    const inputOnChange = (name: string, value: string) => {
        updateState({
            ...state,
            account: {
                ...state.account,
                [name]: {
                    value: value.replace(/  +/g, ' '),
                    errors: validateInput(name, value),
                },
            },
            showErrors: false,
        });
    };

    const handleResetTitle = () =>
        updateState({
            account: { ...state.account, title: { value: '', errors: validateInput('title', '') } },
            showErrors: false,
        });
    const handleResetLogin = () =>
        updateState({
            account: { ...state.account, email: { value: '', errors: validateInput('email', '') } },
            showErrors: false,
        });
    const handleResetPassword = () =>
        updateState({
            ...state,
            account: { ...state.account, password: { value: '', errors: validateInput('password', '') } },
            showErrors: false,
        });
    const toggleShowPassword = () => updateState({ ...state, showPassword: !state.showPassword });

    const validateAllFields = () => {
        updateState({
            ...state,
            account: {
                ...state.account,
                password: {
                    value: state.account.password.value,
                    errors: validateInput('password', state.account.password.value),
                },
                email: { value: state.account.email.value, errors: validateInput('email', state.account.email.value) },
                title: { value: state.account.title.value, errors: validateInput('title', state.account.title.value) },
            },
            showErrors: true,
        });
    };
    return {
        state,
        inputOnChange,
        toggleShowPassword,
        handleResetLogin,
        handleResetPassword,
        handleResetTitle,
        validateAllFields,
    };
};
