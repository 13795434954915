import { Settings } from 'react-slick';

export const sliderConfig: Settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 380,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};
