import React, { FC } from 'react';
import cn from 'classnames';

import { PageSubtitleProps } from './types';

import styles from './PageSubtitle.module.scss';

export const PageSubtitle: FC<PageSubtitleProps> = ({ className, children }) => (
    <h2 className={cn(styles.container, className)}>{children}</h2>
);
