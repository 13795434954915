import { Actions, ActionTypes, DataSourceSelectedState } from './types';

export const initialState: DataSourceSelectedState = {
    errors: [],
    accountToEdit: null,
    currentEditing: null,
    isAccountLinked: false,
    isAccountFormValid: '',
    id: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state: DataSourceSelectedState = initialState, action: ActionTypes): DataSourceSelectedState {
    switch (action.type) {
        case Actions.SetErrors:
            return { ...state, errors: action.payload };
        case Actions.SetAccountToEdit:
            return { ...state, accountToEdit: action.payload };
        case Actions.SetCurrentEditing:
            return { ...state, currentEditing: action.payload };
        case Actions.SetIsAccountLinked:
            return { ...state, isAccountLinked: action.payload };
        case Actions.SetId:
            return { ...state, id: action.payload };
        case Actions.SetIsAccountFormValid:
            return { ...state, isAccountFormValid: action.payload };
        default:
            throw new Error();
    }
}
