import React, { FC } from 'react';
import { SearchedMemberProps } from './types';

import styles from './SearchedMember.module.scss';
import { Icon } from '../Icon/Icon';

export const SearchedMember: FC<SearchedMemberProps> = (props) => {
    const { onClick, testId, email } = props;
    return (
        <div
            data-testid={`${props.testId}.searchedMemberWrapper`}
            className={styles.searchedMemberWrapper}
            onClick={onClick}
        >
            <div className={styles.searchedMemberInfo}>
                <span className={styles.searchedMemberPhoto}>
                    <Icon type={'user'} />
                </span>
                <span data-testid={`${testId}.searchedMemberName`} className={styles.searchedMember}>
                    {email}
                </span>
            </div>
        </div>
    );
};
