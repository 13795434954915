import React from 'react';
import { isEmpty } from 'lodash';
import cn from 'classnames';

import { PageHeading } from 'components/PageHeading/PageHeading';

import { BreadcrumbsPropsType } from './types';

import styles from './Breadcrumbs.module.scss';

export const Breadcrumbs = ({
    idByName,
    setTabInBreadcrumbs,
    unsetAllTabIds,
    firstLevelBreadcrumbTitle,
    className,
}: BreadcrumbsPropsType) => {
    return (
        <PageHeading className={cn(styles.heading, className)}>
            <span
                className={cn(styles.item, {
                    [styles.link]: !isEmpty(idByName),
                })}
                onClick={unsetAllTabIds}
            >
                {firstLevelBreadcrumbTitle}
            </span>
            {!isEmpty(idByName) && ' / '}
            {idByName.map(({ tabName, entityName, entityId }, index) => {
                const isLastBreadcrumbItem = index === idByName.length - 1;
                return (
                    <span
                        key={entityId.id}
                        className={cn(styles.item, {
                            [styles.itemWithDropdown]: isLastBreadcrumbItem,
                        })}
                        onClick={() => {
                            if (!isLastBreadcrumbItem) {
                                setTabInBreadcrumbs!(tabName);
                            }
                        }}
                    >
                        <span
                            className={cn({
                                [styles.link]: !isLastBreadcrumbItem,
                            })}
                        >
                            {entityName || entityId.name}
                        </span>
                        {!isLastBreadcrumbItem && ' / '}
                    </span>
                );
            })}
        </PageHeading>
    );
};
