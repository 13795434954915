import React, { Component } from 'react';

import cn from 'classnames';

import { ExpandIconProps } from './types';
import { Icon } from 'components/Icon/Icon';

import styles from '../../Table.module.scss';

export class ExpandIcon extends Component<ExpandIconProps> {
    render() {
        const { config, row, onExpand } = this.props;
        const isArrowUp = config.withDetails && config.canExpand && row.expanded;
        const isArrowDown = config.withDetails && config.canExpand && !row.expanded;
        return (
            <span
                data-testid="expand-icon"
                className={cn(styles['expand-icon'], {
                    [styles['expand-icon-active']]: config.withDetails && row.children,
                })}
            >
                {isArrowUp && <Icon data-testid="arrow-up" type="arrowup" onClick={onExpand} />}
                {isArrowDown && <Icon data-testid="arrow-down" type="arrowdown" onClick={onExpand} />}
            </span>
        );
    }
}
