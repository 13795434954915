import React, { FC, useState } from 'react';
import { Input } from 'components/Form/components/Input/Input';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';
import { EXPORT_MODAL_DESCRIPTION, EXPORT_MODAL_TITLE } from './constants';
import styles from './EarningsExportModal.module.scss';
import { EarningsExportModalProps } from './interfaces';
import { useExportEarningsMutation } from 'gql/mutations/__generated__/exportEarnings.generated';
import { toast } from 'react-toastify';
import { validateEmail } from './utils/validateEmail';
import { useGetEarningFilters } from 'shared/hooks/earnings/useGetEarningFilters/useGetEarningFilters';
import { parseQueryToSelectTab } from 'pages/Earnings/hooks/utils/generatePathname';
import { GroupByOptions } from 'shared/constants';

export const EarningsExportModal: FC<EarningsExportModalProps> = (props) => {
    const { isModalOpen, closeExportModal, toggleExportModal } = props;
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const [exportEarnings, { error: emailSendingError, loading: isEmailSending }] = useExportEarningsMutation();

    const { category, date, sourceId, search, query, retailerId, productId } = useGetEarningFilters();

    const selectedTab = parseQueryToSelectTab(query.toString());

    function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    function handleEmailReset() {
        setEmail('');
    }

    async function handleSendExportRequest() {
        const validationError = validateEmail(email);
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            setError('');

            const isSuccess = await exportEarnings({
                variables: {
                    email,
                    filter: {
                        category,
                        startDate: date.start,
                        endDate: date.end,
                        sourceId,
                        retailerId,
                        songId: productId,
                        retailerName: selectedTab === GroupByOptions.Retailer ? search : '',
                        songName: selectedTab === GroupByOptions.Product ? search : '',
                        sourceName: selectedTab === GroupByOptions.Source ? search : '',
                    },
                },
            });
            if (isSuccess) {
                toast(
                    'We are working on preparing the report for you. You will receive a link to the report in an email once it is ready.',
                    { type: 'success' },
                );
            }
            handleEmailReset();
            closeExportModal();
        } catch (e) {
            console.error(e);
            toast(emailSendingError?.message, { type: 'error' });
        }
    }

    return (
        <ModalDialog
            isOpen={isModalOpen}
            toggleModal={toggleExportModal}
            onOk={handleSendExportRequest}
            onCancel={closeExportModal}
            onOkBtnText="SEND"
            onCancelBtnText="CANCEL"
            title={EXPORT_MODAL_TITLE}
            className={styles.modalContainer}
            isOkLoading={isEmailSending}
        >
            <div className={styles.modalDescription}>{EXPORT_MODAL_DESCRIPTION}</div>
            <Input
                className={styles.modalEmail}
                id="email"
                label="Email"
                name="email"
                type="email"
                value={email}
                icon="clear"
                onChange={handleEmailChange}
                onIconClick={handleEmailReset}
            />
            {error && <div className={styles.errorMsg}>{error}</div>}
        </ModalDialog>
    );
};
