export const firstname = 'firstName';

export const isEmptyError = 'First Name cannot be empty!';

export const emailLabel = 'Email';

export const passLabel = 'Password';

export const changedOnText = 'Last changed on ';

export const phoneLabel = 'Phone Number';

export const verifiedOnText = 'Verified on';
