import React, { FC, ReactElement, useCallback, useReducer, useState } from 'react';
import cn from 'classnames';

import { AccountData, Actions, SourceAccount } from './types';
import { Button } from 'components/Button/Button';

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { PageSubtitle } from 'components/PageSubtitle/PageSubtitle';
import { AccountForm } from 'components/AccountForm/AccountForm';
import { initialState, reducer } from './reducer';

import { LoaderComponent } from 'components/Loader/Loader';

import styles from './DataSourceSelected.module.scss';
import commonStyles from '../CommonStyles.module.scss';
import { DataSourceItemTable } from 'pages/DataSourceItemTable/DataSourceItemTable';
import { useRemoveSourceCredentialMutation } from 'gql/mutations/__generated__/removeSourceCredential.generated';
import { useEditUserSourceMutation } from 'gql/mutations/__generated__/editUserSource.generated';
import { useSetSourceCredentialMutation } from 'gql/mutations/__generated__/setSourceCrendential.generated';
import { CustomError, LinkedSource } from 'shared/types';
import { ApolloError, NetworkStatus } from '@apollo/client';
import { ACCOUNTS_TITLE, CREATION_ERROR, DELETING_ERROR, NO_DATA_TITLE, ONE_ACCOUNT_TITLE } from './constants';
import { useLinkedSources } from 'shared/hooks/useLinkedSources/useLinkedSources';
import { useTeamAccountsListData } from 'components/TeamAccountsList/hooks/useTeamAccountsListData';

export const DataSourceSelected: FC = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const params = useParams();
    const { id } = params;
    const { data, refetch, networkStatus } = useLinkedSources(true, id);

    const [removeSourceCredentialMutation, { loading: loadingOnAccountDelete }] = useRemoveSourceCredentialMutation();
    const [editUserSourceMutation, { loading: loadingOnAccountEdit }] = useEditUserSourceMutation();
    const [setSourceCredentialMutation, { loading: loadingOnAccountCreate }] = useSetSourceCredentialMutation();
    const { refetch: refetchTeamAccountsListData } = useTeamAccountsListData();
    const [isAnyRowsOpened, setAnyRowsOpened] = useState(false);

    const navigate = useNavigate();
    const linkedAccounts = data?.linkedSources?.filter((linkedSource) => linkedSource);
    const selectedSourceLinkedAccounts =
        linkedAccounts?.find((linkedSource) => linkedSource?.id === id)?.linkedSources ?? [];

    const isLoading =
        networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.refetch ||
        loadingOnAccountDelete ||
        loadingOnAccountCreate ||
        loadingOnAccountEdit;

    const getSource = useCallback(() => {
        const source = linkedAccounts?.find((src) => src?.id === params.id);
        return source;
    }, [linkedAccounts, params.id]);

    const source = getSource();

    const idByName = [
        {
            entityId: { id: source?.id! ?? '', name: source?.name! },
            entityName: source?.name ?? '',
            tabName: '',
        },
    ];

    const goToDataSources = () => {
        navigate('/data-sources');
    };

    const changeSelectedDataSource = (_tabName: string, sourceId: string) => {
        navigate(`/account/data-sources/${sourceId}`);
    };

    const options = linkedAccounts?.filter((i) => i?.id !== source?.id) as [];

    const noData = selectedSourceLinkedAccounts.length === 0;

    const pageSubtitle = noData
        ? NO_DATA_TITLE
        : `${selectedSourceLinkedAccounts.length} ${
              selectedSourceLinkedAccounts.length === 1 ? ONE_ACCOUNT_TITLE : ACCOUNTS_TITLE
          }`;

    const createDataSourceAccount = async (account: AccountData) => {
        try {
            const { title, email, password } = account;
            await setSourceCredentialMutation({
                variables: {
                    credentials: { email: email.value, password: password.value },
                    internalTitle: title.value,
                    sourceId: params.id!,
                },
            });
            refetch();
            dispatch({ type: Actions.SetAccountToEdit, payload: null });
        } catch (error) {
            const errors: CustomError[] = [];
            if ((error as ApolloError)?.message) {
                errors.push(error as ApolloError);
            } else {
                errors.push({
                    message: CREATION_ERROR,
                });
            }
            dispatch({ type: Actions.SetErrors, payload: errors });
        }
    };

    const updateDataSourceAccount = async (rowId: string, accountData: any) => {
        try {
            const { email, password, title } = accountData;
            await editUserSourceMutation({
                variables: {
                    credentials: { email: email.value, password: password.value },
                    internalTitle: title.value,
                    userSourceId: rowId,
                },
            });
            dispatch({ type: Actions.SetIsAccountFormValid, payload: '' });
            refetch();
        } catch (error) {
            const errors: CustomError[] = [];
            if ((error as ApolloError)?.message) {
                errors.push(error as ApolloError);
            } else {
                errors.push({
                    message: 'Something went wrong during account updating.',
                });
                dispatch({ type: Actions.SetErrors, payload: errors });
            }
        }
    };

    const deleteDataSourceAccount = async (accountId: string) => {
        try {
            await removeSourceCredentialMutation({
                variables: { userSourceId: accountId },
            });
            refetch();
            refetchTeamAccountsListData();
        } catch (error) {
            const errors: CustomError[] = [];
            if ((error as ApolloError)?.message) {
                errors.push(error as ApolloError);
            } else {
                errors.push({
                    message: DELETING_ERROR,
                });
            }
            refetch();
            refetchTeamAccountsListData();
            dispatch({ type: Actions.SetErrors, payload: errors });
        }
    };

    const handleSave = (accountData: AccountData, rowId?: string) => {
        const { title, email, password } = accountData;

        if (!title.errors && !email.errors && !password.errors) {
            if (rowId) {
                dispatch({ type: Actions.SetIsAccountFormValid, payload: rowId });
                return updateDataSourceAccount(rowId, accountData);
            }
            createDataSourceAccount(accountData);
        }
    };

    const openModalForm = (accountToEdit: SourceAccount | null = null) => {
        dispatch({ type: Actions.SetAccountToEdit, payload: accountToEdit });
    };

    const closeModalForm = () => {
        dispatch({ type: Actions.SetAccountToEdit, payload: null });
    };

    const getAccountForm = (): ReactElement => {
        return <AccountForm onCancel={closeModalForm} onSave={handleSave} />;
    };

    return (
        <>
            {isLoading ? (
                <div className={styles.loader}>
                    <LoaderComponent />
                </div>
            ) : (
                <div className={cn(commonStyles['main-container'], styles.container)}>
                    <Breadcrumbs
                        className={styles.heading}
                        options={options}
                        idByName={idByName}
                        setTabId={changeSelectedDataSource}
                        setTabInBreadcrumbs={goToDataSources}
                        unsetAllTabIds={goToDataSources}
                        firstLevelBreadcrumbTitle={'Data Sources'}
                    />
                    <PageSubtitle>{pageSubtitle}</PageSubtitle>
                    {!noData ? (
                        <DataSourceItemTable
                            linkedAccounts={selectedSourceLinkedAccounts as LinkedSource[]}
                            onEditAccount={handleSave}
                            onDeleteAccount={deleteDataSourceAccount}
                            isAccountFormValid={state.isAccountFormValid}
                            onSetForbiddenToOpen={setAnyRowsOpened}
                            isAddingNewAccount={!!state.accountToEdit}
                        />
                    ) : null}
                    {state.accountToEdit ? (
                        getAccountForm()
                    ) : (
                        <div className={noData ? styles['btn-block'] : ''}>
                            <div>
                                <Button
                                    style="primary"
                                    isLink={false}
                                    type="primary"
                                    onClick={openModalForm}
                                    isDisabled={isAnyRowsOpened}
                                >
                                    Add Account
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
