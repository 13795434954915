import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditUserSourceMutationVariables = Types.Exact<{
  credentials: Types.SourceCredentials;
  internalTitle: Types.Scalars['String'];
  userSourceId: Types.Scalars['ID'];
}>;


export type EditUserSourceMutation = { __typename?: 'Mutation', editUserSource: { __typename?: 'LinkedSource', category?: Types.Category | null, id?: string | null, name?: string | null, type?: string | null } };


export const EditUserSourceDocument = gql`
    mutation editUserSource($credentials: SourceCredentials!, $internalTitle: String!, $userSourceId: ID!) {
  editUserSource(
    credentials: $credentials
    internalTitle: $internalTitle
    userSourceId: $userSourceId
  ) {
    category
    id
    name
    type
  }
}
    `;
export type EditUserSourceMutationFn = Apollo.MutationFunction<EditUserSourceMutation, EditUserSourceMutationVariables>;

/**
 * __useEditUserSourceMutation__
 *
 * To run a mutation, you first call `useEditUserSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserSourceMutation, { data, loading, error }] = useEditUserSourceMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *      internalTitle: // value for 'internalTitle'
 *      userSourceId: // value for 'userSourceId'
 *   },
 * });
 */
export function useEditUserSourceMutation(baseOptions?: Apollo.MutationHookOptions<EditUserSourceMutation, EditUserSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserSourceMutation, EditUserSourceMutationVariables>(EditUserSourceDocument, options);
      }
export type EditUserSourceMutationHookResult = ReturnType<typeof useEditUserSourceMutation>;
export type EditUserSourceMutationResult = Apollo.MutationResult<EditUserSourceMutation>;
export type EditUserSourceMutationOptions = Apollo.BaseMutationOptions<EditUserSourceMutation, EditUserSourceMutationVariables>;