import React, { FC } from 'react';
import cn from 'classnames';

import { CustomLegendProps } from './types';

import { CustomLegendName } from './components/CustomLegendName';
import { CustomLegendValue } from './components/CustomLegendValue';
import { CustomLegendSkeleton } from './components/CustomLegendSkeleton';
import { emptyLegendSkeleton } from 'shared/constants';

import styles from './CustomLegend.module.scss';
import { useCategorySelection } from 'pages/Earnings/hooks/useCategorySelection';

export const CustomLegend: FC<CustomLegendProps> = ({ earnings, testId, isEmpty }) => {
    const сustomLegendSkeleton = emptyLegendSkeleton;
    const { setSelectedCategory } = useCategorySelection();

    return (
        <ul
            data-testid={`${testId}.customLegendWrapper`}
            className={cn(styles['legend-list'], { [styles.skeleton]: isEmpty })}
        >
            {isEmpty
                ? сustomLegendSkeleton.map((_, index) => (
                      <li data-testid={`${testId}.customLegendItem`} className={styles['legend-item']} key={index}>
                          <CustomLegendSkeleton />
                      </li>
                  ))
                : earnings.map((source, index) => (
                      <li data-testid={`${testId}.customLegendItem`} className={styles['legend-item']} key={index}>
                          <CustomLegendName
                              color={source.color}
                              testId={testId}
                              legendName={source.category}
                              linkTo={setSelectedCategory}
                          />
                          <CustomLegendValue y={source.y} testId={testId}>
                              {source.totalAmount}
                          </CustomLegendValue>
                      </li>
                  ))}
        </ul>
    );
};
