import React, { FC } from 'react';
import { TeamAccountsListTable } from './components/TeamAccountsListTable/TeamAccountListTable';
import { ColumnDef } from 'components/Table/types';

import { ITeamAccountsList } from './types';
import { fields, headers } from './constants';
import { MembersCellRenderer } from 'components/Table/components/CellRenderers/MembersCellRenderer/MembersCellRenderer';
import { DefaultCellRenderer } from 'components/Table/components/CellRenderers/DefaultCellRenderer/DefaultCellRenderer';

export const TeamAccountsList: FC<ITeamAccountsList> = (props) => {
    const { teams, editTeam, onDeleteTeam, dataSourcesDropdownData, teamOwnerInfo, canEditTeam, leaveTeam } = props;

    const teamColumnDefs: ColumnDef[] = [
        {
            field: fields.title,
            headerName: headers.title,
            cellRenderer: DefaultCellRenderer,
        },
        {
            field: fields.dataSources,
            formatBy: fields.dataSources,
            headerName: headers.dataSources,
            cellRenderer: DefaultCellRenderer,
        },
        {
            field: fields.members,
            headerName: headers.members,
            cellRenderer: MembersCellRenderer,
        },
    ];
    return (
        <>
            <TeamAccountsListTable
                columnDefs={teamColumnDefs}
                tableItems={teams}
                leaveTeam={leaveTeam}
                onSaveChanges={editTeam}
                onDeleteTeam={onDeleteTeam}
                dataSourcesDropdownData={dataSourcesDropdownData}
                teamOwnerInfo={teamOwnerInfo}
                canEditTeam={canEditTeam}
            />
        </>
    );
};
