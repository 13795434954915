import { hotjar } from 'react-hotjar';
import { UserInfo } from './shared/providers/UserInfoProvider/interfaces';

export const startHotjar = () => {
    // @ts-ignore
    const hjid: number = process.env.REACT_APP_UTOPIA_HJ_ID;
    // @ts-ignore
    const hjsv: number = process.env.REACT_APP_UTOPIA_HJ_SV;

    if (hjid && hjsv) {
        hotjar.initialize(hjid, hjsv);
    }
};

export const identifyHotjarUser = (userInfo: UserInfo) => {
    const hjid = process.env.REACT_APP_UTOPIA_HJ_ID;
    const hjsv = process.env.REACT_APP_UTOPIA_HJ_SV;

    if (userInfo && userInfo.id && hjid && hjsv) {
        hotjar.identify(userInfo.id, userInfo);
    }
};
