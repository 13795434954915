import React, { useContext, useEffect } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'shared/providers/AuthProvider/AuthProvider';
import { LoaderComponent } from 'components/Loader/Loader';
import * as AuthService from 'shared/services/AuthService';
import styles from './Auth.module.scss';

export const Auth = () => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const searchResult = qs.parse(search, { ignoreQueryPrefix: true });

    const code = searchResult.code?.toString();

    const { isUserAuthenticated, authenticateUser } = useContext(AuthContext);

    useEffect(() => {
        if (code && !isUserAuthenticated) {
            AuthService.getAccessToken(code).then(() => {
                const to = AuthService.getLocation();

                authenticateUser();
                navigate(to);
            });
        }
    }, [authenticateUser, code, isUserAuthenticated, navigate]);

    useEffect(() => {
        if (!code) {
            navigate('/');
        }
    }, [code, navigate]);

    return (
        <div className={styles['auth-container']}>
            <LoaderComponent />
        </div>
    );
};
