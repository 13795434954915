import React, { FC } from 'react';

import { CustomLegendValueProps } from '../types';
import { percentSign } from 'shared/constants';
import { getFormattedAndRoundedValue } from 'shared/utils/getFormattedAndRoundedValue';

import styles from '../CustomLegend.module.scss';

export const CustomLegendValue: FC<CustomLegendValueProps> = ({ children, testId, y }) => {
    return (
        <div className={styles['legend-item-value']}>
            <span data-testid={`${testId}.customLegendAmount`} className={styles['legend-item-amount']}>
                {getFormattedAndRoundedValue(children as string)}
            </span>
            <span className={styles['legend-item-percent']}>
                {y.toFixed(0)}
                {percentSign}
            </span>
        </div>
    );
};
