import { getSelectedPeriodDates } from 'shared/providers/DateFilterProvider/utils/getSelectedPeriodDates';
import { PeriodValues } from 'shared/types';
import { ChartData, ChartXMinMax } from '../interfaces';

export const getChartXMinMax = (chartData: ChartData[], period: PeriodValues): ChartXMinMax => {
    const now = new Date();

    if (!chartData.length) {
        const { dateFrom, dateTo } = getSelectedPeriodDates(period);
        return { chartXMax: dateTo.getTime(), chartXMin: dateFrom.getTime() };
    }

    const lineRanges = chartData.reduce((prev, current) => {
        if (current.data.length === 1) {
            return [...prev, current.data[0].x];
        } else if (current.data.length > 1) {
            return [...prev, current.data[0].x, current.data[current.data.length - 1].x];
        }

        return prev;
    }, [] as number[]);

    const chartXMin = Math.min(...lineRanges);
    const chartXMax = now.getTime();

    return { chartXMin, chartXMax };
};
