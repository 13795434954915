import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { LeftNavigationMenuItem } from './components/LeftNavigationMenuItem/LeftNavigationMenuItem';

import { LeftNavMenuItem } from './types';
import styles from './LeftNavigationMenu.module.scss';

import { leftNavMenuItems } from './constants';

export const EnhancedLeftNavigationMenu: FC = () => {
    const location = useLocation();
    const isNavMenuItemActive = useCallback(
        (navMenuItem: LeftNavMenuItem) => {
            const path = `/${location.pathname.split('/')[1]}`;
            return path === navMenuItem.link;
        },
        [location],
    );

    return (
        <aside className={styles.sideBar}>
            <ul className={styles.navList}>
                {leftNavMenuItems.map((leftNavMenuItem) => (
                    <LeftNavigationMenuItem
                        key={leftNavMenuItem.label}
                        label={leftNavMenuItem.label}
                        to={leftNavMenuItem.link}
                        isNavMenuItemActive={isNavMenuItemActive(leftNavMenuItem)}
                        testId="leftNavigationMenu"
                    >
                        {leftNavMenuItem.label}
                    </LeftNavigationMenuItem>
                ))}
            </ul>
        </aside>
    );
};
