import React, { FC } from 'react';
import { LoaderComponent } from '../Loader/Loader';
import { useGetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';
import { getLinkedAccountsNumber } from '../../shared/utils/getLinkedAccountsNumber';

export const DataSourcesCounter: FC = () => {
    const { data, loading } = useGetLinkedSourcesQuery();
    const accounts = data?.linkedSources ?? [];
    const { count } = getLinkedAccountsNumber(accounts);

    return <>{loading ? <LoaderComponent /> : count}</>;
};
