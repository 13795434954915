import { useContext, useMemo } from 'react';
import { CategoryValues, PAGINATION_LIMIT, Routes } from 'shared/constants';
import { CategoryValuesType } from 'shared/types';

import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { RangeDate, Sort, SortType } from 'gql/__generated__/types';
import { PeriodValues } from 'shared/types';
import { getSelectedPeriodDates } from 'shared/providers/DateFilterProvider/utils/getSelectedPeriodDates';
import { Mask } from '../interfaces';
import { TeamsFilterContext } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';
import { TeamValues } from 'shared/hooks/useTeamSelection/constants';
import { useTeamSelection } from 'shared/hooks/useTeamSelection/useTeamSelection';
import { topFivePagination } from '../useGetEarnings/constants';

export const useGetEarningFilters = () => {
    const { search, pathname } = useLocation();
    const { getTeamIdByName } = useTeamSelection();

    const searchResult = qs.parse(search, { ignoreQueryPrefix: true });

    const isEarnings = pathname === Routes.earnings;

    const query = searchResult.q ?? '';

    const product = searchResult.Product as Mask;
    const productId = product?.id ?? '';

    const source = searchResult.Source as Mask;
    const sourceId = source?.id ?? '';

    const retailer = searchResult.Retailer as Mask;
    const retailerId = retailer?.id ?? '';

    const category = useMemo(() => {
        const obtainedCategory = searchResult.category as CategoryValuesType;

        return !obtainedCategory || obtainedCategory === CategoryValues.All ? undefined : obtainedCategory;
    }, [searchResult.category]);

    const pageRes = parseInt(searchResult.page as string, 10);
    const page = isNaN(pageRes) ? 1 : pageRes;

    const pagination = useMemo(
        () => (isEarnings ? { page, limit: PAGINATION_LIMIT } : topFivePagination),
        [page, isEarnings],
    );
    const searchName = (searchResult.search as string) ?? '';

    const period = (searchResult.selectedPeriod as PeriodValues) ?? PeriodValues.allTime;

    const date: RangeDate = useMemo(() => {
        const periodDates = getSelectedPeriodDates(period);
        const dateFrom = new Date(periodDates?.dateFrom ?? '');
        const dateTo = new Date(periodDates?.dateTo ?? '');

        // set dates to start/end day for better caching
        dateFrom.setUTCHours(0, 0, 0, 0);
        dateTo.setUTCHours(23, 59, 59, 999);
        return {
            start: dateFrom.toISOString(),
            end: dateTo.toISOString(),
        };
    }, [period]);

    const sortType = (searchResult.sort as SortType) ?? SortType.Desc;

    const sort: Sort = useMemo(() => ({ field: 'totalAmount', type: sortType }), [sortType]);

    const { selectedTeam } = useContext(TeamsFilterContext);
    const teamIdByName = getTeamIdByName(selectedTeam);
    const showAllTeams: boolean =
        selectedTeam === TeamValues.allTeams || ((searchResult.showAllTeams as string)! === 'true' ?? false);
    const teamId =
        selectedTeam !== TeamValues.allTeams && selectedTeam !== TeamValues.myEarnings
            ? teamIdByName
                ? teamIdByName
                : (searchResult.teamId as string) ?? ''
            : (searchResult.teamId as string) ?? '';

    return {
        query,
        sourceId,
        productId,
        retailerId,
        category,
        pagination,
        search: searchName,
        date,
        period,
        sort,
        showAllTeams,
        teamId,
        isEarnings,
    };
};
