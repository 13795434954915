export const closeButtonText = 'Close';

export const applyChangesButtonText = 'Apply Changes';

export const createTeamButtonText = 'Create Team';

export const deleteTeamButtonText = 'Delete Team';

export const okModalDeleteTeamBtnText = 'remove';
export const cancelModalDeleteTeamBtnText = 'cancel';
export const titleModalDeleteTeamBtn = 'Delete Team';
export const descriptionModalDeleteTeamText = 'Are you sure you want to delete this team?';

export const leaveTeamButtonText = 'Leave Team';

export const okModalLeaveTeamBtnText = 'leave';
export const cancelModalLeaveTeamBtnText = 'cancel';
export const titleModalLeaveTeamBtn = 'Leave team';
export const descriptionModalLeaveTeamText = 'Are you sure you want to leave this team?';
