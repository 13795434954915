import { ApolloError } from '@apollo/client';
import { Category, Account, Projection, AccountStatuses, UserSourceFileStatus } from 'gql/__generated__/types';
import { AllCategories } from './constants';

export type DataItemType = {
    name: string;
    id: string;
    amount: string;
    date: number;
    selected: boolean;
    category: string;
};

export type DataType = Array<DataItemType>;

export type RevenueTableItemType = {
    __typename?: 'Revenue';
    id: string;
    amount: string;
    name: string;
    category?: string | null;
    accounts?: Account[];
    parentRow?: { [key: string]: any };
};

export type DataSourceDropdownDataType = {
    id: string;
    members: DataSourceTableMemberType[];
    name: string;
    type: string;
}[];

export type TeamTableItemType = {
    isConfirmedInvitation?: boolean;
    invitationEmail?: string;
    role: string;
    member?: object;
    userId?: string;
    shared?: false;
    id: string;
    newMember?: boolean;
    teamId?: string;
    userToCreate?: boolean;
    name?: string;
    surname?: string;
};

export type TableItemType = { [key: string]: any };

export type TableItemsType = Array<TableItemType>;
export type DataSourceTableMemberType = {
    id: string;
    name: string;
    checked?: boolean;
    wasPreviouslyCreated?: boolean;
    sourceId: string;
    type: string;
    external?: boolean;
};

export type TeamSourceTableRowType = {
    name: string;
    sourceId: string;
    type: string;
    userSourceId: string;
};

export type DataSourceTableRowType = {
    name: string;
    members: DataSourceTableMemberType[];
    rowId: string;
    isNew?: boolean;
    noValueChosen?: boolean;
};

export type DataSourcesIdsListType = Array<{
    checked?: boolean;
    id: string;
    sourceId: string;
    type: string;
    wasPreviouslyCreated?: boolean;
    name: string;
}>;

export type TeamAccountsMemberType = {
    id: string;
};

export type TeamAccountsDataSourcesType = {
    source: string;
    members?: DataSourceTableMemberType[];
};

export type TeamAccountsTableItemType = {
    id: string;
    rowId: string;
    name: string;
    members: TeamTableItemType[];
    sources: TeamAccountsDataSourcesType[];
    collapsed: boolean;
    isCreated?: boolean;
};

export type RevenueTableItemsType = Array<RevenueTableItemType>;

export type TeamTableItemsType = TeamTableItemType[];

export type DataSourceTableItemsType = Array<DataSourceTableRowType>;

export type TeamAccountsTableItemsType = Array<TeamAccountsTableItemType>;

export type ChartDataItemType = {
    id: string;
    amount: string;
    name: string;
    date: string;
};

export type ChartDataType = Array<ChartDataItemType>;

export type CustomError = ApolloError | { [key: string]: any };

export interface NavMenuItem {
    label: string;
    link: string;
}

export type DataSource = {
    name: string;
    category: string;
    accounts: Accounts[];
};

export type LinkedSource = {
    __typename?: 'LinkedSource';
    category?: string | null;
    id?: string | null;
    name?: string | null;
    type?: string | null;
    sourceId?: string | null;
    status: AccountStatuses | null;
    parseStatus?: UserSourceFileStatus[] | null;
    lastParsing?: { value?: string | null; unix?: string | null };
} | null;

export type Accounts = {
    id?: string;
    category?: string;
    visibleName?: string;
};

export interface Earnings {
    id: string;
    name: string;
    amount: {
        amount: number;
        date: string;
    }[];
    totalAmount: number;
    projection?: Projection | null;
}

export interface EarningsWithAccounts extends Earnings {
    category?: CategoryValuesType | null;
    accounts: Account[];
}

export interface EarningsPagination {
    limit: number;
    page: number;
    total: number;
}

export interface GraphqlMeta {
    isLoading: boolean;
    error?: ApolloError;
}

export interface EarningsResponseBase extends GraphqlMeta {
    pagination?: EarningsPagination;
    totalEarnings: number;
    hasPagination?: boolean;
}
export interface EarningsResponse extends EarningsResponseBase {
    earnings: Earnings[];
}

export type CategoryValuesType = Category | AllCategories;

export type StaticSource = {
    __typename?: 'StaticSource';
    category?: Category;
    id?: string;
    linkedSources?: LinkedSource[];
    name?: string;
    type?: string;
};

export enum PeriodValues {
    allTime = 'all-time',
    lastThreeMonths = 'last-three-months',
    thisYear = 'this-year',
}

export enum MemberRoles {
    editor = 'EDITOR',
    viewer = 'VIEWER',
    owner = 'OWNER',
}
