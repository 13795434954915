import { Chart, SVGElement } from 'highcharts';
import { CHART_HEADER_LABEL_STYLES } from '../constants';

export const createHeaderLabel = (label: string, chart: Chart): SVGElement => {
    return chart.renderer
        .label(label, 0, 12)
        .attr({
            zIndex: 12,
        })
        .css(CHART_HEADER_LABEL_STYLES)
        .add();
};
