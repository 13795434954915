import { SortType, UserInfo } from 'gql/__generated__/types';
import { Component, FC, ReactElement, ReactNode } from 'react';

import {
    DataSourceDropdownDataType,
    DataSourceTableMemberType,
    EarningsPagination,
    RevenueTableItemType,
    TableItemsType,
} from 'shared/types';

export enum Actions {
    SetSort = 'SET_SORT',
    SetSortToDefaultValue = 'SET_SORT_TO_DEFAULT_VALUE',
    SetDefaultSort = 'SET_DEFAULT_SORT',
    SetItemsLoadedCount = 'SET_ITEMS_LOADED_COUNT',
    SetSearch = 'SET_SEARCH',
}

//TODO figure out expandedRow type
export interface TableRowProps {
    [key: string]: any;
    className?: string;
    config: {
        selectable: boolean;
        withDetails: boolean;
        classnames?: Array<string | { [key: string]: (row: { [key: string]: any }) => boolean }>;
        onSaveChanges?: any;
        onDeleteTeam?: any;
        onLeaveTeam?: any;
        collapsedRow?: any;
        canExpand?: string;
        dataSourcesDropdownData?: DataSourceDropdownDataType;
        teamOwnerInfo?: UserInfo;
    };
    parentRow?: { [key: string]: any };
    row: { [key: string]: any };
    rowIndex?: number;
    columnDefs: ColumnDef[];
    isChildRow?: boolean;
    onRowSelected?: (row: any) => void;
    onRowExpanded?: (row: any) => void;
    isEmpty?: boolean;
    canCollapseOnRowClick?: boolean;
}

export interface DefaultHeaderCellRendererProps {
    config: {
        selectable?: boolean;
        withDetails?: boolean;
        sort: {
            field: string | null;
            type: SortType;
        };
    };
    columnDef: ColumnDef;
    onSort: (field: string) => void;
    onDefaultSort: (field: string) => void;
    isEmpty?: boolean;
}

export interface TableCellProps {
    matchedMediaQueryKey?: string;
    className?: any;
    rowIndex?: number;
    isHeaderCell?: boolean;
    columnDef: ColumnDef;
    cellRenderer?: Component | ReactElement | Function | null;
    row?: { [key: string]: any };
    isEmpty?: boolean;
}

export interface CellRendererProps {
    value: string | number | UserMemberValue;
    config: {
        selectable: boolean;
        withDetails: boolean;
    };
    row: { [key: string]: any };
    rowIndex: number;
    columnDef: ColumnDef;
    parentRow?: { [key: string]: any };
    onExpandRow?: () => void;
    onSelectRow: () => void;
    isEmpty?: boolean;
}

export interface TeamMemberCellRendererProps {
    value: UserMemberValue;
    row: { [key: string]: any };
    columnDef: ColumnDef;
}

export interface UserMemberValue {
    userName: string;
    userAvatarUrl: string;
}

export type UserMember = {
    id: string;
    email: string;
    newMember?: boolean;
};

export interface DataSourceMembersCellRendererProps {
    value: DataSourceTableMemberType[];
    row: { [key: string]: any };
    columnDef: ColumnDef;
}

export interface DataSourceMemberValue {
    id: string;
    email: string;
    checked: boolean;
    type: string;
    sourceId: string;
}

export interface ColumnDef {
    sortFunc?: (a: any, b: any) => number;
    tableItems?: TableItemsType;
    defaultSort?: boolean;
    noLeftCellPadding?: boolean;
    onClick?: (value: any, row?: { [key: string]: any }) => void;
    onChange?: (rowId: string, value: string) => void;
    onEditRow?: (row: { [key: string]: any }) => void;
    onDeleteRow?: (row: { [key: string]: any }) => void;
    onDeleteMember?: (rowId: string, memberId: string) => void;
    onCheckMember?: (rowId: string, memberId: string) => void;
    onDeleteSource?: (rowId: string) => void;
    headerName?: string | ReactNode;
    onCollapseRow?: (rowId: string) => void;
    className?: any;
    suggestedUsersList?: DataSourceDropdownDataType;
    headerCellClassNames?: string;
    cellClassNames?: string;
    field: string;
    sortable?: boolean;
    clickable?: boolean | ((row: { [key: string]: any }) => boolean);
    cellRenderer?: any;
    isOnlyChild?: boolean;
    isViewer?: boolean;
    isEditor?: boolean;
    headerCellRenderer?:
        | (new (props: DefaultHeaderCellRendererProps) => Component)
        | ((props: DefaultHeaderCellRendererProps) => ReactElement | null);
    childCellRenderer?:
        | (new (props: CellRendererProps) => Component)
        | ((props: CellRendererProps) => ReactElement | null);
    valueFormatter?: (value: string | number) => string;
    formatBy?: string;
    responsive?: {
        [key: string]: {
            cellRenderer?:
                | (new (props: CellRendererProps) => Component)
                | ((props: CellRendererProps) => ReactElement | null);
            childCellRenderer?:
                | (new (props: CellRendererProps) => Component)
                | ((props: CellRendererProps) => ReactElement | null);
        };
    };
}

export interface TableItemType extends RevenueTableItemType {
    id: string;
    color?: string;
    selected?: boolean;
    share?: string;
    shared?: boolean;
    children?: TableItemType[];
}

export type TableItemsWithAdditionalFieldsType = ReadonlyArray<TableItemType>;

export type TableProps = {
    shouldShowEmptyState?: boolean;
    className?: string;
    columnDefs: ColumnDef[];
    tableItems: TableItemsType;
    selectedItems?: Array<{ name: string; id: string; color: string }>;
    expandedItems?: Array<{ name: string; id: string; expanded: boolean; children?: any }>;
    collapsedItems?: Array<{ name: string; id: string; collapsed: boolean }>;
    selectable?: boolean;
    withDetails?: boolean;
    withFilter?: boolean;
    filterBy?: string;
    filterInputPlaceholder?: string;
    viewAll?: {
        navigateTo?: string;
        title: string;
        icon: Component | ReactElement | null;
    };
    rowRenderer: FC<TableRowProps>;
    onRowSelected?: (row: any) => void;
    onRowExpanded?: (row: any) => void;
    onCollapseRow?: (rowId: string) => void;
    onSaveChanges?: any;
    rowRendererConfig?: { [key: string]: any };
    defaultSort?: any;
    headerClassName?: string;
    computedFields?: (tableItem: TableItemType) => { [key: string]: any };
    pagination?: EarningsPagination;
    isLoading?: boolean;
    hasPagination?: boolean;
    state: any;
    onSort: (field: string) => void;
    onDefaultSort: (field: string) => void;
    viewMore: (viewAll: any, pagination?: EarningsPagination) => void;
    searchInTable: (searchValue: string) => void;
    canCollapseOnRowClick?: boolean;
};

export type TableState = {
    sort: {
        field: string | null;
        type: SortType;
    };
    search: string | null;
    itemsLoadedCount: number;
    rowData: null | any[];
};

export interface TableReducerInitState {
    itemsPerLoad: number;
    sortType: SortType;
}
