import React, { FC } from 'react';

import cn from 'classnames';

import { CellRendererProps } from '../../../types';

import styles from '../../../Table.module.scss';

export const IndexCellRenderer: FC<CellRendererProps> = ({ row, columnDef, rowIndex }) =>
    row && columnDef && <span className={cn(styles[`${columnDef.field}-label`])}>{rowIndex + 1}</span>;
