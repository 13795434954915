import { IdByTabType } from 'pages/Earnings/hooks/types';

export const getTotalEarningsHeader = (tabsWithRelatedEntityId?: IdByTabType) => {
    const sourceName = tabsWithRelatedEntityId?.Source?.name || '';
    const productName = tabsWithRelatedEntityId?.Product?.name || '';
    const retailerName = tabsWithRelatedEntityId?.Retailer?.name || '';

    return `Total ${sourceName ? sourceName : retailerName} Earnings ${productName && 'from ' + productName}`
        .replace(/\s+/g, ' ')
        .trim();
};
