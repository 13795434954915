import { UserMember } from 'components/Table/types';
import { useFindUsersByEmailLazyQuery } from 'gql/queries/__generated__/findUsersByEmail.generated';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const useHandleNewMemberAddition = (
    row: { [key: string]: any },
    confirmCreateMember?: (value: any, row?: { [key: string]: any }) => void,
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [usersList, setUsersList] = useState<UserMember[]>([]);
    const [findUsersByEmailQuery] = useFindUsersByEmailLazyQuery();
    const findUserByEmail = async (email: string) => {
        setIsLoading(true);
        const { data, loading } = await findUsersByEmailQuery({
            variables: {
                email,
            },
        });
        setIsLoading(loading);
        const users = data?.findUsersByEmail?.filter((user) => user) ?? [];

        setUsersList(users.length ? (users as UserMember[]) : []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedSearch = useCallback(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        debounce(async (searchValue) => findUserByEmail(searchValue), 500),
        [],
    );
    useEffect(() => {
        if (row?.invitationEmail?.length) {
            delayedSearch(row.invitationEmail);
        }
    }, [delayedSearch, row.invitationEmail]);

    const handleInviteNewMember = (user: UserMember) => {
        if (confirmCreateMember) {
            confirmCreateMember(user, row);
        }
    };

    return { isLoading, usersList, handleInviteNewMember };
};
