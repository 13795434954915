import { useMemo } from 'react';
import { useGetTeamListQuery } from 'gql/queries/__generated__/getTeamList.generated';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TeamValues } from './constants';

type UseTeamSelectionParams = (prevSelectedTeam?: string) => {
    selectedTeam: string;
    teamListOptions: string[];
    setTeamQuery: (teamSelectorValue: string) => void;
    getTeamIdByName: (teamName: string) => string | null | undefined;
};

export const useTeamSelection: UseTeamSelectionParams = (prevSelectedTeam) => {
    const { data, loading } = useGetTeamListQuery();
    const navigate = useNavigate();
    const { search } = useLocation();
    const teamList = useMemo(() => data?.getTeams ?? [], [data?.getTeams]);
    const teamNames =
        data?.getTeams?.map((team) => team?.name!).sort((a: string, b: string) => a.localeCompare(b)) ?? [];

    const teamListOptions = loading
        ? ([TeamValues.myEarnings, TeamValues.allTeams] as string[])
        : ([TeamValues.myEarnings, TeamValues.allTeams, ...teamNames] as string[]);
    const [selectedTeam, setSelectedTeam] = useState(prevSelectedTeam ? prevSelectedTeam : teamListOptions[0]);
    const teamIdValue = qs.parse(search, { ignoreQueryPrefix: true }).teamId;
    const showAllTeamsValue = qs.parse(search, { ignoreQueryPrefix: true }).showAllTeams;
    const getTeamIdByName = (teamName: string) => teamList.find((team) => team?.name === teamName)?.id;

    useEffect(() => {
        if (teamList.length && teamIdValue) {
            const teamName = teamList.find((team) => team?.id === teamIdValue)?.name;
            setSelectedTeam(teamName!);
        }
        if (showAllTeamsValue === 'true') {
            setSelectedTeam(TeamValues.allTeams);
        }

        if (selectedTeam !== TeamValues.myEarnings && !teamIdValue && showAllTeamsValue === 'false') {
            setSelectedTeam(TeamValues.myEarnings);
        }
    }, [search, selectedTeam, teamIdValue, showAllTeamsValue, teamList]);

    const queryWithSelectedTeamValue = (teamValue: string, query: qs.ParsedQs) => {
        switch (teamValue) {
            case TeamValues.myEarnings:
                return { ...query, showAllTeams: false, teamId: '' };
            case TeamValues.allTeams:
                return { ...query, showAllTeams: true, teamId: '' };
            default:
                const teamId = teamList.find((team) => team?.name === teamValue)?.id;
                return { ...query, teamId: teamId, showAllTeams: false };
        }
    };

    const setTeamQuery = (teamSelectorValue: string) => {
        const query = qs.parse(search, { ignoreQueryPrefix: true });

        const teamQuery = queryWithSelectedTeamValue(teamSelectorValue, query);
        navigate({ search: qs.stringify(teamQuery) });
    };
    return { selectedTeam, teamListOptions, setTeamQuery, getTeamIdByName };
};
