import { SelectedItem } from 'components/Table/hooks/useData/types';
import { PreparedChartData } from '../interfaces';
import { RevenueTableItemsType } from '../../../types';
import variables from 'shared/styles/variables.module.scss';

export const filterChartDataBySelectedItems = (selectedItems: SelectedItem[], preparedChartData: PreparedChartData) =>
    selectedItems
        .map(({ id, color }) => {
            return {
                data: preparedChartData[id],
                color,
                id,
            };
        })
        .filter(({ data }) => data);

export const filterChartDataByUnSelectedItems = (
    unSelectedItems: RevenueTableItemsType,
    preparedChartData: PreparedChartData,
) => {
    return unSelectedItems
        .map(({ id }) => {
            return {
                data: preparedChartData[id],
                color: variables.sourceColorUnselected,
                id,
            };
        })
        .filter((data) => data);
};
