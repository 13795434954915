import React from 'react';
import {
    AxisSetExtremesEventObject,
    Options,
    SeriesOptionsType,
    SVGElement,
    TooltipFormatterContextObject,
    Point,
    SeriesColumnOptions,
} from 'highcharts';
import { generateStaticMarkup } from 'shared/utils/generateStaticMarkup';
import {
    AREA_OPACITY,
    CHART_LABEL_STYLES,
    CHART_MAX_PADDING,
    CROSSHAIR_CLASS_NAME,
    PLOT_LINE_CONFIG,
    POINT_INTERVAL,
    POINT_WIDTH,
    SCROLLBAR_CONFIG,
    THREE_DAYS,
} from '../constants';
import { ChartHeaderLabels, ChartData, HeaderLabelPositionParams, ChartParams } from '../interfaces';
import { Tooltip } from '../components/Tooltip/Tooltip';
import { calculateHeaderLabelPosition } from './calculateHeaderLabelPosition';
import { createChartHeader } from './createChartHeader';
import { createHeaderLabel } from './createHeaderLabel';
import { createScrollXPositionHandler } from './createScrollXPositionHandler';
import { convertHexToRGBA } from 'shared/utils/convertHexToRGBA';
import { merge } from 'lodash';

import { currencySign } from 'shared/constants';
import { PeriodValues } from 'shared/types';
import { currentMonth, lastThreeMonthsStartValue, thisYearStartValue } from 'shared/utils/getDate';

import variables from 'shared/styles/variables.module.scss';
import { getRemainder } from './getReminder';
import { fillEmptyDataPoints } from './fillEmptyDataPoints';
import { hideCrosshair, showCrosshair } from './toggleCrosshair';

let actualHeaderLabel: SVGElement;
let projectedHeaderLabel: SVGElement;

export const createChartConfig = (params: ChartParams, chartData: ChartData[]) => {
    const {
        type,
        plotLinePoint,
        pointStart,
        pointEnd,
        columnsNumber,
        testId,
        monthlyTotals,
        selectedItem,
        chartYMax,
        chartYMin,
        selectedPeriod,
    } = params;

    const calculateScrollXRelatedPosition = createScrollXPositionHandler(pointStart, pointEnd);

    const plotLineXPosition = calculateScrollXRelatedPosition(plotLinePoint ?? 0);

    const shouldShowHeaders = Boolean(plotLinePoint);

    if (chartData.length === 0) {
        const emptyOptions: Options = {
            credits: {
                enabled: false,
            },

            chart: {
                showAxes: true,
                backgroundColor: variables.baseBackgroundColor,

                style: {
                    fontFamily: variables.baseFontFamily,
                },
            },

            yAxis: {
                min: 1,
                max: 10,
                labels: {
                    format: '${text}',
                    style: CHART_LABEL_STYLES,
                },
                title: {
                    text: '',
                },
                gridLineWidth: 1,
                gridLineColor: variables.chartBorderColor,
            },
            xAxis: {
                lineWidth: 2,
                lineColor: variables.chartBorderColor,
                scrollbar: {
                    ...SCROLLBAR_CONFIG,
                    margin: 34,
                },
            },

            title: { text: '' },
        };
        return emptyOptions;
    }

    chartData = type === 'column' ? fillEmptyDataPoints(pointStart, pointEnd, chartData) : chartData;

    const options: Options = {
        credits: {
            enabled: false,
        },

        xAxis: {
            ordinal: type === 'column',
            showEmpty: true,
            lineWidth: 2,
            lineColor: variables.chartBorderColor,
            crosshair: {
                color: variables.borderColorField,
                className: CROSSHAIR_CLASS_NAME,
            },
            events: {
                setExtremes: function (ev: AxisSetExtremesEventObject) {
                    if (shouldShowHeaders) {
                        const headerLabels: ChartHeaderLabels = { actualHeaderLabel, projectedHeaderLabel };

                        const { plotWidth, plotLeft } = this.chart;

                        const headerLabelPositionParams: HeaderLabelPositionParams = {
                            headerLabels,
                            chartLeftOffset: plotLeft,
                            chartWidth: plotWidth,
                            plotLineXPosition,
                            calculateScrollXRelatedPosition,
                        };

                        calculateHeaderLabelPosition(ev, headerLabelPositionParams);
                    }
                },
            },
            type: 'datetime',
            min: pointStart,
            max: pointEnd,
            scrollbar: SCROLLBAR_CONFIG,
            gridLineWidth: 0,
            tickLength: 0,
            plotLines: [{ value: plotLinePoint, ...PLOT_LINE_CONFIG }],
            reversed: false,
            dateTimeLabelFormats: { month: '%b %Y' },
            labels: {
                style: CHART_LABEL_STYLES,
            },
        },

        yAxis: {
            showEmpty: true,
            labels: {
                formatter: function () {
                    const amount = typeof this.value === 'string' ? Number.parseInt(this.value, 10) : this.value;

                    return amount >= 1000 ? `${currencySign}${amount / 1000}K` : `${currencySign}${amount}`;
                },
                style: CHART_LABEL_STYLES,
            },
            gridLineWidth: 1,
            gridLineColor: variables.chartBorderColor,
            title: {
                text: '',
            },
            reversed: false,
            maxPadding: shouldShowHeaders ? CHART_MAX_PADDING : 0,
            max: chartYMax,
            min: chartYMin,
        },

        title: { text: '' },

        legend: {
            enabled: false,
        },

        tooltip: {
            shared: true,
            useHTML: true,
            borderWidth: 0,
            shadow: false,
            padding: 0,
            formatter: function () {
                const { points: unpreparedPoints, x: date } = this;

                const points = unpreparedPoints
                    ?.filter((item: TooltipFormatterContextObject) => item.point.y && item.point.y != 0)
                    .map((item: TooltipFormatterContextObject) => {
                        const { name: namePoint, color, y } = item.point;
                        const { name: nameSeries, color: colorSeries } = item.point
                            .series as unknown as SeriesColumnOptions;

                        return {
                            name: namePoint || nameSeries,
                            color: color || colorSeries,
                            y,
                        };
                    }) as Point[];

                if (points.length > 0) {
                    showCrosshair();
                    const totalAmount = monthlyTotals[date + THREE_DAYS];
                    const remainder = getRemainder(totalAmount, points);
                    return generateStaticMarkup(
                        <Tooltip
                            points={points}
                            date={date}
                            totalAmount={totalAmount}
                            testId={`${testId}.Tooltip`}
                            selectedItem={selectedItem}
                            remainder={remainder}
                        />,
                    );
                } else {
                    hideCrosshair();
                    return false;
                }
            },
        },

        plotOptions: {
            column: {
                stacking: 'normal',
            },
            series: {
                animation: false,
                borderWidth: 0,
                states: {
                    inactive: {
                        opacity: 0.8,
                    },
                },
            },
        },

        chart: {
            showAxes: true,
            animation: false,
            backgroundColor: variables.baseBackgroundColor,
            events: {
                load: function () {
                    const chart = this,
                        xAxis = chart.xAxis[0];

                    if (shouldShowHeaders) {
                        createChartHeader(chart);

                        actualHeaderLabel = createHeaderLabel('Actual', chart);

                        projectedHeaderLabel = createHeaderLabel('Projected', chart);
                    }

                    switch (selectedPeriod) {
                        case PeriodValues.lastThreeMonths:
                            xAxis.setExtremes(lastThreeMonthsStartValue.getTime(), pointEnd);
                            break;
                        case PeriodValues.thisYear:
                            if (currentMonth <= columnsNumber) {
                                xAxis.setExtremes(thisYearStartValue.getTime(), pointEnd);
                            } else {
                                xAxis.setExtremes(pointEnd - POINT_INTERVAL * columnsNumber, pointEnd);
                            }
                            break;
                        default:
                            xAxis.setExtremes(pointEnd - POINT_INTERVAL * columnsNumber, pointEnd);
                            break;
                    }
                },
            },
            style: {
                fontFamily: variables.baseFontFamily,
            },
        },

        series: chartData.map(({ data, color }) => {
            const shouldShowMarker = data.length === 1;
            const commonData: SeriesOptionsType = {
                name: data[0].name,
                color: color,
                fillColor: convertHexToRGBA(color, AREA_OPACITY),
                type: type,
                dataGrouping: {
                    forced: true,
                    enabled: true,
                    groupAll: true,
                    approximation: 'sum',
                    units: [['month', [1]]],
                },
                threshold: 0,
                pointInterval: POINT_INTERVAL,
                zoneAxis: 'x',
                zones: [
                    {
                        value: plotLinePoint,
                    },
                    { dashStyle: 'Dash' },
                ],
                data,
            };

            const columnTypeExtendedData = { pointWidth: POINT_WIDTH * 0.7 };
            const lineTypeExtendedData = {
                marker: {
                    enabled: shouldShowMarker,
                    symbol: 'circle',
                    radius: 5,
                },
            };

            if (type === 'column') {
                return merge(commonData, columnTypeExtendedData);
            }

            return merge(commonData, lineTypeExtendedData);
        }),
    };

    return options;
};
