import React, { FC, useEffect } from 'react';
import cn from 'classnames';

import { Icon } from 'components/Icon/Icon';
import { DefaultHeaderCellRendererProps } from '../../../types';

import styles from '../../../Table.module.scss';
import { SortType } from 'gql/__generated__/types';

export const DefaultHeaderCellRenderer: FC<DefaultHeaderCellRendererProps> = ({
    config,
    columnDef,
    onSort,
    onDefaultSort,
    isEmpty,
}) => {
    useEffect(() => {
        if (columnDef.defaultSort) {
            onDefaultSort(columnDef.field);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const iconUp = config.sort.field === columnDef.field && config.sort.type === SortType.Asc;
    const iconDown = config.sort.field === columnDef.field && config.sort.type === SortType.Desc;

    return columnDef.headerName ? (
        typeof columnDef.headerName === 'string' ? (
            <span onClick={() => columnDef.sortable && onSort(columnDef.field)}>
                <span>{columnDef.headerName}</span>
                {columnDef.sortable && (
                    <span className={cn(styles['sort-icon'], { [styles.disabled]: isEmpty })}>
                        {iconUp && <Icon type="caretup" />}
                        {iconDown && <Icon type="caretdown" />}
                    </span>
                )}
            </span>
        ) : (
            <div onClick={() => columnDef.sortable && onSort(columnDef.field)}>{columnDef.headerName}</div>
        )
    ) : null;
};
