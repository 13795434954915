export const headingMFAText = 'Sync Your Account';
export const textBlockMFAText =
    ' This partner uses a 2 Factor Authentication system to keep your account secure. Please enter the security code you just received into the field below to sync your account.';
export const sendBtnText = 'Send';

export const mfaCodeInputParams = {
    id: 'mfaCode',
    label: 'Enter your security code',
    name: 'mfaCode',
    type: 'text',
    icon: 'clear',
};

export const GENERIC_ERROR = 'Something went wrong.';
