import React, { createContext, FC, useState } from 'react';
import { TeamValues } from '../../hooks/useTeamSelection/constants';

interface TeamsFilterContextParams {
    selectedTeam: string;
    updateSelectedTeam: (team: string) => void;
}

export const TeamsFilterContext = createContext<TeamsFilterContextParams>({
    selectedTeam: TeamValues.allTeams,
    updateSelectedTeam: () => {},
});

export const TeamsFilterProvider: FC = ({ children }) => {
    const [selectedTeam, setSelectedTeam] = useState('');
    const updateSelectedTeam = (team: string) => {
        setSelectedTeam(team);
    };
    return (
        <TeamsFilterContext.Provider value={{ selectedTeam, updateSelectedTeam }}>
            {children}
        </TeamsFilterContext.Provider>
    );
};
