import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateTeamInvitationQueryVariables = Types.Exact<{
  invitationId: Types.Scalars['ID'];
}>;


export type ValidateTeamInvitationQuery = { __typename?: 'Query', validateTeamInvitation?: { __typename?: 'InvitationInfo', isValid?: boolean | null, senderName?: string | null, senderSurname?: string | null, receiverName?: string | null, receiverSurname?: string | null, team?: string | null } | null };


export const ValidateTeamInvitationDocument = gql`
    query validateTeamInvitation($invitationId: ID!) {
  validateTeamInvitation(invitationId: $invitationId) {
    isValid
    senderName
    senderSurname
    receiverName
    receiverSurname
    team
  }
}
    `;

/**
 * __useValidateTeamInvitationQuery__
 *
 * To run a query within a React component, call `useValidateTeamInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTeamInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTeamInvitationQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useValidateTeamInvitationQuery(baseOptions: Apollo.QueryHookOptions<ValidateTeamInvitationQuery, ValidateTeamInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateTeamInvitationQuery, ValidateTeamInvitationQueryVariables>(ValidateTeamInvitationDocument, options);
      }
export function useValidateTeamInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateTeamInvitationQuery, ValidateTeamInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateTeamInvitationQuery, ValidateTeamInvitationQueryVariables>(ValidateTeamInvitationDocument, options);
        }
export type ValidateTeamInvitationQueryHookResult = ReturnType<typeof useValidateTeamInvitationQuery>;
export type ValidateTeamInvitationLazyQueryHookResult = ReturnType<typeof useValidateTeamInvitationLazyQuery>;
export type ValidateTeamInvitationQueryResult = Apollo.QueryResult<ValidateTeamInvitationQuery, ValidateTeamInvitationQueryVariables>;