import React, { FC } from 'react';

import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { DataSourcesWidgetProps } from './types';
import { Logo } from 'components/Logo/Logo';

import styles from './DataSourcesWidget.module.scss';

import { dataSource, dataSources } from '../../constants';
import { getLinkedAccountsNumber } from '../../../../shared/utils/getLinkedAccountsNumber';
import { LoaderComponent } from 'components/Loader/Loader';
import { useGetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';

export const DataSourcesWidget: FC<DataSourcesWidgetProps> = ({ className }) => {
    const { data, loading } = useGetLinkedSourcesQuery();

    const accounts = data?.linkedSources ?? [];

    const { count, preparedAccounts } = getLinkedAccountsNumber(accounts);

    const isEmpty = !count;
    return (
        <Card className={className}>
            {loading ? (
                <LoaderComponent />
            ) : (
                <div className={styles.container}>
                    <div>
                        <Heading className={styles.heading}>
                            {`${count} ${count === 1 ? dataSource : dataSources}`}
                        </Heading>
                        <ActionButton
                            label="Manage Sources"
                            iconType="manage"
                            iconIsFirst
                            navigateTo="/data-sources"
                            actionClassName="primary"
                        />
                    </div>
                    {isEmpty ? null : (
                        <ul className={styles['logo-list']}>
                            {preparedAccounts.map(({ type }) => (
                                <li key={type} className={styles['logo-item']}>
                                    <Logo type={type ?? ''} />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </Card>
    );
};
