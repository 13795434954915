import { chain, max, maxBy, min, minBy } from 'lodash';
import { ChartType } from '../constants';
import { ChartData } from '../interfaces';

function getDigitsNumber(num: number): number {
    return num.toFixed().toString().length;
}

function roundYValue(num: number) {
    const absValue = Math.abs(num);

    const digits = getDigitsNumber(absValue);

    const round = Math.pow(10, digits - (digits - 1));
    const roundedVal = round * Math.ceil(absValue / round);
    return roundedVal * Math.sign(num);
}

export const getChartYMinMax = (lineChartData: ChartData[], type: ChartType) => {
    let maxY = 0;
    let minY = 0;

    if (type === ChartType.LineChart) {
        maxY =
            max(
                lineChartData.map((source) => {
                    const sourceData = source.data;
                    return maxBy(sourceData, 'y')?.y;
                }),
            ) ?? 0;

        minY =
            min(
                lineChartData.map((source) => {
                    const sourceData = source.data;
                    return minBy(sourceData, 'y')?.y;
                }),
            ) ?? 0;
    }

    if (type === ChartType.StackedBarChart) {
        const barChartData = chain(lineChartData)
            .flatMapDeep((source) => source.data)
            .groupBy('x')
            .mapValues((item) => {
                const mappedValues = item.map(({ y }) => Number(y) ?? 0);

                const negativeTotalAmountByDate = chain(mappedValues)
                    .filter((amount) => amount < 0)
                    .sum()
                    .value();

                const positiveTotalAmountByDate = chain(mappedValues)
                    .filter((amount) => amount > 0)
                    .sum()
                    .value();

                return { negativeTotalAmountByDate, positiveTotalAmountByDate };
            })
            .value();
        maxY = max(Object.values(barChartData).map((period) => period.positiveTotalAmountByDate)) ?? 0;
        minY = min(Object.values(barChartData).map((period) => period.negativeTotalAmountByDate)) ?? 0;
    }

    return { chartYMax: roundYValue(maxY), chartYMin: roundYValue(minY) };
};
