import qs from 'qs';
import { GroupByOptions } from 'shared/constants';
import { QueryTypes } from './types';

export const generatePathname = (
    tabsWithRelatedEntityId: any,
    parsedParams: any,
    selectedTab: string,
    activeTab: string,
    teamId: string,
    showAllTeams: string,
    row?: any,
    selectedTabId?: string,
) => {
    const entity = row && selectedTabId ? { [activeTab]: { id: selectedTabId, name: row.name } } : {};
    const criterias = { ...tabsWithRelatedEntityId, ...entity };
    const { category, selectedPeriod } = parsedParams;
    if (!Object.keys(criterias).length) {
        return qs.stringify({
            q: `all${selectedTab}s`,
            selectedPeriod: selectedPeriod!,
            category: category!,
            teamId: teamId,
            showAllTeams: showAllTeams === 'true',
        });
    }
    const abbreviature = Object.keys(criterias)[1]
        ? `${selectedTab.charAt(0)}${Object.keys(criterias)[0].charAt(0)}${Object.keys(criterias)[1].charAt(
              0,
          )}`.toLowerCase()
        : `${selectedTab.charAt(0)}${Object.keys(criterias)[0].charAt(0)}`.toLowerCase();

    const abbreviatureVerified = Object.values(QueryTypes).includes(abbreviature as QueryTypes);
    return qs.stringify({
        q: abbreviatureVerified && abbreviature.toLowerCase(),
        ...criterias,
        selectedPeriod: selectedPeriod!,
        category: category!,
        teamId: teamId,
        showAllTeams: showAllTeams === 'true',
    });
};

export const generatePathnameByBreadcrumbs = (parsedUrl: any) => {
    const { q, category, selectedPeriod, teamId, showAllTeams, page } = parsedUrl;

    const query = q.slice(1).split('').reverse().join('');
    const entityToSortBy = Object.keys(parsedUrl).find((elem) => elem.charAt(0).toLowerCase() === query.charAt(1));
    const entityChosenInTabs = Object.keys(parsedUrl).find((elem) => elem.charAt(0).toLowerCase() === query.charAt(0));

    return {
        url: qs.stringify({
            q: query,
            [entityToSortBy as string]: parsedUrl[entityToSortBy as string],
            selectedPeriod: selectedPeriod!,
            page: page!,
            category: category!,
            teamId: teamId,
            showAllTeams: showAllTeams === 'true',
        }),
        selectedTab: entityChosenInTabs,
    };
};

export const parseQueryToSelectTab = (query: string) => {
    if (query === 'allSources' || query.charAt(0) === 's') {
        return GroupByOptions.Source;
    }
    if (query === 'allProducts' || query.charAt(0) === 'p') {
        return GroupByOptions.Product;
    }
    if (query.charAt(0) === 'r') {
        return GroupByOptions.Retailer;
    }
};
