import React, { FC } from 'react';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { LOADER_HEIGHT_DEFAULT, LOADER_WIDTH_DEFAULT, loaderType, timeout } from './constants';
import { LoaderProps } from './interfaces';

import styles from './Loader.module.scss';

export const LoaderComponent: FC<LoaderProps> = ({
    width = LOADER_WIDTH_DEFAULT,
    height = LOADER_HEIGHT_DEFAULT,
    isFullPage = false,
}) => {
    const className = isFullPage ? 'full-page-loader-container' : 'loader-container';

    return (
        <div className={styles[className]} data-testid="loader-container">
            <Loader type={loaderType} height={height} width={width} timeout={timeout} />
        </div>
    );
};
