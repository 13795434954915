import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddTeamSourceMutationVariables = Types.Exact<{
  sourceId: Types.Scalars['ID'];
  teamId: Types.Scalars['ID'];
  userSourceId: Types.Scalars['ID'];
}>;


export type AddTeamSourceMutation = { __typename?: 'Mutation', addTeamSource?: { __typename?: 'Team', id?: string | null, name?: string | null } | null };


export const AddTeamSourceDocument = gql`
    mutation addTeamSource($sourceId: ID!, $teamId: ID!, $userSourceId: ID!) {
  addTeamSource(sourceId: $sourceId, teamId: $teamId, userSourceId: $userSourceId) {
    id
    name
  }
}
    `;
export type AddTeamSourceMutationFn = Apollo.MutationFunction<AddTeamSourceMutation, AddTeamSourceMutationVariables>;

/**
 * __useAddTeamSourceMutation__
 *
 * To run a mutation, you first call `useAddTeamSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamSourceMutation, { data, loading, error }] = useAddTeamSourceMutation({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      teamId: // value for 'teamId'
 *      userSourceId: // value for 'userSourceId'
 *   },
 * });
 */
export function useAddTeamSourceMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamSourceMutation, AddTeamSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamSourceMutation, AddTeamSourceMutationVariables>(AddTeamSourceDocument, options);
      }
export type AddTeamSourceMutationHookResult = ReturnType<typeof useAddTeamSourceMutation>;
export type AddTeamSourceMutationResult = Apollo.MutationResult<AddTeamSourceMutation>;
export type AddTeamSourceMutationOptions = Apollo.BaseMutationOptions<AddTeamSourceMutation, AddTeamSourceMutationVariables>;