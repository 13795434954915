import { GroupByOptions } from 'shared/constants';
import { TabValues } from './hooks/types';

export const filterNameByTabName = {
    [TabValues.sources]: 'sourceId',
    [TabValues.products]: 'productId',
    [TabValues.retailers]: 'retailerId',
};

export const groupByByTabName = {
    [TabValues.sources]: 'Source',
    [TabValues.products]: 'Product',
    [TabValues.retailers]: 'Retailer',
};

export const showSocialActivity = 'Show Social Activity';

export const INIT_TABS = [GroupByOptions.Source, GroupByOptions.Product];

export const amountField = 'amount';
