import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAcceptTeamInvitationMutation } from 'gql/mutations/__generated__/acceptTeamInvitation.generated';

export const useInvitationDeclinedModal = (refetch: () => void) => {
    const [acceptTeamInvitationMutation, { loading }] = useAcceptTeamInvitationMutation();
    const [invitationAcceptedModalState, setInvitationAcceptedModalState] = useState(false);
    const [invitationDeclinedModalState, setInvitationDeclinedModalState] = useState(false);
    const [invitationId, setInvitationId] = useState('');
    useEffect(() => {
        if (localStorage.getItem('inviteToTeam') !== null) {
            setInvitationId(localStorage.getItem('inviteToTeam') as string);
            setInvitationDeclinedModalState(true);
            localStorage.removeItem('inviteToTeam');
        }
    }, []);

    const handleAcceptedModalClose = () => {
        setInvitationAcceptedModalState(false);
    };

    const handleDeclinedModalClose = async () => {
        setInvitationDeclinedModalState(false);
        await acceptTeamInvitationMutation({ variables: { invitationId: invitationId, accept: false } });
        setInvitationAcceptedModalState(true);
    };
    const handleAcceptInvitationFromModal = async () => {
        setInvitationDeclinedModalState(false);
        await acceptTeamInvitationMutation({ variables: { invitationId: invitationId, accept: true } });
        toast('You have accepted invite to the team.', { type: 'success' });
        refetch();
    };

    return {
        handleAcceptInvitationFromModal,
        handleAcceptedModalClose,
        handleDeclinedModalClose,
        invitationAcceptedModalState,
        invitationDeclinedModalState,
        loading,
    };
};
