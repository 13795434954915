import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import { UserInfo, UserInfoContextParams } from './interfaces';
import * as AuthService from 'shared/services/AuthService';
import jwt from 'jwt-decode';
import { tokenUpdatedEventName } from 'shared/constants';
import { identifyHotjarUser } from '../../../hotjar';

export const UserInfoContext = createContext<UserInfoContextParams>({
    user: undefined,
    setUser: undefined,
});

export type IdToken = {
    sub?: string;
    given_name?: string;
    family_name?: string;
    email?: string;
};

export const UserInfoProvider: FC = ({ children }) => {
    function updateUserDetails() {
        const token = AuthService.getIdToken();

        let userDetails: IdToken = {};
        try {
            userDetails = token ? jwt<IdToken>(token) : {};
        } catch (ex) {
            console.error('invalid token', ex);

            AuthService.removeToken();
            AuthService.getLoginPage();
        }
        return {
            id: userDetails.sub,
            firstName: userDetails.given_name || '',
            lastName: userDetails.family_name || '',
            email: userDetails.email || '',
            lastChangedDate: '2022-01-01',
            phone: '+123456789',
            phoneVerifiedDate: '2022-01-01',
        };
    }

    const [userInfo, setUserInfo] = useState<UserInfo>(updateUserDetails);

    const updateUserInfo = useCallback(() => {
        const details = updateUserDetails();
        setUserInfo(details);
    }, []);

    useEffect(() => {
        window.addEventListener(tokenUpdatedEventName, updateUserInfo);

        return () => window.removeEventListener(tokenUpdatedEventName, updateUserInfo);
    }, [updateUserInfo]);

    useEffect(() => {
        identifyHotjarUser(userInfo);
    }, [userInfo]);

    function handleNewUser(user: UserInfo) {
        setUserInfo(user);
    }

    return (
        <UserInfoContext.Provider value={{ user: userInfo, setUser: handleNewUser }}>
            {children}
        </UserInfoContext.Provider>
    );
};
