import { Retailer } from 'gql/__generated__/types';
import { Earnings } from 'shared/types';
import { prepareAmountForCharts } from 'shared/utils/prepareAmountForCharts';

export const prepareRetailer = (retailer: Retailer): Earnings => {
    const { id, amount, name, totalAmount, projection } = retailer;
    const preparedAmount = prepareAmountForCharts(amount) ?? [];

    return {
        id: id ?? '',
        name: name ?? '',
        amount: preparedAmount,
        totalAmount: totalAmount ?? 0,
        projection,
    };
};

export const prepareRetailers = (retailers: (Retailer | null)[]): Earnings[] => {
    const filteredRetailers = retailers.filter((retailer) => retailer) as Retailer[];

    return filteredRetailers.map(prepareRetailer);
};
