import React, { FC } from 'react';
import Modal from 'react-modal';
import { ModalProps } from './types';
import { Icon } from 'components/Icon/Icon';
import { Button } from 'components/Button/Button';
import { styleTypes } from 'shared/constants';
import cn from 'classnames';

import styles from './ModalDialog.module.scss';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export const ModalDialog: FC<ModalProps> = ({
    className,
    isOpen,
    toggleModal,
    iconType,
    title,
    description,
    onOk,
    onOkBtnText,
    onCancel,
    onCancelBtnText,
    children,
    isOkLoading,
    shouldSwapButtons,
}) => {
    const okButton = (
        <Button
            testId="modalDialogButtonOk"
            style={styleTypes.primary}
            className={styles.button}
            onClick={onOk}
            isLoading={isOkLoading}
        >
            {onOkBtnText}
        </Button>
    );
    return (
        <Modal
            className={styles.modal}
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="Modal Dialog"
            overlayClassName={styles.overlay}
            bodyOpenClassName={styles.bodyModalOpen}
        >
            <div className={cn(styles.container, className)}>
                <button data-testid="modalDialogCloseButton" className={styles.close} onClick={toggleModal}>
                    <Icon type="close" className={styles.closeIcon} />
                </button>
                {iconType && <Icon data-testid="modalDialogIcon" type={iconType} className={styles.mainIcon} />}
                <h3 data-testid="modalDialogTitle" className={styles.title}>
                    {title}
                </h3>
                {description && (
                    <p data-testid="modalDialogDescription" className={styles.description}>
                        {description}
                    </p>
                )}
                {children}
                <div className={styles.buttonsHolder} data-testid="modalDialogButtonsHolder">
                    {shouldSwapButtons && okButton}
                    {onCancel && onCancelBtnText && (
                        <Button
                            testId="modalDialogButtonCancel"
                            style={styleTypes.secondary}
                            className={styles.button}
                            onClick={onCancel}
                        >
                            {onCancelBtnText}
                        </Button>
                    )}
                    {!shouldSwapButtons && okButton}
                </div>
            </div>
        </Modal>
    );
};
