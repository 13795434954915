import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { CategoryValues } from 'shared/constants';
import { CategoryValuesType } from 'shared/types';

export const useCategorySelection = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const [selectedCategory, updateSelectedCategory] = useState<CategoryValuesType>(CategoryValues.All);

    // TODO avoid the same variables
    const { category } = qs.parse(search, { ignoreQueryPrefix: true });

    useEffect(() => {
        if (category && category !== selectedCategory) {
            updateSelectedCategory(category as CategoryValuesType);
        }
        window.scrollTo(0, 0);
    }, [category, selectedCategory]);

    const setSelectedCategory = (categoryProps: CategoryValuesType) => {
        const query = qs.parse(search, { ignoreQueryPrefix: true });
        const queryWithSelectedCategory = { ...query, category: categoryProps };

        navigate({
            pathname: '/earnings',
            search: qs.stringify(queryWithSelectedCategory),
        });
    };

    return { setSelectedCategory, selectedCategory };
};
