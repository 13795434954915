import React, { FC, useEffect } from 'react';
import { Table } from 'components/Table/Table';
import { TableRowRenderer } from 'components/Table/components/TableRowRenderer/TableRowRenderer';
import styles from './DataSourceListTable.module.scss';
import { DataSourceListTableProps } from './types';
import { useTable } from 'components/Table/hooks/useTable';

export const DataSourceListTable: FC<DataSourceListTableProps> = ({ tableItems, columnDefs }) => {
    const { onSort, onDefaultSort, viewMore, searchInTable, state, updateTableSize } = useTable(tableItems.length);
    useEffect(() => {
        updateTableSize(tableItems.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableItems]);

    return (
        <Table
            onSort={onSort}
            onDefaultSort={onDefaultSort}
            viewMore={viewMore}
            searchInTable={searchInTable}
            state={state}
            className={styles.dataSourceListTable}
            tableItems={tableItems}
            columnDefs={columnDefs}
            rowRenderer={TableRowRenderer}
            withDetails
            rowRendererConfig={{
                classnames: ['responsiveHeightRow'],
            }}
        />
    );
};
