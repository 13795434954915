import React, { FC } from 'react';

import { EnhancedTopRightNavMenu as TopRightNavMenu } from './components/TopRightNavMenu/TopRightNavMenu';
import { LinkItem } from 'components/LinkItem/LinkItem';
// import { SearchInput } from 'components/SearchInput/SearchInput';
import { Logo } from 'components/Logo/Logo';

import styles from './Header.module.scss';

export const Header: FC = () => {
    return (
        <header className={styles.header}>
            <LinkItem to="/" className={styles['logo-link']}>
                <Logo type="utopia" className={styles.logo} />
            </LinkItem>
            {/* <SearchInput value="" className={styles['search-input']} placeholder="Search for sources or products" /> */}
            <TopRightNavMenu />
        </header>
    );
};
