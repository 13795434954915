export const dataSource = 'Data Source';
export const dataSources = 'Data Sources';

export const manageSources = 'Manage Sources';

export const sourceNames = {
    cdbaby: 'cdbaby',
    distrokid: 'distrokid',
    ingrooves: 'ingrooves',
};

export const tabItemKeys = {
    topSources: 'topSources',
    topProducts: 'topProducts',
};

export const topSources = 'Top Sources';

export const topProducts = 'Top Products';

export const totalEarningsInPeriod = 'Total Earnings in Selected Period';

export const TOP_FIVE = 5;
export const TOP_SIX = 6;

export const earningsSourceTab = 'Source';

export const retailerBySourceQuery = 'rs';

export const sourceByProductQuery = 'sp';
