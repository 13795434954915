import React, { Component } from 'react';

import { TableCell } from '../TableCell/TableCell';
import { DefaultCellRenderer } from '../CellRenderers/DefaultCellRenderer/DefaultCellRenderer';

import { ResponsiveCellProps } from './types';

export class ResponsiveCell extends Component<ResponsiveCellProps> {
    render() {
        const { row, rowIndex, config, columnDef, isChildRow, onExpandRow, onSelectRow, parentRow, isEmpty } =
            this.props;

        let CellRenderer: any = isChildRow
            ? columnDef.childCellRenderer
            : columnDef.cellRenderer || DefaultCellRenderer;

        if ((!isChildRow && columnDef.isOnlyChild) || (isChildRow && !CellRenderer)) {
            return null;
        }
        const value =
            columnDef.valueFormatter && columnDef.formatBy
                ? columnDef.valueFormatter(row[columnDef.formatBy])
                : row[columnDef.field];

        return (
            <TableCell
                data-testid="table-cell"
                row={row}
                rowIndex={rowIndex}
                columnDef={columnDef}
                cellRenderer={
                    <CellRenderer
                        parentRow={parentRow}
                        value={value}
                        config={config}
                        row={row}
                        rowIndex={rowIndex}
                        columnDef={columnDef}
                        onExpandRow={onExpandRow}
                        onSelectRow={onSelectRow}
                        isEmpty={isEmpty}
                    />
                }
            />
        );
    }
}
