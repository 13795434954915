import { SortType } from 'gql/__generated__/types';
import { TOP_FIVE } from 'pages/Dashboard/constants';

export const topFivePagination = { limit: TOP_FIVE };

export const sortDescByTotalAmount = (sortType: SortType) => {
    return { field: 'totalAmount', type: sortType };
};

export const ascTableValue = SortType.Asc;
