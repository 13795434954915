import { Button } from 'components/Button/Button';
import { Input } from 'components/Form/components/Input/Input';
import { LoaderComponent } from 'components/Loader/Loader';
import { textType } from 'components/SearchInput/constants';
import { useSendMfaCodeMutation } from 'gql/mutations/__generated__/sendMfaCode.generated';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes, styleTypes } from 'shared/constants';
import { headingMFAText, mfaCodeInputParams, sendBtnText, textBlockMFAText } from './constants';

import styles from './MfaPage.module.scss';

export const MfaPage = () => {
    const [mfaCode, setMfaCode] = useState('');
    const [errors, setErrors] = useState('');
    const params = useParams();
    const { sourceAccId, mfaCodeId } = params;
    const [sendMfaCodeMutation, { error, loading }] = useSendMfaCodeMutation();
    const handleResetCode = () => setMfaCode('');
    const navigate = useNavigate();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMfaCode(event.target.value);
    };
    const handleSaveForm = async () => {
        try {
            await sendMfaCodeMutation({
                variables: {
                    code: mfaCode,
                    mfaCodeId: mfaCodeId!,
                    userSourceId: sourceAccId!,
                },
            });
            navigate({ pathname: Routes.dataSources });
        } catch (err) {
            setErrors(error?.message!);
        }
    };

    return (
        <>
            {loading ? (
                <div className={styles.loader}>
                    <LoaderComponent />
                </div>
            ) : (
                <div className={styles.container}>
                    <div className={styles.heading}>{headingMFAText}</div>
                    <div className={styles.textBlock}>{textBlockMFAText}</div>
                    <div className={styles.mfaCodeInputBlock}>
                        <Input
                            id={mfaCodeInputParams.id}
                            label={mfaCodeInputParams.label}
                            name={mfaCodeInputParams.name}
                            type={mfaCodeInputParams.type}
                            value={mfaCode}
                            icon={mfaCodeInputParams.icon}
                            onChange={handleInputChange}
                            onIconClick={handleResetCode}
                        />
                    </div>
                    <div className={styles.errorMsg}>{errors}</div>
                    <Button
                        type={textType}
                        isLink={false}
                        style={styleTypes.primary}
                        className={styles.btn}
                        onClick={handleSaveForm}
                    >
                        {sendBtnText}
                    </Button>
                </div>
            )}
        </>
    );
};
