import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
export type AccountFragment = { __typename?: 'Account', id?: string | null, totalAmount?: number | null, name?: string | null };

export const AccountFragmentDoc = gql`
    fragment Account on Account {
  id
  totalAmount
  name
}
    `;