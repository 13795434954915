import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTeamListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTeamListQuery = { __typename?: 'Query', getTeams?: Array<{ __typename?: 'Team', name?: string | null, id?: string | null, sources?: Array<{ __typename?: 'TeamSource', category?: Types.Category | null, name?: string | null, type?: string | null, sourceId?: string | null, userSourceId?: string | null, visibleName?: string | null } | null> | null, members?: Array<{ __typename?: 'TeamMember', id?: string | null, invitationEmail?: string | null, isConfirmedInvitation?: boolean | null, role?: Types.TeamMemberRoles | null, teamId?: string | null, userId?: string | null, name?: string | null, surname?: string | null } | null> | null } | null> | null };


export const GetTeamListDocument = gql`
    query getTeamList {
  getTeams {
    name
    id
    sources {
      category
      name
      type
      sourceId
      userSourceId
      visibleName
    }
    members {
      id
      invitationEmail
      isConfirmedInvitation
      role
      teamId
      userId
      name
      surname
    }
  }
}
    `;

/**
 * __useGetTeamListQuery__
 *
 * To run a query within a React component, call `useGetTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamListQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamListQuery, GetTeamListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamListQuery, GetTeamListQueryVariables>(GetTeamListDocument, options);
      }
export function useGetTeamListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamListQuery, GetTeamListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamListQuery, GetTeamListQueryVariables>(GetTeamListDocument, options);
        }
export type GetTeamListQueryHookResult = ReturnType<typeof useGetTeamListQuery>;
export type GetTeamListLazyQueryHookResult = ReturnType<typeof useGetTeamListLazyQuery>;
export type GetTeamListQueryResult = Apollo.QueryResult<GetTeamListQuery, GetTeamListQueryVariables>;