import React, { FC, useEffect } from 'react';

import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Form/components/Input/Input';
import { Icon } from 'components/Icon/Icon';

import { AccountFormProps } from './types';

import { add, edit, styleTypes, typeButton } from 'shared/constants';
import { notificationPartOne, notificationPartThree, notificationPartTwo, passwordInputParams } from './constants';

import styles from './AccountForm.module.scss';
import { useHandleForm } from './hooks/useHandleForm';

export const AccountForm: FC<AccountFormProps> = ({ accountToEdit, onCancel, onSave }) => {
    const {
        state,
        inputOnChange,
        toggleShowPassword,
        handleResetLogin,
        handleResetPassword,
        handleResetTitle,
        validateAllFields,
    } = useHandleForm(accountToEdit);

    const heading = accountToEdit ? edit : add;

    useEffect(() => {
        if (state.showErrors) {
            onSave!(state.account);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showErrors]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        inputOnChange(event.target.name, event.target.value);
    };

    const handleSaveForm = () => {
        validateAllFields();
    };

    return (
        <Card className={styles['modal-block']}>
            <div className={styles.container}>
                <Heading className={styles.heading}>{heading} Account</Heading>
                <Icon type="close" className={styles['btn-close']} onClick={onCancel} />
                <form className={styles.form}>
                    <div className={styles['input-col']}>
                        <Input
                            className={styles['account-form-input']}
                            id="title"
                            label="Title"
                            name="title"
                            type="text"
                            value={state.account.title.value}
                            icon="clear"
                            onChange={handleInputChange}
                            onIconClick={handleResetTitle}
                        >
                            {state.showErrors && <span className={styles.errorMsg}>{state.account.title.errors}</span>}
                        </Input>

                        <Input
                            className={styles['account-form-input']}
                            id="email"
                            label="Login"
                            name="email"
                            type="text"
                            value={state.account.email.value}
                            icon="clear"
                            deleteSpacesOnInput
                            onChange={handleInputChange}
                            onIconClick={handleResetLogin}
                            isAutocomplete={false}
                        >
                            {state.showErrors && <span className={styles.errorMsg}>{state.account.email.errors}</span>}
                        </Input>

                        <Input
                            className={styles['account-form-input']}
                            id={passwordInputParams.id}
                            label={passwordInputParams.label}
                            name={passwordInputParams.name}
                            type={state.showPassword ? 'text' : 'password'}
                            value={state.account.password.value}
                            icon="password"
                            iconTwo="clear"
                            onChange={handleInputChange}
                            onIconTwoClick={handleResetPassword}
                            onIconClick={toggleShowPassword}
                            isAutocomplete={false}
                        >
                            {state.showErrors && (
                                <span className={styles.errorMsg}>{state.account.password.errors}</span>
                            )}
                        </Input>

                        {/* <Input
                            className={styles['account-form-input']}
                            id="teams"
                            label="Teams"
                            type="text"
                            icon="caretdown"
                            placeholder="Select team"
                            subLabel="Optional"
                            labelIcon="info"
                            name="teams"
                        /> */}
                    </div>
                    <div className={styles.info}>
                        <div className={styles.iconWrapper}>
                            <Icon type="info" />
                        </div>
                        <div className={styles.textBlock}>
                            {notificationPartOne} <b>{notificationPartTwo}</b> {notificationPartThree}
                        </div>
                    </div>
                    <div className={styles['btn-wrapper']}>
                        <Button
                            type="button"
                            isLink={false}
                            style="secondary"
                            className={styles.btn}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>

                        {heading === add ? (
                            <Button
                                type={typeButton}
                                isLink={false}
                                style={styleTypes.primary}
                                className={styles.btn}
                                onClick={handleSaveForm}
                            >
                                Add
                            </Button>
                        ) : (
                            <Button
                                type={typeButton}
                                isLink={false}
                                style={styleTypes.primary}
                                className={styles.btn}
                                onClick={handleSaveForm}
                            >
                                Save
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </Card>
    );
};
