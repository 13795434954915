import { useEffect, useState } from 'react';
import { RevenueTableItemsType, RevenueTableItemType } from 'shared/types';
import { findInItemsById, getInitialSelection, getNotSelectedItems, objToStr } from './utils';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { SortType } from 'gql/__generated__/types';
import { TOP_SIX } from 'pages/Dashboard/constants';
interface UseItemSelectionInterface {
    (tableItems: RevenueTableItemsType, section: string, maxSelectedItems: number): {
        selectedItems: RevenueTableItemType[] | any[];
        notSelectedItems: RevenueTableItemType[];
        addSelectedItem: (selectedItemId: string) => void;
        removeSelectedItem: (selectedItemId: string) => void;
    };
}

export const useItemSelection: UseItemSelectionInterface = (tableItems, section, maxSelectedItems = TOP_SIX) => {
    const { search } = useLocation();
    const searchResult = qs.parse(search, { ignoreQueryPrefix: true });
    const { Retailer, Source, category, q, selectedPeriod, teamId, sort } = searchResult;
    const newTabKey = `${section}-${category}-${q}-${objToStr(Source)}-${objToStr(
        Retailer,
    )}-${selectedPeriod}-${teamId}-${sort}`;
    const [currentTabKey, setCurrentTabKey] = useState('');
    const { initialSelectedItems, initialNotSelectedItems } = getInitialSelection(
        tableItems,
        maxSelectedItems,
        sort as SortType,
    );
    const [selectedItems, updateSelectedItems] = useState(initialSelectedItems);
    const [notSelectedItems, updateNotSelectedItems] = useState(initialNotSelectedItems);
    const newSelectedItemsIds = initialSelectedItems.map((i) => i.id).reduce((acc, id) => acc + id, '');
    const [initialSelectedItemsIds, setInitialSelectedItemsIds] = useState(newSelectedItemsIds);
    const addSelectedItem = (selectedItemId: string) => {
        if (selectedItems.length + 1 > maxSelectedItems) {
            return;
        }

        const newItem = findInItemsById(tableItems, selectedItemId);

        if (!newItem) {
            return;
        }

        const itemsForUpdate = [...selectedItems, newItem];

        updateSelectedItems(itemsForUpdate);
    };
    const removeSelectedItem = (selectedItemId: string) => {
        const filteredSelectedItems = selectedItems.filter((item) => item.id !== selectedItemId);

        updateSelectedItems(filteredSelectedItems);
    };

    useEffect(() => {
        if (currentTabKey !== newTabKey || initialSelectedItemsIds !== newSelectedItemsIds) {
            updateSelectedItems(initialSelectedItems);
            updateNotSelectedItems(initialNotSelectedItems);
            setInitialSelectedItemsIds(newSelectedItemsIds);
            setCurrentTabKey(newTabKey);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newSelectedItemsIds]);
    useEffect(() => {
        updateNotSelectedItems(getNotSelectedItems(tableItems, selectedItems));
    }, [tableItems, selectedItems]);

    return {
        selectedItems,
        notSelectedItems,
        addSelectedItem,
        removeSelectedItem,
    };
};
