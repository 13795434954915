import { useEffect, useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { GroupByOptions } from 'shared/constants';
import { QueryTypes } from 'pages/Earnings/hooks/utils/types';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { PeriodValues } from 'shared/types';

export const useTabSelection = (teamId?: string | null) => {
    const { selectedPeriod, setSelectedPeriod } = useContext(DateFilterContext);
    const navigate = useNavigate();
    const { search } = useLocation();
    const parsedParams = qs.parse(search, { ignoreQueryPrefix: true });
    const selectedTab = parsedParams.q === QueryTypes.AllProducts ? GroupByOptions.Product : GroupByOptions.Source;

    const updateSelectedTab = useCallback(
        (selectedTabProps: GroupByOptions) => {
            const queryWithSelectedTab = {
                ...parsedParams,
                q: `all${selectedTabProps}s`,
                // TODO need refactoring
                selectedPeriod: selectedPeriod,
                teamId,
            };

            if (selectedTabProps !== selectedTab || !search) {
                navigate({ search: qs.stringify(queryWithSelectedTab) });
            }
        },
        [parsedParams, selectedPeriod, teamId, selectedTab, search, navigate],
    );

    useEffect(() => {
        updateSelectedTab(selectedTab);
    }, [selectedTab, updateSelectedTab]);

    useEffect(() => {
        const period = parsedParams.selectedPeriod ? (parsedParams.selectedPeriod as PeriodValues) : selectedPeriod;
        setSelectedPeriod!(period);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSelectedPeriod]);

    const setSelectedTab = (selectedTabProps: GroupByOptions) => {
        updateSelectedTab(selectedTabProps);
    };

    return { setSelectedTab, selectedTab };
};
