import { TableItemsType, TableItemType } from 'shared/types';
import { AddCalculatedFieldsType, CollapsedItem, ExpandedItem, SelectedItem } from './types';

export const dataToTableFormat = (
    tableItems: TableItemsType,
    selectedItems?: SelectedItem[],
    expandedItems?: ExpandedItem[],
    computedFields?: (tableItem: TableItemType) => { [key: string]: any },
    collapsedItems?: CollapsedItem[],
) => {
    const addCalculatedFields: AddCalculatedFieldsType = (tableItem) => {
        const expandedItem = expandedItems?.find(
            (expandedItemObject) => expandedItemObject.expanded && expandedItemObject.id === tableItem.id,
        );
        const selectedItem = selectedItems?.find((selectedItemObject) => selectedItemObject.id === tableItem.id);
        const collapsedItem = collapsedItems?.find(
            (collapsedItemObject: any) => collapsedItemObject.id === tableItem.id && collapsedItemObject.collapsed,
        );
        return {
            ...tableItem,
            ...(computedFields && computedFields(tableItem)),
            ...(selectedItem && { color: selectedItem.color, selected: true }),
            ...(expandedItem && { expanded: true }),
            ...(collapsedItem && { collapsed: true }),
            shared: false,
        };
    };

    return tableItems.map(addCalculatedFields);
};

export const useData = (
    tableItems: TableItemsType,
    selectedItems?: SelectedItem[],
    expandedItems?: ExpandedItem[],
    computedFields?: (tableItem: any) => { [key: string]: any },
    collapsedItems?: CollapsedItem[],
) => {
    const rowData =
        selectedItems || expandedItems || computedFields || collapsedItems
            ? dataToTableFormat(tableItems, selectedItems, expandedItems, computedFields, collapsedItems)
            : tableItems;

    return { rowData };
};
