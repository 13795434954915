import React, { FC } from 'react';

import { Icon } from 'components/Icon/Icon';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';

import { styleTypes, emptyStateTitle, emptyStateText, addSources } from 'shared/constants';

import styles from './EmptyState.module.scss';

export const EmptyStateComponent: FC = () => (
    <div className={styles.container}>
        <Icon type="link" className={styles.icon} />
        <div className={styles['description-wrap']}>
            <Heading className={styles.heading}>{emptyStateTitle}</Heading>
            <p>{emptyStateText}</p>
        </div>
        <Button style={styleTypes.primary} className={styles.button} isLink to="/data-sources">
            {addSources}
        </Button>
    </div>
);
