import BigNumber from 'bignumber.js';
import { bigNumberFormat } from 'shared/constants';

BigNumber.config({ FORMAT: bigNumberFormat });

export const getShareValueToShow = (value: string, totalEarnings: string) => {
    const valueBN = new BigNumber(value);

    const totalEarningsBN = new BigNumber(totalEarnings);

    return valueBN.dividedBy(totalEarningsBN).multipliedBy(100).toFixed(1);
};
