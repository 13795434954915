import { AxisSetExtremesEventObject } from 'highcharts';
import { HeaderLabelParams, HeaderLabelPositionParams } from '../interfaces';
import { throttledAdjustActualLabelPosition } from './adjustActualLabelPosition';
import { throttledProjectedLabelPosition } from './adjustProjectedLabelPosition';

export const calculateHeaderLabelPosition = (ev: AxisSetExtremesEventObject, params: HeaderLabelPositionParams) => {
    const { max, min } = ev;

    const { headerLabels, calculateScrollXRelatedPosition, chartWidth, chartLeftOffset, plotLineXPosition } = params;

    const { actualHeaderLabel, projectedHeaderLabel } = headerLabels;

    const from = calculateScrollXRelatedPosition(min);
    const to = calculateScrollXRelatedPosition(max);

    const labelParams: HeaderLabelParams = {
        from,
        to,
        chartWidth,
        plotLineXPosition,
        chartLeftOffset,
    };

    throttledAdjustActualLabelPosition(actualHeaderLabel, labelParams);

    throttledProjectedLabelPosition(projectedHeaderLabel, labelParams);
};
