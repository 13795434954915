import React, { FC, useState } from 'react';

import { Button } from 'components/Button/Button';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';

import { styleTypes } from 'shared/constants';
import {
    deleteTeamButtonText,
    okModalDeleteTeamBtnText,
    cancelModalDeleteTeamBtnText,
    titleModalDeleteTeamBtn,
    descriptionModalDeleteTeamText,
} from '../../constants';

import { OwnerButtonsBlockProps } from './types';

import styles from '../../AccountTeamsTabButtons.module.scss';

export const OwnerButtonsBlock: FC<OwnerButtonsBlockProps> = (props) => {
    const { testId, onDeleteTeam, teamId } = props;

    const [modalState, setModalState] = useState(false);
    const handleDelete = () => onDeleteTeam(teamId);
    const handleCancel = () => setModalState(false);
    const handleOpenModal = () => setModalState(true);

    return (
        <>
            <ModalDialog
                isOpen={modalState}
                toggleModal={() => setModalState(false)}
                onOk={handleDelete}
                onCancel={handleCancel}
                onOkBtnText={okModalDeleteTeamBtnText}
                onCancelBtnText={cancelModalDeleteTeamBtnText}
                title={titleModalDeleteTeamBtn}
                description={descriptionModalDeleteTeamText}
            />
            <Button
                data-testid={`${testId}.closeBtn`}
                style={styleTypes.secondary}
                className={styles.btn}
                isLink={false}
                onClick={handleOpenModal}
            >
                {deleteTeamButtonText}
            </Button>
        </>
    );
};
