import React, { createContext, FC, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';

import { getSelectedPeriodDates } from './utils/getSelectedPeriodDates';
import { RangeDate, FilterContextParams } from './interfaces';

import { PeriodValues } from 'shared/types';

export const DateFilterContext = createContext<FilterContextParams>({
    dates: {
        dateFrom: new Date().getTime(),
        dateTo: new Date().getTime(),
    },
    selectedPeriod: PeriodValues.allTime,
});

export const DateFilterProvider: FC = ({ children }) => {
    const navigate = useNavigate();
    const { search } = useLocation();

    const [dates, setDates] = useState(() => {
        const initialDates = getSelectedPeriodDates(PeriodValues.allTime);
        if (initialDates) {
            return {
                dateFrom: initialDates.dateFrom.getTime(),
                dateTo: initialDates.dateTo.getTime(),
            };
        }
        return initialDates;
    });

    const [selectedPeriod, setSelectedPeriod] = useState(PeriodValues.allTime);

    function handleSetDates(date: RangeDate) {
        setDates(date);
    }

    const handleSetDateFrom = useCallback((date: number) => {
        setDates((prevState) => ({ dateFrom: date, dateTo: prevState?.dateTo! }));
    }, []);

    const handleSetSelectedPeriod = useCallback(
        (periodValue: PeriodValues) => {
            setSelectedPeriod(periodValue);
            const query = qs.parse(search, { ignoreQueryPrefix: true });
            const queryWithSelectedPeriod = { ...query, selectedPeriod: periodValue };
            if (search) {
                navigate({ search: qs.stringify(queryWithSelectedPeriod) });
            }
        },
        [navigate, search],
    );

    const handleGetDatesByPeriod = useCallback((periodValue: PeriodValues) => {
        return getSelectedPeriodDates(periodValue);
    }, []);

    return (
        <DateFilterContext.Provider
            value={{
                dates: dates,
                setDates: handleSetDates,
                setDateFrom: handleSetDateFrom,
                getDatesByPeriod: handleGetDatesByPeriod,
                selectedPeriod: selectedPeriod,
                setSelectedPeriod: handleSetSelectedPeriod,
            }}
        >
            {children}
        </DateFilterContext.Provider>
    );
};
