export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  id?: Maybe<Scalars['ID']>;
  isShared?: Maybe<Scalars['Boolean']>;
  lastParsing?: Maybe<Date>;
  name?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export enum AccountStatuses {
  FetchFailure = 'FETCH_FAILURE',
  FetchInProgress = 'FETCH_IN_PROGRESS',
  LoginFailure = 'LOGIN_FAILURE',
  ReadyToFetch = 'READY_TO_FETCH',
  SaveFailure = 'SAVE_FAILURE'
}

export type Amount = {
  __typename?: 'Amount';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Date>;
};

export type Breakdown = {
  __typename?: 'Breakdown';
  category?: Maybe<Category>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export enum Category {
  Distributors = 'DISTRIBUTORS',
  Merch = 'MERCH',
  Royalties = 'ROYALTIES',
  Touring = 'TOURING'
}

export type Currency = {
  __typename?: 'Currency';
  id?: Maybe<Scalars['ID']>;
  ticker?: Maybe<Scalars['String']>;
};

export type Date = {
  __typename?: 'Date';
  unix?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ExportEarningsFilter = {
  category?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['String']>;
  retailerName?: InputMaybe<Scalars['String']>;
  songId?: InputMaybe<Scalars['String']>;
  songName?: InputMaybe<Scalars['String']>;
  sourceId?: InputMaybe<Scalars['String']>;
  sourceName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export enum InputTeamMemberRoles {
  Editor = 'EDITOR',
  Viewer = 'VIEWER'
}

export type InvitationInfo = {
  __typename?: 'InvitationInfo';
  isValid?: Maybe<Scalars['Boolean']>;
  receiverName?: Maybe<Scalars['String']>;
  receiverSurname?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderSurname?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
};

export type LinkedSource = {
  __typename?: 'LinkedSource';
  category?: Maybe<Category>;
  id?: Maybe<Scalars['ID']>;
  isShared?: Maybe<Scalars['Boolean']>;
  lastParsing?: Maybe<Date>;
  name?: Maybe<Scalars['String']>;
  parseStatus?: Maybe<Array<Maybe<UserSourceFileStatus>>>;
  sourceId?: Maybe<Scalars['ID']>;
  status?: Maybe<AccountStatuses>;
  type?: Maybe<Scalars['String']>;
};

export type MonthlyTotal = {
  __typename?: 'MonthlyTotal';
  month?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Float']>;
  unix?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type MonthlyTotalsFilter = {
  category?: InputMaybe<Category>;
  productId?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['String']>;
  showAllTeams?: InputMaybe<Scalars['Boolean']>;
  sourceType?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTeamInvitation?: Maybe<Scalars['Boolean']>;
  addTeamMember?: Maybe<Team>;
  addTeamSource?: Maybe<Team>;
  changeTeamMemberRole?: Maybe<Team>;
  changeUserCurrency: UserInfo;
  createTeam?: Maybe<Team>;
  deleteTeam?: Maybe<Scalars['Boolean']>;
  deleteTeamMember?: Maybe<Team>;
  deleteTeamSource?: Maybe<Team>;
  editTeamTitle?: Maybe<Team>;
  editUserSource: LinkedSource;
  editUserSourceTitle: LinkedSource;
  exportEarnings?: Maybe<Scalars['Boolean']>;
  leaveTeam?: Maybe<Scalars['Boolean']>;
  removeSourceCredential?: Maybe<Scalars['Boolean']>;
  sendMFACode?: Maybe<Scalars['Boolean']>;
  setSourceCredential: LinkedSource;
};


export type MutationAcceptTeamInvitationArgs = {
  accept: Scalars['Boolean'];
  invitationId: Scalars['ID'];
};


export type MutationAddTeamMemberArgs = {
  email: Scalars['String'];
  role: InputTeamMemberRoles;
  teamId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationAddTeamSourceArgs = {
  sourceId: Scalars['ID'];
  teamId: Scalars['ID'];
  userSourceId?: InputMaybe<Scalars['ID']>;
};


export type MutationChangeTeamMemberRoleArgs = {
  role: InputTeamMemberRoles;
  teamId: Scalars['ID'];
  teamMemberId: Scalars['ID'];
};


export type MutationChangeUserCurrencyArgs = {
  currencyId: Scalars['ID'];
};


export type MutationCreateTeamArgs = {
  title: Scalars['String'];
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['ID'];
};


export type MutationDeleteTeamMemberArgs = {
  teamId: Scalars['ID'];
  teamMemberId: Scalars['ID'];
};


export type MutationDeleteTeamSourceArgs = {
  sourceId: Scalars['ID'];
  teamId: Scalars['ID'];
  userSourceId?: InputMaybe<Scalars['ID']>;
};


export type MutationEditTeamTitleArgs = {
  teamId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationEditUserSourceArgs = {
  credentials: SourceCredentials;
  internalTitle: Scalars['String'];
  userSourceId: Scalars['ID'];
};


export type MutationEditUserSourceTitleArgs = {
  internalTitle: Scalars['String'];
  userSourceId: Scalars['ID'];
};


export type MutationExportEarningsArgs = {
  email: Scalars['String'];
  filter?: InputMaybe<ExportEarningsFilter>;
};


export type MutationLeaveTeamArgs = {
  teamId: Scalars['ID'];
};


export type MutationRemoveSourceCredentialArgs = {
  userSourceId: Scalars['ID'];
};


export type MutationSendMfaCodeArgs = {
  code: Scalars['String'];
  mfaCodeId: Scalars['ID'];
  userSourceId: Scalars['ID'];
};


export type MutationSetSourceCredentialArgs = {
  credentials: SourceCredential;
  internalTitle: Scalars['String'];
  sourceId: Scalars['ID'];
};

export type Pagination = {
  limit: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
};

export enum ParseStatus {
  TeTd = 'TE_TD'
}

export type Product = {
  __typename?: 'Product';
  account?: Maybe<Array<Maybe<Account>>>;
  amount?: Maybe<Array<Maybe<Amount>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  projection?: Maybe<Projection>;
  totalAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<ProductType>;
};

export type ProductFilter = {
  category?: InputMaybe<Category>;
  date?: InputMaybe<RangeDate>;
  name?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['ID']>;
  showAllTeams?: InputMaybe<Scalars['Boolean']>;
  sourceTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type ProductList = {
  __typename?: 'ProductList';
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<Product>>>;
  total?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export enum ProductType {
  Merch = 'MERCH',
  Song = 'SONG'
}

export type Projection = {
  __typename?: 'Projection';
  currencyId?: Maybe<Scalars['ID']>;
  month1?: Maybe<Scalars['Float']>;
  month2?: Maybe<Scalars['Float']>;
  month3?: Maybe<Scalars['Float']>;
  month4?: Maybe<Scalars['Float']>;
  month5?: Maybe<Scalars['Float']>;
  month6?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  currency?: Maybe<Array<Maybe<Currency>>>;
  findUsersByEmail?: Maybe<Array<Maybe<SearchUserInfo>>>;
  getMonthlyTotals?: Maybe<Array<Maybe<MonthlyTotal>>>;
  getProducts?: Maybe<ProductList>;
  getRetailers?: Maybe<RetailerList>;
  getSourceBreakdowns?: Maybe<Array<Maybe<Breakdown>>>;
  getSources?: Maybe<Array<Maybe<Source>>>;
  getTeams?: Maybe<Array<Maybe<Team>>>;
  linkedSources?: Maybe<Array<Maybe<StaticSource>>>;
  userInfo?: Maybe<UserInfo>;
  validateTeamInvitation?: Maybe<InvitationInfo>;
};


export type QueryFindUsersByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetMonthlyTotalsArgs = {
  filter?: InputMaybe<MonthlyTotalsFilter>;
};


export type QueryGetProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryGetRetailersArgs = {
  filter?: InputMaybe<RetailerFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryGetSourceBreakdownsArgs = {
  filter?: InputMaybe<SourceBreakdownFilter>;
};


export type QueryGetSourcesArgs = {
  filter?: InputMaybe<SourceFilter>;
};


export type QueryValidateTeamInvitationArgs = {
  invitationId: Scalars['ID'];
};

export type RangeDate = {
  end?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
};

export type Retailer = {
  __typename?: 'Retailer';
  amount?: Maybe<Array<Maybe<Amount>>>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  projection?: Maybe<Projection>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type RetailerFilter = {
  date?: InputMaybe<RangeDate>;
  name?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  showAllTeams?: InputMaybe<Scalars['Boolean']>;
  sourceType?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type RetailerList = {
  __typename?: 'RetailerList';
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  retailers?: Maybe<Array<Maybe<Retailer>>>;
  total?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type SearchUserInfo = {
  __typename?: 'SearchUserInfo';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type Sort = {
  field: Scalars['String'];
  type: SortType;
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Source = {
  __typename?: 'Source';
  account?: Maybe<Array<Maybe<Account>>>;
  amount?: Maybe<Array<Maybe<Amount>>>;
  category?: Maybe<Category>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projection?: Maybe<Projection>;
  type?: Maybe<Scalars['String']>;
};

export type SourceBreakdownFilter = {
  date?: InputMaybe<RangeDate>;
  showAllTeams?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type SourceCredential = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SourceCredentials = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SourceFilter = {
  date?: InputMaybe<RangeDate>;
  productId?: InputMaybe<Scalars['String']>;
  retailerId?: InputMaybe<Scalars['String']>;
  showAllTeams?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type StaticSource = {
  __typename?: 'StaticSource';
  category?: Maybe<Category>;
  id?: Maybe<Scalars['ID']>;
  linkedSources?: Maybe<Array<Maybe<LinkedSource>>>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  id?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<TeamMember>>>;
  name?: Maybe<Scalars['String']>;
  sources?: Maybe<Array<Maybe<TeamSource>>>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  id?: Maybe<Scalars['ID']>;
  invitationEmail?: Maybe<Scalars['String']>;
  isConfirmedInvitation?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<TeamMemberRoles>;
  surname?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
};

export enum TeamMemberRoles {
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

export type TeamSource = {
  __typename?: 'TeamSource';
  category?: Maybe<Category>;
  name?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  userSourceId?: Maybe<Scalars['ID']>;
  visibleName?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  currency?: Maybe<Currency>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export enum UserSourceFileStatus {
  Parsed = 'PARSED',
  ParseFailure = 'PARSE_FAILURE',
  ParsingInProgress = 'PARSING_IN_PROGRESS',
  ReadyForParsing = 'READY_FOR_PARSING',
  SaveFailure = 'SAVE_FAILURE'
}
