import React, { FC } from 'react';

import { TableCell } from '../TableCell/TableCell';
import { DefaultHeaderCellRenderer } from '../CellRenderers/DefaultHeaderCellRenderer/DefaultHeaderCellRenderer';

import { TableHeaderRowProps } from './types';

export const TableHeaderRow: FC<TableHeaderRowProps> = ({
    className,
    config,
    columnDefs,
    onSort,
    onDefaultSort,
    isEmpty,
}) => {
    return (
        <div className={className}>
            {columnDefs
                .filter((item) => !item.isOnlyChild)
                .map((columnDef, index) => {
                    const HeaderCellRenderer = columnDef.headerCellRenderer || DefaultHeaderCellRenderer;
                    return (
                        <TableCell
                            key={index}
                            isHeaderCell
                            columnDef={columnDef}
                            cellRenderer={
                                <HeaderCellRenderer
                                    config={config}
                                    columnDef={columnDef}
                                    onSort={onSort}
                                    onDefaultSort={onDefaultSort}
                                    isEmpty={isEmpty}
                                />
                            }
                        />
                    );
                })}
        </div>
    );
};
