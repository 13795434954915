import { v4 as uuidv4 } from 'uuid';

export const headerNames = {
    dataSource: 'Data Source',
    shared: 'Shared Accounts',
};

export const fieldNames = {
    name: 'name',
    members: 'members',
};

export const cellClassNames = {
    half: 'half',
};

export const DataSourceListHeading = 'Data Source Accounts';

export const AddDataSourceText = '+ Add source';

export const dataSourceRowsMock = [
    {
        name: 'Select Data Source',
        members: [],
        rowId: uuidv4(),
        isNew: true,
        noValueChosen: true,
    },
];
