import React, { FC } from 'react';

import { Icon } from 'components/Icon/Icon';
import { Logo } from 'components/Logo/Logo';

import { contactUsText, copyrightText, privacyPolicyText, termsOfServiceText } from './constants';

import styles from './Footer.module.scss';

export const Footer: FC = () => {
    const testId = 'Footer';
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles['logo-box']}>
                    <Logo type="utopia" className={styles.logo} />
                    <span data-testid={`${testId}.Copyright`}>{copyrightText}</span>
                </div>
                <div className={styles['list-box']}>
                    <ul className={styles['footer-list']}>
                        <li className={styles['footer-item']}>
                            <a
                                href="https://utopiamusic.com/pdf/utopia_terms_v3_20220811.pdf"
                                className={styles['footer-link']}
                            >
                                {termsOfServiceText}
                            </a>
                        </li>
                        <li className={styles['footer-item']}>
                            <a href="https://utopiamusic.com/policy/privacy" className={styles['footer-link']}>
                                {privacyPolicyText}
                            </a>
                        </li>
                        <li className={styles['footer-item']}>
                            <a href="mailto:justin.levenson@utopiamusic.com" className={styles['footer-link']}>
                                {contactUsText}
                            </a>
                        </li>
                    </ul>
                    <ul className={styles['social-list']}>
                        <li className={styles['social-item']}>
                            <a href="https://www.facebook.com/likeutopiamusic" className={styles['social-link']}>
                                <Icon type="facebook" className={styles['social-svg']} />
                            </a>
                        </li>
                        <li className={styles['social-item']}>
                            <a href="https://www.instagram.com/likeutopiamusic" className={styles['social-link']}>
                                <Icon type="instagram" className={styles['social-svg']} />
                            </a>
                        </li>
                        <li className={styles['social-item']}>
                            <a href="https://twitter.com/likeutopiamusic" className={styles['social-link']}>
                                <Icon type="twitter" className={styles['social-svg']} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};
