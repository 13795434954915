import React from 'react';
import cn from 'classnames';

import styles from './NumberOfLinked.module.scss';
import { NumberOfLinkedProps } from './types';
import { getNumberOfLinkedBlockToShow } from './utils';

export const NumberOfLinked = (props: NumberOfLinkedProps) => {
    return (
        <span data-testid={`${props.testId}.numberItem`} className={cn(styles.container, props.className)}>
            {getNumberOfLinkedBlockToShow(props.numOfLinked)}
        </span>
    );
};
