import React, { FC } from 'react';
import cn from 'classnames';
import styles from './Form.module.scss';

import { FormProps } from './types';

import { CloseButton } from 'components/CloseButton/CloseButton';

export const Form: FC<FormProps> = ({ className, children, onCancel }) => (
    <form className={cn(styles.container, className)}>
        <CloseButton onCancel={onCancel} />
        {children}
    </form>
);
