import { ApolloCache } from '@apollo/client';

// based on https:/ / github.com / apollographql / apollo - client / issues / 7129;
const convertKeyArgs = (key: string): Record<string, any> | undefined => {
    const keyArgs = key.substring(key.indexOf('(') + 1, key.lastIndexOf(')'));

    try {
        const args = JSON.parse(keyArgs);

        return args;
    } catch (error) {
        return undefined;
    }
};

export const extractFieldNameAndArgs = (key: string) => {
    if (!key.includes('(')) {
        return { fieldName: key, args: undefined };
    }
    const fieldName = key.slice(0, key.indexOf('('));

    const args = convertKeyArgs(key);

    return { fieldName, args };
};

export const invalidateApolloCacheFor = (
    cache: ApolloCache<any>,
    fieldAndArgsTest: (options: { fieldName: string; args: Record<string, any> | undefined }) => boolean,
) => {
    const rootQueryKeys = Object.keys(cache.extract().ROOT_QUERY);
    const itemsToEvict = rootQueryKeys
        .map((key) => extractFieldNameAndArgs(key))
        .filter((options) => fieldAndArgsTest(options));

    itemsToEvict.forEach(({ fieldName, args }) => {
        cache.evict({
            id: 'ROOT_QUERY',
            fieldName,
            args: args,
        });
    });
    cache.gc();
};
