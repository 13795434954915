import React, { FC, useContext } from 'react';
import { AuthContext } from 'shared/providers/AuthProvider/AuthProvider';
import * as AuthService from 'shared/services/AuthService';
import { LoaderComponent } from 'components/Loader/Loader';
import { useLocation } from 'react-router-dom';
import { Routes } from 'shared/constants';

export const RequireAuth: FC = ({ children }) => {
    const { isUserAuthenticated } = useContext(AuthContext);
    const location = useLocation();

    if (!isUserAuthenticated && location.pathname !== Routes.auth) {
        AuthService.getLoginPage();
        return <LoaderComponent isFullPage={true} />;
    }

    return <>{children}</>;
};
