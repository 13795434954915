import React, { FC, useContext, useState } from 'react';

import { PageHeading } from 'components/PageHeading/PageHeading';
import { MainInfoBlock } from './components/MainInfoBlock/MainInfoBlock';
import { SmallInfoBlock } from './components/SmallInfoBlock/SmallInfoBlock';
import { DataSourcesCounter } from 'components/DataSourcesCounter/DataSourcesCounter';

import { ProfileProps } from './types';

import { datasourcesType, teamType } from './components/SmallInfoBlock/constants';
import { profileHeadingText } from './constants';
import { UserInfoContext } from 'shared/providers/UserInfoProvider/UserInfoProvider';
import { updateProfile } from 'shared/services/IAMService';
import styles from './Profile.module.scss';
import commonStyles from '../CommonStyles.module.scss';
import { TeamsCounter } from 'components/TeamsCounter/TeamsCounter';

export const Profile: FC<ProfileProps> = ({ testId }) => {
    const { user, setUser } = useContext(UserInfoContext);
    const [updatingProfile, setUpdatingProfile] = useState(false);

    const updateUserProfile = async (details: any) => {
        setUpdatingProfile(true);
        await updateProfile(details);
        if (setUser) setUser(details);
        setUpdatingProfile(false);
    };

    return (
        <div className={commonStyles['account-container']}>
            <PageHeading className={styles.heading}>{profileHeadingText}</PageHeading>
            <div className={styles['profile-row']}>
                <MainInfoBlock
                    userDetails={user!}
                    updateUserDetails={updateUserProfile!}
                    updatingProfile={updatingProfile}
                    className={styles['main-block']}
                    testId={`${testId}.mainInfoBlock`}
                />
                <div className={styles['data-block-wrapper']}>
                    <SmallInfoBlock
                        className={styles['data-block']}
                        type={datasourcesType}
                        numOfType={<DataSourcesCounter />}
                        icon="data"
                        testId={`${testId}.smallInfoBlock`}
                    />
                    <SmallInfoBlock
                        className={styles['data-block']}
                        type={teamType}
                        numOfType={<TeamsCounter />}
                        icon="member"
                        testId={`${testId}.smallInfoBlock`}
                    />
                </div>
            </div>
        </div>
    );
};
