import { CustomError, LinkedSource } from 'shared/types';

export type SourceAccount = {
    id?: string;
    category?: string;
    name?: string;
    __typename: 'LinkedSource';
    type?: string;
};

export interface AccountData {
    email: { value: string; errors: string };
    title: { value: string; errors: string };
    password: { value: string; errors: string };
}

export interface DataSourceSelectedState {
    errors: CustomError[];
    accountToEdit: SourceAccount | null;
    currentEditing: { id: string | null } | null;
    isAccountLinked?: boolean;
    isAccountFormValid: string;
    id?: string;
}

export enum Actions {
    SetErrors = 'SET_ERRORS',
    SetAccountToEdit = 'SET_ACCOUNT_TO_EDIT',
    SetCurrentEditing = 'SET_CURRENT_EDITING',
    SetIsAccountLinked = 'SET_IS_ACCOUNT_LINKED',
    SetAccounts = 'SET_ACCOUNTS',
    SetId = 'SET_ID',
    SetIsAccountFormValid = 'SET_IS_ACCOUNT_FORM_VALID',
}

type SetErrors = {
    type: Actions.SetErrors;
    payload: any;
};

type SetAccountToEdit = {
    type: Actions.SetAccountToEdit;
    payload: SourceAccount | null;
};

type SetCurrentEditing = {
    type: Actions.SetCurrentEditing;
    payload: { id: string | null } | null;
};

type SetIsAccountLinked = {
    type: Actions.SetIsAccountLinked;
    payload: boolean | undefined;
};

type SetAccounts = {
    type: Actions.SetAccounts;
    payload: LinkedSource[];
};

type SetId = {
    type: Actions.SetId;
    payload: string | undefined;
};

type SetIsAccountFormValid = {
    type: Actions.SetIsAccountFormValid;
    payload: string;
};

export type ActionTypes =
    | SetErrors
    | SetAccountToEdit
    | SetCurrentEditing
    | SetIsAccountLinked
    | SetAccounts
    | SetId
    | SetIsAccountFormValid;
