import { getToken } from 'shared/services/AuthService';

const uri = process.env.REACT_APP_UTOPIA_IAM_ENDPOINT!;
const root = 'v1';

export type ProfileUpdateDto = {
    firstName?: string;
    lastName?: string;
};

export async function updateProfile(profile: ProfileUpdateDto): Promise<ProfileUpdateDto> {
    return fetch(`${uri}/${root}/profile`, {
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(profile),
    }).then((res: Response) => {
        return res.json();
    });
}
