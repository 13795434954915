import React, { FC } from 'react';
import cn from 'classnames';

import { ToggleSwitchProps } from './types';

import styles from './ToggleSwitch.module.scss';

export const ToggleSwitch: FC<ToggleSwitchProps> = (props) => {
    const { label, state, toggle, disabled, testId } = props;

    return (
        <label className={styles['toggle-container']}>
            <input onClick={toggle} className={styles['toggle-checkbox']} type="checkbox" disabled={disabled} />
            <div
                className={cn(styles['toggle-area'], {
                    [styles['toggle-area-active']]: state,
                })}
            >
                <span
                    className={cn(styles['toggle-button'], {
                        [styles['toggle-button-active']]: state,
                    })}
                />
            </div>
            <span data-testid={`${testId}.label`} className={styles['toggle-text']}>
                {label}
            </span>
        </label>
    );
};
