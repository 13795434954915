import { useState } from 'react';

export const useSwitcher = (initialState: boolean) => {
    const [checked, setChecked] = useState(initialState);
    const toggleSwitcher = () => setChecked(!checked);

    return {
        checked,
        toggleSwitcher,
    };
};
