import React, { FC, useState } from 'react';
import { TeamMemberTable } from './components/TeamMemberTable/TeamMemberTable';
import { DeleteRowCellRenderer } from 'components/Table/components/CellRenderers/DeleteRowCellRenderer/DeleteRowCellRenderer';
import { StatusCellRenderer } from 'components/Table/components/CellRenderers/StatusCellRenderer/StatusCellRenderer';
import { TeamMemberCellRenderer } from 'components/Table/components/CellRenderers/TeamMemberCellRenderer/TeamMemberCellRenderer';
import { Button } from 'components/Button/Button';
import styles from './TeamMembersList.module.scss';
import { TeamMemberListProps } from './types';
import { styleTypes } from 'shared/constants';
import {
    AddMemberButtonText,
    cancelModalBtnText,
    cellClassNames,
    descriptionModalText,
    fieldNames,
    headerNames,
    okModalBtnText,
    TeamMembersText,
    titleModalBtn,
} from './constants';
import { ColumnDef } from 'components/Table/types';
import { AccountRoleRenderer } from 'components/Table/components/CellRenderers/AccountRoleCellRenderer/AccountRoleCellRenderer';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';

export const TeamMembersList: FC<TeamMemberListProps> = (props) => {
    const [modalState, setModalState] = useState<any>('');
    const { tableItems, deleteRow, addMember, confirmMember, changePermission, isViewer, isEditor, changeMember } =
        props;

    const handleDeleteRow = (row: any) => setModalState(row);
    const handleAcceptDelete = () => {
        deleteRow(modalState);
        setModalState(false);
    };
    const handleCancelDelete = () => setModalState(false);
    const teamColumnDefs: ColumnDef[] = [
        {
            field: fieldNames.member,
            headerName: headerNames.dataSource,
            cellRenderer: TeamMemberCellRenderer,
            onClick: confirmMember,
            onChange: changeMember,
            noLeftCellPadding: true,
            className: (row: { [key: string]: any }) => {
                if (row?.newMember) return cellClassNames.largeDouble;
                return cellClassNames.large;
            },
        },
        {
            field: fieldNames.account,
            headerName: headerNames.accounts,
            className: (row: { [key: string]: any }) => {
                if (row?.newMember) return cellClassNames.hidden;
                return cellClassNames.large;
            },
            noLeftCellPadding: true,
        },
        {
            field: fieldNames.status,
            headerName: headerNames.status,
            cellRenderer: StatusCellRenderer,
            className: cellClassNames.small,
            noLeftCellPadding: true,
        },
        {
            field: fieldNames.permission,
            headerName: headerNames.permission,
            cellRenderer: AccountRoleRenderer,
            onChange: changePermission,
            className: cellClassNames.permissionColumn,
            noLeftCellPadding: true,
            tableItems,
        },
        {
            isViewer: isViewer,
            isEditor: isEditor,
            field: fieldNames.delete,
            cellRenderer: DeleteRowCellRenderer,
            onClick: handleDeleteRow,
            className: cellClassNames.iconColumn,
        },
    ];
    return (
        <>
            <ModalDialog
                isOpen={!!modalState}
                toggleModal={() => setModalState(false)}
                onOk={handleAcceptDelete}
                onCancel={handleCancelDelete}
                onOkBtnText={okModalBtnText}
                onCancelBtnText={cancelModalBtnText}
                title={titleModalBtn}
                description={[descriptionModalText]}
            />
            <div className={styles.heading}>{TeamMembersText}</div>
            <TeamMemberTable columnDefs={teamColumnDefs} tableItems={tableItems} />
            {!(isViewer || isEditor) && (
                <Button
                    style={styleTypes.secondary}
                    className={styles.addMemberButton}
                    isLink={false}
                    onClick={addMember}
                >
                    {AddMemberButtonText}
                </Button>
            )}
        </>
    );
};
