import React, { FC } from 'react';
import IconMap from './LogoMap';
import { LogoProps } from './types';
import cn from 'classnames';
import styles from './Logo.module.scss';

export const Logo: FC<LogoProps> = ({ type, className }) => {
    const LogoComponent = IconMap[type.replace(/\s/, '').toLowerCase()];
    const wrapperClassName = cn(styles.logoWrapper, className);

    return (
        <span className={wrapperClassName}>
            <LogoComponent id={`${type}-logo`} />
        </span>
    );
};
