import React, { useContext } from 'react';

import { Heading } from 'components/Heading/Heading';
import { MainTotalSum } from 'components/MainTotalSum/MainTotalSum';
import { PeriodSubtitle } from 'components/PeriodSubtitle/PeriodSubtitle';

import { TotalEarningsPropsType } from './types';

import { getFormattedAndRoundedValue } from 'shared/utils/getFormattedAndRoundedValue';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';

import styles from './TotalEarningsHeading.module.scss';
import { getTotalEarningsHeader } from './helper';

export const TotalEarningsHeading = ({
    totalEarnings,
    isLoading,
    isDisabled,
    tabsWithRelatedEntityId,
}: TotalEarningsPropsType) => {
    const { selectedPeriod } = useContext(DateFilterContext);

    return (
        <div className={styles.container}>
            <div>
                <Heading className={styles.title} data-testid="totalEarningsHeading">
                    {getTotalEarningsHeader(tabsWithRelatedEntityId)}
                </Heading>
                <PeriodSubtitle selectedPeriod={selectedPeriod} isDisabled={isDisabled} />
            </div>
            {!isLoading && <MainTotalSum>{getFormattedAndRoundedValue(totalEarnings.toString())}</MainTotalSum>}
        </div>
    );
};
