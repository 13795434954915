import { DataSourceDropdownDataType, TableItemsType } from 'shared/types';

export const useGetDropdownOptions = (
    tableItems: TableItemsType | undefined,
    suggestedUsersList: DataSourceDropdownDataType | undefined,
) => {
    const dataSourceNamesAlreadyUsed =
        tableItems?.map((item) => item.name as String).filter((item) => item !== '') ?? [];

    const dropdownOptions =
        suggestedUsersList?.filter(
            (option) => dataSourceNamesAlreadyUsed.findIndex((name) => name === option.name) < 0,
        ) ?? [];

    return dropdownOptions;
};
