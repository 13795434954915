import React, { FC, useEffect } from 'react';
import { Table } from 'components/Table/Table';
import { TableRowRenderer } from 'components/Table/components/TableRowRenderer/TableRowRenderer';
import styles from './TeamMemberTable.module.scss';
import { TeamMemberTableProps } from './types';
import { useTable } from 'components/Table/hooks/useTable';
import { capitalize } from 'lodash';
import { invitationStatus, ownerValue } from 'components/TeamMembersList/constants';

export const TeamMemberTable: FC<TeamMemberTableProps> = ({ tableItems, columnDefs }) => {
    const { onSort, onDefaultSort, viewMore, searchInTable, state, updateTableSize } = useTable(tableItems.length);
    useEffect(() => {
        updateTableSize(tableItems.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableItems]);

    const computedFields = (item: any) => {
        const isConfirmedInvitation = () => {
            if (!item.isConfirmedInvitation) {
                if (item.role === ownerValue) return '';
                if (item.newMember || item.userToCreate) return invitationStatus.notSent;
                return invitationStatus.pending;
            }
            return invitationStatus.active;
        };
        const capitalizedPermission = capitalize(item.role);
        return {
            ...item,
            role: capitalizedPermission,
            isConfirmedInvitation: isConfirmedInvitation(),
        };
    };

    return (
        <Table
            onSort={onSort}
            onDefaultSort={onDefaultSort}
            viewMore={viewMore}
            searchInTable={searchInTable}
            state={state}
            className={styles['team-member-table']}
            tableItems={tableItems}
            columnDefs={columnDefs}
            rowRenderer={TableRowRenderer}
            withDetails
            computedFields={computedFields}
        />
    );
};
