import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import { ActionProps } from './types';

import styles from './Action.module.scss';

export const Action: FC<ActionProps> = ({ className, children, navigateTo, onClick }) => {
    if (navigateTo) {
        return (
            <Link to={navigateTo} className={cn(styles.btn, className)}>
                {children}
            </Link>
        );
    }
    return (
        <button className={cn(styles.btn, className)} onClick={onClick}>
            {children}
        </button>
    );
};
