import { useState } from 'react';
import { RevenueTableItemsType, TeamAccountsTableItemsType } from 'shared/types';

export const useShowCollapsed = (tableItems: RevenueTableItemsType | TeamAccountsTableItemsType) => {
    const initialCollapsedItemsList = tableItems.map((tableItem) => ({ ...tableItem, collapsed: false }));
    const [rowsCollapsed, setRowsCollapsed] = useState(initialCollapsedItemsList);

    const changeCollapsedRowsList = (id: string) => {
        if (!rowsCollapsed.some((row) => row.id !== id && row.collapsed)) {
            setRowsCollapsed(
                [...rowsCollapsed].map((tableItem) => {
                    if (tableItem.id === id) {
                        return {
                            ...tableItem,
                            collapsed: !tableItem.collapsed,
                        };
                    }
                    return tableItem;
                }),
            );
        }
    };

    return { rowsCollapsed, changeCollapsedRowsList };
};
