import React, { FC, useContext } from 'react';
import { Table } from 'components/Table/Table';
import styles from './TeamAccountsListTable.module.scss';
import { ITeamAccountsList } from './types';
import { TableRowRenderer } from 'components/Table/components/TableRowRenderer/TableRowRenderer';
import { useTable } from 'components/Table/hooks/useTable';
import { AccountTeamExpandedRow } from 'components/Table/components/TableRowRenderer/components/AccountTeamExpandedRow/AccountTeamExpandedRow';
import { useShowCollapsed } from 'shared/hooks/useShowCollapsed';
import { TeamsContext } from 'pages/Teams/providers/TeamsProvider';

export const TeamAccountsListTable: FC<ITeamAccountsList> = ({
    tableItems,
    columnDefs,
    onDeleteTeam,
    onSaveChanges,
    leaveTeam,
    dataSourcesDropdownData,
    teamOwnerInfo,
    canEditTeam,
}) => {
    const { rowsCollapsed, changeCollapsedRowsList } = useShowCollapsed(tableItems);
    const { onSort, onDefaultSort, viewMore, searchInTable, state } = useTable(tableItems.length);
    const { collapse } = useContext(TeamsContext);
    const handleCollapseRow = (rowId: string) => {
        if (canEditTeam) {
            collapse(!rowsCollapsed.find((row) => row.id === rowId)?.collapsed);
            changeCollapsedRowsList(rowId);
        }
    };

    const computedFields = (item: any) => {
        return {
            ...item,
            dataSources: `${item?.sources?.length ? item.sources.length : 0} ${
                item?.sources?.length === 1 ? 'Account' : 'Accounts'
            }`,
        };
    };

    return (
        <Table
            collapsedItems={rowsCollapsed}
            onSort={onSort}
            onDefaultSort={onDefaultSort}
            viewMore={viewMore}
            searchInTable={searchInTable}
            state={state}
            className={styles.teamAccountsListTable}
            tableItems={tableItems}
            columnDefs={columnDefs}
            rowRenderer={TableRowRenderer}
            withDetails
            rowRendererConfig={{
                classnames: ['withBorderRadius'],
                onSaveChanges: onSaveChanges,
                collapsedRow: AccountTeamExpandedRow,
                onDeleteTeam: onDeleteTeam,
                onLeaveTeam: leaveTeam,
                dataSourcesDropdownData: dataSourcesDropdownData,
                teamOwnerInfo: teamOwnerInfo,
                canEditRow: canEditTeam,
            }}
            onCollapseRow={handleCollapseRow}
            headerClassName={styles.withoutBorderHeader}
            computedFields={computedFields}
            canCollapseOnRowClick
        />
    );
};
