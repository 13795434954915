import { AccountStatuses, UserSourceFileStatus } from 'gql/__generated__/types';
import { LinkedSource } from '../../shared/types';

const ScrapeErrorStatuses: AccountStatuses[] = [
    AccountStatuses.FetchFailure,
    AccountStatuses.LoginFailure,
    AccountStatuses.SaveFailure,
];

const ParseErrorStatuses: UserSourceFileStatus[] = [
    UserSourceFileStatus.ParseFailure,
    UserSourceFileStatus.SaveFailure,
];

export const sourceHasProblem = (linkedSources: LinkedSource[] | undefined): boolean =>
    Boolean(
        linkedSources?.some((linked) => {
            return !linked?.status || ScrapeErrorStatuses.includes(linked.status);
        }) ||
            linkedSources?.some((linked) => linked?.parseStatus?.some((status) => ParseErrorStatuses.includes(status))),
    );
