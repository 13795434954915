import { PeriodValues } from 'shared/types';

export const dropdownPlaceholder = 'Select an option';

export const allTimeText = 'All Time';
export const thisYearText = 'This Year';
export const lastThreeMonthsText = 'Last 3 months';
export const disabledText = 'Period';

export const periodSelectorValues = [PeriodValues.allTime, PeriodValues.thisYear, PeriodValues.lastThreeMonths];
