import { CROSSHAIR_CLASS_NAME } from '../constants';

const updateCrosshairVisibility = (show: boolean) => {
    let crosshair = document.getElementsByClassName(CROSSHAIR_CLASS_NAME);
    if (crosshair[0]) {
        (crosshair[0] as any).style.visibility = show ? '' : 'hidden';
    }
};

export const showCrosshair = () => {
    updateCrosshairVisibility(true);
};

export const hideCrosshair = () => {
    updateCrosshairVisibility(false);
};
