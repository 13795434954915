import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Earnings } from 'shared/types';
import qs from 'qs';
import { PAGINATION_LIMIT } from 'shared/constants';

export const useEarningsPagination = () => {
    const { search } = useLocation();

    const [earnings, setEarnings] = useState<Earnings[]>([]);
    const [savedSearch, setSavedSearch] = useState('');
    const searchResult = qs.parse(search, { ignoreQueryPrefix: true });

    useEffect(() => {
        const searchCopy = Object.assign({}, searchResult);
        delete searchCopy.page;

        const strSearch = JSON.stringify(searchCopy);

        if (strSearch !== savedSearch) {
            setSavedSearch(strSearch);
        }
    }, [savedSearch, searchResult]);

    const setEarningsWithPagination = useCallback((newEarnings: Earnings[], page: number) => {
        setEarnings((prevEarnings) => {
            const noMorePagesToLoad = prevEarnings.length % PAGINATION_LIMIT;
            if (prevEarnings.length > page * PAGINATION_LIMIT || page === 1 || noMorePagesToLoad) {
                return [...newEarnings];
            }
            if (prevEarnings.length === page * PAGINATION_LIMIT) {
                return [...prevEarnings];
            }
            return [...prevEarnings, ...newEarnings];
        });
    }, []);

    return {
        setEarningsWithPagination,
        earnings,
    };
};
