import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { DateLabels } from './components/DateLabels/DateLabels';
import { createChartConfig } from './utils/createChartConfig';
import { ChartParams, ChartProps } from './interfaces';
import { getChartXMinMax } from './utils/getChartXMinMax';
import { POINT_WIDTH } from './constants';
import { ChartType } from './constants';
import { getChartYMinMax } from './utils/getChartYMinMax';
import { currentMonth } from 'shared/utils/getDate';

import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { PeriodValues } from 'shared/types';

import './Chart.scss';
import styles from './Chart.module.scss';

export const Chart: FC<ChartProps> = (props) => {
    const { selectedPeriod } = useContext(DateFilterContext);
    const {
        chartData,
        isFilledWithColor = false,
        testId,
        chartType,
        monthlyTotals,
        selectedItem,
        isLoading,
        hasProjections,
    } = props;

    const chartTestId = `${testId}.Chart`;

    const [columnsNumber, setColumnsNumber] = useState(0);

    const { chartXMax, chartXMin } = getChartXMinMax(chartData, selectedPeriod);

    const { chartYMax, chartYMin } = getChartYMinMax(chartData, chartType);

    const type = (() => {
        if (chartType === ChartType.LineChart) {
            return isFilledWithColor ? 'area' : 'line';
        }

        return 'column';
    })();

    const plotLinePoint = hasProjections ? new Date().getTime() : undefined;

    const params: ChartParams = {
        isFilledWithColor,
        plotLinePoint,
        pointStart: chartXMin,
        pointEnd: chartXMax,
        columnsNumber,
        type,
        testId: chartTestId,
        monthlyTotals,
        selectedItem,
        chartYMax,
        chartYMin,
        selectedPeriod,
    };

    const options = createChartConfig(params, chartData);

    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const calculateColumnsNumber = useCallback(
        (chart: HighchartsReact.RefObject) => {
            const visibleColumns = (chart.chart.chartWidth - POINT_WIDTH) / POINT_WIDTH;
            switch (selectedPeriod) {
                case PeriodValues.lastThreeMonths:
                    setColumnsNumber(2);
                    break;
                case PeriodValues.thisYear:
                    if (currentMonth <= visibleColumns) {
                        setColumnsNumber(currentMonth);
                    } else {
                        setColumnsNumber(Math.round(visibleColumns));
                    }
                    break;
                default:
                    setColumnsNumber(Math.round(visibleColumns));
                    break;
            }
        },
        [selectedPeriod],
    );

    useEffect(() => {
        if (chartRef?.current) {
            const bindedCalculateColumnsNumber = calculateColumnsNumber.bind(null, chartRef?.current);

            calculateColumnsNumber(chartRef?.current);
            window.addEventListener('resize', bindedCalculateColumnsNumber);
            return () => {
                window.removeEventListener('resize', bindedCalculateColumnsNumber);
            };
        }
    }, [calculateColumnsNumber, chartRef]);

    useEffect(() => {
        if (isLoading) {
            chartRef.current?.chart.showLoading('Loading');
        } else {
            chartRef.current?.chart.hideLoading();
        }
    }, [isLoading, chartRef.current?.chart]);

    return (
        <div className={styles.container}>
            <HighchartsReact highcharts={Highcharts} options={options} immutable ref={chartRef} />
            <DateLabels minDate={chartXMin} maxDate={chartXMax} columnsNumber={columnsNumber} testId={chartTestId} />
        </div>
    );
};
