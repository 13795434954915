import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import { PeriodSelector } from 'components/PeriodSelector/PeriodSelector';
import { EarningsBreakdown } from './components/EarningsBreakdown/EarningsBreakdown';
import { DataSourcesWidget } from './components/DataSourcesWidget/DataSourcesWidget';
import { ChildSourceCellRenderer } from 'components/Table/components/CellRenderers/ChildSourceCellRenderer/ChildSourceCellRenderer';
import { IndexCellRenderer } from 'components/Table/components/CellRenderers/IndexCellRenderer/IndexCellRenderer';
import { SelectCellRenderer } from 'components/Table/components/CellRenderers/SelectCellRenderer/SelectCellRenderer';
import { ShareCellRenderer } from 'components/Table/components/CellRenderers/ShareCellRenderer/ShareCellRenderer';
import { SourceCellRenderer } from 'components/Table/components/CellRenderers/SourceCellRenderer/SourceCellRenderer';
import { TableRowRenderer } from 'components/Table/components/TableRowRenderer/TableRowRenderer';
import { Tab } from 'components/Tab/Tab';
import { TabItem } from 'components/Tab/TabItem/TabItem';
import { Table } from 'components/Table/Table';
import { EarningsButtons } from 'components/EarningsButtons/EarningsButtons';
import { ColumnDef, TableItemType } from 'components/Table/types';
import { TotalEarningsBar } from 'components/TotalEarningsBar/TotalEarningsBar';
import { TotalEarningsHeading } from 'components/TotalEarningsHeading/TotalEarningsHeading';

import { DashboardPropsType } from './types';

import {
    earningsSourceTab,
    retailerBySourceQuery,
    sourceByProductQuery,
    tabItemKeys,
    TOP_FIVE,
    topProducts,
    topSources,
} from './constants';
import { getEarningsLink } from './utils';

import { useColor } from 'shared/hooks/useColor/useColor';
import { useTabSelection } from './hooks/useTabSelection';

import { Icon } from 'components/Icon/Icon';
import { getFormattedAndRoundedValue } from 'shared/utils/getFormattedAndRoundedValue';
import { getShareValueToShow } from 'shared/utils/getShareValueToShow';
import { isItemSpecial } from 'shared/utils/isItemSpecial';
import { getLastParsedDateFromQuery, getLastUpdatedDateText } from 'shared/utils/getDate';
import { usePreparedChartData } from 'shared/hooks/usePreparedChartData/usePreparedChartData';

import { CategoryValues, GroupByOptions, ShowProjectedEarnings } from 'shared/constants';
import { useChartTypeSelection } from 'components/Chart/hooks/useChartTypeSelector';
import { ChartType } from 'components/Chart/constants';
import { ChartTypeSelector } from 'components/Chart/components/ChartTypeSelector/ChartTypeSelector';
import { Chart } from 'components/Chart/Chart';
import { EmptyStateComponent } from 'components/EmptyState/EmptyState';
import { UserInfoContext } from 'shared/providers/UserInfoProvider/UserInfoProvider';

import { Card } from 'components/Card/Card';

import { useGetEarnings } from 'shared/hooks/earnings/useGetEarnings/useGetEarnings';
import { prepareForTable } from 'shared/hooks/earnings/utils/prepareForTable';
import { Account, SortType } from 'gql/__generated__/types';
import { useTable } from 'components/Table/hooks/useTable';
import { useShowExpanded } from 'shared/hooks/useShowExpanded';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { useSwitcher } from 'shared/hooks/useSwitcher/useSwitcher';
import { EarningsExportModal } from 'components/EarningsExportModal/EarningsExportModal';
import { useExportModal } from 'components/EarningsExportModal/hooks/useExportModal';
import { useTeamSelection } from 'shared/hooks/useTeamSelection/useTeamSelection';
import { TeamsSelector } from 'components/TeamsSelector/TeamsSelector';

import { useGetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';

import styles from './Dashboard.module.scss';
import commonStyles from '../CommonStyles.module.scss';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { useGetMonthlyTotals } from 'shared/hooks/earnings/useGetMonthlyTotals/useGetMonthlyTotals';
import { TeamsFilterContext } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';
import { useItemSelection } from '../../shared/hooks/useItemSelection/useItemSelection';
import { TeamValues } from 'shared/hooks/useTeamSelection/constants';

export const Dashboard = (props: DashboardPropsType) => {
    const { testId } = props;

    const navigate = useNavigate();
    const { search } = useLocation();

    const userInfo = useContext(UserInfoContext).user;
    const { selectedPeriod } = useContext(DateFilterContext);
    const { firstName, lastName, avatar } = userInfo!;

    const { chartTypeValue, updateChartTypeValue } = useChartTypeSelection(ChartType.StackedBarChart);
    const { onSort, onDefaultSort, viewMore, searchInTable, state } = useTable(5);

    const { earnings, isLoading: isEarningsLoading, totalEarnings, pagination, hasPagination } = useGetEarnings();

    const { monthlyTotals, isMonthlyTotalsLoading } = useGetMonthlyTotals();

    const isLoading = isEarningsLoading || isMonthlyTotalsLoading;

    const { selectedTeam: prevSelectedTeam } = useContext(TeamsFilterContext);
    const { selectedTeam, teamListOptions, setTeamQuery, getTeamIdByName } = useTeamSelection(prevSelectedTeam);

    const { data } = useGetLinkedSourcesQuery();
    const noData =
        !data?.linkedSources?.some((source) => source?.linkedSources?.length) &&
        !isLoading &&
        selectedTeam === TeamValues.myEarnings;

    const lastParsedDate = getLastParsedDateFromQuery(data);

    const teamId = getTeamIdByName(selectedTeam);

    const { selectedTab, setSelectedTab } = useTabSelection(teamId);

    const tableItems = useMemo(() => prepareForTable(earnings), [earnings]);

    const { rowsExpanded, changeExpandedRowsList } = useShowExpanded(tableItems);
    const { addSelectedItem, removeSelectedItem, selectedItems, notSelectedItems } = useItemSelection(
        tableItems,
        'dashboard',
        TOP_FIVE,
    );

    const selectedItemsWithColor = useColor(selectedItems);

    const { checked: shouldShowProjections, toggleSwitcher: toggleProjectionAppearance } = useSwitcher(false);

    const { chartData, notSelectedChartData, hasProjections } = usePreparedChartData(
        earnings,
        selectedItemsWithColor,
        shouldShowProjections,
        notSelectedItems,
        isLoading,
    );

    const { isExportModalOpen, closeExportModal, toggleExportModal } = useExportModal(false);

    const redirectToEarnings = (rowId: string, row: { [key: string]: any }) => {
        const abbreviature = selectedTab === earningsSourceTab ? retailerBySourceQuery : sourceByProductQuery;

        navigate({
            pathname: '/earnings',
            search: qs.stringify({
                q: abbreviature,
                [selectedTab]: { id: rowId, name: row.name },
                selectedPeriod: selectedPeriod,
                category: CategoryValues.All,
                teamId,
            }),
        });
    };

    const handleSourceTabClick = () => {
        setSelectedTab(GroupByOptions.Source);
    };

    const handleProductTabClick = () => {
        setSelectedTab(GroupByOptions.Product);
    };

    const checkForExpandableRows = tableItems.some((elem) => elem.accounts?.length);

    const columnDefs = [
        {
            field: 'select',
            cellRenderer: SelectCellRenderer,
        },
        {
            field: 'index',
            headerName: '#',
            cellRenderer: IndexCellRenderer,
        },
        {
            onClick: redirectToEarnings,
            clickable: (row: { [key: string]: any }) => {
                return !isItemSpecial(row.id);
            },
            field: 'name',
            headerName: GroupByOptions[selectedTab],
            cellRenderer: SourceCellRenderer,
            childCellRenderer: ChildSourceCellRenderer,
            className: (row: { [key: string]: any }) => {
                if (row?.totalAmount) return 'nameCellDashboard';
            },
        },
        selectedTab === GroupByOptions.Source
            ? {
                  field: 'category',
                  headerName: 'Category',
              }
            : null,
        {
            field: 'amount',
            headerName: 'Amount',
            formatBy: 'amount',
            defaultSort: SortType.Desc,
            valueFormatter: getFormattedAndRoundedValue,
        },
        {
            field: 'share',
            headerName: 'Share',
            cellRenderer: ShareCellRenderer,
            formatBy: 'amount',
            valueFormatter: (val: string) => getShareValueToShow(val, totalEarnings.toString()),
            className: () => {
                if (checkForExpandableRows) return 'expanded';
            },
        },
        {
            field: 'totalAmount',
            childCellRenderer: ChildSourceCellRenderer,
            formatBy: 'totalAmount',
            isOnlyChild: true,
        },
    ].filter((i) => i !== null) as ColumnDef[];

    const tabs = [
        <TabItem
            key={tabItemKeys.topSources}
            active={selectedTab === GroupByOptions.Source}
            onClick={handleSourceTabClick}
            isDisabled={noData}
        >
            {topSources}
        </TabItem>,
        <TabItem
            key={tabItemKeys.topProducts}
            active={selectedTab === GroupByOptions.Product}
            onClick={handleProductTabClick}
            isDisabled={noData}
        >
            {topProducts}
        </TabItem>,
    ];

    const greeting = `Hi ${firstName}!`;

    return (
        <div className={cn(commonStyles['main-container'], styles['main-container'])}>
            <div className={cn(commonStyles['heading-wrapper'], styles['heading-wrapper'])}>
                <div className={styles['user-info']}>
                    <span className={styles['user-photo']}>
                        {avatar ? <img src={avatar} alt={`${firstName} ${lastName}`} /> : <Icon type="user" />}
                    </span>
                    <span>{greeting}</span>
                </div>
                <div className={commonStyles['dropdown-container']}>
                    <div className={commonStyles['filters-wrapper']}>
                        <TeamsSelector
                            teamListOptions={teamListOptions}
                            selectedTeam={selectedTeam}
                            setSelectedTeam={setTeamQuery}
                        />
                        <PeriodSelector
                            className={commonStyles['filter-item']}
                            testId={`${testId}.periodSelectorComponent`}
                            isDisabled={noData}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.widgets}>
                <div className={styles['left-col']}>
                    <Card className={styles['card-block']}>
                        <TotalEarningsHeading totalEarnings={totalEarnings} isLoading={isLoading} isDisabled={noData} />
                        <div className={commonStyles['widget-options']}>
                            <div className={commonStyles['widget-tab-row']}>
                                <Tab className={commonStyles['widget-tab']}>{tabs}</Tab>
                                <EarningsButtons
                                    showEarningsLink
                                    isDisabled={noData}
                                    toggleExportModal={toggleExportModal}
                                />
                            </div>
                            <div className={commonStyles['widget-tab-row']}>
                                <div className={commonStyles['chart-options']}>
                                    {hasProjections && (
                                        <ToggleSwitch
                                            label={ShowProjectedEarnings}
                                            state={shouldShowProjections}
                                            toggle={toggleProjectionAppearance}
                                            disabled={false}
                                        />
                                    )}
                                </div>
                                <div className={commonStyles['chart-options']}>
                                    {lastParsedDate && (
                                        <span className={commonStyles['info-block']}>
                                            {`Updated on ${getLastUpdatedDateText(lastParsedDate)}`}
                                        </span>
                                    )}
                                    <ChartTypeSelector
                                        chartType={chartTypeValue}
                                        updateChartType={updateChartTypeValue}
                                        isDisabled={noData}
                                    />
                                </div>
                            </div>
                        </div>
                        <Chart
                            chartData={chartData}
                            notSelectedChartData={notSelectedChartData}
                            chartType={chartTypeValue}
                            monthlyTotals={monthlyTotals}
                            isLoading={isLoading}
                            selectedItem={selectedTab}
                            isFilledWithColor
                            hasProjections={hasProjections && shouldShowProjections}
                        />
                        <TotalEarningsBar totalEarnings={totalEarnings} isLoading={isLoading} />
                        <Table
                            shouldShowEmptyState={noData}
                            state={state}
                            onSort={onSort}
                            onDefaultSort={onDefaultSort}
                            viewMore={viewMore}
                            searchInTable={searchInTable}
                            tableItems={tableItems}
                            selectedItems={selectedItemsWithColor}
                            columnDefs={columnDefs}
                            rowRenderer={TableRowRenderer}
                            withDetails
                            selectable
                            expandedItems={rowsExpanded}
                            pagination={pagination}
                            hasPagination={hasPagination}
                            viewAll={{
                                title: 'View All',
                                icon: <Icon type="viewall" />,
                                navigateTo: getEarningsLink(search),
                            }}
                            onRowSelected={(row: TableItemType) => {
                                if (row.selected) {
                                    removeSelectedItem(row.id);
                                } else {
                                    addSelectedItem(row.id);
                                }
                            }}
                            onRowExpanded={(row: TableItemType) => changeExpandedRowsList(row.id)}
                            rowRendererConfig={{
                                classnames: [
                                    {
                                        'selected-row': (row: TableItemType) => isItemSpecial(row.id),
                                    },
                                ],
                                withDetails: true,
                                canExpand: 'accounts',
                            }}
                            isLoading={isLoading}
                            computedFields={(item: any) => {
                                if (item && item.accounts) {
                                    const computedAccounts = item.accounts.map((account: Account) => ({
                                        ...account,
                                        totalAmount: getFormattedAndRoundedValue(
                                            (account.totalAmount ?? '').toString(),
                                        ),
                                    }));

                                    return {
                                        ...item,
                                        accounts: computedAccounts,
                                    };
                                }
                                return item;
                            }}
                        />
                        {noData && <EmptyStateComponent />}
                    </Card>
                </div>
                <div className={styles['right-col']}>
                    <EarningsBreakdown testId={`${testId}.earningsBreakdown`} className={styles['card-block']} />
                    <DataSourcesWidget testId={`${testId}.earningsBreakdown`} className={styles['card-block']} />
                </div>
            </div>
            <EarningsExportModal
                isModalOpen={isExportModalOpen}
                closeExportModal={closeExportModal}
                toggleExportModal={toggleExportModal}
            />
        </div>
    );
};
