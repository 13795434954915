import { GetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';
import { AccountStatuses, UserSourceFileStatus } from 'gql/__generated__/types';
import { LinkedSource } from 'shared/types';

const ParsingInProgressStatuses: UserSourceFileStatus[] = [
    UserSourceFileStatus.ReadyForParsing,
    UserSourceFileStatus.ParsingInProgress,
];

export const isGetLinkedSourcesQueryProcessing = (sources: GetLinkedSourcesQuery | undefined) =>
    Boolean(
        sources?.linkedSources?.some((source) =>
            source?.linkedSources?.some(
                (linked) =>
                    (!linked?.lastParsing && linked?.status === AccountStatuses.ReadyToFetch) ||
                    linked?.status === AccountStatuses.FetchInProgress,
            ),
        ) ||
            sources?.linkedSources?.some((ls) =>
                ls?.linkedSources?.some((l) =>
                    l?.parseStatus?.some((status) => status && ParsingInProgressStatuses.includes(status)),
                ),
            ),
    );

export const isProcessing = (linkedSources: LinkedSource[] | undefined) =>
    Boolean(
        linkedSources?.some(
            (linked) =>
                (!linked?.lastParsing && linked?.status === AccountStatuses.ReadyToFetch) ||
                linked?.status === AccountStatuses.FetchInProgress,
        ) ||
            linkedSources?.some((linked) =>
                linked?.parseStatus?.some((status) => status && ParsingInProgressStatuses.includes(status)),
            ),
    );
