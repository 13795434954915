import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Header } from 'components/Header/Header';
import { EnhancedLeftNavigationMenu as LeftNavigationMenu } from 'components/LeftNavigationMenu/LeftNavigationMenu';
import { Footer } from 'components/Footer/Footer';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { DataSources } from 'pages/DataSources/DataSources';
import { Profile } from 'pages/Profile/Profile';
import { Earnings } from 'pages/Earnings/Earnings';
import { Teams } from 'pages/Teams/Teams';

import styles from './Root.module.scss';
import { DataSourceSelected } from 'pages/DataSourceSelected/DataSourceSelected';
import { Auth } from 'pages/Auth/Auth';
import { MfaPage } from 'components/MfaPage/MfaPage';
import { InvitationPage } from 'pages/InvitationPage/InvitationPage';
import { RequireAuth } from 'components/RequireAuth/RequireAuth';

export const Root = () => {
    //temporary setter since we don't have that BL yet
    localStorage.setItem('currency', '$');

    return (
        <RequireAuth>
            <div className={styles.wrapper}>
                <Header />
                <main className={styles.content}>
                    <LeftNavigationMenu />
                    <Routes>
                        <Route path="/" element={<Dashboard testId="dashboardPage" />} />
                        <Route path="/auth" element={<Auth />} />
                        <Route path="/earnings" element={<Earnings />} />
                        <Route path="/data-sources" element={<DataSources />} />
                        <Route path="/data-sources/:id" element={<DataSourceSelected />} />
                        <Route path="/invitations/:id/:status" element={<InvitationPage />} />
                        <Route path="/mfa/:sourceAccId/:mfaCodeId" element={<MfaPage />} />
                        <Route path="/teams" element={<Teams testId="teams" />} />
                        <Route path="/profile" element={<Profile testId="profilePage" />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </RequireAuth>
    );
};
