import React, { FC } from 'react';

import cn from 'classnames';
import styles from './StatusCellRenderer.module.scss';
import { CellRendererProps } from 'components/Table/types';
import { ownerValue, statusColors } from 'components/TeamMembersList/constants';

export const StatusCellRenderer: FC<CellRendererProps> = ({ value, row }) => {
    return (
        <span>
            {value && typeof value === 'string' && row.role !== ownerValue && (
                <span className={cn(styles.dot, styles[statusColors[value]])}></span>
            )}
            {row.role !== ownerValue && value}
        </span>
    );
};
