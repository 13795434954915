import { RevenueTableItemType, RevenueTableItemsType } from 'shared/types';
import { specialEntries } from '../../constants';
import { sortByBNStrings } from '../../utils/sortByBNStrings';
import { SortType } from '../../../gql/__generated__/types';

const filter = (item: RevenueTableItemType) => item.id !== specialEntries.adjustment.id;

export const getNotSelectedItems = (ti: RevenueTableItemsType, si: RevenueTableItemsType) => {
    return ti.filter((item) => {
        return !si.includes(item);
    });
};

export const findInItemsById = (items: RevenueTableItemsType, id: string) => {
    return items.find((item) => item.id === id);
};

export const getInitialSelection = (items: RevenueTableItemsType, selectedAmount: number, sort: SortType) => {
    const filteredItems = items.filter(filter);
    const sortedItems = sortByBNStrings(filteredItems, 'amount', sort);
    const initialSelectedItems = sortedItems.slice(0, selectedAmount);
    const initialNotSelectedItems = getNotSelectedItems(items, initialSelectedItems);

    return {
        initialSelectedItems,
        initialNotSelectedItems,
    };
};

export const objToStr = (obj: any) => {
    let result = ``;
    if (obj && obj.hasOwnProperty('id')) {
        for (const key in obj) {
            result = result.concat(`${key}-${obj[key]}`);
        }
    }
    return result.trim();
};
