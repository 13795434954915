import { useCallback, useEffect, useMemo, useState } from 'react';
import { RevenueTableItemsType } from 'shared/types';

export const useShowExpanded = (tableItems: RevenueTableItemsType) => {
    const initialExpandedItemsList = useMemo(
        () => tableItems.map((tableItem) => ({ ...tableItem, expanded: false })),
        [tableItems],
    );

    const [rowsExpanded, setRowsExpanded] = useState(initialExpandedItemsList);

    useEffect(() => {
        setRowsExpanded(initialExpandedItemsList);
    }, [initialExpandedItemsList]);

    const changeExpandedRowsList = useCallback(
        (id: string) => {
            setRowsExpanded(
                [...rowsExpanded].map((tableItem) => {
                    if (tableItem.id === id) {
                        return {
                            ...tableItem,
                            expanded: !tableItem.expanded,
                        };
                    }
                    return tableItem;
                }),
            );
        },
        [rowsExpanded],
    );
    return { rowsExpanded, changeExpandedRowsList };
};
