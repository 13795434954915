import { ChartData } from 'components/Chart/interfaces';

export const getMinMaxDate = (filteredChartItems: ChartData[]) => {
    const now = new Date();
    const lineRanges = filteredChartItems.reduce((prev, current) => {
        if (current.data.length === 1) {
            return [...prev, current.data[0].x];
        } else if (current.data.length > 1) {
            return [...prev, current.data[0].x, current.data[current.data.length - 1].x];
        }
        return prev;
    }, [] as number[]);

    const dateFrom = lineRanges.length ? Math.min(...lineRanges) : now.getTime();
    const dateTo = now.getTime();

    return { dateFrom, dateTo };
};
