import React, { FC } from 'react';

import { ActionButton } from 'components/ActionButton/ActionButton';

import { TableViewMoreButtonProps } from './types';

import styles from './TableViewMoreButton.module.scss';

export const TableViewMoreButton: FC<TableViewMoreButtonProps> = ({ config, onClick }) => {
    return config ? (
        <ActionButton
            navigateTo={config.navigateTo}
            label={config.title}
            iconType="arrowdown"
            onClick={onClick}
            actionClassName="primary"
            className={styles['more-btn']}
            actionLabelClassName={styles['action-label']}
        />
    ) : null;
};
