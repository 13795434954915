import React, { FC, memo, useContext, useMemo } from 'react';
import cn from 'classnames';

import { CustomLegend } from './components/CustomLegend/CustomLegend';
import { BiggestEarningSource } from './components/BiggestEarningSource/BiggestEarningSource';
import { Card } from 'components/Card/Card';
import { Heading } from 'components/Heading/Heading';
import { PeriodSubtitle } from 'components/PeriodSubtitle/PeriodSubtitle';

import { EarningBreakdownProps } from './types';
import { generateTotal, generateCurrentNumber } from './utils';
import { generateStaticMarkup } from 'shared/utils/generateStaticMarkup';

import { colors, EarningsBdownHeading, pieChartParams, pieChartParamsDisabled } from './constants';

import styles from './EarningsBreakdown.module.scss';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { LoaderComponent } from 'components/Loader/Loader';
import { useGetSourceBreakdownsQuery } from 'gql/queries/__generated__/getSourceBreakdowns.generated';
import { RangeDate } from 'gql/__generated__/types';
import { maxBy } from 'lodash';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { TeamsFilterContext } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';
import { getSelectedPeriodDates } from 'shared/providers/DateFilterProvider/utils/getSelectedPeriodDates';
import { useTeamSelection } from 'shared/hooks/useTeamSelection/useTeamSelection';
import { TeamValues } from 'shared/hooks/useTeamSelection/constants';

const Breakdowns: FC<EarningBreakdownProps> = ({ className, testId }) => {
    const { selectedPeriod } = useContext(DateFilterContext);
    const { selectedTeam: prevSelectedTeam } = useContext(TeamsFilterContext);
    const { selectedTeam, getTeamIdByName } = useTeamSelection(prevSelectedTeam);

    const date: RangeDate = useMemo(() => {
        const periodDates = getSelectedPeriodDates(selectedPeriod);
        const dateFrom = new Date(periodDates?.dateFrom ?? '');
        const dateTo = new Date(periodDates?.dateTo ?? '');

        // set dates to start/end day for better caching
        dateFrom.setUTCHours(0, 0, 0, 0);
        dateTo.setUTCHours(23, 59, 59, 999);
        return {
            start: dateFrom.toISOString(),
            end: dateTo.toISOString(),
        };
    }, [selectedPeriod]);

    const { data, loading } = useGetSourceBreakdownsQuery({
        variables: {
            filter: {
                date,
                teamId: getTeamIdByName(selectedTeam),
                showAllTeams: selectedTeam === TeamValues.allTeams,
            },
        },
    });

    const breakdowns = data?.getSourceBreakdowns ?? [];

    const isEmpty = !breakdowns.length;

    let staticMarkup = '';

    const total = generateTotal(breakdowns);

    const breakdownWithPercentage = breakdowns.map((i) => {
        const currentNumber = generateCurrentNumber(i, total);
        return {
            ...i,
            y: currentNumber,
            color: colors[i?.category ?? ''],
        };
    });

    const biggestEarningsSource = maxBy(breakdownWithPercentage, 'totalAmount');

    if (biggestEarningsSource) {
        staticMarkup = generateStaticMarkup(
            <BiggestEarningSource
                testId={`${testId}.biggestEarningSource`}
                name={biggestEarningsSource.category!}
                percent={biggestEarningsSource.y}
            />,
        );
    }

    const chartOptions = isEmpty ? pieChartParamsDisabled : pieChartParams;

    return (
        <Card className={cn(className, styles.container, styles.loading)}>
            {loading ? (
                <LoaderComponent />
            ) : (
                <>
                    <Heading className={styles.heading}>{EarningsBdownHeading}</Heading>
                    <PeriodSubtitle className={isEmpty ? styles.disabled : ''} selectedPeriod={selectedPeriod} />
                    <HighchartsReact
                        highcharts={Highcharts}
                        immutable
                        options={{
                            ...chartOptions,
                            title: {
                                ...pieChartParams.title,
                                text: staticMarkup,
                            },
                            series: [{ data: breakdownWithPercentage }],
                        }}
                    />
                    <CustomLegend
                        testId={`${testId}.customLegend`}
                        earnings={breakdownWithPercentage}
                        isEmpty={isEmpty}
                    />
                </>
            )}
        </Card>
    );
};

export const EarningsBreakdown = memo(Breakdowns);
