import { CategoryValues } from 'shared/constants';
import { EarningsWithAccounts } from 'shared/types';
import { sortByBNStrings } from 'shared/utils/sortByBNStrings';
import { EnhancedSourceFilter } from '../interfaces';
import { filterSourceEarningsByCategory } from './filterSourceEarningsByCategory';
import { filterSourceEarningsByName } from './filterSourceEarningsByName';

export const filterSourceEarnings = (
    sourceEarnings: EarningsWithAccounts[],
    filters: EnhancedSourceFilter,
): EarningsWithAccounts[] => {
    const { sort, limit, category = CategoryValues.All, name = '' } = filters;

    const notEmptySourceEarnings = sourceEarnings.filter((se) => {
        if ((se.accounts.length !== 0 && se.amount.length !== 0) || se.totalAmount !== 0) return se;
    });

    const sortSourceEarningsByTotalAmount = sortByBNStrings(notEmptySourceEarnings, sort.field, sort.type);

    const filteredSourceEarningsByCategory = filterSourceEarningsByCategory(sortSourceEarningsByTotalAmount, category);

    const filteredSourceEarningsByName = filterSourceEarningsByName(filteredSourceEarningsByCategory, name);

    return limit ? filteredSourceEarningsByName.slice(0, limit) : filteredSourceEarningsByName;
};
