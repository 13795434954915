import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppApolloProvider } from 'gql/apolloClient/ApolloProvider';
import { UserInfoProvider } from 'shared/providers/UserInfoProvider/UserInfoProvider';
import { DateFilterProvider } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { AuthProvider } from 'shared/providers/AuthProvider/AuthProvider';
import { TeamsFilterProvider } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';
import { TeamsProvider } from 'pages/Teams/providers/TeamsProvider';

import { Root } from 'components/Root/Root';
import { Toaster } from 'components/Toaster/Toaster';

import './App.scss';

export const App: FC = () => (
    <AppApolloProvider>
        <BrowserRouter>
            <AuthProvider>
                <UserInfoProvider>
                    <DateFilterProvider>
                        <TeamsFilterProvider>
                            <TeamsProvider>
                                <Root />
                                <Toaster />
                            </TeamsProvider>
                        </TeamsFilterProvider>
                    </DateFilterProvider>
                </UserInfoProvider>
            </AuthProvider>
        </BrowserRouter>
    </AppApolloProvider>
);
