export const createBtnText = 'Create New Team';

export const fields = {
    title: 'name',
    dataSources: 'dataSources',
    members: 'members',
};

export const headers = {
    title: 'Title',
    dataSources: 'Data Sources',
    members: 'Members',
};
