import React, { FC } from 'react';

import { Button } from 'components/Button/Button';
import { CancelContinueButtonsBlockProps } from './types';

import styles from './CancelContinueButtonsBlock.module.scss';
import { cancelText, styleTypes, typeButton } from 'shared/constants';

export const CancelContinueButtonsBlock: FC<CancelContinueButtonsBlockProps> = ({
    continueButtonLabel,
    onCancel,
    onSave,
    isDisabled,
}) => (
    <div className={styles['btn-block']}>
        <Button isLink={false} style={styleTypes.secondary} type={typeButton} className={styles.btn} onClick={onCancel}>
            {cancelText}
        </Button>
        <Button
            isLink={false}
            style={styleTypes.primary}
            type={typeButton}
            className={styles.btn}
            onClick={onSave}
            isDisabled={isDisabled}
        >
            {continueButtonLabel}
        </Button>
    </div>
);
