import { Point } from 'highcharts';

export const getRemainder = (total: number, selectedPoints: Point[]) => {
    const totalSelected = selectedPoints
        .map((p) => p.y ?? 0)
        .reduce((ts, y) => {
            return ts + y;
        });

    return Number(Number(total - totalSelected).toFixed(2));
};
