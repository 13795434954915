import React, { FC } from 'react';

import cn from 'classnames';

import { TabItemProps } from './types';

import styles from './TabItem.module.scss';

export const TabItem: FC<TabItemProps> = ({ className, clickable = true, children, onClick, active, isDisabled }) => (
    <div
        onClick={onClick}
        className={cn(styles['tab-item'], className, {
            [styles.active]: active,
            [styles.clickable]: clickable,
            [styles.disabled]: isDisabled,
        })}
    >
        {children}
    </div>
);
