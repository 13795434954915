import { CategoryValues } from 'shared/constants';
import { CategoryValuesType, EarningsWithAccounts } from 'shared/types';

export const filterSourceEarningsByCategory = (
    sourceEarnings: EarningsWithAccounts[],
    category: CategoryValuesType,
): EarningsWithAccounts[] => {
    if (category === CategoryValues.All) {
        return sourceEarnings;
    }

    return sourceEarnings.filter((source) => source.category === category);
};
