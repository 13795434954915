import React, { FC } from 'react';
import cn from 'classnames';

import { PageHeadingProps } from './types';

import styles from './PageHeading.module.scss';

export const PageHeading: FC<PageHeadingProps> = ({ className, children }) => (
    <h1 className={cn(styles.heading, className)}>{children}</h1>
);
