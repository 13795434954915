export const changePassText = 'Change Password';

export const notificationText =
    'Password should be at least 8 characters. Use a mix of lowercase and uppercase letters, numbers, and symbols.';

export const saveBtnLabel = 'Save';

export const confirmBtnLabel = 'Confirm Password';

export const newPassLabel = 'New Password';

export const currentPassLabel = 'Current Password';
