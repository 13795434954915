import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeTeamMemberRoleMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID'];
  teamMemberId: Types.Scalars['ID'];
  role: Types.InputTeamMemberRoles;
}>;


export type ChangeTeamMemberRoleMutation = { __typename?: 'Mutation', changeTeamMemberRole?: { __typename?: 'Team', id?: string | null, name?: string | null } | null };


export const ChangeTeamMemberRoleDocument = gql`
    mutation changeTeamMemberRole($teamId: ID!, $teamMemberId: ID!, $role: InputTeamMemberRoles!) {
  changeTeamMemberRole(teamId: $teamId, teamMemberId: $teamMemberId, role: $role) {
    id
    name
  }
}
    `;
export type ChangeTeamMemberRoleMutationFn = Apollo.MutationFunction<ChangeTeamMemberRoleMutation, ChangeTeamMemberRoleMutationVariables>;

/**
 * __useChangeTeamMemberRoleMutation__
 *
 * To run a mutation, you first call `useChangeTeamMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTeamMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTeamMemberRoleMutation, { data, loading, error }] = useChangeTeamMemberRoleMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      teamMemberId: // value for 'teamMemberId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useChangeTeamMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTeamMemberRoleMutation, ChangeTeamMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTeamMemberRoleMutation, ChangeTeamMemberRoleMutationVariables>(ChangeTeamMemberRoleDocument, options);
      }
export type ChangeTeamMemberRoleMutationHookResult = ReturnType<typeof useChangeTeamMemberRoleMutation>;
export type ChangeTeamMemberRoleMutationResult = Apollo.MutationResult<ChangeTeamMemberRoleMutation>;
export type ChangeTeamMemberRoleMutationOptions = Apollo.BaseMutationOptions<ChangeTeamMemberRoleMutation, ChangeTeamMemberRoleMutationVariables>;