//import { fromPromise } from '@apollo/client';
import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as AuthService from 'shared/services/AuthService';

export const errorLink = onError((err) => {
    const { graphQLErrors, networkError, operation, forward } = err;

    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
    }

    if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
        return fromPromise(
            AuthService.refreshToken().catch(() => {
                AuthService.removeToken();
                AuthService.getLoginPage();
            }),
        )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                    headers: {
                        ...oldHeaders,
                        authorization: `Bearer ${accessToken}`,
                    },
                });
                return forward(operation);
            });
    }
});
