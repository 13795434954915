import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMonthlyTotalsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MonthlyTotalsFilter>;
}>;


export type GetMonthlyTotalsQuery = { __typename?: 'Query', getMonthlyTotals?: Array<{ __typename?: 'MonthlyTotal', month?: number | null, total?: number | null, year?: number | null, unix?: string | null } | null> | null };


export const GetMonthlyTotalsDocument = gql`
    query getMonthlyTotals($filter: MonthlyTotalsFilter) {
  getMonthlyTotals(filter: $filter) {
    month
    total
    year
    unix
  }
}
    `;

/**
 * __useGetMonthlyTotalsQuery__
 *
 * To run a query within a React component, call `useGetMonthlyTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyTotalsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMonthlyTotalsQuery(baseOptions?: Apollo.QueryHookOptions<GetMonthlyTotalsQuery, GetMonthlyTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMonthlyTotalsQuery, GetMonthlyTotalsQueryVariables>(GetMonthlyTotalsDocument, options);
      }
export function useGetMonthlyTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyTotalsQuery, GetMonthlyTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMonthlyTotalsQuery, GetMonthlyTotalsQueryVariables>(GetMonthlyTotalsDocument, options);
        }
export type GetMonthlyTotalsQueryHookResult = ReturnType<typeof useGetMonthlyTotalsQuery>;
export type GetMonthlyTotalsLazyQueryHookResult = ReturnType<typeof useGetMonthlyTotalsLazyQuery>;
export type GetMonthlyTotalsQueryResult = Apollo.QueryResult<GetMonthlyTotalsQuery, GetMonthlyTotalsQueryVariables>;