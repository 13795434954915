import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetSourceCredentialMutationVariables = Types.Exact<{
  credentials: Types.SourceCredential;
  internalTitle: Types.Scalars['String'];
  sourceId: Types.Scalars['ID'];
}>;


export type SetSourceCredentialMutation = { __typename?: 'Mutation', setSourceCredential: { __typename?: 'LinkedSource', category?: Types.Category | null, id?: string | null, name?: string | null, type?: string | null } };


export const SetSourceCredentialDocument = gql`
    mutation setSourceCredential($credentials: SourceCredential!, $internalTitle: String!, $sourceId: ID!) {
  setSourceCredential(
    credentials: $credentials
    internalTitle: $internalTitle
    sourceId: $sourceId
  ) {
    category
    id
    name
    type
  }
}
    `;
export type SetSourceCredentialMutationFn = Apollo.MutationFunction<SetSourceCredentialMutation, SetSourceCredentialMutationVariables>;

/**
 * __useSetSourceCredentialMutation__
 *
 * To run a mutation, you first call `useSetSourceCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSourceCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSourceCredentialMutation, { data, loading, error }] = useSetSourceCredentialMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *      internalTitle: // value for 'internalTitle'
 *      sourceId: // value for 'sourceId'
 *   },
 * });
 */
export function useSetSourceCredentialMutation(baseOptions?: Apollo.MutationHookOptions<SetSourceCredentialMutation, SetSourceCredentialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSourceCredentialMutation, SetSourceCredentialMutationVariables>(SetSourceCredentialDocument, options);
      }
export type SetSourceCredentialMutationHookResult = ReturnType<typeof useSetSourceCredentialMutation>;
export type SetSourceCredentialMutationResult = Apollo.MutationResult<SetSourceCredentialMutation>;
export type SetSourceCredentialMutationOptions = Apollo.BaseMutationOptions<SetSourceCredentialMutation, SetSourceCredentialMutationVariables>;