import React, { FC } from 'react';
import { Icon } from 'components/Icon/Icon';
import { DataSourceTeamMemberProps } from './types';
import styles from './DataSourceTeamMember.module.scss';
import classNames from 'classnames';

export const DataSourceTeamMember: FC<DataSourceTeamMemberProps> = (props) => {
    const { member, rowId, onCheck, isViewer, isEditor } = props;
    const disabledForEditor = member.external && isEditor;
    const disabled = disabledForEditor || isViewer;

    const handleCheckMember = () => onCheck!(rowId, member.id!);
    return (
        <div className={classNames(styles.sourceMemberWrapper, { [styles.disabled]: disabled })}>
            <div onClick={handleCheckMember} className={styles.sourceMemberEmailWrapper}>
                <div className={classNames(styles.sourceMemberCheckedWrapper, { [styles.disabled]: disabled })}>
                    <Icon
                        type={member.checked ? 'checkeditem' : 'uncheckeditem'}
                        className={styles.sourceMemberCheckedWrapper}
                        inline
                    />
                </div>
                <span className={styles.sourceMemberName}>{member.name}</span>
            </div>
        </div>
    );
};
