/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useMemo } from 'react';
import { useGetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';
import { DataSourceDropdownDataType, MemberRoles, TeamSourceTableRowType, TeamTableItemType } from 'shared/types';
import { groupBy } from 'lodash';
import { UserInfoContext } from 'shared/providers/UserInfoProvider/UserInfoProvider';

export const usePrepareTeamListData = (teamList: any) => {
    const { data, loading: dataSourcesLoading } = useGetLinkedSourcesQuery();
    const userInfo = useContext(UserInfoContext).user;
    const dataSourcesDropdownData: DataSourceDropdownDataType = useMemo(() => {
        return (
            data
                ?.linkedSources!.reduce((acc: any, curr) => {
                    const dataSourceTableMembers =
                        curr?.linkedSources!.map((linkedSource) => ({
                            sourceId: linkedSource?.sourceId!,
                            id: linkedSource?.id!,
                            name: linkedSource?.name!,
                            type: linkedSource?.type!,
                        })) ?? [];
                    if (curr?.linkedSources?.length) {
                        return [
                            ...acc,
                            {
                                id: curr?.id!,
                                members: dataSourceTableMembers,
                                name: curr?.name!,
                                type: curr?.type!,
                            },
                        ];
                    }
                    return acc;
                }, [])
                .sort((a: any, b: any) => {
                    const textA = a?.name.toUpperCase();
                    const textB = b?.name.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                }) ?? []
        );
    }, [data?.linkedSources]);

    const teamOwnerInfo = {
        email: userInfo?.email,
        name: userInfo?.firstName,
        surname: userInfo?.lastName,
    };

    const parseDataForTable = (sourceRows: any) => {
        const groupSourcesByType: { name: string; rowId: string; members: TeamSourceTableRowType[] }[] = Object.entries(
            groupBy(sourceRows, 'type'),
        ).map(([_, linkedAccounts]) => {
            return { name: linkedAccounts[0].visibleName, members: linkedAccounts, rowId: linkedAccounts[0].sourceId };
        });

        const sourcesAddedWithAllMembers = groupSourcesByType.map((source) => {
            const listOfAllLinkedAccounts = dataSourcesDropdownData.find((item) => item.id === source.rowId);
            if (listOfAllLinkedAccounts && source.rowId === listOfAllLinkedAccounts.id) {
                const checkAddedDataSources = listOfAllLinkedAccounts?.members?.map((member) => {
                    const linkedSource = source.members?.find((elem) => elem.userSourceId === member?.id);
                    if (linkedSource) {
                        return { ...member, checked: true, wasPreviouslyCreated: true };
                    }
                    return member;
                });

                const externalSourceAccounts = source.members.reduce((acc: any, curr) => {
                    if (!listOfAllLinkedAccounts?.members.some((item) => item.id === curr.userSourceId)) {
                        return [
                            ...acc,
                            {
                                sourceId: curr.sourceId,
                                name: curr.name,
                                id: curr.userSourceId,
                                type: curr.type,
                                checked: true,
                                wasPreviouslyCreated: true,
                                external: true,
                            },
                        ];
                    }
                    return acc;
                }, []);

                return {
                    ...source,
                    name: listOfAllLinkedAccounts.name,
                    members: [...checkAddedDataSources, ...externalSourceAccounts],
                };
            }

            return {
                ...source,
                members: source.members.map((member) => ({
                    sourceId: member.sourceId,
                    name: member.name,
                    id: member.userSourceId,
                    type: member.type,
                    checked: true,
                    wasPreviouslyCreated: true,
                    external: true,
                })),
            };
        });

        return sourcesAddedWithAllMembers;
    };

    const teamsService = teamList
        .map((team: any) => ({
            ...team,
            sources: parseDataForTable(team.sources),
            members: team.members
                .map((member: TeamTableItemType) => ({
                    ...member,
                    name: member.name || '',
                    surname: member.surname || '',
                }))
                .sort((a: TeamTableItemType, b: TeamTableItemType) => {
                    if (a.role !== MemberRoles.owner && b.role !== MemberRoles.owner) {
                        return a.name! < b.name! ? -1 : 1;
                    }
                    if (a.role === MemberRoles.owner && b.role !== MemberRoles.owner) {
                        return -1;
                    }
                    if (a.role !== MemberRoles.owner && b.role === MemberRoles.owner) {
                        return 1;
                    }
                    return 0;
                }),
            isViewer:
                team.members.find((member: any) => member.invitationEmail === userInfo?.email)?.role ===
                MemberRoles.viewer,
            isEditor:
                team.members.find((member: any) => member.invitationEmail === userInfo?.email)?.role ===
                MemberRoles.editor,
        }))
        .sort((a: any, b: any) => {
            const textA = a?.name.toUpperCase();
            const textB = b?.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

    return { teamsService, dataSourcesDropdownData, teamOwnerInfo };
};
