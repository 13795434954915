import { SortType } from 'gql/__generated__/types';
import { Actions, TableReducerInitState } from './types';

export const initialState = (state: TableReducerInitState) => {
    return {
        sort: {
            field: null,
            type: state.sortType,
        },
        search: null,
        itemsLoadedCount: state.itemsPerLoad,
    };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state: any = initialState, action: any): any {
    switch (action.type) {
        case Actions.SetSort:
            return {
                ...state,
                sort: { field: action.payload, type: state.sort.type === SortType.Desc ? SortType.Asc : SortType.Desc },
            };
        case Actions.SetSortToDefaultValue:
            return {
                ...state,
                sort: { field: action.payload, type: SortType.Desc },
            };
        case Actions.SetDefaultSort:
            return {
                ...state,
                sort: { field: action.payload, type: state.sort.type },
            };
        case Actions.SetItemsLoadedCount:
            return {
                ...state,
                itemsLoadedCount: action.payload,
            };
        case Actions.SetSearch:
            return {
                ...state,
                search: action.payload,
            };
        default:
            throw new Error();
    }
}
