import React, { createContext, FC, useState } from 'react';

export const TeamsContext = createContext<any>({});

export const TeamsProvider: FC = ({ children }) => {
    const [isSomeItemCollapsed, setSomeItemIsCollapsed] = useState(false);
    function handleSetSomeItemIsCollapsed(arg: any) {
        setSomeItemIsCollapsed(arg);
    }

    const [inviter, setInviter] = useState({});
    const handleSetInviter = (info: {}) => {
        setInviter(info);
    };

    return (
        <TeamsContext.Provider
            value={{
                collapse: handleSetSomeItemIsCollapsed,
                isSomeItemCollapsed: isSomeItemCollapsed,
                inviter,
                setInviter: handleSetInviter,
            }}
        >
            {children}
        </TeamsContext.Provider>
    );
};
