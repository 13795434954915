export const validateInput = (name: string, value: string) => {
    if (name === 'email' && !value) {
        return 'Login is required.';
    }
    if (name === 'title' && !value) {
        return 'Title is required.';
    }
    if (name === 'title' && !value.match(/^[0-9a-zA-Z ]+$/) && value.length) {
        return 'Sorry, only letters (A-z), numbers (0-9) are allowed';
    }
    if (name === 'email' && !value.match(/^[0-9a-zA-Z,.@-_ ]+$/) && value.length) {
        return 'Sorry, only letters (A-z), numbers(0-9) and special characters ("@","-",",",".") are allowed';
    }
    if (name === 'title' && value.length > 256) {
        return 'Field length exceeded 256 symbols';
    }
    if (name === 'password' && !value) {
        return 'Password is required.';
    }
    return '';
};
