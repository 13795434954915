import { AccountForm } from 'components/AccountForm/AccountForm';
import { AccountData } from 'pages/DataSourceSelected/types';
import React, { FC } from 'react';
import { EditAccountExpandedRowProps } from './types';

export const EditAccountExpandedRow: FC<EditAccountExpandedRowProps> = (props) => {
    const { row, onCollapseRow, onSaveChanges } = props;
    const saveAccountData = (accountData: AccountData) => {
        onSaveChanges(row.id, accountData);
    };
    return <AccountForm accountToEdit={row} onCancel={() => onCollapseRow(row.id)} onSave={saveAccountData} />;
};
