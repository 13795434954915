import jwt from 'jwt-decode';
import { tokenUpdatedEvent } from 'shared/constants';

const uri = process.env.REACT_APP_API_GATEWAY!;

const redirectUri = `${window.location.origin}/auth`;

const root = '/';

const accessToken = 'accessToken';
const idToken = 'idToken';

export type AccessToken = {
    iss: string;
    azp: string;
};

export function getToken(): string | null {
    return localStorage.getItem(accessToken);
}

export function getIdToken(): string | null {
    return localStorage.getItem(idToken);
}

export function getPasswordResetUrl(): string | null {
    const token = getToken();
    if (!token) return null;

    const issuer = jwt<AccessToken>(token);
    return (
        `${issuer.iss}/protocol/openid-connect/auth?client_id=${issuer.azp}` +
        `&redirect_uri=${window.location.href}` +
        `&response_mode=fragment` +
        `&response_type=code` +
        `&scope=openid` +
        `&prompt=login` +
        `&kc_action=UPDATE_PASSWORD`
    );
}

export function setToken(token: string, id: string): void {
    localStorage.setItem(accessToken, token);
    localStorage.setItem(idToken, id);
    window.dispatchEvent(tokenUpdatedEvent);
}

export function removeToken(): void {
    localStorage.removeItem(accessToken);
    localStorage.removeItem(idToken);
}

export function saveLocation(location: string): void {
    localStorage.setItem('location', location);
}

export function getLocation(): string {
    return localStorage.getItem('location') ?? root;
}

export async function getLoginPage() {
    try {
        const response = await fetch(`${uri}/api/get-login-page?redirect_uri=${redirectUri}`);

        const data = await response.json();
        saveLocation(`${window.location.pathname}${window.location.search}`);
        window.open(data?.loginPage, '_self');
    } catch (error) {
        console.error(error);
        // todo handle error
    }
}

export async function getAccessToken(code: string) {
    const response = await fetch(`${uri}/api/get-access-key?redirect_uri=${redirectUri}&code=${code}`, {
        method: 'POST',
        body: JSON.stringify({}),
    });
    const data = await response.json();

    setToken(data.access_token, data.id_token);
}

export async function refreshToken() {
    const token = getToken();

    const response = await fetch(`${uri}/api/refresh-access-key?redirect_uri=${redirectUri}&access_token=${token}`, {
        method: 'POST',
        body: JSON.stringify({}),
    });

    const data = await response.json();

    setToken(data.access_token, data.id_token);

    return data.access_token;
}

export async function logout() {
    try {
        removeToken();

        const response = await fetch(`${uri}/api/logout?redirect_uri=${redirectUri}`);

        const data = await response.json();

        saveLocation(`${window.location.pathname}${window.location.search}`);
        window.open(data?.logoutPage, '_self');
    } catch (error) {
        console.error(error);
        // todo handle error
    }
}
