import { addMonths } from 'date-fns';
import { Projection } from 'gql/__generated__/types';
import { chain } from 'lodash';
import { Earnings } from 'shared/types';
import { PROJECTION_MONTH_NUMBER } from '../constants';
import { ChartItem } from '../interfaces';

export const prepareChartData = (chartData: Earnings[], shouldShowProjections: boolean) =>
    chain(chartData)
        .flatMapDeep((chartItem) => {
            const { name, id, amount, projection } = chartItem;

            const earnings = amount.map((amountItem) => {
                const { date, amount: amountValue } = amountItem;
                return {
                    x: new Date(+date ?? '').getTime(),
                    y: amountValue,
                    id: id ?? '',
                    name,
                    color: undefined,
                };
            });

            if (projection && shouldShowProjections) {
                const proj = Array.from(Array(PROJECTION_MONTH_NUMBER).keys()).reduce((acc, item) => {
                    const key = `month${item + 1}` as keyof Projection;
                    if (projection[key]) {
                        acc.push({
                            x: addMonths(new Date(), item).getTime(),
                            y: (projection[key] as number) ?? 0,
                            id: id ?? '',
                            name,
                            color: undefined,
                        });
                    }
                    return acc;
                }, [] as ChartItem[]);
                return earnings.concat(proj);
            } else {
                return earnings;
            }
        })
        .groupBy('id')
        .value();
