import React, { FC } from 'react';
import { Icon } from 'components/Icon/Icon';
import { MembersCellRendererAvatar } from './components/MembersCellRendererAvatar/MembersCellRendererAvatar';
import { CellRendererProps } from 'components/Table/types';
import { TeamAccountsMemberType } from 'shared/types';
import styles from './MembersCellRenderer.module.scss';

export const MembersCellRenderer: FC<CellRendererProps> = (props) => {
    const { row } = props;

    return (
        <div className={styles.membersContainer}>
            <div className={styles.membersWrapper}>
                {row.members.map((member: TeamAccountsMemberType, index: number) => (
                    <MembersCellRendererAvatar
                        key={`${member.id}-${row.rowId}`}
                        member={member}
                        index={index}
                        membersQty={row.members.length}
                    />
                ))}
                <div className={styles.membersIconWrapper}>
                    <Icon type="arrowright" inline />
                </div>
            </div>
        </div>
    );
};
