import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveSourceCredentialMutationVariables = Types.Exact<{
  userSourceId: Types.Scalars['ID'];
}>;


export type RemoveSourceCredentialMutation = { __typename?: 'Mutation', removeSourceCredential?: boolean | null };


export const RemoveSourceCredentialDocument = gql`
    mutation removeSourceCredential($userSourceId: ID!) {
  removeSourceCredential(userSourceId: $userSourceId)
}
    `;
export type RemoveSourceCredentialMutationFn = Apollo.MutationFunction<RemoveSourceCredentialMutation, RemoveSourceCredentialMutationVariables>;

/**
 * __useRemoveSourceCredentialMutation__
 *
 * To run a mutation, you first call `useRemoveSourceCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSourceCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSourceCredentialMutation, { data, loading, error }] = useRemoveSourceCredentialMutation({
 *   variables: {
 *      userSourceId: // value for 'userSourceId'
 *   },
 * });
 */
export function useRemoveSourceCredentialMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSourceCredentialMutation, RemoveSourceCredentialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSourceCredentialMutation, RemoveSourceCredentialMutationVariables>(RemoveSourceCredentialDocument, options);
      }
export type RemoveSourceCredentialMutationHookResult = ReturnType<typeof useRemoveSourceCredentialMutation>;
export type RemoveSourceCredentialMutationResult = Apollo.MutationResult<RemoveSourceCredentialMutation>;
export type RemoveSourceCredentialMutationOptions = Apollo.BaseMutationOptions<RemoveSourceCredentialMutation, RemoveSourceCredentialMutationVariables>;