import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLinkedSourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLinkedSourcesQuery = { __typename?: 'Query', linkedSources?: Array<{ __typename?: 'StaticSource', category?: Types.Category | null, id?: string | null, name?: string | null, type?: string | null, linkedSources?: Array<{ __typename?: 'LinkedSource', category?: Types.Category | null, id?: string | null, name?: string | null, sourceId?: string | null, type?: string | null, status?: Types.AccountStatuses | null, parseStatus?: Array<Types.UserSourceFileStatus | null> | null, lastParsing?: { __typename?: 'Date', value?: string | null, unix?: string | null } | null } | null> | null } | null> | null };


export const GetLinkedSourcesDocument = gql`
    query getLinkedSources {
  linkedSources {
    category
    id
    name
    type
    linkedSources {
      category
      id
      name
      sourceId
      type
      lastParsing {
        value
        unix
      }
      status
      parseStatus
    }
  }
}
    `;

/**
 * __useGetLinkedSourcesQuery__
 *
 * To run a query within a React component, call `useGetLinkedSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLinkedSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLinkedSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLinkedSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetLinkedSourcesQuery, GetLinkedSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinkedSourcesQuery, GetLinkedSourcesQueryVariables>(GetLinkedSourcesDocument, options);
      }
export function useGetLinkedSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkedSourcesQuery, GetLinkedSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinkedSourcesQuery, GetLinkedSourcesQueryVariables>(GetLinkedSourcesDocument, options);
        }
export type GetLinkedSourcesQueryHookResult = ReturnType<typeof useGetLinkedSourcesQuery>;
export type GetLinkedSourcesLazyQueryHookResult = ReturnType<typeof useGetLinkedSourcesLazyQuery>;
export type GetLinkedSourcesQueryResult = Apollo.QueryResult<GetLinkedSourcesQuery, GetLinkedSourcesQueryVariables>;