import { CategoryValues } from 'shared/constants';
import { CategoryValuesType } from 'shared/types';

export const presentCategory = (category: CategoryValuesType, isUpperCase?: boolean) => {
    if (category === CategoryValues.Royalties) return 'PROs';

    if (isUpperCase) {
        return category;
    }

    const lowercaseCategory = category.toLowerCase();

    return lowercaseCategory.charAt(0).toUpperCase() + lowercaseCategory.slice(1);
};
