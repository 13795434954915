import React, { FC } from 'react';

import { TableHeaderRow } from '../TableHeaderRow/TableHeaderRow';

import { TableHeaderProps } from './types';

export const TableHeader: FC<TableHeaderProps> = ({
    className,
    columnDefs,
    config,
    onSort,
    onDefaultSort,
    isEmpty,
}) => {
    return (
        <TableHeaderRow
            className={className}
            config={config}
            columnDefs={columnDefs}
            onSort={onSort}
            onDefaultSort={onDefaultSort}
            isEmpty={isEmpty}
        />
    );
};
