import React, { FC } from 'react';
import cn from 'classnames';

import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Form/components/Input/Input';
import { CancelContinueButtonsBlock } from 'components/Form/components/CancelContinueButtonsBlock/CancelContinueButtonsBlock';
import { Form } from '../Form/Form';
import { Notification } from 'components/Form/components/Input/components/Notification/Notification';

import { EditProfileInfoFormProps } from './types';
import { Icon } from 'components/Icon/Icon';

import { editProfileInfoText, firstNameLabel, lastNameLabel, saveLabel } from './constants';
import { styleTypes } from 'shared/constants';

import styles from './EditProfileInfoForm.module.scss';
import commomStyles from '../../Profile.module.scss';

export const EditProfileInfoForm: FC<EditProfileInfoFormProps> = ({
    firstName,
    lastName,
    onCancel,
    onSave,
    isSaving,
    onInputChange,
    onClearInput,
    formErrors,
    testId,
    className,
}) => (
    <Form data-testid={`${testId}.form`} className={className} onCancel={onCancel}>
        <Heading className={commomStyles['form-heading']}>{editProfileInfoText}</Heading>
        <div className={styles['user-block']}>
            <div className={styles['photo-wrapper']}>
                <div className={styles.photo}>
                    <Icon type={'user'} />
                </div>
                <div className={styles['delete-btn']}>
                    <Icon type="remove" className={styles['delete-icon']} />
                </div>
            </div>
            <Button isLink={false} style={styleTypes.secondary} type="button">
                Upload image
            </Button>
        </div>

        <div className={commomStyles['input-wrapper']}>
            <Input
                data-testid={`${testId}.firstName`}
                id="firstname"
                label={firstNameLabel}
                name="firstName"
                icon="clear"
                value={firstName}
                className={commomStyles['input-block']}
                onChange={onInputChange}
                onIconClick={() => onClearInput('firstName')}
            />
            {formErrors.firstName ? (
                <Notification className={cn(commomStyles.notification, commomStyles['error-notification'])}>
                    {formErrors.firstName}
                </Notification>
            ) : null}

            <Input
                data-testid={`${testId}.lastName`}
                id="lastname"
                label={lastNameLabel}
                name="lastName"
                icon="clear"
                value={lastName}
                className={commomStyles['input-block']}
                onChange={onInputChange}
                onIconClick={() => onClearInput('lastName')}
            />
        </div>

        <CancelContinueButtonsBlock
            data-testid={`${testId}.cancelContinueButtonsBlock`}
            continueButtonLabel={saveLabel}
            onCancel={onCancel}
            onSave={onSave}
            isDisabled={isSaving}
        />
    </Form>
);
