import { SVGElement } from 'highcharts';
import { throttle } from 'lodash';
import { MIN_HEADER_LABEL_PADDING, THROTTLE_TIME } from '../constants';
import { HeaderLabelParams } from '../interfaces';
import { moveHeaderLabel } from './moveHeaderLabel';

export const adjustActualLabelPosition = (label: SVGElement, params: HeaderLabelParams) => {
    const { from, to, plotLineXPosition, chartWidth, chartLeftOffset } = params;
    const labelWidth = label.element.getBoundingClientRect()?.width ?? 0;

    if (to > plotLineXPosition && from < plotLineXPosition) {
        const headerVisibleWidth = ((plotLineXPosition - from) / (to - from)) * chartWidth + chartLeftOffset;

        // Minus because header wider then chart
        const minLabelWidth = labelWidth + MIN_HEADER_LABEL_PADDING;

        if (headerVisibleWidth <= minLabelWidth) {
            label.hide();
        } else {
            const pos = headerVisibleWidth / 2 - labelWidth / 2;
            label.show();
            moveHeaderLabel(label, pos);
        }
    } else if (to < plotLineXPosition) {
        const pos = (chartWidth + chartLeftOffset) / 2 - labelWidth / 2;

        label.show();
        moveHeaderLabel(label, pos);
    } else {
        label.hide();
    }
};

export const throttledAdjustActualLabelPosition = throttle(adjustActualLabelPosition, THROTTLE_TIME);
