import React, { FC } from 'react';

import cn from 'classnames';

import { SearchInputProps } from './types';
import { Icon } from 'components/Icon/Icon';

import styles from './SearchInput.module.scss';
import { textType } from './constants';

export const SearchInput: FC<SearchInputProps> = (props) => {
    const { testId, className, value, id, name, placeholder, onChange } = props;
    return (
        <div data-testid={`${testId}.inputWrapper`} className={cn(styles['search-box'], className)}>
            <input
                data-testid={`${testId}.input`}
                value={value}
                id={id}
                type={textType}
                name={name}
                placeholder={placeholder}
                onChange={(event) => (onChange ? onChange(event) : void 0)}
                className={styles.search}
            />
            <Icon type="search" className={styles['search-icon']} />
        </div>
    );
};
