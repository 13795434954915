import React, { FC } from 'react';
import cn from 'classnames';

import { ButtonProps } from './types';

import styles from './Button.module.scss';

export const Button: FC<ButtonProps> = ({ name, isActive, onClick }) => {
    return (
        <li
            className={cn(styles['btn-item'], {
                [styles['btn-active']]: isActive,
            })}
            onClick={onClick}
        >
            {name}
        </li>
    );
};
