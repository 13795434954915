import React, { useContext, useMemo } from 'react';

import { PeriodSelector } from 'components/PeriodSelector/PeriodSelector';
import { ActionButton } from 'components//ActionButton/ActionButton';
import { CategorySelectionButtons } from './components/CategorySelectionButtons/CategorySelectionButtons';
import { SelectCellRenderer } from 'components/Table/components/CellRenderers/SelectCellRenderer/SelectCellRenderer';
import { ShareCellRenderer } from 'components/Table/components/CellRenderers/ShareCellRenderer/ShareCellRenderer';
import { SourceCellRenderer } from 'components/Table/components/CellRenderers/SourceCellRenderer/SourceCellRenderer';
import { TableRowRenderer } from 'components/Table/components/TableRowRenderer/TableRowRenderer';
import { Tab } from 'components/Tab/Tab';
import { TabItem } from 'components/Tab/TabItem/TabItem';
import { Table } from 'components/Table/Table';

import { Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs';

import { ColumnDef } from 'components/Table/types';
import { TableItemType } from 'shared/types';

import { GroupByOptions, ShowProjectedEarnings, viewMore as viewMoreText } from 'shared/constants';

import { useShowExpanded } from 'shared/hooks/useShowExpanded';
import { useColor } from 'shared/hooks/useColor/useColor';

import { useCategorySelection } from './hooks/useCategorySelection';
import { useTabSelection } from './hooks/useTabSelection';

import { isItemSpecial } from 'shared/utils/isItemSpecial';
import { getShareValueToShow } from 'shared/utils/getShareValueToShow';
import { getFormattedAndRoundedValue } from 'shared/utils/getFormattedAndRoundedValue';
import { getLastParsedDateFromQuery, getLastUpdatedDateText } from 'shared/utils/getDate';
import { Icon } from 'components/Icon/Icon';

import { usePreparedChartData } from 'shared/hooks/usePreparedChartData/usePreparedChartData';
import { useChartTypeSelection } from 'components/Chart/hooks/useChartTypeSelector';
import { ChartType } from 'components/Chart/constants';
import { Chart } from 'components/Chart/Chart';
import { ChartTypeSelector } from 'components/Chart/components/ChartTypeSelector/ChartTypeSelector';

import { EmptyStateComponent } from 'components/EmptyState/EmptyState';
import { Card } from 'components/Card/Card';
import { prepareForTable } from 'shared/hooks/earnings/utils/prepareForTable';
import { useGetEarnings } from 'shared/hooks/earnings/useGetEarnings/useGetEarnings';
import { Account, SortType } from 'gql/__generated__/types';
import { useTable } from 'components/Table/hooks/useTable';
import { ChildSourceCellRenderer } from 'components/Table/components/CellRenderers/ChildSourceCellRenderer/ChildSourceCellRenderer';
import { useSwitcher } from 'shared/hooks/useSwitcher/useSwitcher';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { TeamsSelector } from 'components/TeamsSelector/TeamsSelector';
import { useTeamSelection } from 'shared/hooks/useTeamSelection/useTeamSelection';
import { EarningsButtons } from 'components/EarningsButtons/EarningsButtons';
import { useExportModal } from 'components/EarningsExportModal/hooks/useExportModal';
import { EarningsExportModal } from 'components/EarningsExportModal/EarningsExportModal';
import { TotalEarningsBar } from 'components/TotalEarningsBar/TotalEarningsBar';
import { TotalEarningsHeading } from 'components/TotalEarningsHeading/TotalEarningsHeading';

import { useGetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';

import styles from './Earnings.module.scss';
import commonStyles from '../CommonStyles.module.scss';
import { useGetMonthlyTotals } from 'shared/hooks/earnings/useGetMonthlyTotals/useGetMonthlyTotals';
import { TeamsFilterContext } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';
import { useItemSelection } from 'shared/hooks/useItemSelection/useItemSelection';
import { TeamValues } from 'shared/hooks/useTeamSelection/constants';
import { TOP_SIX } from 'pages/Dashboard/constants';

export const Earnings = () => {
    const { chartTypeValue, updateChartTypeValue } = useChartTypeSelection(ChartType.StackedBarChart);
    const { selectedCategory, setSelectedCategory } = useCategorySelection();
    const { onSort, onDefaultSort, viewMore, searchInTable, state, onSortToDefaultValue } = useTable(10);

    const { earnings, isLoading: isEarningsLoading, pagination, totalEarnings, hasPagination } = useGetEarnings();
    const { monthlyTotals, isMonthlyTotalsLoading } = useGetMonthlyTotals();

    const isLoading = isEarningsLoading || isMonthlyTotalsLoading;

    const { data } = useGetLinkedSourcesQuery();

    const lastParsedDate = getLastParsedDateFromQuery(data);

    const { selectedTeam: prevSelectedTeam } = useContext(TeamsFilterContext);
    const { selectedTeam, teamListOptions, setTeamQuery, getTeamIdByName } = useTeamSelection(prevSelectedTeam);
    const teamId = getTeamIdByName(selectedTeam);

    const {
        unsetAllTabIds,
        setTabId,
        setTabInBreadcrumbs,
        setSelectedTabId,
        navigateAndSelectTab,
        displayedTabs,
        activeTab,
        tabsWithRelatedEntityId,
    } = useTabSelection(state, pagination, onSortToDefaultValue, teamId);

    const noData =
        !data?.linkedSources?.some((source) => source?.linkedSources?.length) &&
        !isLoading &&
        selectedTeam === TeamValues.myEarnings;
    const tableItems = useMemo(() => prepareForTable(earnings), [earnings]);

    const { addSelectedItem, removeSelectedItem, selectedItems, notSelectedItems } = useItemSelection(
        tableItems,
        'earnings',
        TOP_SIX,
    );
    const { rowsExpanded, changeExpandedRowsList } = useShowExpanded(tableItems);

    const selectedItemsWithColor = useColor(selectedItems);

    const { checked: shouldShowProjections, toggleSwitcher: toggleProjectionAppearance } = useSwitcher(false);

    const { chartData, notSelectedChartData, hasProjections } = usePreparedChartData(
        earnings,
        selectedItemsWithColor,
        shouldShowProjections,
        notSelectedItems,
    );

    const { isExportModalOpen, closeExportModal, toggleExportModal } = useExportModal(false);
    const checkForExpandableRows = tableItems.some((elem) => elem.accounts?.length);

    const columnDefs = [
        {
            field: 'select',
            cellRenderer: SelectCellRenderer,
        },
        {
            onClick: setSelectedTabId,
            clickable: (row: { [key: string]: any }) => {
                return (
                    Object.entries(tabsWithRelatedEntityId).filter(([, value]) => value).length < 2 &&
                    !isItemSpecial(row.id)
                );
            },
            field: 'name',
            headerName: GroupByOptions[activeTab],
            cellRenderer: SourceCellRenderer,
            childCellRenderer: ChildSourceCellRenderer,
            className: () => {
                if (GroupByOptions[activeTab] === GroupByOptions.Source) return 'source-cell';
            },
        },
        activeTab === GroupByOptions.Source
            ? {
                  field: 'category',
                  headerName: 'Category',
              }
            : null,
        {
            field: 'amount',
            headerName: 'Amount',
            sortable: true,
            defaultSort: SortType.Desc,
            formatBy: 'amount',
            valueFormatter: getFormattedAndRoundedValue,
        },
        {
            field: 'share',
            headerName: 'Share',
            cellRenderer: ShareCellRenderer,
            formatBy: 'amount',
            valueFormatter: (val: string) => getShareValueToShow(val, totalEarnings.toString()),
            className: () => {
                if (checkForExpandableRows) return 'expanded';
            },
        },
        {
            field: 'totalAmount',
            childCellRenderer: ChildSourceCellRenderer,
            formatBy: 'totalAmount',
            isOnlyChild: true,
        },
    ].filter((i) => i !== null) as ColumnDef[];

    const tabs = displayedTabs.map((tabName) => (
        <TabItem
            active={activeTab === tabName}
            key={tabName}
            clickable={displayedTabs.length > 1}
            onClick={() => displayedTabs.length > 1 && navigateAndSelectTab!(tabName)}
        >
            {`${tabName}s`}
        </TabItem>
    ));

    const isSearchActive = state.search !== null;

    return (
        <div className={commonStyles['main-container']}>
            <div className={commonStyles['heading-wrapper']}>
                <Breadcrumbs
                    tabsWithRelatedEntityId={tabsWithRelatedEntityId}
                    setTabId={setTabId}
                    setTabInBreadcrumbs={setTabInBreadcrumbs}
                    unsetAllTabIds={unsetAllTabIds}
                    firstLevelBreadcrumbTitle="Earnings"
                />
                <div className={commonStyles['dropdown-container']}>
                    <div className={commonStyles['filters-wrapper']}>
                        <TeamsSelector
                            className={commonStyles['filter-item']}
                            teamListOptions={teamListOptions}
                            selectedTeam={selectedTeam}
                            setSelectedTeam={setTeamQuery}
                        />
                        <PeriodSelector className={commonStyles['filter-item']} isDisabled={noData} />
                    </div>
                </div>
            </div>
            {!tabsWithRelatedEntityId.Source ? (
                <div className={styles['tab-row']}>
                    <CategorySelectionButtons
                        setSelectedCategory={setSelectedCategory}
                        selectedCategory={selectedCategory}
                    />
                    <ActionButton
                        actionClassName="primary"
                        label="Manage Sources"
                        iconType="manage"
                        iconIsFirst
                        navigateTo="/data-sources"
                    />
                </div>
            ) : null}
            <Card className={styles['card-block']}>
                <TotalEarningsHeading
                    tabsWithRelatedEntityId={tabsWithRelatedEntityId}
                    totalEarnings={totalEarnings}
                    isLoading={isLoading}
                    isDisabled={noData}
                />
                <div className={commonStyles['widget-options']}>
                    <div className={commonStyles['widget-tab-row']}>
                        <Tab className={commonStyles['widget-tab']}>{tabs}</Tab>
                        <EarningsButtons
                            downloadButtonLabel="Download statement"
                            showEarningsLink={false}
                            toggleExportModal={toggleExportModal}
                        />
                    </div>
                    <div className={commonStyles['widget-tab-row']}>
                        <div className={commonStyles['chart-options']}>
                            {hasProjections && (
                                <ToggleSwitch
                                    label={ShowProjectedEarnings}
                                    state={shouldShowProjections}
                                    toggle={toggleProjectionAppearance}
                                    disabled={false}
                                />
                            )}
                        </div>
                        <div className={commonStyles['chart-options']}>
                            {lastParsedDate && (
                                <span className={commonStyles['info-block']}>
                                    {`Updated on ${getLastUpdatedDateText(lastParsedDate)}`}
                                </span>
                            )}
                            <ChartTypeSelector
                                chartType={chartTypeValue}
                                updateChartType={updateChartTypeValue}
                                isDisabled={noData}
                            />
                        </div>
                    </div>
                </div>
                <Chart
                    chartData={chartData}
                    notSelectedChartData={notSelectedChartData}
                    chartType={chartTypeValue}
                    monthlyTotals={monthlyTotals}
                    isFilledWithColor
                    isLoading={isLoading}
                    selectedItem={activeTab}
                    hasProjections={hasProjections}
                />
                <TotalEarningsBar totalEarnings={totalEarnings} isLoading={isLoading} />
                <Table
                    shouldShowEmptyState={noData}
                    onSort={onSort}
                    onDefaultSort={onDefaultSort}
                    viewMore={viewMore}
                    searchInTable={searchInTable}
                    state={state}
                    className={noData && !isSearchActive ? '' : 'earnings-table'}
                    expandedItems={rowsExpanded}
                    tableItems={tableItems}
                    selectedItems={selectedItemsWithColor}
                    columnDefs={columnDefs}
                    rowRenderer={TableRowRenderer}
                    withDetails
                    selectable
                    withFilter
                    filterBy="name"
                    filterInputPlaceholder={`Filter ${activeTab.toLocaleLowerCase()}s by name`}
                    viewAll={{
                        title: viewMoreText,
                        icon: <Icon type="arrowdown" />,
                    }}
                    onRowSelected={(row: TableItemType) => {
                        if (row.selected) {
                            removeSelectedItem(row.id);
                        } else {
                            addSelectedItem(row.id);
                        }
                    }}
                    onRowExpanded={(row: TableItemType) => changeExpandedRowsList(row.id)}
                    rowRendererConfig={{
                        classnames: [
                            {
                                'selected-row': (row: TableItemType) => isItemSpecial(row.id),
                            },
                        ],
                        withDetails: true,
                        canExpand: 'accounts',
                    }}
                    pagination={pagination}
                    hasPagination={hasPagination}
                    isLoading={isLoading}
                    computedFields={(item: any) => {
                        if (item && item.accounts) {
                            const computedAccounts = item.accounts.map((account: Account) => ({
                                ...account,
                                totalAmount: getFormattedAndRoundedValue((account.totalAmount ?? '').toString()),
                            }));

                            return {
                                ...item,
                                accounts: computedAccounts,
                            };
                        }
                        return item;
                    }}
                />
                {noData && <EmptyStateComponent />}
            </Card>
            <EarningsExportModal
                isModalOpen={isExportModalOpen}
                closeExportModal={closeExportModal}
                toggleExportModal={toggleExportModal}
            />
        </div>
    );
};
