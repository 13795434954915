import React, { FC } from 'react';

import { emptyRow } from 'components/Table/constants';
import { TableBodyProps } from './types';

export const TableBody: FC<TableBodyProps> = ({
    className,
    columnDefs,
    onRowSelected,
    onRowExpanded,
    onCollapseRow,
    onSaveChanges,
    rowData,
    rowRenderer,
    rowRendererConfig,
    sort,
    isEmpty,
    canCollapseOnRowClick,
}) => {
    const RowRenderer = rowRenderer;
    rowData = isEmpty ? emptyRow : rowData;

    return (
        <div data-testid="tBody" className={className}>
            {rowData.map((row, rowIndex) => (
                <RowRenderer
                    data-testid="rowRenderer"
                    key={`row-${row.id || rowIndex}-${sort.field ? row[sort.field] : 'no-sort'}`}
                    config={rowRendererConfig}
                    row={row}
                    rowIndex={rowIndex}
                    columnDefs={columnDefs}
                    onRowSelected={onRowSelected}
                    onRowExpanded={onRowExpanded}
                    onCollapseRow={onCollapseRow}
                    onSaveChanges={onSaveChanges}
                    isEmpty={isEmpty}
                    canCollapseOnRowClick={canCollapseOnRowClick}
                />
            ))}
        </div>
    );
};
