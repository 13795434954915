import React, { FC } from 'react';
import { TooltipInfoProps } from './interfaces';
import { DECIMAL_PLACES } from '../../../constants';
import BigNumber from 'bignumber.js';
import { getFormattedAndRoundedValue } from 'shared/utils/getFormattedAndRoundedValue';

import styles from './TooltipInfo.module.scss';

export const TooltipInfo: FC<TooltipInfoProps> = (props) => {
    const { color, name, amount, testId } = props;
    const tooltipInfoTestId = `${testId}.Info`;
    const adjustedAmount = new BigNumber(amount).decimalPlaces(DECIMAL_PLACES);
    return (
        <div className={styles['tooltip-info-container']} data-testid={tooltipInfoTestId}>
            <div className={styles['tooltip-info-name']}>
                <div className={styles['tooltip-info-mark']} style={{ backgroundColor: color as string }} />
                <span className={styles['tooltip-info']}>{name}</span>
            </div>
            <span className={styles['tooltip-info']}>{getFormattedAndRoundedValue(adjustedAmount.toString())}</span>
        </div>
    );
};
