import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptTeamInvitationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars['ID'];
  accept: Types.Scalars['Boolean'];
}>;


export type AcceptTeamInvitationMutation = { __typename?: 'Mutation', acceptTeamInvitation?: boolean | null };


export const AcceptTeamInvitationDocument = gql`
    mutation acceptTeamInvitation($invitationId: ID!, $accept: Boolean!) {
  acceptTeamInvitation(invitationId: $invitationId, accept: $accept)
}
    `;
export type AcceptTeamInvitationMutationFn = Apollo.MutationFunction<AcceptTeamInvitationMutation, AcceptTeamInvitationMutationVariables>;

/**
 * __useAcceptTeamInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptTeamInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTeamInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTeamInvitationMutation, { data, loading, error }] = useAcceptTeamInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      accept: // value for 'accept'
 *   },
 * });
 */
export function useAcceptTeamInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTeamInvitationMutation, AcceptTeamInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTeamInvitationMutation, AcceptTeamInvitationMutationVariables>(AcceptTeamInvitationDocument, options);
      }
export type AcceptTeamInvitationMutationHookResult = ReturnType<typeof useAcceptTeamInvitationMutation>;
export type AcceptTeamInvitationMutationResult = Apollo.MutationResult<AcceptTeamInvitationMutation>;
export type AcceptTeamInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptTeamInvitationMutation, AcceptTeamInvitationMutationVariables>;