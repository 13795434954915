import React, { FC } from 'react';
import cn from 'classnames';

import { ResponsiveCell } from '../../../ResponsiveCell/ResponsiveCell';

import { TableRowProps } from '../../../../types';

import styles from './TableRow.module.scss';

export const TableRow: FC<TableRowProps> = (props) => {
    const {
        className,
        config,
        rowIndex = 0,
        columnDefs,
        isEmpty,
        isChildRow,
        row,
        onRowSelected = () => {},
        onCollapseRow = () => {},
        canCollapseOnRowClick,
    } = props;

    const getRowRendererClassnames = () => {
        return config.classnames!.map((item) => {
            if (typeof item === 'string') {
                return styles[item];
            }
            return Object.entries(item).map(([classname, func]) => (func && func(row) ? styles[classname] : ''));
        });
    };

    const handleCollapseRow = () => {
        if (canCollapseOnRowClick) {
            onCollapseRow(row.rowId || row.id);
        }
    };

    return (
        <div
            data-testid="table-row"
            onClick={handleCollapseRow}
            className={cn(styles.row, className, [config.classnames ? getRowRendererClassnames() : null])}
        >
            {columnDefs.map((columnDef) => {
                return (
                    <ResponsiveCell
                        key={`${columnDef.field}-cell-${row.id}`}
                        config={config}
                        row={row}
                        rowIndex={rowIndex}
                        columnDef={columnDef}
                        isChildRow={isChildRow}
                        onSelectRow={() => onRowSelected(row)}
                        isEmpty={isEmpty}
                    />
                );
            })}
        </div>
    );
};
