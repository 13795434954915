import React, { ChangeEvent, FC, useState } from 'react';
import { TeamMembersList } from 'components/TeamMembersList/TeamMembersList';
import { DataSourceTeamList } from 'components/DataSourceTeamList/DataSourceTeamList';
import { AccountTeamsTabButtons } from './components/AccountTeamsTabButtons/AccountTeamsTabButtons';
import { AccountTeamsNameSection } from './components/AccountTeamsNameSection/AccountTeamsNameSection';
import { AccountTeamsHeader } from './components/AccountTeamsHeader/AccountTeamsHeader';
import { useTeamMemberTableData } from 'components/TeamMembersList/hooks/useTeamMemberTableData';
import { useDataSourceMemberTable } from 'components/DataSourceTeamList/hooks/useDataSourceMembersTable';
import { dataSourceRowsMock } from 'components/DataSourceTeamList/constants';
import { ownerValue, teamOwnerValue } from 'components/TeamMembersList/constants';
import { AccountTeamsTabProps } from './types';
import { teamNameIsEmptyText, teamNameOnlyNumbersLetters } from './constants';

import styles from './AccountTeamsTab.module.scss';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';
import { isEqual } from 'lodash';
import { cancelModalBtn, modalDescription, modalTitle, okModalBtn } from './constants';

import { TeamTableItemType } from 'shared/types';
import { InputTeamMemberRoles } from 'gql/__generated__/types';

export const AccountTeamsTab: FC<AccountTeamsTabProps> = (props) => {
    const { name, isEditor, isViewer } = props.row;

    const [teamName, setTeamName] = useState<string>(name || '');
    const [teamCreationError, setTeamCreationError] = useState('');
    const [modalState, setModalState] = useState(false);
    const {
        testId,
        onClose,
        editMode,
        row,
        onDeleteTeam,
        onLeaveTeam,
        dataSourcesDropdownData,
        teamOwnerInfo,
        onSaveChanges,
    } = props;

    const {
        dataSourceRows,
        handleChange,
        handleCheckMember,
        handleDeleteSource,
        handleAddDataSource,
        dataSourcesToCreateList,
        dataSourcesToDeleteList,
    } = useDataSourceMemberTable(
        row.sources?.length ? row.sources : isViewer ? [] : dataSourceRowsMock,
        dataSourcesDropdownData!,
    );
    const {
        tableItemsData,
        changePermission,
        deleteRow,
        addMember,
        confirmMember,
        changeMember,
        validateTeamMembers,
        membersToUpdateRoleList,
    } = useTeamMemberTableData(row.members.length ? row.members : teamOwnerValue(teamOwnerInfo));

    const checkIfNameChanged = teamName !== name;

    const saveChanges = () => {
        const isTeamMembersDataValid = validateTeamMembers();
        const teamNameTrimmed = teamName.trim();
        if (isTeamMembersDataValid) {
            const membersToDeleteList = row!.members
                .filter(
                    (o: TeamTableItemType) => !tableItemsData.some((member: TeamTableItemType) => member.id === o.id),
                )
                .map((member: TeamTableItemType) => member.id);

            const membersToCreateList = tableItemsData
                .filter((o) => !row.members.some((member: TeamTableItemType) => member.id === o.id))
                .filter((o) => o.role.toUpperCase() !== ownerValue.toUpperCase())
                .map((member: TeamTableItemType) => ({
                    email: member.invitationEmail as string,
                    role: member.role as InputTeamMemberRoles,
                    id: member.id,
                    newMember: member.newMember,
                }));

            if (teamNameTrimmed === '') {
                return setTeamCreationError(teamNameIsEmptyText);
            }
            if (!/^[A-Za-z0-9 ]*$/.test(teamNameTrimmed)) {
                return setTeamCreationError(teamNameOnlyNumbersLetters);
            }
            onSaveChanges(
                checkIfNameChanged ? teamNameTrimmed : '',
                membersToCreateList,
                membersToDeleteList,
                dataSourcesToCreateList,
                dataSourcesToDeleteList,
                row.rowId,
                membersToUpdateRoleList,
            );
            onClose();
        }
    };

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        const validatedName = e.target.value.replace(/  +/g, ' ');
        setTeamCreationError('');
        setTeamName(validatedName);
    };

    const onClearName = () => setTeamName('');

    const checkForChanges = () => {
        const noTeamMembersChanges =
            isEqual(row!.members, tableItemsData) ||
            (!row.members.length &&
                tableItemsData.some((member) => member.role === ownerValue) &&
                tableItemsData.length === 1);
        const noDataSourceAccountsChanges =
            isEqual(dataSourceRows, row!.sources) ||
            (isEqual(dataSourceRows, dataSourceRowsMock) && !row.sources.length);
        const noTeamChanges = noTeamMembersChanges && !checkIfNameChanged && noDataSourceAccountsChanges;
        if (noTeamChanges) {
            return false;
        }
        return true;
    };

    const handleClose = () => {
        if (checkForChanges()) {
            return setModalState(true);
        }
        onClose();
    };

    const handleSave = () => {
        saveChanges();
        setModalState(false);
    };

    const handleDiscard = () => {
        setModalState(false);
        onClose();
    };

    const handleDeleteTeam = (teamId: string) => {
        onDeleteTeam!(teamId);
        onClose();
    };

    const handleLeaveTeam = (teamId: string) => {
        onLeaveTeam!(teamId);
        onClose();
    };

    return (
        <div>
            <div className={styles.teamsWrapper}>
                <ModalDialog
                    isOpen={modalState}
                    toggleModal={() => setModalState(false)}
                    onOk={handleSave}
                    onCancel={handleDiscard}
                    onOkBtnText={okModalBtn}
                    onCancelBtnText={cancelModalBtn}
                    title={modalTitle}
                    description={[
                        modalDescription.firstLine,
                        <br key="modalDescription" />,
                        modalDescription.secondLine,
                    ]}
                />
                <AccountTeamsHeader
                    onClose={handleClose}
                    editMode={editMode}
                    teamName={row.name}
                    testId={`${testId}.accountTeamsHeader`}
                />
                <AccountTeamsNameSection
                    isViewer={isViewer}
                    isEditor={isEditor}
                    onChange={onChangeName}
                    onClear={onClearName}
                    error={teamCreationError}
                    value={teamName}
                    testId={`${testId}.accountTeamsNameSection`}
                />
                <DataSourceTeamList
                    isEditor={isEditor}
                    isViewer={isViewer}
                    dataSourceDropdownData={dataSourcesDropdownData}
                    testId={`${testId}.dataSourceTeamsList`}
                    tableItems={dataSourceRows}
                    onChange={handleChange}
                    onDeleteSource={handleDeleteSource}
                    onCheckMember={handleCheckMember}
                    onAddDataSource={handleAddDataSource}
                />
                <TeamMembersList
                    isEditor={isEditor}
                    isViewer={isViewer}
                    tableItems={tableItemsData}
                    changePermission={changePermission}
                    deleteRow={deleteRow}
                    addMember={addMember}
                    confirmMember={confirmMember}
                    changeMember={changeMember}
                />
                <AccountTeamsTabButtons
                    isViewer={isViewer}
                    isEditor={isEditor}
                    teamId={row.rowId}
                    testId={`${testId}.teamsTabButtons`}
                    saveChanges={saveChanges}
                    close={handleClose}
                    onLeaveTeam={handleLeaveTeam}
                    onDeleteTeam={handleDeleteTeam}
                    isNewTeam={row.isCreated}
                    editMode={editMode}
                />
            </div>
        </div>
    );
};
