import React, { FC, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Slider from 'react-slick';
import cn from 'classnames';

import { DataSourcesItem as DataSourceItem } from 'components/DataSourcesSection/components/DataSourceItem/DataSourceItem';
import { NumberOfLinked } from 'components/NumberOfLinked/NumberOfLinked';
import { ArrowButton } from './components/ArrowButton/ArrowButton';
import { DataSourceSliderProps } from './types';
import { Logo } from 'components/Logo/Logo';

import './slider.scss';
import styles from './DataSourceSlider.module.scss';
import 'slick-carousel/slick/slick.css';
import { sliderConfig } from './constants';
import { StaticSource } from 'shared/types';
import { isProcessing } from 'shared/utils/isProcessing';
import { sourceHasProblem } from 'shared/utils/sourceHasProblem';

export const EnhancedDataSourceSlider: FC<DataSourceSliderProps> = (props) => {
    let slider = useRef<Slider | null>(null);
    const { pathname } = useLocation();
    const { sources, withSourceLinks, isOnboarding, testId } = props;

    const [isLeftButtonActive, setIsLeftButtonActive] = useState(false);
    const [isRightButtonActive, setIsRightButtonActive] = useState(false);

    useEffect(() => {
        setIsRightButtonActive(sliderConfig.slidesToShow! < sources.length);
    }, [sources.length]);

    const updateButtonState = (currentSlide: number): void => {
        const slidesToShow = slider.current?.props.slidesToShow ?? 1;

        setIsLeftButtonActive(currentSlide !== 0);
        setIsRightButtonActive(slidesToShow + currentSlide !== sources.length);
    };

    const getSourceLink = (source: StaticSource) => {
        return withSourceLinks ? `${pathname}/${source.id}` : null;
    };
    return (
        <div className={styles['slider-container']}>
            <Slider
                ref={(instance) => (slider.current = instance)}
                {...sliderConfig}
                afterChange={(currentSlide: number) => updateButtonState(currentSlide)}
            >
                {sources.map((source: StaticSource) => {
                    return (
                        <DataSourceItem
                            testId="dataSourceItem"
                            key={source.type}
                            isConnected={Boolean(source.linkedSources?.length)}
                            isProcessing={isProcessing(source.linkedSources)}
                            withProblem={sourceHasProblem(source.linkedSources)}
                            sourceLink={getSourceLink(source)}
                        >
                            <Logo type={source.type!} className={styles['data-source-icon']} />
                            <NumberOfLinked numOfLinked={source.linkedSources?.length} />
                        </DataSourceItem>
                    );
                })}
            </Slider>
            <div
                className={cn(styles['button-container'], {
                    'button-container-on-boarding': isOnboarding,
                })}
            >
                <ArrowButton
                    testId="sliderArrowButton"
                    data-testid={`${testId}.sliderWrapperLeftBtn`}
                    className="button-left"
                    isActive={isLeftButtonActive}
                    onClick={() => isLeftButtonActive && slider.current && slider.current.slickPrev()}
                />
                <ArrowButton
                    testId="sliderArrowButton"
                    data-testid={`${testId}.sliderWrapperRightBtn`}
                    className="button-right"
                    isActive={isRightButtonActive}
                    onClick={() => isRightButtonActive && slider.current && slider.current.slickNext()}
                />
            </div>
        </div>
    );
};
