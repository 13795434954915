import { ModalDialog } from 'components/ModalDialog/ModalDialog';
import React, { FC } from 'react';
import { btnText, descriptionText } from './constants';
import { TeamInvitationAcceptedModalProps } from './types';

export const TeamInvitationAcceptedModal: FC<TeamInvitationAcceptedModalProps> = ({ isOpen, onClose }) => {
    return (
        <>
            <ModalDialog
                isOpen={isOpen}
                toggleModal={onClose}
                onOk={onClose}
                onOkBtnText={btnText}
                description={descriptionText}
            />
        </>
    );
};
