import { DEFAULT_DAY_VALUE } from '../constants';
import { ChartData, ChartMainData, ChartPointMainData } from '../interfaces';

export const fillEmptyDataPoints = (unixStartDate: number, unixEndDate: number, chartData: ChartData[]) => {
    const filledInChartData: ChartData[] = [];
    const startDate = new Date(unixStartDate);
    const endDate = new Date(unixEndDate);

    chartData.forEach(({ data, id, color }) => {
        const filledInData: ChartMainData = [];

        for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
            Array.from(Array(12).keys(), (month) => {
                if (
                    !(year === startDate.getFullYear() && month < startDate.getMonth()) &&
                    !(year === endDate.getFullYear() && month > endDate.getMonth())
                ) {
                    const date = new Date(Date.UTC(year, month, DEFAULT_DAY_VALUE)).getTime();
                    filledInData.push({
                        x: date,
                        y: data.find((d) => d.x === date)
                            ? (data.find((d) => d.x === date) as ChartPointMainData).y
                            : 0,
                        id: id,
                        name: data[0].name,
                    });
                }
            });
        }

        filledInChartData.push({
            data: filledInData,
            id,
            color,
        });
    });

    return filledInChartData;
};
