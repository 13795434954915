import React, { FC, useContext } from 'react';
import cn from 'classnames';
import Dropdown from 'react-dropdown';

import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';

import { PeriodSelectorProps } from './types';
import { PeriodValues } from 'shared/types';

import { allTimeText, dropdownPlaceholder, thisYearText, lastThreeMonthsText, disabledText } from './constants';

import 'react-dropdown/style.css';
import styles from './PeriodSelector.module.scss';

export const PeriodSelector: FC<PeriodSelectorProps> = ({ className, testId, isDisabled }) => {
    const { selectedPeriod, setSelectedPeriod } = useContext(DateFilterContext);

    const getPeriodSelectorDropdownOptions = () => {
        return [
            {
                value: PeriodValues.thisYear as unknown as string,
                label: <span className={styles.title}>{thisYearText}</span>,
            },
            {
                value: PeriodValues.allTime as unknown as string,
                label: <span className={styles.title}>{allTimeText}</span>,
            },
            {
                value: PeriodValues.lastThreeMonths as unknown as string,
                label: <span className={styles.title}>{lastThreeMonthsText}</span>,
            },
        ];
    };

    const options = !isDisabled ? getPeriodSelectorDropdownOptions() : [];

    return (
        <Dropdown
            data-testid={`${testId}.dropdown`}
            options={options}
            className={cn(styles.dropdown, className)}
            controlClassName={cn(styles['dropdown-control'], { [styles.disabled]: isDisabled })}
            placeholderClassName={cn(styles['dropdown-placeholder'], { [styles.disabled]: isDisabled })}
            menuClassName={styles['dropdown-menu']}
            arrowClassName={cn(styles['dropdown-arrow'], { [styles.disabled]: isDisabled })}
            placeholder={dropdownPlaceholder}
            value={!isDisabled ? (selectedPeriod as unknown as string) : `${disabledText}:`}
            onChange={({ value }) => setSelectedPeriod!(value as unknown as PeriodValues)}
            disabled={isDisabled}
        />
    );
};
