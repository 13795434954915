import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSourceBreakdownsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SourceBreakdownFilter>;
}>;


export type GetSourceBreakdownsQuery = { __typename?: 'Query', getSourceBreakdowns?: Array<{ __typename?: 'Breakdown', category?: Types.Category | null, totalAmount?: number | null } | null> | null };


export const GetSourceBreakdownsDocument = gql`
    query getSourceBreakdowns($filter: SourceBreakdownFilter) {
  getSourceBreakdowns(filter: $filter) {
    category
    totalAmount
  }
}
    `;

/**
 * __useGetSourceBreakdownsQuery__
 *
 * To run a query within a React component, call `useGetSourceBreakdownsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceBreakdownsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceBreakdownsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSourceBreakdownsQuery(baseOptions?: Apollo.QueryHookOptions<GetSourceBreakdownsQuery, GetSourceBreakdownsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourceBreakdownsQuery, GetSourceBreakdownsQueryVariables>(GetSourceBreakdownsDocument, options);
      }
export function useGetSourceBreakdownsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourceBreakdownsQuery, GetSourceBreakdownsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourceBreakdownsQuery, GetSourceBreakdownsQueryVariables>(GetSourceBreakdownsDocument, options);
        }
export type GetSourceBreakdownsQueryHookResult = ReturnType<typeof useGetSourceBreakdownsQuery>;
export type GetSourceBreakdownsLazyQueryHookResult = ReturnType<typeof useGetSourceBreakdownsLazyQuery>;
export type GetSourceBreakdownsQueryResult = Apollo.QueryResult<GetSourceBreakdownsQuery, GetSourceBreakdownsQueryVariables>;