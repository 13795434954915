import React, { FC } from 'react';
import styles from './MembersCellRendererAvatar.module.scss';
import { IMembersCellRendererAvatar } from './types';
import { MEMBERS_TO_SHOWN } from './constants';
import { getZIndex, getPosition } from './utils';
import { Icon } from 'components/Icon/Icon';

export const MembersCellRendererAvatar: FC<IMembersCellRendererAvatar> = (props) => {
    const { index, membersQty } = props;

    if (index <= MEMBERS_TO_SHOWN) {
        return (
            <span
                className={index === MEMBERS_TO_SHOWN ? styles['avatar-empty'] : styles.avatar}
                style={{
                    left: getPosition(index),
                    zIndex: getZIndex(index),
                }}
            >
                {props.index === MEMBERS_TO_SHOWN && (
                    <span className={styles.emptyAvatarLabel}>+{membersQty - MEMBERS_TO_SHOWN}</span>
                )}
                {index < MEMBERS_TO_SHOWN && <Icon type={'user'} />}
            </span>
        );
    }

    return null;
};
