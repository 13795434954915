import React, { FC, useContext } from 'react';
import cn from 'classnames';
import Dropdown from 'react-dropdown';
import { dropdownPlaceholder } from 'components/PeriodSelector/constants';
import { TeamsSelectorProps } from './types';

import styles from './TeamsSelector.module.scss';
import { TeamsFilterContext } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';

export const TeamsSelector: FC<TeamsSelectorProps> = ({
    className,
    selectedTeam,
    setSelectedTeam,
    teamListOptions,
}) => {
    const { updateSelectedTeam } = useContext(TeamsFilterContext);
    const handleSelectTeam = ({ value }: { value: string }) => {
        setSelectedTeam(value);
        updateSelectedTeam(value);
    };
    return (
        <Dropdown
            options={teamListOptions}
            className={cn(styles.dropdown, className)}
            controlClassName={styles['dropdown-control']}
            placeholderClassName={styles['dropdown-placeholder']}
            menuClassName={styles['dropdown-menu']}
            arrowClassName={styles['dropdown-arrow']}
            placeholder={dropdownPlaceholder}
            value={selectedTeam}
            onChange={handleSelectTeam}
        />
    );
};
