import { SelectedItem } from 'components/Table/hooks/useData/types';
import { useContext, useEffect, useMemo } from 'react';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { Earnings, RevenueTableItemsType } from 'shared/types';
import {
    filterChartDataBySelectedItems,
    filterChartDataByUnSelectedItems,
} from './utils/filterChartDataBySelectedItems';
import { getMinMaxDate } from './utils/getMinMaxDate';
import { prepareChartData } from './utils/prepareChartData';

export const usePreparedChartData = (
    earnings: Earnings[],
    selectedItems: SelectedItem[],
    shouldShowProjections: boolean,
    notSelectedItems: RevenueTableItemsType,
    isLoading?: boolean,
) => {
    const { setDateFrom } = useContext(DateFilterContext);
    const preparedChartData = useMemo(
        () => prepareChartData(earnings, shouldShowProjections),
        [earnings, shouldShowProjections],
    );

    const filteredChartItems = useMemo(
        () => filterChartDataBySelectedItems(selectedItems, preparedChartData),
        [selectedItems, preparedChartData],
    );

    const notSelectedChartData = useMemo(
        () => filterChartDataByUnSelectedItems(notSelectedItems, preparedChartData),
        [notSelectedItems, preparedChartData],
    );

    const hasProjections = useMemo(() => earnings.some(({ projection }) => projection), [earnings]);

    useEffect(() => {
        if (!isLoading) {
            const { dateFrom } = getMinMaxDate(filteredChartItems);
            setDateFrom!(dateFrom);
        }
    }, [filteredChartItems, isLoading, setDateFrom, preparedChartData]);

    return {
        chartData: filteredChartItems,
        notSelectedChartData,
        hasProjections,
    };
};
