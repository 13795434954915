import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteTeamSourceMutationVariables = Types.Exact<{
  sourceId: Types.Scalars['ID'];
  teamId: Types.Scalars['ID'];
  userSourceId: Types.Scalars['ID'];
}>;


export type DeleteTeamSourceMutation = { __typename?: 'Mutation', deleteTeamSource?: { __typename?: 'Team', id?: string | null } | null };


export const DeleteTeamSourceDocument = gql`
    mutation deleteTeamSource($sourceId: ID!, $teamId: ID!, $userSourceId: ID!) {
  deleteTeamSource(
    sourceId: $sourceId
    teamId: $teamId
    userSourceId: $userSourceId
  ) {
    id
  }
}
    `;
export type DeleteTeamSourceMutationFn = Apollo.MutationFunction<DeleteTeamSourceMutation, DeleteTeamSourceMutationVariables>;

/**
 * __useDeleteTeamSourceMutation__
 *
 * To run a mutation, you first call `useDeleteTeamSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamSourceMutation, { data, loading, error }] = useDeleteTeamSourceMutation({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      teamId: // value for 'teamId'
 *      userSourceId: // value for 'userSourceId'
 *   },
 * });
 */
export function useDeleteTeamSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamSourceMutation, DeleteTeamSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamSourceMutation, DeleteTeamSourceMutationVariables>(DeleteTeamSourceDocument, options);
      }
export type DeleteTeamSourceMutationHookResult = ReturnType<typeof useDeleteTeamSourceMutation>;
export type DeleteTeamSourceMutationResult = Apollo.MutationResult<DeleteTeamSourceMutation>;
export type DeleteTeamSourceMutationOptions = Apollo.BaseMutationOptions<DeleteTeamSourceMutation, DeleteTeamSourceMutationVariables>;