import { checkIsEmail } from 'shared/utils/checkIsEmail';

export const validateEmail = (value: string) => {
    if (!value) {
        return 'Email is required';
    }

    if (!checkIsEmail(value)) {
        return 'Please enter valid email address';
    }
    return '';
};
