import React, { FC } from 'react';
import { utcToZonedTime, format } from 'date-fns-tz';
import { TooltipProps } from './interfaces';
import { TooltipInfo } from './TooltipInfo/TooltipInfo';
import { TooltipTotal } from './TooltipTotal/TooltipTotal';

import styles from './Tooltip.module.scss';
import variables from 'shared/styles/variables.module.scss';

export const Tooltip: FC<TooltipProps> = ({ points, date, testId, totalAmount, remainder }) => {
    const tooltipTestId = `${testId}.Tooltip`;

    if (!points?.length) return null;

    const tooltipDate = format(utcToZonedTime(new Date(date), 'UTC'), 'MMMM y', { timeZone: 'UTC' });

    if (remainder) {
        points.push({
            color: variables.sourceColorUnselected,
            name: 'Unselected',
            y: remainder,
        });
    }

    return (
        <div className={styles['custom-tooltip-container']}>
            <span className={styles['custom-tooltip-header']} data-testid={`${tooltipTestId}.Date`}>
                {tooltipDate}
            </span>
            {points.map((point) => {
                const { name, color, y } = point;
                return (
                    <TooltipInfo
                        key={name + color}
                        name={name}
                        amount={y ?? 0}
                        color={color ?? variables.baseBackgroundColor}
                        testId={tooltipTestId}
                    />
                );
            })}
            <TooltipTotal total={totalAmount} testId={tooltipTestId} />
        </div>
    );
};
