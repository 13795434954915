import React, { FC } from 'react';

import { ReactComponent as artie } from './logos/Artie.svg';
import { ReactComponent as lyric } from './logos/Lyric.svg';
import { ReactComponent as cdbaby } from './logos/CDBaby-sources.svg';
import { ReactComponent as distrokid } from './logos/Distrokid-sources.svg';
import { ReactComponent as tunecore } from './logos/Tunecore-sources.svg';
import { ReactComponent as symphonic } from './logos/Symphonic-sources.svg';
import { ReactComponent as ingrooves } from './logos/InGrooves-sources.svg';
import { ReactComponent as ascap } from './logos/ASCAP-sources.svg';
import { ReactComponent as bmi } from './logos/BMI-sources.svg';
import { ReactComponent as sesac } from './logos/SESAC-sources.svg';
import { ReactComponent as shopify } from './logos/Shopify-sources.svg';
import { ReactComponent as socan } from './logos/SOCAN-sources.svg';
import { ReactComponent as soundexchange } from './logos/SoundExchange-sources.svg';
import { ReactComponent as touring } from './logos/Touring-sources.svg';
import { ReactComponent as merchnow } from './logos/MerchNOW-sources.svg';
import { ReactComponent as zazzle } from './logos/Zazzle-sources.svg';
import { ReactComponent as merchbar } from './logos/Merchbar-sources.svg';
import { ReactComponent as songtrust } from './logos/SongTrust-sources.svg';
import { ReactComponent as instagram } from './logos/Instagram-sources.svg';
import { ReactComponent as facebook } from './logos/Facebook-sources.svg';
import { ReactComponent as youtube } from './logos/YouTube-sources.svg';
import { ReactComponent as twitter } from './logos/Twitter-sources.svg';
import { ReactComponent as fuga } from './logos/Fuga-sources.svg';
import { ReactComponent as ada } from './logos/ADA-sources.svg';
import { ReactComponent as orchard } from './logos/Orchard-sources.svg';
import { ReactComponent as warner } from './logos/Warner-sources.svg';
import { ReactComponent as utopia } from './logos/Utopia.svg';

const IconMap: {
    [key: string]: FC<React.SVGProps<SVGSVGElement>>;
} = {
    artie,
    lyric,
    cdbaby,
    distrokid,
    tunecore,
    symphonic,
    ingrooves,
    ascap,
    bmi,
    sesac,
    shopify,
    socan,
    soundexchange,
    touring,
    merchnow,
    zazzle,
    merchbar,
    songtrust,
    instagram,
    facebook,
    youtube,
    twitter,
    fuga,
    ada,
    orchard,
    warner,
    utopia,
};

export default IconMap;
