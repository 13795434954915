import React from 'react';
import { groupBy } from 'lodash';

import { DataSourcesSection as Section } from 'components/DataSourcesSection/DataSourcesSection';
import { EnhancedDataSourceSlider as DataSourceSlider } from 'components/DataSourceSlider/DataSourceSlider';
import { DataSourcesListProps } from './types';
import { headingByCategory } from './constants';
import styles from './DataSourceList.module.scss';
import { LinkedSource } from 'gql/__generated__/types';

export const DataSourceList = ({ sources, testId, linkedAccounts }: DataSourcesListProps) => {
    const sourceList = Object.entries(groupBy(sources, 'category')).map(([category, sourcesList]) => {
        const linkedAccountsByCategory = groupBy(linkedAccounts, 'category')[category];
        const linkedAccountsCountByCategory = linkedAccountsByCategory.reduce(
            (acc, curr) => acc.concat(curr.linkedSources as LinkedSource[]),
            [] as LinkedSource[],
        ).length;

        return (
            <Section
                testId="dataSourceSection"
                data-testid={`${testId}.${category}`}
                key={category}
                isDataSourcesPage
                heading={headingByCategory[category.toLowerCase()].title || category.toLowerCase()}
                position={headingByCategory[category.toLowerCase()].position}
                connectedSourcesNum={linkedAccountsCountByCategory}
            >
                <DataSourceSlider
                    testId="dataSourceSlider"
                    key={JSON.stringify(sourcesList)}
                    sources={sourcesList}
                    withSourceLinks
                />
            </Section>
        );
    });
    const sourceListSorted = sourceList.sort((a, b) => a.props.position - b.props.position);

    return (
        <div className={styles.container} data-testid={`${testId}.sourceList`}>
            {sourceListSorted}
        </div>
    );
};
