import { useEffect, useReducer } from 'react';
import { Actions } from '../types';
import { initialState, reducer } from '../reducer';
import { EarningsPagination } from 'shared/types';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { SortType } from 'gql/__generated__/types';

export const useTable = (itemsPerLoad: number) => {
    const navigate = useNavigate();
    const { search, pathname } = useLocation();

    const parsedParams = qs.parse(search, { ignoreQueryPrefix: true });

    const [state, dispatch] = useReducer(
        reducer,
        initialState({ itemsPerLoad, sortType: parsedParams.sort ? (parsedParams.sort as SortType) : SortType.Desc }),
    );

    const updateTableSize = (size: number) => {
        dispatch({ type: Actions.SetItemsLoadedCount, payload: size });
    };

    const searchInTable = (searchValue: string) => {
        dispatch({ type: Actions.SetSearch, payload: searchValue });
    };

    const onSort = (field: string) => {
        dispatch({ type: Actions.SetSort, payload: field });
    };

    const onSortToDefaultValue = (field: string) => {
        dispatch({ type: Actions.SetSortToDefaultValue, payload: field });
    };

    useEffect(() => {
        searchInTable(parsedParams.search as string);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDefaultSort = (field: string) => {
        dispatch({ type: Actions.SetDefaultSort, payload: field });
    };

    const viewMore = (viewAll: any, pagination?: EarningsPagination) => {
        if (viewAll && !viewAll.navigateTo) {
            if (pagination) {
                const { total, limit } = pagination;

                if (limit <= total) {
                    const query = qs.parse(search, { ignoreQueryPrefix: true });
                    const pageRes = parseInt(query.page as string, 10);
                    const page = isNaN(pageRes) ? 1 : pageRes;
                    const currentPage = { page: page + 1 };

                    navigate(
                        {
                            pathname,
                            search: qs.stringify({ ...query, ...currentPage }),
                        },
                        { replace: true },
                    );
                }
            } else {
                const itemsLoadedCount = state.itemsLoadedCount + itemsPerLoad;

                dispatch({ type: Actions.SetItemsLoadedCount, payload: itemsLoadedCount });
            }
        }
    };

    useEffect(() => {
        searchInTable('');
    }, [parsedParams.q, parsedParams.category]);

    return { onSort, onDefaultSort, viewMore, searchInTable, state, updateTableSize, onSortToDefaultValue };
};
