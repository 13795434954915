import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';

import { Icon } from 'components/Icon/Icon';
import { DataSourceItemProps } from './types';

import styles from './DataSourceItem.module.scss';

const ProblemIndicator = () => (
    <div className={styles['indicator-container']} data-testid="problem-indicator">
        <Icon type="datasourcewarning"></Icon>
    </div>
);

const SuccessIndicator = () => (
    <div className={styles['indicator-container']} data-testid="success-indicator">
        <Icon type="checkedsuccess"></Icon>
    </div>
);

const ProcessingIndicator = () => (
    <div className={styles['indicator-container']} data-testid="processing-indicator">
        <Icon type="processing"></Icon>
    </div>
);

export const DataSourcesItem: FC<DataSourceItemProps> = ({
    className,
    children,
    isConnected,
    withProblem,
    sourceLink,
    testId,
    isProcessing,
}) => {
    const getIndicator = (): JSX.Element | undefined => {
        if (isConnected) {
            switch (true) {
                case withProblem:
                    return <ProblemIndicator />;
                case isProcessing:
                    return <ProcessingIndicator />;
                default:
                    return <SuccessIndicator />;
            }
        }
    };

    return (
        <div data-testid={`${testId}.itemWrapper`} className={styles['container-bordered']}>
            <div
                data-testid={`${testId}.dataSourceitem`}
                className={cn(styles.container, className, {
                    [styles['container-connected']]: isConnected,
                })}
            >
                <div className={styles.indicators}>{getIndicator()}</div>
                {sourceLink ? (
                    <NavLink
                        data-testid={`${testId}.itemLink`}
                        className={cn(styles.inner, {
                            [styles['container-linked-number-is-null']]: !isConnected,
                        })}
                        to={sourceLink}
                    >
                        {children}
                    </NavLink>
                ) : (
                    <div data-testid={`${testId}.itemInner`} className={styles.inner}>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};
