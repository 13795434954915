import { GetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';

export const today = new Date();
export const currentMonth = new Date().getMonth();

export const getDateWithYear = (date: any) => {
    return new Date(date).toLocaleString('en', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
};

export const todayWithYearText = today.toLocaleString('en', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
});

export const thisYearStartValue = new Date(today.getFullYear(), 0, 1);

export const thisYearStartText = thisYearStartValue.toLocaleString('en', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
});

export const lastThreeMonthsStartValue = new Date(today.getFullYear(), today.getMonth() - 2, 1);

export const lastThreeMonthsStartText = lastThreeMonthsStartValue.toLocaleString('en', {
    month: 'short',
    year: 'numeric',
});

export const lastThreeMonthsEndText = new Date(today.getFullYear(), today.getMonth(), today.getDate()).toLocaleString(
    'en',
    {
        month: 'short',
        year: 'numeric',
    },
);

export const getLastUpdatedDateText = (date: Date) => {
    return new Date(date).toLocaleString('en', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
};

export const getLastParsedDateFromQuery = (data: GetLinkedSourcesQuery | undefined): Date | null => {
    const allUnixDates: number[] = [];

    data?.linkedSources?.forEach((source) =>
        source?.linkedSources?.forEach((linkedSource) => {
            if (linkedSource?.lastParsing && linkedSource.lastParsing.unix) {
                allUnixDates.push(parseInt(linkedSource.lastParsing.unix));
            }
        }),
    );

    return allUnixDates.length > 0 ? new Date(Math.max(...allUnixDates)) : null;
};
