import variables from 'shared/styles/variables.module.scss';

export const colors = [
    variables.sourceColor1,
    variables.sourceColor2,
    variables.sourceColor3,
    variables.sourceColor4,
    variables.sourceColor5,
    variables.sourceColor6,
    variables.sourceColor7,
    variables.sourceColor8,
    variables.sourceColor9,
    variables.sourceColor10,
];
