import React, { FC } from 'react';

import cn from 'classnames';

import { TableCellProps } from '../../types';

import styles from './TableCell.module.scss';
import commonStyles from '../../Table.module.scss';

export const TableCell: FC<TableCellProps> = ({ isHeaderCell, columnDef, cellRenderer, row }) => {
    const { className = '' } = columnDef;
    const checkClassName = (rowObject: { [key: string]: any } | undefined) => {
        return typeof className === 'string' ? className : className(rowObject);
    };

    const headerCellClassNames = cn(
        styles['header-cell'],
        styles[`header-${columnDef.field}-cell`],
        commonStyles[`header-${columnDef.field}-cell`],
        columnDef.noLeftCellPadding,
        styles[checkClassName(row)],
        {
            [styles['header-cell-sortable']]: columnDef.sortable,
            [styles.noLeftCellPadding]: columnDef.noLeftCellPadding,
        },
    );

    const cellClassNames = cn(
        styles.cell,
        styles[`${columnDef.field}-cell`],
        commonStyles[`${columnDef.field}-cell`],
        styles[checkClassName(row)],
        {
            [styles.noLeftCellPadding]: columnDef.noLeftCellPadding,
            [styles[`${columnDef.field}-childCell`]]: row?.totalAmount,
        },
    );

    return (
        <div data-testid="table-cell" className={isHeaderCell ? headerCellClassNames : cellClassNames}>
            {cellRenderer}
        </div>
    );
};
