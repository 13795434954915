import React, { FC } from 'react';

import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { styleTypes } from 'shared/constants';

import { noDataText, noDataButton } from '../../constants';

import styles from './NoDataBlock.module.scss';
import { NoDataBlockProps } from './types';

export const NoDataBlock: FC<NoDataBlockProps> = ({ openForm, isFormOpened }) => {
    return (
        <div className={styles.container}>
            {!isFormOpened ? (
                <>
                    <p className={styles.text}>{noDataText}</p>
                    <Card className={styles['card-container']}>
                        <Button
                            style={styleTypes.primary}
                            className={styles.teamMembersListButton}
                            isLink={false}
                            onClick={openForm}
                        >
                            {noDataButton}
                        </Button>
                    </Card>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
