import React, { FC } from 'react';
import { Icon } from 'components/Icon/Icon';
import { DataSourceTeamMember } from 'components/DataSourceTeamMember/DataSourceTeamMember';
import { DataSourceMembersCellRendererProps } from 'components/Table/types';
import styles from './DataSourceMembersCellRenderer.module.scss';

export const DataSourceMembersCellRenderer: FC<DataSourceMembersCellRendererProps> = (props) => {
    const { value, row, columnDef } = props;
    const { onCheckMember, onDeleteSource, isViewer, isEditor, tableItems } = columnDef;
    const isOwner = !isViewer && !isEditor;
    const isEditorAndNoExternalSources = isEditor && value.every((member) => !member.external);
    const isRowRemovable =
        tableItems &&
        ((tableItems?.length > 0 && !tableItems.some((acc) => acc.noValueChosen)) || tableItems?.length > 1) &&
        (isOwner || isEditorAndNoExternalSources);

    return (
        <>
            <div className={styles.emailCellWrapper}>
                {value?.map((member) => (
                    <DataSourceTeamMember
                        key={member.name}
                        member={member}
                        rowId={row.rowId}
                        onCheck={onCheckMember}
                        isViewer={isViewer}
                        isEditor={isEditor}
                    />
                ))}
            </div>
            <div className={styles.deleteCellWrapper}>
                {isRowRemovable && (
                    <Icon
                        className={styles.removeItemIcon}
                        inline
                        type="remove"
                        onClick={() => onDeleteSource!(row.rowId)}
                    />
                )}
            </div>
        </>
    );
};
