import { differenceBy } from 'lodash';
import { useEffect, useState } from 'react';
import { colors } from './constants';
import { calculateAvailableColors } from './utils/calculateAvailableColors';
import { RevenueTableItemType } from '../../types';

export const useColor = (selectedItems: RevenueTableItemType[]) => {
    // TODO: rewrite this hook, figure out its purpose, fix typing

    const initialItemsWithColors = selectedItems.map((i, index) => ({
        ...i,
        color: colors[index],
    }));

    const [itemsWithColors, updateItemsWithColors] = useState(initialItemsWithColors);

    useEffect(() => {
        const newItems = differenceBy(selectedItems, itemsWithColors, (i) => i.id);

        const availableColors = calculateAvailableColors(selectedItems, itemsWithColors, newItems);

        if (availableColors.length === colors.length) {
            const newItemsWithColors = selectedItems.map((i, index) => ({
                ...i,
                color: colors[index],
            }));

            updateItemsWithColors(newItemsWithColors);
        } else {
            const newItemsWithColors = newItems.map((i, index) => ({
                ...i,
                color: availableColors[index],
            }));

            const oldItemsWithoutNew = itemsWithColors.filter((i) =>
                selectedItems.map((item) => item.id).includes(i.id),
            );

            const updatedItemsWithColors = [...oldItemsWithoutNew, ...newItemsWithColors].sort(
                (a: RevenueTableItemType, b: RevenueTableItemType) => Number(b.amount) - Number(a.amount),
            );

            updateItemsWithColors(updatedItemsWithColors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    return itemsWithColors;
};
