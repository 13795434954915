import React, { FC, useEffect } from 'react';
import { TableRowRenderer } from 'components/Table/components/TableRowRenderer/TableRowRenderer';
import { Table } from 'components/Table/Table';
import styles from './DataSourceItemTable.module.scss';
import { useTable } from 'components/Table/hooks/useTable';
import { DataSourceTableItemProps } from './types';
import { ColumnDef } from 'components/Table/types';
import { ContextMenuCellRenderer } from 'components/Table/components/CellRenderers/ContextMenuCellRenderer/ContextMenuCellRenderer';
import { cellClassNames } from 'components/TeamMembersList/constants';
import { useShowCollapsed } from '../../shared/hooks/useShowCollapsed';
import { EditAccountExpandedRow } from 'pages/DataSourceSelected/components/EditAccountExpandedRow/EditAccountExpandedRow';
import { AccountData } from 'pages/DataSourceSelected/types';
import { DataSourceTitleRenderer } from 'components/Table/components/CellRenderers/DataSourceTitleRenderer/DataSourceTitleRenderer';
import { DataSourceLastUpdateRenderer } from '../../components/Table/components/CellRenderers/DataSourceLastUpdate/DataSourceLastUpdateRenderer';

export const DataSourceItemTable: FC<DataSourceTableItemProps> = ({
    linkedAccounts,
    onDeleteAccount,
    onEditAccount,
    isAccountFormValid,
    onSetForbiddenToOpen,
    isAddingNewAccount,
}) => {
    //TODO add view more
    const { onSort, onDefaultSort, viewMore, searchInTable, state, updateTableSize } = useTable(linkedAccounts.length);

    useEffect(() => {
        updateTableSize(linkedAccounts.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkedAccounts]);

    const { rowsCollapsed, changeCollapsedRowsList } = useShowCollapsed(linkedAccounts as []);

    const isForbiddenToOpen = rowsCollapsed.some((row) => row.collapsed) || isAddingNewAccount;

    useEffect(() => {
        onSetForbiddenToOpen(isForbiddenToOpen);
    }, [isForbiddenToOpen, onSetForbiddenToOpen]);

    useEffect(() => {
        if (isAccountFormValid) {
            changeCollapsedRowsList(isAccountFormValid);
        }
    }, [isAccountFormValid, changeCollapsedRowsList]);

    const onEditRow = (row: { [key: string]: any }) => {
        if (!isForbiddenToOpen) {
            changeCollapsedRowsList(row.id);
        }
    };

    const onDeleteRow = (row: { [key: string]: any }) => {
        if (!isForbiddenToOpen) {
            onDeleteAccount(row.id);
        }
    };

    const saveChanges = (rowId: string, account: AccountData) => {
        onEditAccount(account, rowId);
    };

    const dataSourcesColumnDefs: ColumnDef[] = [
        {
            field: 'name',
            headerName: 'Title',
            cellRenderer: DataSourceTitleRenderer,
        },
        {
            field: 'lastUpdate',
            headerName: 'Last Update',
            cellRenderer: DataSourceLastUpdateRenderer,
        },
        {
            field: 'contextMenu',
            cellRenderer: ContextMenuCellRenderer,
            className: cellClassNames.iconColumn,
            onDeleteRow,
            onEditRow,
        },
    ];

    return (
        <>
            <Table
                collapsedItems={rowsCollapsed}
                onSort={onSort}
                onDefaultSort={onDefaultSort}
                viewMore={viewMore}
                searchInTable={searchInTable}
                state={state}
                className={styles.dataSourceItemListTable}
                tableItems={linkedAccounts as []}
                headerClassName={styles['table-header']}
                columnDefs={dataSourcesColumnDefs}
                rowRenderer={TableRowRenderer}
                rowRendererConfig={{
                    classnames: ['withBorderRadius'],
                    collapsedRow: EditAccountExpandedRow,
                }}
                onCollapseRow={(rowId: string) => changeCollapsedRowsList(rowId)}
                onSaveChanges={(rowId: string, data: AccountData) => saveChanges(rowId, data)}
            />
        </>
    );
};
