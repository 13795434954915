import BigNumber from 'bignumber.js';
import { bigNumberFormat } from 'shared/constants';

BigNumber.config({ FORMAT: bigNumberFormat });

export const getFormattedAndRoundedValue = (value: string, isCurrency = true) => {
    if (!value) {
        return null;
    }
    const valueBN = new BigNumber(value);
    const isValueLessThenOneCent = valueBN.lt(0.01) && valueBN.gt(0);
    const roundAmount = valueBN.gte(0.01) || valueBN.lte(0) ? value : '0.00';

    const formatCurrency = (currencyValue: string): string => {
        return new BigNumber(currencyValue).toFormat(2);
    };

    const formatAmmount = () => {
        if (isCurrency) {
            if (isValueLessThenOneCent) {
                return '<0.01';
            }
            return formatCurrency(roundAmount);
        }
        return roundAmount;
    };

    return formatAmmount();
};
