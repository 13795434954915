import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UserInfoContext } from 'shared/providers/UserInfoProvider/UserInfoProvider';
import { TeamTableItemType } from 'shared/types';

export const useGetOwnerMemberInfo = (): TeamTableItemType => {
    const userData = useContext(UserInfoContext).user;
    const ownerMemberEmail = userData?.email;
    const ownerMemberId = userData?.id;
    const ownerMemberInfo: TeamTableItemType = {
        role: 'Owner',
        member: {
            userName: '',
            userAvatarUrl: '',
        },
        invitationEmail: ownerMemberEmail,
        id: ownerMemberId || uuidv4(),
    };
    return ownerMemberInfo;
};
