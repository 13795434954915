import { ApolloLink } from '@apollo/client';
import { getToken } from 'shared/services/AuthService';

export const authLink = new ApolloLink((operation, forward) => {
    const accessToken = getToken();

    operation.setContext(({ headers }: { headers: Headers }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
        },
    }));

    return forward(operation);
});
