import React, { FC } from 'react';
import { Icon } from 'components/Icon/Icon';
import { CellRendererProps } from 'components/Table/types';
import styles from './DeleteRowCellRenderer.module.scss';
import { ownerValue } from 'components/TeamMembersList/constants';

export const DeleteRowCellRenderer: FC<CellRendererProps> = ({ columnDef, row }) => {
    return (
        <div className={styles.container}>
            {row.role !== ownerValue && !columnDef.isViewer && !columnDef.isEditor && (
                <Icon className={styles.removeItemIcon} inline type="remove" onClick={() => columnDef.onClick!(row)} />
            )}
        </div>
    );
};
