import React from 'react';
import FuzzySearch from 'fuzzy-search';
import cn from 'classnames';

import { TableSearch } from './components/TableSearch/TableSearch';
import { TableHeader } from './components/TableHeader/TableHeader';
import { TableBody } from './components/TableBody/TableBody';
import { TableViewMoreButton } from './components/TableViewMoreButton/TableViewMoreButton';
import { TableProps } from './types';

import styles from './Table.module.scss';
import { useData } from './hooks/useData/useData';
import { TableItemType } from 'shared/types';
import { LoaderComponent } from 'components/Loader/Loader';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { noDataFound } from './constants';

export const Table = (props: TableProps) => {
    const {
        expandedItems,
        selectedItems,
        collapsedItems,
        tableItems,
        viewAll,
        columnDefs,
        filterBy,
        selectable,
        withDetails,
        onRowSelected,
        onRowExpanded,
        headerClassName,
        className,
        computedFields,
        onCollapseRow,
        pagination,
        isLoading,
        hasPagination,
        state,
        onSort,
        onDefaultSort,
        viewMore,
        searchInTable,
        onSaveChanges,
        canCollapseOnRowClick,
        shouldShowEmptyState,
    } = props;
    const { rowData } = useData(tableItems, selectedItems, expandedItems, computedFields, collapsedItems);

    const { search } = useLocation();

    const searchResult = qs.parse(search, { ignoreQueryPrefix: true });
    const searchName = (searchResult.search as string) ?? '';

    const filter = (rowDataArg: TableItemType[]) => {
        if (filterBy && searchName) {
            const t = new FuzzySearch(rowDataArg, [filterBy]);

            return t.search(searchName);
        }

        return rowDataArg;
    };

    const filteredRowData = filter(rowData);

    const canShowActiveButtonWithPagination = pagination && pagination.limit * pagination.page < pagination.total;

    const canShowActiveButtonWithMockPagination = state.itemsLoadedCount < filteredRowData.length;

    const isTableWithActionButton = pagination
        ? canShowActiveButtonWithPagination
        : canShowActiveButtonWithMockPagination;

    const rowDatatoRender = hasPagination ? filteredRowData : filteredRowData.slice(0, state.itemsLoadedCount);
    const noData = rowData.length === 0;

    const handleViewMoreClick = () => {
        viewMore(viewAll, pagination);
    };

    return (
        <div data-testid="table-container" className={cn(styles[`${className}`])}>
            <TableSearch
                data-testid="filter-table-input"
                value={state.search || ''}
                className={styles['table-search']}
                onSearch={(event) => searchInTable(event.target.value)}
                placeholder={props.filterInputPlaceholder || 'Filter by name'}
                withFilter={props.withFilter || false}
            />
            <div className={styles.table}>
                {isLoading ? (
                    <LoaderComponent />
                ) : noData && !shouldShowEmptyState ? (
                    <div data-testid="no-data-found" className={styles.noDataFound}>
                        {noDataFound}
                    </div>
                ) : (
                    <>
                        <TableHeader
                            config={{
                                sort: state.sort,
                            }}
                            columnDefs={columnDefs}
                            onSort={onSort}
                            onDefaultSort={onDefaultSort}
                            className={cn(styles['table-header'], headerClassName)}
                            isEmpty={noData}
                        />
                        <TableBody
                            columnDefs={columnDefs}
                            onRowSelected={onRowSelected}
                            onRowExpanded={onRowExpanded}
                            onCollapseRow={onCollapseRow}
                            onSaveChanges={onSaveChanges}
                            rowData={rowDatatoRender}
                            rowRenderer={props.rowRenderer}
                            rowRendererConfig={{
                                selectable: selectable || false,
                                withDetails: withDetails || false,
                                ...props.rowRendererConfig,
                            }}
                            sort={state.sort}
                            isEmpty={noData}
                            canCollapseOnRowClick={canCollapseOnRowClick}
                        />
                    </>
                )}
            </div>
            {isTableWithActionButton && !isLoading && (
                <TableViewMoreButton data-testid="viewMore" config={viewAll} onClick={handleViewMoreClick} />
            )}
        </div>
    );
};
