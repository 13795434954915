import { useGetTeamListQuery } from 'gql/queries/__generated__/getTeamList.generated';
import { DataSourcesIdsListType } from 'shared/types';
import { useDeleteTeamMutation } from 'gql/mutations/__generated__/deleteTeam.generated';
import { useCreateTeamMutation } from 'gql/mutations/__generated__/createTeam.generated';
import { useEditTeamTitleMutation } from 'gql/mutations/__generated__/editTeamTitle.generated';
import { useAddTeamMemberMutation } from 'gql/mutations/__generated__/addTeamMember.generated';
import { useDeleteTeamMemberMutation } from 'gql/mutations/__generated__/deleteTeamMember.generated';
import { useAddTeamSourceMutation } from 'gql/mutations/__generated__/addTeamSource.generated';
import { useDeleteTeamSourceMutation } from 'gql/mutations/__generated__/deleteTeamSource.generated';
import { useChangeTeamMemberRoleMutation } from 'gql/mutations/__generated__/changeTeamMemberRole.generated';
import {
    MembersToCreateListType,
    MembersToDeleteListType,
    MembersToUpdateRoleList,
} from 'components/TeamMembersList/hooks/types';
import { InputTeamMemberRoles } from 'gql/__generated__/types';
import { invalidateApolloCacheFor } from 'gql/apolloClient/utils/invalidateApolloCacheFor';
import { useContext } from 'react';
import { TeamsContext } from 'pages/Teams/providers/TeamsProvider';
import { useLeaveTeamMutation } from 'gql/mutations/__generated__/leaveTeam.generated';

export const useTeamAccountsListData = () => {
    const { data, loading, refetch } = useGetTeamListQuery({ notifyOnNetworkStatusChange: true });
    const { collapse } = useContext(TeamsContext);
    const [deleteTeamMutation, { loading: deleteTeamLoading }] = useDeleteTeamMutation();
    const [createTeamMutation, { loading: createTeamLoading }] = useCreateTeamMutation();
    const [editTeamTitleMutation, { loading: editTeamTitleLoading }] = useEditTeamTitleMutation();
    const [addTeamMemberMutation, { loading: addTeamMemberLoading }] = useAddTeamMemberMutation();
    const [deleteTeamMemberMutation, { loading: deleteTeamMemberLoading }] = useDeleteTeamMemberMutation();
    const [addTeamSourceMutation, { loading: addTeamSourceLoading }] = useAddTeamSourceMutation({
        update: (cache, result) => {
            invalidateApolloCacheFor(cache, (options) => {
                const { fieldName, args } = options;
                return fieldName === 'getSources' && args?.filter?.teamId === result.data?.addTeamSource?.id;
            });
        },
    });
    const [deleteTeamSourceMutation, { loading: deleteTeamSourceLoading }] = useDeleteTeamSourceMutation({
        update: (cache, result) => {
            invalidateApolloCacheFor(cache, (options) => {
                const { fieldName, args } = options;
                return fieldName === 'getSources' && args?.filter?.teamId === result.data?.deleteTeamSource?.id;
            });
        },
    });
    const [changeTeamMemberRoleMutation, { loading: changeTeamRoleLoading }] = useChangeTeamMemberRoleMutation();
    const [leaveTeamMutation, { loading: leaveTeamLoading }] = useLeaveTeamMutation();
    const teamsFetched = data?.getTeams ?? [];

    const teamList = teamsFetched.map((row) => ({ ...row, rowId: row?.id }));

    const isLoading =
        loading ||
        deleteTeamLoading ||
        createTeamLoading ||
        editTeamTitleLoading ||
        addTeamMemberLoading ||
        deleteTeamMemberLoading ||
        addTeamSourceLoading ||
        deleteTeamSourceLoading ||
        changeTeamRoleLoading ||
        leaveTeamLoading;

    const saveSourcesAndMembers = async (
        rowId: string,
        membersToCreateList: MembersToCreateListType,
        membersToDeleteList: MembersToDeleteListType,
        dataSourcesToCreateList: DataSourcesIdsListType,
        dataSourcesToDeleteList: DataSourcesIdsListType,
        membersToUpdateRoleList?: MembersToUpdateRoleList,
    ) => {
        if (membersToCreateList.length) {
            await Promise.all(
                membersToCreateList.map(
                    (member: { email: string; role: InputTeamMemberRoles; id?: string; newMember?: boolean }) => {
                        const { email, role, id: userId, newMember } = member;

                        return addTeamMemberMutation({
                            variables: {
                                email,
                                role: role.toUpperCase() as InputTeamMemberRoles,
                                teamId: rowId,
                                userId: newMember ? null : userId,
                            },
                        });
                    },
                ),
            );
        }
        if (membersToDeleteList.length) {
            await Promise.all(
                membersToDeleteList.map((member: string) => {
                    return deleteTeamMemberMutation({
                        variables: {
                            teamId: rowId,
                            teamMemberId: member,
                        },
                    });
                }),
            );
        }
        if (membersToUpdateRoleList?.length) {
            await Promise.all(
                membersToUpdateRoleList.map((member) => {
                    const { role, userId } = member;
                    return changeTeamMemberRoleMutation({
                        variables: {
                            teamId: rowId,
                            teamMemberId: userId,
                            role,
                        },
                    });
                }),
            );
        }
        if (dataSourcesToCreateList.length) {
            await Promise.all(
                dataSourcesToCreateList.map((dataSource) => {
                    const { id: userSourceId, sourceId } = dataSource;
                    return addTeamSourceMutation({
                        variables: {
                            sourceId,
                            teamId: rowId,
                            userSourceId,
                        },
                    });
                }),
            );
        }
        if (dataSourcesToDeleteList.length) {
            await Promise.all(
                dataSourcesToDeleteList.map((dataSource) => {
                    const { id: userSourceId, sourceId } = dataSource;
                    return deleteTeamSourceMutation({
                        variables: {
                            sourceId,
                            teamId: rowId,
                            userSourceId,
                        },
                    });
                }),
            );
        }
        collapse(false);
        refetch();
    };

    const createTeam = async (
        name: string,
        membersToCreateList: MembersToCreateListType,
        membersToDeleteList: MembersToDeleteListType,
        dataSourcesToCreateList: DataSourcesIdsListType,
        dataSourcesToDeleteList: DataSourcesIdsListType,
    ) => {
        const { data: createdTeamId } = await createTeamMutation({
            variables: {
                title: name,
            },
            refetchQueries: ['getTeamList'],
        });
        saveSourcesAndMembers(
            createdTeamId?.createTeam?.id!,
            membersToCreateList,
            membersToDeleteList,
            dataSourcesToCreateList,
            dataSourcesToDeleteList,
        );
    };

    const deleteTeam = async (teamId: string) => {
        await deleteTeamMutation({ variables: { teamId } });
        refetch();
    };

    const leaveTeam = async (teamId: string) => {
        await leaveTeamMutation({
            variables: {
                teamId,
            },
        });
        refetch();
    };

    const editTeam = async (
        name: string,
        membersToCreateList: MembersToCreateListType,
        membersToDeleteList: MembersToDeleteListType,
        dataSourcesToCreateList: DataSourcesIdsListType,
        dataSourcesToDeleteList: DataSourcesIdsListType,
        rowId: string,
        membersToUpdateRoleList: MembersToUpdateRoleList,
    ) => {
        if (name) await editTeamTitleMutation({ variables: { teamId: rowId, title: name } });

        saveSourcesAndMembers(
            rowId,
            membersToCreateList,
            membersToDeleteList,
            dataSourcesToCreateList,
            dataSourcesToDeleteList,
            membersToUpdateRoleList,
        );
    };

    return {
        createTeam,
        editTeam,
        teamList,
        leaveTeam,
        deleteTeam,
        isLoading,
        refetch,
    };
};
