import React, { FC } from 'react';
import cn from 'classnames';

import { Card } from 'components/Card/Card';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { Icon } from 'components/Icon/Icon';

import { SmallInfoBlockProps } from './types';

import styles from './SmallInfoBlock.module.scss';
import { datasourcesText, datasourcesType, manageSourcesText, manageTeamsText, teamsText } from './constants';

export const SmallInfoBlock: FC<SmallInfoBlockProps> = ({ className, type, numOfType, icon, testId }) => (
    <Card className={className}>
        <div className={styles.row}>
            <div data-testid={`${testId}.icon`} className={cn(styles['icon-circle'], styles['icon-circle-' + type])}>
                <Icon type={icon} />
            </div>
            <span data-testid={`${testId}.heading`} className={styles.heading}>
                {type === datasourcesType ? datasourcesText : teamsText}
            </span>
            <span data-testid={`${testId}.number`} className={styles.number}>
                {numOfType}
            </span>
        </div>
        <ActionButton
            navigateTo={`/${type}`}
            iconType="manage"
            label={type === datasourcesType ? `${manageSourcesText}` : `${manageTeamsText}`}
            iconIsFirst
            actionClassName="primary"
        />
    </Card>
);
