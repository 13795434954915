import React, { FC } from 'react';

import cn from 'classnames';

import { ExpandIcon } from '../../ExpandIcon/ExpandIcon';
import { CellRendererProps } from '../../../types';

import styles from '../../../Table.module.scss';

export const ShareCellRenderer: FC<CellRendererProps> = ({ value, row, columnDef, onExpandRow = () => {}, config }) => {
    return (
        <span className={cn(styles[`${columnDef.field}-label`])}>
            {value ? `${value}%` : '0%'}
            {row?.accounts && <ExpandIcon config={config} row={row} columnDef={columnDef} onExpand={onExpandRow} />}
        </span>
    );
};
