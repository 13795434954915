import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
export type AmountFragment = { __typename?: 'Amount', amount?: number | null, currency?: { __typename?: 'Currency', id?: string | null, ticker?: string | null } | null, date?: { __typename?: 'Date', unix?: string | null } | null };

export const AmountFragmentDoc = gql`
    fragment Amount on Amount {
  amount
  currency {
    id
    ticker
  }
  date {
    unix
  }
}
    `;