import { Category } from 'gql/__generated__/types';
import { EarningsPagination } from 'shared/types';

export const screenRes = {
    phone: '(max-width: 720px)',
    tablet: '(min-width: 720px) and (max-width: 1024px)',
};

export const navMenuItems = [
    {
        label: 'Profile',
        link: '/profile',
    },
];

export const specialEntries = {
    productNotReported: {
        id: '00000000-0000-0000-0000-000000000000',
        name: '[Product Not Reported]',
    },
    retailerNotReported: {
        id: '00000000-0000-0000-0000-000000000001',
        name: '[Retailer Not Reported]',
    },
    tracksUnderOneCent: {
        id: '00000000-0000-0000-0000-000000000002',
        name: '[Tracks Under One Cent]',
    },
    foreignRoyalties: {
        id: '00000000-0000-0000-0000-000000000003',
        name: '[Foreign Royalties]',
    },
    adjustment: {
        id: '00000000-0000-0000-0000-000000000004',
        name: '[Adjustment]',
    },
};

export const percentSign = '%';

export const zero = '0';

export const noDataAvailable = ' (No Data Available)';

export const type = 'type';

export const name = 'name';

export const email = 'email';

export const date = 'date';

export const edit = 'Edit';

export const add = 'Add';

export const typeButton = 'button';

export const viewAllEarningsText = 'View All Earnings';

export const cancelText = 'Cancel';

export const rootComponentText = {
    groups: 'Groups will be here',

    subscription: 'Subscription will be here',
};

export const styleTypes = {
    primary: 'primary',
    secondary: 'secondary',
};

export const currencySign = localStorage.getItem('currency');

export const bigNumberFormat = {
    prefix: currencySign as string,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: '',
};

export const showSocialActivity = 'Show Social Activity';

export const viewMore = 'View More';

export const downloadButtonLabel = 'Download statement';

export const signUpText = 'Don’t have an Account? Sign up';

export const addSources = 'Add Sources';
export const emptyStateTitle = 'Link Earning Sources';
export const emptyStateText = 'Connect your income sources to get insights into your earnings.';
export const emptyLegendSkeleton = [{}, {}, {}];
export const emptyLegendAmount = '$0.00';
export const emptyLegendValue = '0%';

export enum GroupByOptions {
    Source = 'Source',
    Retailer = 'Retailer',
    Product = 'Product',
}

export const selectedTypes = {
    [GroupByOptions.Product]: ' - Selected products',
    [GroupByOptions.Source]: ' - Selected sources',
    [GroupByOptions.Retailer]: ' - Selected retailers',
};

export enum Sources {
    BMI = 'bmi',
    TUNECORE = 'tunecore',
    WARNER = 'warner',
    SYMPHONIC = 'symphonic',
    INGROOVES = 'ingrooves',
    SOUNDEXCHANGE = 'soundexchange',
}

export const PAGINATION_DEFAULT: EarningsPagination = {
    limit: 0,
    page: 0,
    total: 0,
};

export enum Routes { // todo switch router to constants
    earnings = '/earnings',
    auth = '/auth',
    dataSources = '/data-sources',
}

export enum AllCategories {
    All = 'ALL',
}

export const CategoryValues = { ...AllCategories, ...Category };

export const PAGINATION_LIMIT = 10;

export const ShowProjectedEarnings = 'Show Projected Earnings';

export const tokenUpdatedEventName = 'TokenUpdated';
export const tokenUpdatedEvent = new Event(tokenUpdatedEventName);

export const defaultPollingInterval = 5000; // 5 seconds;
