import React, { FC } from 'react';
import { LoaderComponent } from '../Loader/Loader';
import { useGetTeamListQuery } from 'gql/queries/__generated__/getTeamList.generated';

export const TeamsCounter: FC = () => {
    const { data, loading } = useGetTeamListQuery();
    const teams = data?.getTeams! ?? [];
    const count = teams.length;

    return <>{loading ? <LoaderComponent /> : count}</>;
};
