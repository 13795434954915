import React, { FC, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import cn from 'classnames';
import Dropdown from 'react-dropdown';

import { Label } from './components/Label/Label';
import { DropdownHeader } from './components/DropdownHeader/DropdownHeader';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { navMenuItems } from 'shared/constants';
import { Icon } from 'components/Icon/Icon';

import 'react-dropdown/style.css';
import styles from './TopRightNavMenu.module.scss';

import { logoutLabel, logoutValue } from '../../constants';
import { TopRightNavMenuDropdownValue } from './types';
import { NavMenuItem } from 'shared/types';
import { UserInfoContext } from 'shared/providers/UserInfoProvider/UserInfoProvider';
import * as AuthService from 'shared/services/AuthService';
import { AuthContext } from 'shared/providers/AuthProvider/AuthProvider';

export const EnhancedTopRightNavMenu: FC = () => {
    const navigate = useNavigate();
    const userInfo = useContext(UserInfoContext).user;
    const { unauthenticateUser } = useContext(AuthContext);
    const { firstName, lastName, avatar } = userInfo!;

    const handleChange = useCallback(
        (value: string) => {
            if (value != 'logout') {
                navigate(value);
            } else {
                //signOut();
                AuthService.logout();
            }
        },
        [navigate],
    );

    const handleLogout = useCallback(() => {
        AuthService.logout();
        unauthenticateUser();
    }, [unauthenticateUser]);

    const getTopRightNavMenuDropdownItems = useCallback(() => {
        const logOutOption = {
            value: logoutValue,
            label: (
                <ActionButton
                    label={logoutLabel}
                    iconType="logout"
                    onClick={handleLogout}
                    iconIsFirst
                    className={styles['logout-btn']}
                />
            ),
            className: cn(styles['dropdown-option'], styles['logout-option']),
        };
        const topRightNavMenuDropdownItems: TopRightNavMenuDropdownValue[] = navMenuItems.map(
            (navMenuItem: NavMenuItem) => ({
                value: navMenuItem.link,
                label: <Label navMenuItemTitle={navMenuItem.label} />,
                className: styles['dropdown-option'],
            }),
        );
        topRightNavMenuDropdownItems.push(logOutOption);
        return topRightNavMenuDropdownItems;
    }, [handleLogout]);

    const topRightNavMenuDropdownItems = getTopRightNavMenuDropdownItems();

    const userName = `${firstName} ${lastName}`;

    return (
        <Dropdown
            options={topRightNavMenuDropdownItems}
            className={styles.dropdown}
            controlClassName={styles['dropdown-control']}
            placeholderClassName={styles['dropdown-placeholder']}
            menuClassName={styles['dropdown-menu']}
            arrowOpen={
                <>
                    <DropdownHeader userName={userName} userAvatar={avatar} />
                    <Icon type="caretup" className={cn(styles['dropdown-arrow'], styles.up)} />
                </>
            }
            arrowClosed={
                <>
                    <DropdownHeader userName={userName} userAvatar={avatar} />
                    <Icon type="caretdown" className={cn(styles['dropdown-arrow'], styles.down)} />
                </>
            }
            onChange={(option) => handleChange(option.value)}
        />
    );
};
