import { CategoryValues } from 'shared/constants';
import variables from 'shared/styles/variables.module.scss';

export const colors: { [key: string]: string } = {
    [CategoryValues.Royalties]: variables.categoryColor2,
    [CategoryValues.Merch]: variables.categoryColor3,
    [CategoryValues.Touring]: variables.categoryColor4,
    [CategoryValues.Distributors]: variables.categoryColor1,
    default: variables.categoryColorDefault,
};

export const EarningsBdownHeading = 'Earnings Breakdown by Source Category';

export const pieChartParams = {
    credits: {
        enabled: false,
    },

    chart: {
        type: 'pie',
        backgroundColor: variables.baseBackgroundColor,
    },
    title: {
        useHTML: true,
        align: 'center',
        verticalAlign: 'middle',
        y: 20,
    },
    plotOptions: {
        pie: {
            size: '80%',
            fillColor: colors.default,
            borderWidth: 0,
            innerSize: '85%',
            border: 'none',
            enableMouseTracking: false,
            dataLabels: {
                enabled: false,
            },
        },
    },
};

export const pieChartParamsDisabled = {
    credits: {
        enabled: false,
    },

    chart: {
        type: 'pie',
        backgroundColor: variables.baseBackgroundColor,
    },
    plotOptions: {
        pie: {
            size: '80%',
            fillColor: variables.chartFieldColor,
            borderWidth: 0,
            innerSize: '85%',
            border: 'none',
            enableMouseTracking: false,
            dataLabels: {
                enabled: false,
            },
        },
    },
};
