import React, { FC } from 'react';
import cn from 'classnames';

import { NotificationProps } from './types';

import styles from './Notification.module.scss';

export const Notification: FC<NotificationProps> = ({ children, className }) => (
    <div className={cn(styles.container, className)}>{children}</div>
);
