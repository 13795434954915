import React from 'react';

import cn from 'classnames';

import { Icon } from 'components/Icon/Icon';
import { CellRendererProps } from '../../../types';

import styles from '../../../Table.module.scss';

export const SelectCellRenderer = ({ row, columnDef, onSelectRow, config, isEmpty }: CellRendererProps) => {
    const backgroundColor =
        !config.selectable || (config.selectable && row.selected) || isEmpty ? row.color : 'transparent';

    return (
        <span
            data-testid="select-click"
            className={cn(styles[`${columnDef.field}-label`], {
                [styles[`${columnDef.field}-label-selectable`]]: config && config.selectable,
                [styles[`${columnDef.field}-label-selected`]]:
                    !config.selectable || (config.selectable && row.selected),
            })}
            style={{ backgroundColor }}
            onClick={() => config.selectable && onSelectRow()}
        >
            {(!config.selectable || (config.selectable && row.selected) || isEmpty) && <Icon type="check" />}
        </span>
    );
};
