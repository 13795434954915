import React, { FC } from 'react';
import cn from 'classnames';

import { DataSourcesSectionProps } from './types';

import styles from './DataSourcesSection.module.scss';
import { connectedText } from './constants';

export const DataSourcesSection: FC<DataSourcesSectionProps> = ({
    isDataSourcesPage,
    className,
    heading,
    connectedSourcesNum,
    children,
    testId,
}) =>
    isDataSourcesPage ? (
        <div data-testid={`${testId}.dataSourceSection`} className={cn(styles.container, className)}>
            <div className={styles['heading-wrapper']}>
                <h3 data-testid={`${testId}.dataSourceHeading`} className={styles.heading}>
                    {heading}
                </h3>
                <span data-testid={`${testId}.dataSourceConnected`} className={styles.subheading}>
                    {connectedSourcesNum}
                    {connectedText}
                </span>
            </div>
            <div data-testid={`${testId}.dataSourceWrapper`} className={styles['sources-wrapper']}>
                {children}
            </div>
        </div>
    ) : (
        <div data-testid={`${testId}.dataSourceEmptySection`} />
    );
