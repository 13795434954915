import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindUsersByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type FindUsersByEmailQuery = { __typename?: 'Query', findUsersByEmail?: Array<{ __typename?: 'SearchUserInfo', id?: string | null, email?: string | null } | null> | null };


export const FindUsersByEmailDocument = gql`
    query findUsersByEmail($email: String!) {
  findUsersByEmail(email: $email) {
    id
    email
  }
}
    `;

/**
 * __useFindUsersByEmailQuery__
 *
 * To run a query within a React component, call `useFindUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindUsersByEmailQuery(baseOptions: Apollo.QueryHookOptions<FindUsersByEmailQuery, FindUsersByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUsersByEmailQuery, FindUsersByEmailQueryVariables>(FindUsersByEmailDocument, options);
      }
export function useFindUsersByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUsersByEmailQuery, FindUsersByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUsersByEmailQuery, FindUsersByEmailQueryVariables>(FindUsersByEmailDocument, options);
        }
export type FindUsersByEmailQueryHookResult = ReturnType<typeof useFindUsersByEmailQuery>;
export type FindUsersByEmailLazyQueryHookResult = ReturnType<typeof useFindUsersByEmailLazyQuery>;
export type FindUsersByEmailQueryResult = Apollo.QueryResult<FindUsersByEmailQuery, FindUsersByEmailQueryVariables>;