import React, { FC } from 'react';
import cn from 'classnames';

import { UserInfoRowProps } from './types';
import { Icon } from 'components/Icon/Icon';

import styles from '../../MainInfoBlock.module.scss';

export const UserInfoRow: FC<UserInfoRowProps> = ({
    rowLabel,
    rowData,
    rowAdditional,
    isEditable,
    onEditClick,
    testId,
}) => (
    <div data-testid={testId} className={cn(styles['info-row'], { [styles.editable]: isEditable })}>
        <span className={styles.label}>{rowLabel}</span>
        <div className={styles['data-wrapper']}>
            <span className={styles.data}>{rowData}</span>
            <span className={styles.note}>{rowAdditional}</span>
        </div>
        {isEditable ? <Icon type="edit" className={styles['edit-btn']} onClick={onEditClick} /> : void null}
    </div>
);
