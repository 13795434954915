import React, { FC } from 'react';

import { Button as FilterButton } from './components/Button/Button';

import { WithCategorySelectionPropsType } from '../../hooks/types';

import { categories } from './constants';

import styles from './CategorySelectionButtons.module.scss';

export const CategorySelectionButtons: FC<WithCategorySelectionPropsType> = ({
    setSelectedCategory,
    selectedCategory,
}) => {
    return (
        <ul className={styles['btn-list']}>
            {categories.map(({ name, value }) => {
                return (
                    <FilterButton
                        key={name}
                        name={name}
                        isActive={selectedCategory === value}
                        onClick={() => setSelectedCategory(value)}
                    />
                );
            })}
        </ul>
    );
};
