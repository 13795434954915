import React, { FC } from 'react';
import styles from './SharedColumnHeader.module.scss';
import { Icon } from 'components/Icon/Icon';
import { ISharedColumnHeader } from './types';

export const SharedColumnHeader: FC<ISharedColumnHeader> = (props) => {
    const { children, onClick, testId } = props;
    return (
        <div className={styles.headerNameWrapper}>
            <span data-testid={`${testId}.name`}>{children}</span>
            <div data-testid={`${testId}.icon`} onClick={onClick} className={styles.iconWrapper}>
                <Icon type="info" />
            </div>
        </div>
    );
};
