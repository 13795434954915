import React, { FC, useContext } from 'react';
import { ModalDialog } from 'components/ModalDialog/ModalDialog';
import { acceptBtnText, cancelBtnText, descriptionText1, descriptionText2, greetingText, titleText } from './constants';
import { TeamInvitationDeclinedModalProps } from './types';

import { UserInfoContext } from 'shared/providers/UserInfoProvider/UserInfoProvider';
import { TeamsContext } from 'pages/Teams/providers/TeamsProvider';

import styles from './TeamInvitationDeclinedModal.module.scss';

export const TeamInvitationDeclinedModal: FC<TeamInvitationDeclinedModalProps> = ({ isOpen, onClose, onAccept }) => {
    const { user } = useContext(UserInfoContext);
    const { inviter } = useContext(TeamsContext);

    const userInfo = `${user?.firstName} ${user?.lastName}`;
    const inviterInfo = `${inviter.inviterName} ${inviter.inviterSurname}`;
    const modalText = `${greetingText} ${userInfo}. ${descriptionText1} ${inviterInfo}'s ${descriptionText2}`;

    return (
        <ModalDialog
            isOpen={isOpen}
            toggleModal={onClose}
            onCancel={onClose}
            onOk={onAccept}
            title={titleText}
            onCancelBtnText={cancelBtnText}
            onOkBtnText={acceptBtnText}
            description={modalText}
            shouldSwapButtons
            className={styles.modal}
        />
    );
};
