export const cancelModalBtn = 'discard';
export const okModalBtn = 'save';
export const modalTitle = 'Review Changes';
export const modalDescription = {
    firstLine: 'You have made changes.',
    secondLine: 'Do you want to discard or save them?',
};

export const teamNameIsEmptyText = 'Team Name is required';
export const teamNameOnlyNumbersLetters = 'Only A..Z, 0-9 are allowed';

export const teamHasUnfinishedChangesText = 'Finish editing team members or data sources';
