import { Amount } from 'gql/__generated__/types';
import { Maybe } from 'graphql/jsutils/Maybe';

export const prepareAmountForCharts = (amount?: Maybe<Maybe<Amount>[]>) =>
    amount?.map((amountItem) => {
        return {
            amount: amountItem?.amount ?? 0,
            date: amountItem?.date?.unix ?? '',
        };
    });
