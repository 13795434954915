import React, { useContext } from 'react';

import { TotalEarningsBarPropsType } from './types';

import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { PeriodValues } from 'shared/types';
import { getFormattedAndRoundedValue } from 'shared/utils/getFormattedAndRoundedValue';
import {
    getDateWithYear,
    lastThreeMonthsEndText,
    lastThreeMonthsStartText,
    thisYearStartText,
    todayWithYearText,
} from 'shared/utils/getDate';

import { totalEarningsHeading } from './constants';

import styles from './TotalEarningsBar.module.scss';

export const TotalEarningsBar = ({ totalEarnings, isLoading }: TotalEarningsBarPropsType) => {
    const { dates, selectedPeriod } = useContext(DateFilterContext);

    const periodLabels = {
        [PeriodValues.thisYear]: `${thisYearStartText} - ${todayWithYearText}`,
        [PeriodValues.allTime]: `${getDateWithYear(dates?.dateFrom)} - ${todayWithYearText}`,
        [PeriodValues.lastThreeMonths]: `${lastThreeMonthsStartText} - ${lastThreeMonthsEndText}`,
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>{totalEarningsHeading}</div>
            {!isLoading && (
                <>
                    <div className={styles.period}>{periodLabels[selectedPeriod]}</div>
                    <div className={styles.amount}>{getFormattedAndRoundedValue(totalEarnings.toString())}</div>
                </>
            )}
        </div>
    );
};
