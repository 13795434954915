import React, { FC } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { authLink } from './links/authLink';
import { errorLink } from './links/errorLink';

const earningEntityCustomIDPolicy = { keyFields: ['id', 'totalAmount'] };

const cache = new InMemoryCache({
    typePolicies: {
        Account: earningEntityCustomIDPolicy,
        Product: earningEntityCustomIDPolicy,
        Retailer: earningEntityCustomIDPolicy,
    },
});

const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT!;

const link = ApolloLink.from([errorLink, authLink, new HttpLink({ uri })]);

const client = new ApolloClient({
    uri,
    link: link,
    cache,
});

// NOTE: temporarily disabling cache clear for alpha demo presentation so Justin can get faster load times
/*const storeUpdateTime = 1000 * 60 * 2; //2 min
setInterval(() => {
    client.clearStore();
}, storeUpdateTime);*/

export const AppApolloProvider: FC = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;
