import React, { FC } from 'react';

import { TableSearchProps } from './types';

import { SearchInput } from 'components/SearchInput/SearchInput';

export const TableSearch: FC<TableSearchProps> = ({ className, onSearch, placeholder, withFilter, value }) => {
    return withFilter ? (
        <SearchInput
            data-testid="searchInput"
            testId="searchInput"
            value={value}
            className={className}
            placeholder={placeholder}
            onChange={onSearch}
        />
    ) : null;
};
