import React, { FC } from 'react';
import cn from 'classnames';

import { Action } from './components/Action/Action';
import { ActionButtonProps } from './types';
import { Icon } from 'components/Icon/Icon';

import styles from './ActionButton.module.scss';

export const ActionButton: FC<ActionButtonProps> = (props) => {
    const {
        navigateTo = '',
        iconIsFirst,
        actionClassName,
        actionLabelClassName,
        onClick,
        iconType,
        label,
        className,
    } = props;

    return iconIsFirst ? (
        <Action navigateTo={navigateTo} className={cn(className, styles[`${actionClassName}`])} onClick={onClick}>
            <Icon type={iconType} className={styles.icon} />
            <span className={cn(styles.label, actionLabelClassName)}>{label}</span>
        </Action>
    ) : (
        <Action navigateTo={navigateTo} className={cn(className, styles[`${actionClassName}`])} onClick={onClick}>
            <span className={cn(styles.label, actionLabelClassName)}>{label}</span>
            <Icon type={iconType} className={styles.icon} />
        </Action>
    );
};

/* eslint-disable react/require-default-props */

// TODO: Add default props values
ActionButton.defaultProps = {};
