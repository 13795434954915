import { LinkedSource, StaticSource } from 'gql/__generated__/types';
import { uniqBy } from 'lodash';

export const getLinkedAccountsNumber = (accounts: (StaticSource | null)[]) => {
    const preparedAccounts: LinkedSource[] = uniqBy(
        accounts.reduce((acc, curr) => acc.concat(curr?.linkedSources as LinkedSource[]), [] as LinkedSource[]),
        'type',
    );

    return {
        count: preparedAccounts.length,
        preparedAccounts: preparedAccounts,
    };
};
