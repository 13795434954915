import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import { AmountFragmentDoc } from '../../fragments/__generated__/Amount.generated';
import { ProjectionFragmentDoc } from '../../fragments/__generated__/Projection.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRetailersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.RetailerFilter>;
  pagination: Types.Pagination;
  sort?: Types.InputMaybe<Types.Sort>;
}>;


export type GetRetailersQuery = { __typename?: 'Query', getRetailers?: { __typename?: 'RetailerList', limit?: number | null, page?: number | null, total?: number | null, totalAmount?: number | null, retailers?: Array<{ __typename?: 'Retailer', id?: string | null, name?: string | null, totalAmount?: number | null, amount?: Array<{ __typename?: 'Amount', amount?: number | null, currency?: { __typename?: 'Currency', id?: string | null, ticker?: string | null } | null, date?: { __typename?: 'Date', unix?: string | null } | null } | null> | null, projection?: { __typename?: 'Projection', month1?: number | null, month2?: number | null, month3?: number | null, month4?: number | null, month5?: number | null, month6?: number | null, currencyId?: string | null } | null } | null> | null } | null };


export const GetRetailersDocument = gql`
    query getRetailers($filter: RetailerFilter, $pagination: Pagination!, $sort: Sort) {
  getRetailers(filter: $filter, pagination: $pagination, sort: $sort) {
    limit
    page
    total
    totalAmount
    retailers {
      id
      name
      totalAmount
      amount {
        ...Amount
      }
      projection {
        ...Projection
      }
    }
  }
}
    ${AmountFragmentDoc}
${ProjectionFragmentDoc}`;

/**
 * __useGetRetailersQuery__
 *
 * To run a query within a React component, call `useGetRetailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetailersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetRetailersQuery(baseOptions: Apollo.QueryHookOptions<GetRetailersQuery, GetRetailersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRetailersQuery, GetRetailersQueryVariables>(GetRetailersDocument, options);
      }
export function useGetRetailersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRetailersQuery, GetRetailersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRetailersQuery, GetRetailersQueryVariables>(GetRetailersDocument, options);
        }
export type GetRetailersQueryHookResult = ReturnType<typeof useGetRetailersQuery>;
export type GetRetailersLazyQueryHookResult = ReturnType<typeof useGetRetailersLazyQuery>;
export type GetRetailersQueryResult = Apollo.QueryResult<GetRetailersQuery, GetRetailersQueryVariables>;