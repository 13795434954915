export const inputPlaceholder = 'Filter sources by name';
export const noDataText = 'You don’t have any accounts for this data source';
export const noDataButton = 'Add account';
export const categoriesEnum = [
    { type: 'bmi', category: 'pros' },
    { type: 'soundexchange', category: 'pros' },
    { type: 'ingrooves', category: 'distributors' },
    { type: 'symphonic', category: 'distributors' },
    { type: 'warner', category: 'distributors' },
    { type: 'tunecore', category: 'distributors' },
];
export const sourcePollInterval = 5000; // 5 seconds
