import { Account, Product } from 'gql/__generated__/types';
import { EarningsWithAccounts } from 'shared/types';
import { prepareAmountForCharts } from 'shared/utils/prepareAmountForCharts';

export const prepareProduct = (product: Product): EarningsWithAccounts => {
    const { id, amount, name, totalAmount, projection, account } = product;
    const preparedAmount = prepareAmountForCharts(amount) ?? [];

    const checkedAccounts = (account?.filter((acc) => acc) ?? []) as Account[];

    return {
        id: id ?? '',
        name: name ?? '',
        amount: preparedAmount,
        totalAmount: totalAmount ?? 0,
        projection,
        accounts: checkedAccounts,
    };
};

export const prepareProducts = (products: (Product | null)[]): EarningsWithAccounts[] => {
    const filteredProducts = products.filter((product) => product) as Product[];

    return filteredProducts.map(prepareProduct);
};
