import React, { FC } from 'react';

import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Form/components/Input/Input';
import { Notification } from 'components/Form/components/Input/components/Notification/Notification';
import { CancelContinueButtonsBlock } from 'components/Form/components/CancelContinueButtonsBlock/CancelContinueButtonsBlock';
import { Form } from '../Form/Form';

import { ChangePasswordFormProps } from './types';

import {
    changePassText,
    confirmBtnLabel,
    currentPassLabel,
    newPassLabel,
    notificationText,
    saveBtnLabel,
} from './constants';

import commomStyles from '../../Profile.module.scss';

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ onCancel, testId, className }) => (
    <Form data-testid={`${testId}.form`} className={className} onCancel={onCancel}>
        <Heading className={commomStyles['form-heading']}>{changePassText}</Heading>

        <div className={commomStyles['input-wrapper']}>
            <Input
                id="current-password"
                label={currentPassLabel}
                name="current-password"
                className={commomStyles['input-block']}
            />
            <Input id="new-password" label={newPassLabel} name="new-password" className={commomStyles['input-block']} />
            <Notification className={commomStyles.notification}>{notificationText}</Notification>
            <Input
                id="confirm-password"
                label={confirmBtnLabel}
                name="confirm-password"
                className={commomStyles['input-block']}
            />
        </div>

        <CancelContinueButtonsBlock
            data-testid={`${testId}.cancelContinueButtonsBlock`}
            continueButtonLabel={saveBtnLabel}
            onCancel={onCancel}
        />
    </Form>
);
