import { SVGElement } from 'highcharts';
import { throttle } from 'lodash';
import { MIN_HEADER_LABEL_PADDING, THROTTLE_TIME } from '../constants';
import { HeaderLabelParams } from '../interfaces';
import { moveHeaderLabel } from './moveHeaderLabel';

export const adjustProjectedLabelPosition = (label: SVGElement, params: HeaderLabelParams) => {
    const { from, to, plotLineXPosition, chartWidth, chartLeftOffset } = params;
    const labelWidth = label.element.getBoundingClientRect()?.width ?? 0;

    if (to > plotLineXPosition) {
        const headerVisibleWidth =
            chartWidth + chartLeftOffset - (((plotLineXPosition - from) / (to - from)) * chartWidth + chartLeftOffset);

        const minLabelWidth = labelWidth + MIN_HEADER_LABEL_PADDING;

        if (headerVisibleWidth < minLabelWidth) {
            label.hide();
        } else if (from > plotLineXPosition) {
            label.show();
            const pos = (chartWidth + chartLeftOffset) / 2 - labelWidth / 2 + chartLeftOffset / 2;
            moveHeaderLabel(label, pos);
        } else {
            const pos = chartWidth + chartLeftOffset - headerVisibleWidth / 2 - labelWidth / 2;
            label.show();
            moveHeaderLabel(label, pos);
        }
    } else {
        label.hide();
    }
};

export const throttledProjectedLabelPosition = throttle(adjustProjectedLabelPosition, THROTTLE_TIME);
