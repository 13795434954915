import { ApolloError, useApolloClient } from '@apollo/client';
import { Pagination, ProductFilter, Sort } from 'gql/__generated__/types';

import { useCallback, useState } from 'react';
import { EarningsPagination } from 'shared/types';
import { UseGetProductEarningsResponse } from './interfaces';
import { prepareProducts } from '../utils/prepareProduct';
import { useGetProductsLazyQuery, GetProductsDocument } from 'gql/queries/__generated__/getProducts.generated';
import { UseEarningsParams } from '../interfaces';

export const useGetProductEarnings = ({ setEarnings }: UseEarningsParams): UseGetProductEarningsResponse => {
    const [earningsPagination, setEarningsPagination] = useState<EarningsPagination | undefined>(undefined);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<ApolloError | undefined>(undefined);

    const apolloClient = useApolloClient();
    const [getProductEarningsQuery] = useGetProductsLazyQuery({ fetchPolicy: 'cache-first' });

    const getProductEarnings = useCallback(
        async (pagination: Pagination, filter: ProductFilter, sort?: Sort) => {
            setIsLoading(true);
            const options = {
                variables: {
                    pagination,
                    sort,
                    filter,
                },
            };

            // lookup in cache first before requerying api
            let productData = apolloClient.readQuery({
                ...options,
                query: GetProductsDocument,
            });
            if (!productData) {
                const { data, error: productError } = await getProductEarningsQuery(options);
                productData = data;
                setError(productError);
            }

            const products = productData?.getProducts?.products ?? [];
            const preparedProducts = prepareProducts(products);
            const obtainedPagination = {
                limit: productData?.getProducts?.limit ?? 0,
                page: productData?.getProducts?.page ?? 0,
                total: productData?.getProducts?.total ?? 0,
            };
            const total = productData?.getProducts?.totalAmount ?? 0;

            setEarnings(preparedProducts, obtainedPagination.page);
            setEarningsPagination(obtainedPagination);
            setTotalEarnings(total);
            setIsLoading(false);
        },
        [apolloClient, getProductEarningsQuery, setEarnings],
    );

    return {
        isLoading,
        error,
        pagination: earningsPagination,
        totalEarnings,
        getProductEarnings,
    };
};
