import React, { FC } from 'react';
import { emptyLegendAmount, emptyLegendValue } from 'shared/constants';

import variables from 'shared/styles/variables.module.scss';
import styles from '../CustomLegend.module.scss';

export const CustomLegendSkeleton: FC = () => {
    return (
        <>
            <div className={styles['legend-item-title']}>
                <span className={styles['legend-item-marker']} style={{ background: variables.chartFieldColor }} />
                <span className={styles['skeleton-cell-block']} />
            </div>
            <div className={styles['legend-item-value']}>
                <span className={styles['legend-item-amount']}>{emptyLegendAmount}</span>
                <span className={styles['legend-item-percent']}>{emptyLegendValue}</span>
            </div>
        </>
    );
};
