import { CategoryValues } from 'shared/constants';
import { presentCategory } from 'shared/utils/presentCategory';

export const categories = [
    { name: presentCategory(CategoryValues.All, true), value: CategoryValues.All },
    { name: presentCategory(CategoryValues.Distributors, true), value: CategoryValues.Distributors },
    { name: presentCategory(CategoryValues.Royalties, true), value: CategoryValues.Royalties },
    { name: presentCategory(CategoryValues.Merch, true), value: CategoryValues.Merch },
    // { name: 'TOURING', value: CategoryValues.touring },
];
