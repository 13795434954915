import React, { FC } from 'react';
import styles from './AccountRoleCellRenderer.module.scss';
import Dropdown from 'react-dropdown';
import { CellRendererProps } from 'components/Table/types';
import { AccountDropdownOptions, ownerValue } from 'components/TeamMembersList/constants';
import { useGetOwnerMemberInfo } from 'components/TeamMembersList/hooks/useGetOwnerMemberInfo';
import { TeamMemberRoles } from 'gql/__generated__/types';

export const AccountRoleRenderer: FC<CellRendererProps> = ({ value, columnDef, row }) => {
    const teamOwnerTableItem = columnDef.tableItems?.find((item) => item.role === TeamMemberRoles.Owner);

    const ownerMemberInfo = useGetOwnerMemberInfo();

    const currentUserIsTeamOwner =
        teamOwnerTableItem && teamOwnerTableItem.invitationEmail === ownerMemberInfo.invitationEmail;

    const canEditPermission =
        (row.role !== ownerValue && currentUserIsTeamOwner && row.userId) || row.userToCreate || row.newMember;

    return !canEditPermission ? (
        <>
            <span>{value}</span>
        </>
    ) : (
        <Dropdown
            options={AccountDropdownOptions}
            value={row.role}
            onChange={(option) => columnDef.onChange!(row.id, option.value)}
            className={styles.dropdown}
            controlClassName={styles['dropdown-control']}
            placeholderClassName={styles['dropdown-placeholder']}
            menuClassName={styles['dropdown-menu']}
            arrowClassName={styles['dropdown-arrow']}
        />
    );
};
