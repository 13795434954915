import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { pick } from 'lodash';

import { calculateNewTabs } from './utils/calculateNewTabs';
import { checkShouldRestoreToDefault } from './utils/checkShouldRestoreToDefault';
import { amountField, INIT_TABS } from '../constants';
import { IdByTabType } from './types';
import { CategoryValues, GroupByOptions } from 'shared/constants';
import { generatePathname, generatePathnameByBreadcrumbs, parseQueryToSelectTab } from './utils/generatePathname';
import { EarningsPagination, PeriodValues } from 'shared/types';
import { QueryTypes } from './utils/types';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';

export const useTabSelection = (
    state: any,
    pagination: EarningsPagination | undefined,
    onSort: (field: string) => void,
    prevTeamId?: string | null,
) => {
    const navigate = useNavigate();
    const ref = useRef(true);

    const { search, pathname } = useLocation();
    const { setSelectedPeriod, selectedPeriod } = useContext(DateFilterContext);

    const [activeTab, setActiveTab] = useState(GroupByOptions.Source);
    const [displayedTabs, setDisplayedTabs] = useState(INIT_TABS);
    const [tabsWithRelatedEntityId, settabsWithRelatedEntityId] = useState<IdByTabType>({});
    const [savedSearch, setSavedSearch] = useState('');

    const parsedParams = qs.parse(search, { ignoreQueryPrefix: true });

    useEffect(() => {
        const searchCopy = Object.assign({}, parsedParams);
        delete searchCopy.page;

        const strSearch = JSON.stringify(searchCopy);

        const pageRes = parseInt(parsedParams.page as string, 10);
        const page = isNaN(pageRes) ? 1 : pageRes;

        if (strSearch !== savedSearch) {
            setSavedSearch(strSearch);
            if (page !== 1) {
                setTimeout(() => {
                    navigate({
                        pathname,
                        search: qs.stringify({ ...parsedParams, page: 1 }),
                    });
                }, 0);
            }
        }
    }, [navigate, parsedParams, pathname, savedSearch]);

    useEffect(() => {
        const period = parsedParams.selectedPeriod ? (parsedParams.selectedPeriod as PeriodValues) : selectedPeriod;
        setSelectedPeriod!(period);
        if (!parsedParams.q || !parsedParams) {
            return navigate(
                {
                    pathname,
                    search: qs.stringify({
                        q: QueryTypes.AllSources,
                        selectedPeriod: period,
                        category: CategoryValues.All,
                        teamId: prevTeamId,
                    }),
                },
                { replace: true },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFirstRender = () => {
        const firstRender = ref.current;
        ref.current = false;
        return firstRender;
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            const query = qs.parse(search, { ignoreQueryPrefix: true });
            const sort = { sort: state.sort.type };
            const searchQuery = { search: state.search };

            if (parsedParams.q) {
                navigate(
                    {
                        pathname,
                        search: qs.stringify({ ...query, ...sort, ...searchQuery }),
                    },
                    { replace: true },
                );
            }
        }, 500);
        return () => clearTimeout(handler);
    }, [navigate, parsedParams.q, pathname, search, state.search, state.sort]);

    useEffect(() => {
        const query = qs.parse(search, { ignoreQueryPrefix: true });
        const page = parseInt(query.page as string, 10);

        if (isNaN(page) && pagination) {
            const initPage = { page: 1 };
            navigate(
                {
                    pathname,
                    search: qs.stringify({ ...query, ...initPage }),
                },
                { replace: true },
            );
        }
    }, [navigate, pathname, search, pagination]);

    useEffect(() => {
        const tabsWithRelatedEntityIdFromSearch = pick(
            qs.parse(search, { ignoreQueryPrefix: true }),
            Object.keys(GroupByOptions),
        );

        const activeTabEntities = Object.keys(tabsWithRelatedEntityIdFromSearch) as GroupByOptions[];

        const calculatedTabsToDisplay = calculateNewTabs(activeTabEntities, displayedTabs);
        const canShowCurrentTab = calculatedTabsToDisplay.some((tab) => tab === activeTab);

        if (checkShouldRestoreToDefault(tabsWithRelatedEntityIdFromSearch, displayedTabs)) {
            setActiveTab(GroupByOptions.Source);
            setDisplayedTabs(INIT_TABS);
            settabsWithRelatedEntityId({});
            return;
        }

        setDisplayedTabs(calculatedTabsToDisplay);
        settabsWithRelatedEntityId(tabsWithRelatedEntityIdFromSearch);

        const query = parsedParams.q as string;

        if (isFirstRender() && parsedParams.q) {
            return setActiveTab(parseQueryToSelectTab(query)!);
        }

        const checkedActiveTab = query ? parseQueryToSelectTab(query)! : activeTab;
        const selectedTab = canShowCurrentTab ? checkedActiveTab : calculatedTabsToDisplay[0];
        setActiveTab(selectedTab);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        let selectedTabInUrl;
        switch (parsedParams.q) {
            case QueryTypes.AllProducts:
                selectedTabInUrl = GroupByOptions.Product;
                break;
            case QueryTypes.ProductBySource:
                selectedTabInUrl = GroupByOptions.Product;
                break;
            case QueryTypes.ProductByRetailer:
                selectedTabInUrl = GroupByOptions.Product;
                break;
            case QueryTypes.ProductBySourceAndRetailer:
                selectedTabInUrl = GroupByOptions.Product;
                break;
            case QueryTypes.ProductByRetailerAndSource:
                selectedTabInUrl = GroupByOptions.Product;
                break;
            case QueryTypes.AllSources:
                selectedTabInUrl = GroupByOptions.Source;
                break;
            case QueryTypes.SourceByProduct:
                selectedTabInUrl = GroupByOptions.Source;
                break;
            case QueryTypes.SourceByRetailer:
                selectedTabInUrl = GroupByOptions.Source;
                break;
            case QueryTypes.SourceByProductAndRetailer:
                selectedTabInUrl = GroupByOptions.Source;
                break;
            case QueryTypes.RetailerByProduct:
                selectedTabInUrl = GroupByOptions.Retailer;
                break;
            case QueryTypes.RetailerBySource:
                selectedTabInUrl = GroupByOptions.Retailer;
                break;
            case QueryTypes.RetailerByProductAndSource:
                selectedTabInUrl = GroupByOptions.Retailer;
                break;
            case QueryTypes.RetailerBySourceAndProduct:
                selectedTabInUrl = GroupByOptions.Retailer;
                break;
            default:
                selectedTabInUrl = GroupByOptions.Source;
                break;
        }

        setActiveTab(selectedTabInUrl);
    }, [parsedParams.q]);

    const setSelectedTabId = (selectedTabId: string, row: any) => {
        const query = qs.parse(search, { ignoreQueryPrefix: true });
        const { showAllTeams } = query;

        const tabsWithRelatedEntityIdFromSearch = pick(
            qs.parse(search, { ignoreQueryPrefix: true }),
            Object.keys(GroupByOptions),
        );
        const activeTabEntities = [...(Object.keys(tabsWithRelatedEntityIdFromSearch) as GroupByOptions[]), activeTab];
        const calculatedTabsToDisplay = calculateNewTabs(activeTabEntities, displayedTabs);
        const canShowCurrentTab = calculatedTabsToDisplay.some((tab) => tab === activeTab);
        const selectedTab = canShowCurrentTab ? activeTab : calculatedTabsToDisplay[0];

        navigate({
            pathname,
            search: generatePathname(
                tabsWithRelatedEntityId,
                parsedParams,
                selectedTab,
                activeTab,
                prevTeamId as string,
                showAllTeams as string,
                row,
                selectedTabId,
            ),
        });
    };

    const navigateAndSelectTab = (tabName: GroupByOptions) => {
        onSort(amountField);
        const query = qs.parse(search, { ignoreQueryPrefix: true });
        const { teamId, showAllTeams } = query;

        navigate({
            pathname,
            search: generatePathname(
                tabsWithRelatedEntityId,
                parsedParams,
                tabName,
                activeTab,
                teamId as string,
                showAllTeams as string,
            ),
        });
        setActiveTab(tabName);
    };

    const setTabInBreadcrumbs = () => {
        const tabsWithRelatedEntityIdFromSearch = qs.parse(search.slice(1));
        const { url, selectedTab } = generatePathnameByBreadcrumbs(tabsWithRelatedEntityIdFromSearch);

        navigate({
            pathname,
            search: url,
        });

        setActiveTab(selectedTab as GroupByOptions);
    };

    const setTabId = (tabName: string, tabId: string) => {
        const newSearch = qs.parse(search.slice(1));

        navigate({
            pathname,
            search: qs.stringify({ ...newSearch, [tabName]: tabId }),
        });
    };

    const unsetAllTabIds = () => {
        navigate({
            pathname,
            search: qs.stringify({
                q: QueryTypes.AllSources,
                selectedPeriod: selectedPeriod,
                teamId: prevTeamId,
                category: CategoryValues.All,
            }),
        });
        setDisplayedTabs(INIT_TABS);
        settabsWithRelatedEntityId({});
        setActiveTab(GroupByOptions.Source);
    };

    return {
        unsetAllTabIds,
        setTabId,
        setTabInBreadcrumbs,
        setSelectedTabId,
        navigateAndSelectTab,
        displayedTabs,
        activeTab,
        tabsWithRelatedEntityId,
    };
};
