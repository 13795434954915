import { useGetMonthlyTotalsQuery } from 'gql/queries/__generated__/getMonthlyTotals.generated';
import { useMemo } from 'react';
import { useGetEarningFilters } from '../useGetEarningFilters/useGetEarningFilters';

export const useGetMonthlyTotals = () => {
    const { date, productId, retailerId, teamId, showAllTeams, category, sourceId } = useGetEarningFilters();
    const { data, loading, error } = useGetMonthlyTotalsQuery({
        variables: {
            filter: {
                startDate: date.start,
                productId,
                retailerId,
                teamId,
                showAllTeams,
                category,
                sourceType: sourceId,
            },
        },
    });

    const preparedMonthlyTotals = useMemo(
        () =>
            data?.getMonthlyTotals?.reduce(
                (obj, item) => {
                    const key = Number(item?.unix ?? 0);
                    const value = item?.total;

                    return {
                        ...obj,
                        [key]: value,
                    };
                },
                {} as {
                    [x: string]: number;
                },
            ),
        [data?.getMonthlyTotals],
    );

    return { monthlyTotals: preparedMonthlyTotals ?? {}, isMonthlyTotalsLoading: loading, montlyTotalsError: error };
};
