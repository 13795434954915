import { startOfYear, startOfMonth, subMonths, toDate } from 'date-fns';
import { PeriodValues } from 'shared/types';

export const getSelectedPeriodDates = (selectedPeriod: PeriodValues) => {
    const dateFromAndToBySelectedPeriod = {
        [PeriodValues.allTime]: {
            dateFrom: toDate(0),
            dateTo: toDate(Date.now()),
        },
        [PeriodValues.lastThreeMonths]: {
            dateFrom: toDate(startOfMonth(subMonths(Date.now(), 2))),
            dateTo: toDate(Date.now()),
        },
        [PeriodValues.thisYear]: {
            dateFrom: toDate(startOfYear(Date.now())),
            dateTo: toDate(Date.now()),
        },
    };
    return dateFromAndToBySelectedPeriod[selectedPeriod];
};
