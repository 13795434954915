import React, { FC } from 'react';
import { CloseButton } from 'components/CloseButton/CloseButton';
import styles from './AccountTeamsHeader.module.scss';
import { AccountTeamsHeaderProps } from './types';
import { defaultTitle } from './constants';
export const AccountTeamsHeader: FC<AccountTeamsHeaderProps> = (props) => {
    const { onClose, editMode, teamName, testId } = props;

    return (
        <div className={styles.container}>
            <h3 className={styles.teamName} data-testid={`${testId}.teamName`}>
                {editMode ? teamName : defaultTitle}
            </h3>
            <CloseButton data-testid={`${testId}.collapseButton`} className={styles.button} onCancel={onClose} />
        </div>
    );
};
