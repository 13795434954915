import { DataSourcesIdsListType, DataSourceTableMemberType } from 'shared/types';

export const filterDataSourceMembers = (members: DataSourceTableMemberType[], memberId: string) => {
    return [...members].map((member: DataSourceTableMemberType) => {
        if (member.id === memberId) {
            return {
                ...member,
                checked: !member.checked,
            };
        }
        return member;
    });
};
const findChangedMember = (members: DataSourceTableMemberType[], memberId: string) =>
    members.find((member: DataSourceTableMemberType) => member.id === memberId);
export const removeDataSources = (
    members: DataSourceTableMemberType[],
    memberId: string,
    dataSourcesToDelete: DataSourcesIdsListType,
) => {
    const memberChanged = findChangedMember(members, memberId);

    if (memberChanged && memberChanged!.wasPreviouslyCreated && memberChanged!.checked)
        return [...dataSourcesToDelete, memberChanged];
    if (memberChanged!.wasPreviouslyCreated && !memberChanged!.checked)
        return dataSourcesToDelete.filter((dataSource: DataSourceTableMemberType) => dataSource.id !== memberId);
    return dataSourcesToDelete;
};

export const addDataSources = (
    members: DataSourceTableMemberType[],
    memberId: string,
    dataSourcesToCreate: DataSourcesIdsListType,
) => {
    const memberChanged = findChangedMember(members, memberId);

    if (memberChanged && !memberChanged!.wasPreviouslyCreated && !memberChanged!.checked) {
        return [...dataSourcesToCreate, memberChanged];
    }
    if (!memberChanged!.wasPreviouslyCreated && memberChanged!.checked)
        return dataSourcesToCreate.filter((dataSource: DataSourceTableMemberType) => dataSource.id !== memberId);
    return dataSourcesToCreate;
};

export const removeDataSourcesOnDelete = (
    members: DataSourceTableMemberType[],
    memberId: string,
    dataSourcesToDelete: DataSourcesIdsListType,
    dataSourcesToCreate: DataSourcesIdsListType,
) => {
    const memberChanged = findChangedMember(members, memberId);
    if (memberChanged && memberChanged!.wasPreviouslyCreated) return [...dataSourcesToDelete, memberChanged];
    return dataSourcesToCreate.filter((dataSource: DataSourceTableMemberType) => dataSource.id !== memberId);
};

export const getDataSourcesToDelete = (
    members: DataSourceTableMemberType[],
    memberId: string,
    dataSourcesToDelete: DataSourcesIdsListType,
) => {
    const memberChanged = findChangedMember(members, memberId);
    const sourceAlreadyInDeleteList = dataSourcesToDelete.findIndex((src) => src.id === memberId) >= 0;
    if (memberChanged && memberChanged!.wasPreviouslyCreated && !sourceAlreadyInDeleteList) {
        return [...dataSourcesToDelete, memberChanged];
    }
    return dataSourcesToDelete;
};

export const getDataSourcesToCreateOnDelete = (memberId: string, dataSourcesToCreate: DataSourcesIdsListType) => {
    return dataSourcesToCreate.filter((dataSource: DataSourceTableMemberType) => dataSource.id !== memberId);
};
