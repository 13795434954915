export const headingByCategory: { [key: string]: { title: string; position: number } } = {
    distributors: {
        title: 'Distributors',
        position: 0,
    },
    royalties: {
        title: 'Performing & Neighbouring Rights (Royalties)',
        position: 1,
    },
    merch: {
        title: 'Merch',
        position: 2,
    },
};
