import { Account, Source } from 'gql/__generated__/types';
import { sumBy } from 'lodash';
import { EarningsWithAccounts } from 'shared/types';

const prepareSource = (source: Source): EarningsWithAccounts => {
    const { amount, category, type, name, account, projection } = source;

    const mappedAmount = amount!.map((amountItem) => {
        return {
            amount: amountItem?.amount ?? 0,
            date: amountItem?.date?.unix ?? '',
        };
    });

    const checkedAccounts = (account?.filter((acc) => acc) ?? []) as Account[];

    return {
        id: type ?? '',
        name: name ?? '',
        amount: mappedAmount,
        category,
        totalAmount: sumBy(checkedAccounts, 'totalAmount') ?? 0,
        accounts: checkedAccounts,
        projection,
    };
};

export const prepareSources = (source: (Source | null)[]): EarningsWithAccounts[] => {
    const filteredSource = source.filter((account) => account) as Source[];
    return filteredSource.map(prepareSource);
};
