import React, { FC } from 'react';
import cn from 'classnames';

import { SharedLabel } from 'components/SharedLabel/SharedLabel';
import { CellRendererProps } from '../../../types';

import styles from '../../../Table.module.scss';

export const SourceCellRenderer: FC<CellRendererProps> = ({ row, columnDef }) => {
    const isClickable = (rowObject: { [key: string]: any }) => {
        return typeof columnDef.clickable === 'boolean' ? columnDef.clickable : columnDef.clickable!(rowObject);
    };

    return (
        <span className={styles[`source-label`]}>
            <span
                data-testid="sourcecellrenderer"
                onClick={() => {
                    return isClickable(row) && columnDef.onClick!(row.id, row);
                }}
                className={cn(isClickable(row) ? styles['source-cell-clickable'] : styles['source-cell'], {
                    [styles['skeleton-cell-block']]: !row.name,
                })}
            >
                {row[columnDef.field]}
            </span>
            {row.shared && <SharedLabel className={styles['shared-label']} />}
        </span>
    );
};
