import React, { FC } from 'react';

import cn from 'classnames';

import { CellRendererProps } from '../../../types';

import { SharedLabel } from 'components/SharedLabel/SharedLabel';

import styles from './ChildSourceCellRenderer.module.scss';

export const ChildSourceCellRenderer: FC<CellRendererProps> = ({ row, columnDef }) => {
    return (
        <div className={cn(styles[`${columnDef.field}-expanded-row`])}>
            <div className={styles.sourcesContainer}>
                {/* {row[columnDef.field].map((item: string) => (
                    <div className={styles.itemContainer} key={item}>
                        <div className={styles.userEmailItem}>{item}</div>
                    </div>
                ))} */}
                {row[columnDef.field]}
            </div>
            {row.shared && <SharedLabel className={cn(styles.sharedLabel)} />}
        </div>
    );
};
