import React, { FC } from 'react';

import { CustomLegendNameProps } from '../types';

import styles from '../CustomLegend.module.scss';
import { categories } from 'pages/Earnings/components/CategorySelectionButtons/constants';
import { presentCategory } from 'shared/utils/presentCategory';
import { CategoryValuesType } from 'shared/types';

export const CustomLegendName: FC<CustomLegendNameProps> = ({ testId, color, legendName, linkTo }) => {
    return (
        <>
            {categories.map(({ name, value }) => {
                if (legendName === value) {
                    return (
                        <div className={styles['legend-item-title']} key={name}>
                            <span className={styles['legend-item-marker']} style={{ background: color }} />
                            <span
                                data-testid={`${testId}.customLegendName`}
                                className={styles['legend-item-name']}
                                onClick={() => linkTo(value)}
                            >
                                {presentCategory(legendName as CategoryValuesType)}
                            </span>
                        </div>
                    );
                }
            })}
        </>
    );
};
