import React, { FC } from 'react';
import Dropdown from 'react-dropdown';
import cn from 'classnames';
import { CellRendererProps } from 'components/Table/types';
import styles from './DataSourceSelectRenderer.module.scss';
import { DataSourceTableMemberType } from 'shared/types';
import { useGetDropdownOptions } from 'components/DataSourceTeamList/hooks/useGetDropdownOptions';

export const DataSourceSelectRenderer: FC<CellRendererProps> = (props) => {
    const { row, columnDef } = props;

    const currentOptionValue = row.name ? { value: row.name, label: row.name } : '';
    const dataSourcePreviouslyCreated = row?.members?.some(
        (member: DataSourceTableMemberType) => member.wasPreviouslyCreated,
    );

    const dropdownOptions = useGetDropdownOptions(columnDef.tableItems, columnDef.suggestedUsersList);
    const dropdownOptionsMapped = dropdownOptions.map((option) => ({
        value: option.name,
        label: option.name,
        className: styles['dropdown-option'],
    }));

    const isCreated = dataSourcePreviouslyCreated || columnDef.isViewer;

    return (
        <div className={styles.sourcesListWrapper}>
            <Dropdown
                value={currentOptionValue}
                options={dropdownOptionsMapped}
                className={styles.dropdown}
                controlClassName={cn(styles['dropdown-control'], { [styles.disabled]: isCreated })}
                placeholderClassName={cn(styles['dropdown-placeholder'], styles['dropdown-placeholder-light'], {
                    [styles.disabled]: isCreated,
                })}
                menuClassName={styles['dropdown-menu']}
                arrowClassName={cn(styles['dropdown-arrow'], { [styles.disabled]: isCreated })}
                onChange={(option) => columnDef.onChange!(row.rowId, option.value)}
                disabled={isCreated}
            />
        </div>
    );
};
