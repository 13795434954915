import { ApolloError, useApolloClient } from '@apollo/client';
import { useGetSourcesLazyQuery, GetSourcesDocument } from 'gql/queries/__generated__/getSources.generated';
import { sumBy } from 'lodash';

import { useCallback, useState } from 'react';
import { UseEarningsParams } from '../interfaces';
import { prepareSources } from '../utils/prepareSource';
import { EnhancedSourceFilter, UseGetSourceEarningsResponse } from './interfaces';
import { filterSourceEarnings } from './utils/filterSourceEarnings';

export const useGetSourceEarnings = ({ setEarnings }: UseEarningsParams): UseGetSourceEarningsResponse => {
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<ApolloError | undefined>(undefined);

    const apolloClient = useApolloClient();
    const [getSourceEarningsQuery] = useGetSourcesLazyQuery();

    const getSourceEarnings = useCallback(
        async (filter: EnhancedSourceFilter) => {
            setIsLoading(true);
            const options = {
                variables: {
                    filter: {
                        date: filter.date,
                        productId: filter.productId,
                        retailerId: filter.retailerId,
                        showAllTeams: filter.showAllTeams,
                        teamId: filter.teamId,
                    },
                },
            };

            // lookup in cache first before requerying api
            let sourceData = apolloClient.readQuery({
                ...options,
                query: GetSourcesDocument,
            });
            if (!sourceData) {
                const { data, error: sourceError } = await getSourceEarningsQuery(options);
                sourceData = data;
                setError(sourceError);
            }

            const sources = sourceData?.getSources ?? [];
            const preparedSources = prepareSources(sources);
            const filteredSources = filterSourceEarnings(preparedSources, filter);
            const total = sumBy(filteredSources, 'totalAmount');
            setEarnings(filteredSources, 1);
            setTotalEarnings(total);
            setIsLoading(false);
        },
        [apolloClient, getSourceEarningsQuery, setEarnings],
    );

    return {
        isLoading,
        error,
        totalEarnings,
        getSourceEarnings,
    };
};
