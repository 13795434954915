import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { LinkItemProps } from './types';

import styles from './LinkItem.module.scss';

export const LinkItem: FC<LinkItemProps> = ({ className, style, to, children, isLink = true, onClick }) =>
    isLink ? (
        <Link style={style} to={to} className={cn(styles.link, className)}>
            {children}
        </Link>
    ) : (
        <a href="/" onClick={onClick} className={cn(styles.link, className)} style={style}>
            {children}
        </a>
    );
