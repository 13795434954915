import React from 'react';
import { percentSign } from 'shared/constants';
import { CategoryValuesType } from 'shared/types';
import { presentCategory } from 'shared/utils/presentCategory';

import styles from './BiggestEarningSource.module.scss';
import { numberOne } from './constants';
import { BiggestEarningSourceProps } from './types';

export const BiggestEarningSource = (props: BiggestEarningSourceProps) => {
    const { percent, name, testId } = props;

    return (
        <div className={styles['number-one-wrapper']}>
            <span data-testid={`${testId}.numberOne`} className={styles['number-one']}>
                {numberOne}
            </span>
            <span data-testid={`${testId}.sourcePercent`} className={styles['source-percent']}>
                {percent.toFixed(0)}
                {percentSign}
            </span>
            <button data-testid={`${testId}.sourceName`} className={styles['source-name']}>
                {presentCategory(name as CategoryValuesType, true)}
            </button>
        </div>
    );
};
