import React, { FC } from 'react';
import cn from 'classnames';

import { CardProps } from './types';

import styles from './Card.module.scss';

export const Card: FC<CardProps> = ({ className, style, children }) => {
    return (
        <div className={cn(styles['card-block'], className)} style={style}>
            <div className={styles['card-container']}>{children}</div>
        </div>
    );
};
