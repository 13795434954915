import React, { FC } from 'react';
import cn from 'classnames';

import { HeadingProps } from './types';

import styles from './Heading.module.scss';

export const Heading: FC<HeadingProps> = ({ className, children }) => (
    <h2 className={cn(styles.heading, className)}>{children}</h2>
);
