import { GroupByOptions, CategoryValues } from 'shared/constants';
import { CategoryValuesType } from 'shared/types';

export enum TabValues {
    sources = 'sources',
    retailers = 'retailers',
    products = 'products',
}

export type CategoryValuesString = keyof typeof CategoryValues;

export type WithCategorySelectionPropsType = {
    selectedCategory: CategoryValuesType;
    setSelectedCategory: (selectedCategory: CategoryValuesType) => void;
};

export type IdByTabType = {
    [key in GroupByOptions]?: any;
};
