import React, { FC, useContext, useEffect, useState } from 'react';
import FuzzySearch from 'fuzzy-search';

import { PageHeading } from 'components/PageHeading/PageHeading';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { DataSourceList } from './components/DataSourceList/DataSourceList';
import { inputPlaceholder } from './constants';
import { LoaderComponent } from 'components/Loader/Loader';
import { name } from 'shared/constants';
import styles from './DataSources.module.scss';
import commonStyles from '../CommonStyles.module.scss';
import { PeriodValues, StaticSource } from 'shared/types';
import { useLinkedSources } from 'shared/hooks/useLinkedSources/useLinkedSources';
import { TeamsFilterContext } from 'shared/providers/TeamsFilterProvider/TeamsFilterProvider';
import { DateFilterContext } from 'shared/providers/DateFilterProvider/DateFilterProvider';
import { NetworkStatus } from '@apollo/client';

export const DataSources: FC = () => {
    const [state, updateState] = useState<string>('');
    const { data, networkStatus } = useLinkedSources(true);
    const { updateSelectedTeam } = useContext(TeamsFilterContext);
    const { setSelectedPeriod } = useContext(DateFilterContext);

    useEffect(() => {
        setSelectedPeriod!(PeriodValues.allTime);
        updateSelectedTeam('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const linkedAccounts = data?.linkedSources?.filter((linkedSource) => linkedSource) ?? [];

    const filterSources = (sourcesProps: ReadonlyArray<any>) => {
        const t = new FuzzySearch(sourcesProps as [], [name]);

        return t.search(state);
    };

    const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch;

    return (
        <>
            {loading ? (
                <div className={styles.loader}>
                    <LoaderComponent />
                </div>
            ) : (
                <div className={commonStyles['main-container']}>
                    <div className={styles.heading}>
                        <PageHeading>Data Sources</PageHeading>
                        <SearchInput
                            testId="searchInput"
                            value={state}
                            className={styles['search-input']}
                            placeholder={inputPlaceholder}
                            onChange={(event) => updateState(event.target.value)}
                        />
                    </div>
                    <DataSourceList
                        testId="dataSourceList"
                        sources={filterSources(linkedAccounts)}
                        linkedAccounts={linkedAccounts as StaticSource[]}
                    />
                </div>
            )}
        </>
    );
};
