import React, { FC } from 'react';
import cn from 'classnames';
import Dropdown from 'react-dropdown';

import { ChartTypeSelectorProps } from './types';

import styles from './ChartTypeSelector.module.scss';
import { dropdownChartSelectorPlaceholder, dropdownChartTypeSelectorOptions } from '../../constants';

export const ChartTypeSelector: FC<ChartTypeSelectorProps> = ({ chartType, updateChartType, testId, isDisabled }) => {
    return (
        <Dropdown
            data-testid={`${testId}.container`}
            options={dropdownChartTypeSelectorOptions}
            placeholder={dropdownChartSelectorPlaceholder}
            className={styles.dropdown}
            controlClassName={cn(styles['dropdown-control'], { [styles.disabled]: isDisabled })}
            placeholderClassName={cn(styles['dropdown-placeholder'], { [styles.disabled]: isDisabled })}
            menuClassName={styles['dropdown-menu']}
            arrowClassName={cn(styles['dropdown-arrow'], { [styles.disabled]: isDisabled })}
            value={String(chartType)}
            onChange={(option) => updateChartType?.(Number(option.value))}
            disabled={isDisabled}
        />
    );
};
