import * as Types from 'gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportEarningsMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  filter?: Types.InputMaybe<Types.ExportEarningsFilter>;
}>;


export type ExportEarningsMutation = { __typename?: 'Mutation', exportEarnings?: boolean | null };


export const ExportEarningsDocument = gql`
    mutation exportEarnings($email: String!, $filter: ExportEarningsFilter) {
  exportEarnings(email: $email, filter: $filter)
}
    `;
export type ExportEarningsMutationFn = Apollo.MutationFunction<ExportEarningsMutation, ExportEarningsMutationVariables>;

/**
 * __useExportEarningsMutation__
 *
 * To run a mutation, you first call `useExportEarningsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportEarningsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportEarningsMutation, { data, loading, error }] = useExportEarningsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportEarningsMutation(baseOptions?: Apollo.MutationHookOptions<ExportEarningsMutation, ExportEarningsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportEarningsMutation, ExportEarningsMutationVariables>(ExportEarningsDocument, options);
      }
export type ExportEarningsMutationHookResult = ReturnType<typeof useExportEarningsMutation>;
export type ExportEarningsMutationResult = Apollo.MutationResult<ExportEarningsMutation>;
export type ExportEarningsMutationOptions = Apollo.BaseMutationOptions<ExportEarningsMutation, ExportEarningsMutationVariables>;