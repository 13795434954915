import FuzzySearch from 'fuzzy-search';
import { name } from 'shared/constants';
import { EarningsWithAccounts } from 'shared/types';

export const filterSourceEarningsByName = (
    sources: EarningsWithAccounts[],
    searchName: string,
): EarningsWithAccounts[] => {
    const t = new FuzzySearch(sources, [name]);

    return t.search(searchName);
};
