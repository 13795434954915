import { Earnings, EarningsWithAccounts } from 'shared/types';
import { presentCategory } from 'shared/utils/presentCategory';
import { TableEarnings } from '../interfaces';

export const prepareForTable = (sources: (Earnings | EarningsWithAccounts)[]): TableEarnings[] =>
    sources.map((source) => {
        const { totalAmount, id, name } = source;

        const preparedSource: TableEarnings = {
            amount: totalAmount.toString(),
            id: id ?? '',
            name,
        };

        if ('category' in source) {
            preparedSource.category = source.category && presentCategory(source.category, true);
        }

        if ('accounts' in source) {
            preparedSource.accounts = source.accounts;
        }

        return preparedSource;
    });
