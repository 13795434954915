import { without } from 'lodash';
import { GroupByOptions } from 'shared/constants';

export const calculateNewTabs = (
    activatedTabs: GroupByOptions[],
    displayedTabs: GroupByOptions[],
): GroupByOptions[] => {
    if (!activatedTabs.length && displayedTabs.length) {
        return displayedTabs;
    }

    return without(Object.keys(GroupByOptions), ...activatedTabs) as GroupByOptions[];
};
