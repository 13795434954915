import React, { FC } from 'react';
import cn from 'classnames';

import { SharedLabelProps } from './types';

import styles from './SharedLabel.module.scss';
import { sharedText } from './constants';

export const SharedLabel: FC<SharedLabelProps> = ({ className }) => (
    <span className={cn(styles.label, className)}>{sharedText}</span>
);
