import React, { createContext, FC, useEffect, useState } from 'react';
import * as AuthService from 'shared/services/AuthService';
import { Routes } from 'shared/constants';
import { AuthContextParams } from './interfaces';

export const AuthContext = createContext<AuthContextParams>({
    isUserAuthenticated: false,
    authenticateUser: () => {},
    unauthenticateUser: () => {},
});

export const AuthProvider: FC = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const pathname = window.location.pathname;

    const token = AuthService.getToken();

    useEffect(() => {
        const isNotLoggedIn = !isAuthenticated && !token;
        if (isNotLoggedIn && pathname !== `${Routes.auth}`) {
            AuthService.getLoginPage();
        } else if (token && !isAuthenticated) {
            setIsAuthenticated(true);
        }
    }, [isAuthenticated, pathname, token]);

    function handleAuthenticateUser() {
        setIsAuthenticated(true);
    }

    function handleUnauthenticateUser() {
        setIsAuthenticated(true);
    }

    return (
        <AuthContext.Provider
            value={{
                isUserAuthenticated: isAuthenticated,
                authenticateUser: handleAuthenticateUser,
                unauthenticateUser: handleUnauthenticateUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
