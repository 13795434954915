import React, { FC } from 'react';
import { CellRendererProps } from '../../../types';
import styles from '../../../Table.module.scss';
import { LinkedSource } from 'shared/types';
import { getLastUpdatedDateText } from '../../../../../shared/utils/getDate';

export const DataSourceLastUpdateRenderer: FC<CellRendererProps> = ({ columnDef, value, isEmpty, row }) => {
    const currentRow = row as LinkedSource;
    const timestamp = Number(currentRow?.lastParsing?.unix);
    const lastUpdate = timestamp ? getLastUpdatedDateText(new Date(timestamp)) : '';
    return isEmpty && !value ? (
        <span className={styles['skeleton-cell-block']} />
    ) : (
        <>
            <span className={styles[`${columnDef.field}-label`]}>{lastUpdate}</span>
        </>
    );
};
