import { useEffect, useState } from 'react';
import { useGetLinkedSourcesQuery } from 'gql/queries/__generated__/getLinkedSources.generated';
import { defaultPollingInterval } from 'shared/constants';
import { isGetLinkedSourcesQueryProcessing } from 'shared/utils/isProcessing';

export const useLinkedSources = (poll: boolean = false, sourceId?: string) => {
    const [shouldPoll, setShouldPoll] = useState(false);
    const result = useGetLinkedSourcesQuery({
        pollInterval: shouldPoll ? defaultPollingInterval : 0,
        notifyOnNetworkStatusChange: true,
    });
    const { data } = result;

    useEffect(() => {
        setShouldPoll(
            poll &&
                isGetLinkedSourcesQueryProcessing(
                    sourceId
                        ? { ...data, linkedSources: data?.linkedSources?.filter((s) => s?.id === sourceId) }
                        : data,
                ),
        );
    }, [data, poll, sourceId]);

    return result;
};
