import React from 'react';
import { LoaderComponent } from 'components/Loader/Loader';
import { useAcceptTeamInvitationMutation } from 'gql/mutations/__generated__/acceptTeamInvitation.generated';
import { useValidateTeamInvitationQuery } from 'gql/queries/__generated__/validateTeamInvitation.generated';
import { FC, useCallback, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'shared/providers/AuthProvider/AuthProvider';
import { invitationIsInvalid, InvitationStatus } from './constants';
import { TeamsContext } from 'pages/Teams/providers/TeamsProvider';

import styles from './InvitationPage.module.scss';

export const InvitationPage: FC = () => {
    const { setInviter } = useContext(TeamsContext);
    const { isUserAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [acceptTeamInvitationMutation, { loading: isAcceptInvitationLoading }] = useAcceptTeamInvitationMutation();
    const params = useParams();
    const { id, status } = params;

    const { data: validateInvitationData, loading: isValidateInvitationLoading } = useValidateTeamInvitationQuery({
        variables: {
            invitationId: id ?? '',
        },
    });

    const isInvitationIdValid = validateInvitationData?.validateTeamInvitation?.isValid;
    const inviterName = validateInvitationData?.validateTeamInvitation?.senderName;
    const inviterSurname = validateInvitationData?.validateTeamInvitation?.senderSurname;

    const isLoading = isAcceptInvitationLoading || isValidateInvitationLoading;

    const onAccept = useCallback(async () => {
        await acceptTeamInvitationMutation({ variables: { invitationId: id!, accept: true } });
        navigate({ pathname: '/teams' });
    }, [acceptTeamInvitationMutation, id, navigate]);
    const onDecline = useCallback(() => {
        setInviter({ inviterName, inviterSurname });
        localStorage.setItem('inviteToTeam', id as string);
        navigate({ pathname: '/teams' });
    }, [id, navigate, inviterName, inviterSurname, setInviter]);

    useEffect(() => {
        if (isInvitationIdValid && !isValidateInvitationLoading) {
            if (status === InvitationStatus.ACCEPT) {
                onAccept();
            }

            if (status === InvitationStatus.DECLINE) {
                onDecline();
            }
        }
    }, [isInvitationIdValid, isValidateInvitationLoading, onAccept, onDecline, status]);

    return (
        <>
            {isLoading || !isUserAuthenticated ? (
                <div className={styles.loader}>
                    <LoaderComponent />
                </div>
            ) : (
                <div className={styles.pageContainer}>{!isInvitationIdValid && <span>{invitationIsInvalid}</span>}</div>
            )}
        </>
    );
};
