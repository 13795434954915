import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ButtonProps, ButtonState } from './types';

import { styleTypes } from 'shared/constants';

import styles from './Button.module.scss';
import { LoaderComponent } from 'components/Loader/Loader';

export class Button extends React.Component<ButtonProps, ButtonState> {
    constructor(props: ButtonProps) {
        super(props);
        // TODO: Check what the property for
        /* eslint-disable react/no-unused-state */
        this.state = { isActive: true };
    }

    render() {
        const { isLink, to, style, className, children, type, onClick, isDisabled, testId, isLoading } = this.props;
        if (isLink) {
            return (
                <Link
                    to={to || ''}
                    className={cn(
                        styles['button-default'],
                        {
                            [styles[`button-${style}`]]: style !== styleTypes.secondary,
                            [styles.disabled]: isDisabled,
                        },
                        className,
                    )}
                >
                    {children}
                </Link>
            );
        }
        return (
            <button
                className={cn(
                    styles['button-default'],
                    {
                        [styles[`button-${style}`]]: style !== styleTypes.secondary,
                    },
                    className,
                )}
                disabled={isDisabled}
                // TODO: Specify the type for the button
                /* eslint-disable react/button-has-type */
                type={type}
                onClick={onClick}
                data-testid={testId}
            >
                {isLoading ? <LoaderComponent height={20} width={20} /> : children}
            </button>
        );
    }
}
