import React, { FC } from 'react';

import { Breadcrumbs as SharedBreadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';

import { BreadcrumbsProps } from './types';
//import { calculateEarningsGroupByVariables } from 'pages/Earnings/utils/calculateGroupByVariables';
import { GroupByOptions } from 'shared/constants';

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
    const { tabsWithRelatedEntityId, setTabId, setTabInBreadcrumbs, unsetAllTabIds, firstLevelBreadcrumbTitle } = props;

    const tabNames = Object.keys(tabsWithRelatedEntityId) as GroupByOptions[];

    //TODO return breadcrumbs fetching ARTIE-1091

    //const { groupByFirst, groupBySecond } = calculateEarningsGroupByVariables(tabNames);

    // /** TODO: figure out when it's needed to apply second set of ids */
    // const { data: breadcrumbsData } = useGetBreadcrumbsQuery({
    //     variables: {
    //         groupBy: groupByFirst,
    //         productId: tabsWithRelatedEntityId[GroupByOptions.Product],
    //         retailerId: tabsWithRelatedEntityId[GroupByOptions.Retailer],
    //         sourceId: tabsWithRelatedEntityId[GroupByOptions.Source],
    //         groupBy2: groupBySecond,
    //     },
    // });

    const firstLevelEntries: any[] = [];
    const secondLevelEntries: any[] = [];

    const entitiesByIndex = [firstLevelEntries, secondLevelEntries];

    const idByName = tabNames.map((key, index) => {
        const entityId = tabsWithRelatedEntityId[key] ?? '';
        return {
            entityId,
            tabName: key,
            entityName: entitiesByIndex[index].find((i: any) => i.id === entityId)?.name ?? null,
        };
    });

    const options = tabNames.length === 2 ? secondLevelEntries : firstLevelEntries;

    return (
        <SharedBreadcrumbs
            idByName={idByName}
            options={options}
            setTabId={setTabId}
            setTabInBreadcrumbs={setTabInBreadcrumbs}
            unsetAllTabIds={unsetAllTabIds}
            firstLevelBreadcrumbTitle={firstLevelBreadcrumbTitle}
        />
    );
};
